// @ts-nocheck
/* eslint-disable */
import { Button } from "components/ShadcnComponents/button"
import React from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { ADMIN_DOCUSEAL_CONFIGS } from "routes"

const DocusealModelContent = () => {
  const userObject = useSelector((state) => state.authReducer.userObject)
  const history = useHistory()
  return (
    <div className="flex flex-col gap-4 justify-center text-center items-center max-w-[600px]">
      <div className="text-2xl font-semibold text-center">
        DocuSeal Configuration Needed
      </div>

      {userObject?.userType === "SalesRep" ? (
        <p>
          It looks like the DocuSeal configuration is incomplete. Please reach
          out to the admin to ensure the API URL and other necessary settings
          are properly configured to enable full functionality.
        </p>
      ) : (
        <>
          <p>
            Your DocuSeal configuration is not set up yet. To use DocuSeal
            features, please configure your API url and other necessary
            settings.
          </p>
          <div className="flex justify-center items-center gap-2">
            <Button onClick={() => history.push(ADMIN_DOCUSEAL_CONFIGS)}>
              Configure DocuSeal
            </Button>
          </div>
        </>
      )}
    </div>
  )
}

export default DocusealModelContent
