// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from "react"
import ReportConfig from "../components/ReportConfig"
import { getAllWebsiteConfig } from "store/actions/websiteReportConfigActions"
import { useSelector } from "react-redux";

const WebsiteAnalyticsReportConfig = () => {
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [analyticsData, setAnalyticsData] = useState(null)

  const getData = async () => {
    try {
      await getAllWebsiteConfig("analytics",projectId)
        .then((response) => setAnalyticsData(response))
        .catch((error) => console.error(error))
    } catch (error) {
      console.error("Error fetching data:", error)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <div>
      <ReportConfig
        title="Analytics Report Configs"
        configData={analyticsData}
        type="analytics"
        resetAllData={getData}
        projectId={projectId}
      />
    </div>
  )
}

export default WebsiteAnalyticsReportConfig
