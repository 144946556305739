/* eslint-disable space-before-function-paren */
import { Code, CodeIcon, LinkIcon } from 'lucide-react'
import pretty from 'pretty'
import React, { useEffect } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { ALL_CUSTOM_FIELDS, getDemandNoteFieldSetting } from './edit/components/setting'
import useProjectId from 'hooks/useProjectId'
import { Drawer } from 'antd'
import { getCorporatDemandNotes, GetDemandNotes } from 'store/actions/demandNotes'
import { getCustomForm } from 'store/actions/customFormActions'
import FormSettingsDrawer from './formSettings'

export default function FormActionButtons({ form }) {
  const { projectId } = useProjectId()
  const publicLink = `${window.location.origin}/forms/${form._id}`
  const iframe = `<iframe src="${publicLink}" style="display: flex;height: 100vh;width: 100vw;padding: 0 !important; margin: 0 !important;"></iframe>`
  return <div className='flex items-center justify-center gap-x-2'>
    <CopyToClipboard
      text={publicLink} onCopy={() => {
        alert(`Public Form Link copied to clipboard \n\n ${publicLink} \n\n`)
      }}>
      <div
        title='Copy Public Link'
        className='bg-black font-normal rounded-full border border-border text-white px-2 py-1 text-sm flex items-center justify-center gap-x-1 cursor-pointer'>
        <LinkIcon size={14} /> Copy
      </div>
    </CopyToClipboard>
    <CopyToClipboard
      text={iframe} onCopy={() => {
        alert(`iframe \n\n ${iframe} \n\n`)
      }}>
      <div
        title='Copy Public Link'
        className='bg-black font-normal rounded-full border border-border text-white px-2 py-1 text-sm flex items-center justify-center gap-x-1 cursor-pointer'>
        <Code size={14} /> Iframe
      </div>
    </CopyToClipboard>
    <HtmlCodeView formId={form._id}
      projectId={projectId}
    />
  </div>
}

function HtmlCodeView({ formId, projectId }) {
  const [open, setOpen] = React.useState(false)
  const [demandNotes, setDemandNotes] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [loadingForm, setLoadingForm] = React.useState(false)
  const [customForm, setCustomForm] = React.useState(null)
  const [code, setCode] = React.useState('')
  const [count, setCount] = React.useState(0)

  useEffect(() => {
    if (!open) { return }
    if (!formId) { return }
    setLoadingForm(true)
    getCustomForm(formId).then((response) => {
      setCustomForm(response.data)
    }).finally(() => {
      setLoadingForm(false)
    })
  }, [formId, count, open])
  useEffect(() => {
    if (!customForm) { return }
    const code = generateFormFields(customForm, {
      projectId,
      formId: customForm?._id,
      demandNotes
    })
    setCode(code)
  }, [customForm, demandNotes, projectId])

  useEffect(() => {
    if (!open) { return }
    setLoading(true)
    const promises = []
    promises.push(GetDemandNotes())
    promises.push(getCorporatDemandNotes())
    Promise.all(promises).then((response) => {
      const all = [...response[0].map((item) => {
        return {
          ...item,
          type: 'project'
        }
      })]
      all.push(...response[1].map((item) => {
        return {
          ...item,
          type: 'corporate'
        }
      }))
      setDemandNotes(all)
    }).catch((error) => {
      console.error('error', error)
    }).finally(() => {
      setLoading(false)
    })
  }, [open])

  const onClose = () => {
    setOpen(false)
  }
  if (loading || loadingForm) {
    return <div>Loading...</div>
  }
  return (
    <div>
      <div
        onClick={() => {
          setOpen(true)
        }}
        title='Copy Public Link'
        className='bg-black font-normal rounded-full border border-border text-white px-2 py-1 text-sm flex items-center justify-center gap-x-1 cursor-pointer'>
        <CodeIcon size={14} /> Html
      </div>
      <Drawer
        size='large'
        closable={false} placement="right" onClose={() => {
          onClose()
        }} open={open}>
        <div>
          <div className='flex items-center justify-between w-full'>
            <h1>HTML Code</h1>
            <div className='flex gap-x-1'>
              <CopyToClipboard text={code} onCopy={() => {
                alert('HTML Code copied to clipboard')
              }}>
                <div className='cursor-pointer bg-black font-normal rounded-full border border-border text-white px-3.5 py-2 text-sm flex w-auto items-center justify-center gap-x-1'>
                  <CodeIcon size={14} /> Copy HTML
                </div>
              </CopyToClipboard>
              <FormSettingsDrawer formId={formId} onClose={() => {
                setCount(count + 1)
              }} />
            </div>
          </div>
          <pre className='border border-border px-1'>
            <code>
              {code}
            </code>
          </pre>
        </div>
      </Drawer>
    </div>
  )
}

function generateFormFields(form, config) {
  const projectId = config?.projectId
  const formId = form._id
  const formUrl = `${process.env.REACT_APP_BE_URL}/leads/presale/v2`
  const formElements = []

  const demandNotesFields = config?.demandNotes?.map((item) => {
    return getDemandNoteFieldSetting(item)
  }) ?? []
  const allCustomFields = [...ALL_CUSTOM_FIELDS, ...demandNotesFields]
  form?.fields?.forEach((field) => {
    const name = field.field_name
    const finalFieldName = String(name)?.split('____')[0]
    if (field.element === 'CustomElement') {
      const [key] = name?.split('____')
      if (key) {
        const customField = allCustomFields.find((item) => item.key === key)
        if (customField) {
          const html = customField?.getHtml(field)
          formElements.push(html)
        }
      }
    } else {
      let formElement
      switch (field.element) {
        case 'Header':
          formElement = `<h1>${field.text}</h1>`
          break
        case 'Label':
          formElement = `<label>${field.text}</label>`
          break
        case 'Paragraph':
          formElement = `<p>${field.text}</p>`
          break
        case 'Dropdown':
          formElement = `<select name="${finalFieldName}">`
          field.options.forEach((option) => {
            formElement += `<option value="${option.value}">${option.text}</option>`
          })
          formElement += '</select>'
          break
        case 'NumberInput':
          formElement = `<input type="number" name="${finalFieldName}" placeholder="${field.label}"/>`
          break
        case 'TextArea':
          formElement = `<textarea name="${finalFieldName}" placeholder="${field.label}"></textarea>`
          break
        case 'PhoneNumber':
          formElement = `<input type="tel" name="${finalFieldName}" placeholder="${field.label}"/>`
          break
        case 'Image':
          formElement = `<img src="${field.src}" alt="${field.label}"/>`
          break
        case 'HyperLink':
          formElement = `<a href="${field.href}">${field.text}</a>`
          break
        case 'Download':
          formElement = `<a href="${field.file_path}" download>${field.text}</a>`
          break
        case 'Camera':
          formElement = `<input type="file" accept="image/*" capture="camera" name="${finalFieldName}" placeholder="${field.label}"/>`
          break
        case 'FileUpload':
          formElement = `<input type="file" name="${finalFieldName}" placeholder="${field.label}"/>`
          break
        case 'Range':
          formElement = `<input type="range" name="${finalFieldName}" min="${field.min_value}" max="${field.max_value}" step="${field.step}" value="${field.default_value}"/>`
          break
        case 'Signature':
          formElement = `<input type="text" name="${finalFieldName}" placeholder="${field.label}"/>`
          break
        case 'DatePicker':
          formElement = `<input type="date" name="${finalFieldName}" placeholder="${field.label}"/>`
          break
        case 'Rating':
          formElement = `<input type="number" name="${finalFieldName}" min="1" max="5" placeholder="${field.label}"/>`
          break
        case 'EmailInput':
          formElement = `<input type="email" name="${finalFieldName}" placeholder="${field.label}"/>`
          break
        case 'Tags':
          formElement = `<select name="${finalFieldName}" multiple>`
          field.options.forEach((option) => {
            formElement += `<option value="${option.value}">${option.text}</option>\n`
          })
          formElement += '</select>'
          break
        case 'TextInput':
          formElement = `<input type="text" name="${finalFieldName}" placeholder="${field.label}"/>`
          break
        case 'RadioButtons':
          formElement = '<div>\n'
          field.options.forEach((option) => {
            formElement += `<input type="radio" name="${finalFieldName}" value="${option.value}"/><label for="${finalFieldName}">${option.text}</label>\n`
          })
          formElement += '</div>'
          break
        case 'Checkboxes':
          formElement = '<div>\n'
          field.options.forEach((option) => {
            formElement += `<input type="checkbox" name="${finalFieldName}" value="${option.value}"/><label for="${finalFieldName}">${option.text}</label>\n`
          })
          formElement += '</div>'
          break
        default:
          console.log('Unknown element type:', field)
        // formElement = `<p>Unknown element type: ${field.element}</p>`
      }
      formElements.push(formElement)
    }
  })
  const content = formElements.join('\n')
  // const hCaptchaKey = process.env.REACT_APP_HCAPTCHA_KEY || '10000000-ffff-ffff-ffff-000000000001'
  // <form action="https://wildon-629f9be5731b.herokuapp.com/api/leads/presale" method="POST">
  const formSettings = form?.settings
  const leadSource = formSettings?.leadSource || 'Website'
  const usersLists = formSettings?.usersLists || []
  const usersListsIds = usersLists.map((item) => item)
  const autoReplyEmailTemplateId = formSettings?.autoReplyEmailTemplate || ''
  const successRedirect = formSettings?.successRedirect || 'https://example.com/success'
  const alreadyExistRedirect = formSettings?.alreadyExistRedirect || 'https://example.com/error?message=alreadyRegistered'
  const errorRedirect = formSettings?.errorRedirect || 'https://example.com/error'

  const isRecaptchaEnabled = formSettings?.recaptchaEnabled ?? false
  const recaptchaSiteKey = '6LcRvcAqAAAAANOn7m8KkVEhsDoy18ZtZfrw_6ab'

  // <script src="https://js.hcaptcha.com/1/api.js" async defer></script>
  // <div class="h-captcha" data-sitekey="${hCaptchaKey}"></div>
  let recaptchaCode = ''
  let recaptchaScript = ''
  if (isRecaptchaEnabled && recaptchaSiteKey) {
    recaptchaCode = `<div class="g-recaptcha" data-sitekey="${recaptchaSiteKey}"></div>`
    recaptchaScript = '<script src="https://www.google.com/recaptcha/enterprise.js" async defer></script>'
  }
  const formHtml =
    `${recaptchaScript}
    <form action="${formUrl}" method="POST">
      ${content}
      <div>
        <label>Are you a REALTORS®/Broker?</label><br>
        <input type="radio" id="realtorYes" name="isRealtor" value="true">
        <label for="realtorYes">Yes</label><br>
        <input type="radio" id="realtorNo" name="isRealtor" value="false">
        <label for="realtorNo">No</label>
      </div>
      <input type="hidden" name="leadSource" value="${leadSource}"/>
      <input type="hidden" name="project" value="${projectId}"/>
      <input type="hidden" name="formId" value="${formId}"/>
      <input type="hidden" name="autoReplyEmailTemplateId" value="${autoReplyEmailTemplateId}"/>
      <input type="hidden" name="autoAddToLists" value="${usersListsIds}"/>
      <input type="hidden" name="redirect_success"  value="${successRedirect}"/>
      <input type="hidden" name="redirect_existing" value="${alreadyExistRedirect}"/>
      <input type="hidden" name="redirect_error" value="${errorRedirect}"/>
      ${recaptchaCode}
      <input type="submit" value="Submit">
    </form>
    `
  const formattedHtml = pretty(formHtml)
  return formattedHtml
}
