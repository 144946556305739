/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import React, { memo, useCallback, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import {
  AppHeader,
  PagesWrapper,
  AdminSideMenu
} from 'components'
import { connect, useSelector } from 'react-redux'
import MobilePageHeader from 'components/MobilePageHeader'
import { useHistory } from 'react-router-dom'

const MemoAdminSideMenu = memo(({ showMenu, setShowMenu }) => (
  <AdminSideMenu showMenu={showMenu} setShowMenu={setShowMenu} />
))

const MemoAppHeader = memo(({ setShowMenu }) => (
  <AppHeader setShowMenu={setShowMenu} />
))

// const router = useNavigate()
const HeaderAndPageWrapper = memo(({ children }) => {
  const show = useSelector((state) => state.appReducer)
  return (
    <div className={`flex flex-col w-full flex-1 overflow-hidden bg-white transition-all duration-300 ease-in-out delay-0 ${(show.show && !show.isHover) ? 'md:ml-[281px]' : 'ml-0'}`}>
      {children}
    </div>
  )
})

const Children = memo(({ children }) => (
  <div className="w-full bg-siteColor relative z-1" style={{ height: 'calc(100% - 64px)' }}>
    {children}
  </div>
))

const AdminContainer = (props) => {
  const [showMenu, setShowMenu] = useState(false)
  const { children } = props
  const _setShowMenu = useCallback(() => {
    setShowMenu(!showMenu)
  }, [showMenu])

  return (
    <div className="h-screen flex overflow-hidden bg-white" id="platformWrapper">
      <MemoAdminSideMenu showMenu={showMenu} setShowMenu={_setShowMenu} />
      {/* <MemoAppHeader setShowMenu={_setShowMenu} /> */}
      <HeaderAndPageWrapper>
        {/* <MemoAppHeader setShowMenu={_setShowMenu} /> */}
        <PagesWrapper>
          <MobilePageHeader />
          <div className='mt-16'></div>
          <Children>{children}</Children>
        </PagesWrapper>
      </HeaderAndPageWrapper>
    </div>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(AdminContainer)

AdminContainer.defaultProps = {
  isUserLoggedIn: false,
  hideBackButton: false,
  renderContainer: true
}

AdminContainer.propTypes = {
  children: propTypes.node.isRequired
  // listOfProjects: propTypes.array
}

MemoAdminSideMenu.propTypes = {
  showMenu: propTypes.bool,
  setShowMenu: propTypes.func
  // listOfProjects: propTypes.array
}

MemoAppHeader.propTypes = {
  setShowMenu: propTypes.func
}

HeaderAndPageWrapper.propTypes = {
  children: propTypes.node
}

Children.propTypes = {
  children: propTypes.node
}

export const AdminDashboardComponent = (props) => {
  const history = useHistory()
  useEffect(() => {
    history.push('/admin/dashboard')
  }, [])
  return (
    <div className="w-full relative ">
      <img
        src="/background-img.png"
        className="object-cover   w-full h-[360px]"
      />
      <div className=" top-8 w-full px-[20px] absolute  pt-10 xl:px-[40px] z-99 flex justify-evenly ">
        <div className=" w-[151px]"></div>
        <div className="w-full max-w-[300px] xl:max-w-[345px] z-99 bg-[white]  flex text-[14px] xl:text-[16px] font-bold justify-between px-[16px] pt-[8px]">
          <div>This Week - 24/04/2023 - 30/04/2023</div>
          <div>v</div>
        </div>
        <div className="w-full max-w-[300px] xl:max-w-[345px] "></div>
        <div className="w-full max-w-[300px] xl:max-w-[345px] "></div>
      </div>
      <div className="px-[20px] xl:px-[40px] flex z-99 pt-6 absolute top-24 w-full justify-evenly">
        <div className="flex flex-col gap-y-6">
          <div className="bg-white z-99 px-5 py-4 rounded-[4px]  flex flex-col top-20 justify-between  h-44 max-w-xxs">
            <div>
              <img src="/total-sale.svg" />
            </div>
            <div className="flex flex-col">
              <div className="text-[12px] font-[300]">TOTAL SALES</div>
              <div className="text-[#2E2B2E]">CA$56,854,000</div>
            </div>
          </div>
          <div className="bg-white z-99 px-5 py-4 rounded-[4px]  flex flex-col justify-between  h-44 max-w-xxs">
            <div>
              <img src="/avg-price.svg" />
            </div>
            <div className="flex flex-col">
              <div className="text-[12px] font-[300]">
                AVE PRICE PER SQ. FT
              </div>
              <div className="text-[#2E2B2E]">CA $1,134</div>
            </div>
          </div>
          <div className="bg-white z-99 px-5 py-4 rounded-[4px]  flex flex-col top-0 justify-between  h-44 max-w-xxs">
            <div>
              <img src="/unit-sold.svg" />
            </div>
            <div className="flex flex-col">
              <div className="text-[12px] font-[300]">UNITS SOLD</div>
              <div className="text-[#2E2B2E]">109</div>
            </div>
          </div>
          <div className="bg-white z-99 px-5 py-4 rounded-[4px]  flex flex-col top-0 justify-between  h-44 max-w-xxs">
            <div>
              <img src="/total-leads.svg" />
            </div>
            <div className="flex flex-col">
              <div className="text-[12px] font-[300]">TOTAL Leads</div>
              <div className="text-[#2E2B2E]">2688</div>
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            history.push('/admin/columbia')
          }}
          className="w-full z-99 cursor-pointer  max-w-[300px] xl:max-w-[345px] "
        >
          <div className="bg-[#EDDF65] pl-[23px] pt-5 border-[1px] border-black text-[24px] font-[700]">
            British Columbia
          </div>
          <div className="bg-[white] pl-[23px] pb-[23px] flex flex-col gap-y-[10px]">
            <div>
              <div className="text-[#2E2B2E] text-[28px]">5</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                Active Projects
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">3</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                CITIES
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">530</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                NEW LEADS
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">CA$ 27,182,000</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                SALES VOLUME
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">36</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                NEW SALES
              </div>
            </div>

            <div>
              <div className="text-[#2E2B2E] text-[28px]">18</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                TOTAL FIRM SALES
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">6</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                RESCISSIONS
              </div>
            </div>
            <div className="text-[#2E2B2EB2] text-[15px] font-[700]">
              Projects
            </div>
            <div className="bg-[#F2F2F1] border-[1px] border-[#2E2B2E] mr-[13px] py-[15px] pl-[16px] flex flex-col gap-y-[15px] ">
              <div className="flex items-center gap-x-[8px]">
                <input type="checkbox" />
                <label>1818 Pacifica - Vancouver</label>
              </div>
              <div className="flex items-center gap-x-[8px]">
                <input type="checkbox" />
                <label>Pike Square - Metrotown</label>
              </div>
            </div>
          </div>
        </div>
        <div className=" z-99 w-full max-w-[300px] xl:max-w-[345px] ">
          <div className="bg-[#EDDF65] pl-[23px] pt-5 border-[1px] border-black text-[24px] font-[700]">
            Alberta
          </div>
          <div className="bg-[white] pl-[23px] pb-[23px] flex flex-col gap-y-[10px]">
            <div>
              <div className="text-[#2E2B2E] text-[28px]">3</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                Active Projects
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">2</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                CITIES
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">1882</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                NEW LEADS
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">CA$ 8,898,000</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                SALES VOLUME
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">52</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                NEW SALES
              </div>
            </div>

            <div>
              <div className="text-[#2E2B2E] text-[28px]">24</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                TOTAL FIRM SALES
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">12</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                RESCISSIONS
              </div>
            </div>
            <div className="text-[#2E2B2EB2] text-[15px] font-[700]">
              Projects
            </div>
            <div className="bg-[#F2F2F1] border-[1px] border-[#2E2B2E] mr-[13px] py-[15px] pl-[16px] flex flex-col gap-y-[15px] ">
              <div className="flex items-center gap-x-[8px]">
                <input type="checkbox" />
                <label>401 Drake  - Calgary</label>
              </div>
              <div className="flex items-center gap-x-[8px]">
                <input type="checkbox" />
                <label>Albert Landing - Okotoks </label>
              </div>
            </div>
          </div>
        </div>
        <div className=" z-99 w-full  max-w-[300px] xl:max-w-[345px] ">
          <div className="bg-[#EDDF65] pl-[23px] pt-5 border-[1px] border-black text-[24px] font-[700]">
            Ontario
          </div>
          <div className="bg-[white] pl-[23px] pb-[23px] flex flex-col gap-y-[10px]">
            <div>
              <div className="text-[#2E2B2E] text-[28px]">4</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                Active Projects
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">4</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                CITIES
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">276</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                NEW LEADS
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">CA$ 15,182,000</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                SALES VOLUME
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">21</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                NEW SALES
              </div>
            </div>

            <div>
              <div className="text-[#2E2B2E] text-[28px]">12</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                TOTAL FIRM SALES
              </div>
            </div>
            <div>
              <div className="text-[#2E2B2E] text-[28px]">5</div>
              <div className="text-[#2E2B2EB2] font-[300] text-[16px]">
                RESCISSIONS
              </div>
            </div>
            <div className="text-[#2E2B2EB2] text-[15px] font-[700]">
              Projects
            </div>
            <div className="bg-[#F2F2F1] border-[1px] border-[#2E2B2E] mr-[13px] py-[15px] pl-[16px] flex flex-col gap-y-[15px] ">
              <div className="flex items-center gap-x-[8px]">
                <input type="checkbox" />
                <label>Hills 19 - Toronto</label>
              </div>
              <div className="flex items-center gap-x-[8px]">
                <input type="checkbox" />
                <label>Kora - Hamilton </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
