/* eslint-disable */
import React from 'react'
import * as ReactFlow from '@xyflow/react'
import Container from '../../Container'
const { Handle, Position } = ReactFlow

export default function EmailNylasActionNode(_data) {
    const selected = _data.selected
    const data = _data.data
    const config = data.config
    const inputValues = data.inputValues
    const subject = inputValues?.subject || ''
    return (
        <Container
            _data={_data}
            config={config} selected={selected} >
            <Handle type="target" position={Position.Top} />
            <div className='flex flex-col justify-start items-start space-x-1 mt-1' style={{
                fontSize: 8,
            }}>
                <div className='font-semibold'>Subject</div>
                {!subject &&
                    <div className='font-light ml-0 text-red-500'>
                        Please enter valid subject
                    </div>
                }
                {subject && <div className='font-light ml-0'>{subject}</div>}
            </div>
            <Handle type="source" position={Position.Bottom} />
        </Container>
    )
}
