/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { AdminPageWrapper, ProjectOffers } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { useHistory } from 'react-router-dom'
import { ADMIN_OFFER, ADMIN_PURCHASE_UNIT } from 'routes'
import { connect, useSelector } from 'react-redux'
import { Button } from 'components/ShadcnComponents/button'
import { MapPin } from 'lucide-react'
import useDocusealConfigModal from 'hooks/useDocusealConfigModal'
import DocusealConfigModel from '../docusealConfig/component/DocusealModelConfig'
import UnitPurchaseModal from 'components/Inventory/components/UnitPurchaseModal'

// const Button = (props) => {
//   return (
//     <button className='text-softBlack text-center text-base font-medium py-1.5 px-4 space-x-1.5 flex items-center'
//       disabled={props.disabled}
//       onClick={props.onClick}
//     >
//       {props.children}
//     </button>
//   )
// }

const Offers = (props) => {
  const { appProject } = props
  const history = useHistory()
  // @ts-ignore
  const appReducer = useSelector(state => state.appReducer)
  const [projectInfo, setProjectInfo] = useState({})
  const { docusealData } = useDocusealConfigModal()
  const [isDocusealModalOpen, setIsDocusealModalOpen] = useState(false)
  const [isUnitPurchaseModalOpen, setIsUnitPurchaseModalOpen] = useState(false)
  useEffect(() => {
    const list = appReducer?.listOfProjects
    const selectedProjectId = appReducer?.appProject

    if (!list || !selectedProjectId) return

    const activeProject = list.find(project => project._id === selectedProjectId)
    setProjectInfo(activeProject)
  }, [appReducer])

  const createOffer = useCallback(() => {
    if (!docusealData) {
      setIsDocusealModalOpen(true)
    } else {
      history.push({
        pathname: ADMIN_PURCHASE_UNIT,
        state: {
          projectId: appProject
        }
      })
    }
  }, [appProject, history, docusealData])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <Button disabled={!appProject}
        onClick={() => setIsUnitPurchaseModalOpen(true)}
      >
        <TFilledPlusIcon className='h-6 w-6' />
        Create Transaction
      </Button>
    </div>
  )

  return (
    <>
      {/* <PageHeader
        title='Transactions'
        rightContent={<RightContent />}
      /> */}
      <div className='flex justify-between items-center mx-4 pt-4'>
        <div className="flex flex-col gap-2">
          <h1 className="text-xl mb-0 font-semibold">{projectInfo?.projectName}</h1>
          <div className="flex items-center text-gray-500 text-sm">
            <MapPin className="w-4 h-4 mr-1" />
           {`${projectInfo?.city}, ${projectInfo?.country}`}
          </div>
        </div>
        <div><RightContent /></div>
      </div>
      <AdminPageWrapper style={{ height: 'calc(100vh - 100px)' }} fullscreen>
        <ProjectOffers projectInfo={projectInfo} />
      </AdminPageWrapper>
      <DocusealConfigModel open={isDocusealModalOpen} close={() => setIsDocusealModalOpen(false)} />
      <UnitPurchaseModal
        show={isUnitPurchaseModalOpen}
        setShow={setIsUnitPurchaseModalOpen}
        onMethodSelected={(selectedMethod) => {
          if (selectedMethod === "quick-form") {
            history.push(
              `${ADMIN_PURCHASE_UNIT}`
            )
          } else if (selectedMethod === "wizard") {
            history.push(ADMIN_OFFER)
          }
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Offers)

Offers.propTypes = {
  appProject: propTypes.string
}

// Button.propTypes = {
//   disabled: propTypes.bool,
//   onClick: propTypes.func,
//   children: propTypes.node
// }
