/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useGetFilterUsers from "hooks/useGetFilterUsers";
import { useInView } from "react-intersection-observer";
import { Avatar, Typography, Card, Divider, message } from "antd";
import useContactContext from "pages/newContacts/hooks/useContactContext";
import {
  getAllDealTargets,
  getAllLeadStatuses,
  getAllLeadStatusesOrganized,
} from "store/actions/leadStatusAction";
import Lottie from "react-lottie";
import loadingAnimation from "assets/lottieJsons/tableLoading.json";
import moment from "moment";
import { LottieWrapper } from "components";
import CustomButton from "components/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ADMIN_LEAD_DASHBOARD_SETTINGS } from "routes";
import { getHubspotDeal, updateDealTarget, updateLeadStatus } from "store/actions/usersActions";
import CustomSelect from "pages/tasks/components/CustomSelect";

const boardStyles = {
  container: {
    display: "flex",
    overflowX: "auto",
    padding: "20px",
    gap: "20px",
    minHeight: "calc(100vh - 200px)",
    background: "transparent",
  },
  columnContent: {
    minHeight: "100px",
    padding: "4px",
  },
  draggingCard: {
    transform: "rotate(1deg)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.12)",
  },
};

const AdminLeadDashboard = () => {
  const { view, state } = useContactContext();
  const router = useHistory();
  const [filter, setFilter] = useState({
    page: 0,
    limit: 200,
    project: view === "corporate" ? "" : state.filters.project,
    sortType: "desc",
  });
  const [hasMore, setHasMore] = useState(true);
  const { ref: scrollRef, inView } = useInView();

  const { filteredUsers, filtering, refetchFilteredUser } = useGetFilterUsers(
    "",
    "Default",
    filter
  );
  const [columns, setColumns] = useState({});
  const [leadStatuses, setLeadStatuses] = useState([]);
  const [dealTargets, setDealTargets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState("lead");
  const [hubspotData, setHubspotData] = useState([]);
  const dataSourceOptions = [
    { label: "Lead", value: "lead" },
    { label: "Deal", value: "deal" },
  ];
  // useEffect(() => {
  //   if (inView && !filtering && hasMore) {
  //     setFilter((prev) => ({
  //       ...prev,
  //       page: prev.page + 1,
  //     }));
  //   }
  // }, [inView, filtering, hasMore]);

  useEffect(() => {
    refetchFilteredUser();
  }, [filter]);

  useEffect(() => {
    if (filteredUsers?.data?.length) {
      if (dataSource === "lead") {
        setColumns((prevColumns) => {
          const newColumns = { ...prevColumns };
          filteredUsers.data.forEach((user) => {
            const statusId = getLeadStatusId(user);
            if (newColumns[statusId]) {
              if (!newColumns[statusId].find((u) => u._id === user._id)) {
                newColumns[statusId] = [...newColumns[statusId], user];
              }
            } else {
              newColumns[statusId] = [user];
            }
          });

          return newColumns;
        });
      } else {
        setColumns((prevColumns) => {
          const newColumns = { ...prevColumns };
          filteredUsers.data.forEach((user) => {
            const statusId = getDealTargetId(user);
            if (newColumns[statusId]) {
              if (!newColumns[statusId].find((u) => u._id === user._id)) {
                newColumns[statusId] = [...newColumns[statusId], user];
              }
            } else {
              newColumns[statusId] = [user];
            }
          });
          return newColumns;
        });
      }
    }
  }, [filteredUsers]);

  const getLeadStatusId = (user) => {
    const statusName = {
      Buyer: user.buyerData?.leadStatus,
      LeadBroker: user.leadBrokerData?.leadStatus,
    }[user.userType];

    return leadStatuses.find(
      (status) => status.name.toLowerCase() === statusName?.toLowerCase()
    )?.id;
  };

  const getDealTargetId = (user) => {
    return dealTargets.find(
      (status) =>
        status.name.toLowerCase() === user?.hubspot?.dealStage?.toLowerCase()
    )?.id;
  };

  const processHubspotData = (hubspotData) => {
    const groupedData = {};
    hubspotData?.forEach((deal) => {
      if (deal?.payload?.properties?.dealstage) {
        const source = deal.payload.properties.dealstage.source;
        if (!groupedData[source]) {
          groupedData[source] = [];
        }
        let name = deal?.contact
          ? deal?.contact?.firstName + " " + deal?.contact?.lastName
          : deal?.payload?.objectId;
        groupedData[source].push({
          ID: deal?.payload?.objectId,
          name: name,
          _id: deal?.payload?.objectId,
          status: "Open",
          createdAt: deal?.createdAt,
          probability: (
            deal?.payload?.properties?.hs_deal_stage_probability?.value * 100
          ).toFixed(0),
        });
      }
    });

    return groupedData;
  };

  // useEffect(() => {
  //   if (dataSource == "lead") {
  //     refetchFilteredUser();
  //   } else {
  //     setIsLoading(true);
  //     getHubspotDeal().then((res) => {
  //       const hubspotData = res.data;
  //       const hubspotColumns = processHubspotData(hubspotData);
  //       setColumns(hubspotColumns);
  //       setIsLoading(false);
  //     });
  //   }

  // }, [dataSource, filteredUsers, hubspotData]);

  const fetchLeadStatuses = async () => {
    try {
      const response = await getAllLeadStatuses();
      const sortedStatuses = response
        ?.filter((status) => status.selected)
        .sort((a, b) => a.position - b.position);
      setLeadStatuses(sortedStatuses);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDealTargets = async () => {
    try {
      const response = await getAllDealTargets();
      const sortedStatuses = response
        ?.filter((status) => status.selected)
        .sort((a, b) => a.position - b.position);
      console.log(sortedStatuses);
      setDealTargets(sortedStatuses);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // const fetchLeadStatusesOrganized = async () => {
  //   try {
  //     const response = await getAllLeadStatusesOrganized();
  //     console.log(response);
  //     setLeadStatuses(response.leadStatuses);
  //     setColumns(response.columns);
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    if (dataSource === "lead") {
      fetchLeadStatuses();
    } else {
      fetchDealTargets();
    }
  }, [dataSource]);
  useEffect(() => {
    if (filteredUsers) {
      setIsLoading(true);
      if (dataSource === "lead") {
        const initialColumns = leadStatuses.reduce((acc, status) => {
          acc[status.id] = filteredUsers?.data.filter((user) => {
            let key = {
              Buyer: "buyerData",
              "Lead Broker": "leadBrokerData",
              Default: "leadStatus",
            }[user.userType];
            return (
              user[key]?.leadStatus.toLowerCase() ===
                status.name.toLowerCase() ||
              user.buyerData?.leadStatus.toLowerCase() ===
                status.name.toLowerCase() ||
              user.leadBrokerData?.leadStatus.toLowerCase() ===
                status.name.toLowerCase()
            );
          });
          return acc;
        }, {});
        setColumns(initialColumns);
        setIsLoading(false);
      } else {
        const initialColumns = dealTargets.reduce((acc, status) => {
          acc[status.id] = filteredUsers?.data.filter((user) => {
            return (
              user?.hubspot?.dealStage?.toLowerCase() ===
              status.name.toLowerCase()
            );
          });
          return acc;
        }, {});
        console.log(initialColumns);
        setColumns(initialColumns);
        setIsLoading(false);
      }
    }
  }, [filteredUsers, leadStatuses, dealTargets, dataSource]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if(destination.droppableId === source.droppableId) return;

    const sourceColumn = columns[source.droppableId] || [];
    const destColumn = columns[destination.droppableId] || [];

    const sourceItems = [...sourceColumn];
    const destItems = [...destColumn];

    const [removed] = sourceItems.splice(source.index, 1);

    destItems.splice(destination.index, 0, removed);

    setColumns({
      ...columns,
      [source.droppableId]: sourceItems,
      [destination.droppableId]: destItems,
    });

    if (dataSource === "lead") {
      const newLeadStatus = leadStatuses.find(
        (status) => status.id === destination.droppableId
      )?.name;

      if (newLeadStatus) {
        updateLeadStatus(newLeadStatus, removed._id)
          .then(() => {})
          .catch((error) => {
            setColumns({
              ...columns,
              [source.droppableId]: sourceColumn,
              [destination.droppableId]: destColumn,
            });
            console.error(error);
          });
      }
    } else {
      const newDealTarget = dealTargets.find(
        (status) => status.id === destination.droppableId
      )?.name;
      if (newDealTarget) {
        updateDealTarget(newDealTarget, removed._id)
          .then(() => {})
          .catch((error) => {
            setColumns({
              ...columns,
              [source.droppableId]: sourceColumn,
              [destination.droppableId]: destColumn,
            });
            console.error(error);
          });
      }
    }
  };
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="w-full bg-zinc-100">
        <div className="flex flex-col gap-4 mb-8 bg-zinc-100">
          <div className="flex justify-between w-full px-4 lg:px-10 py-4 lg:py-6 items-center">
            <div className="text-4xl font-medium">
              {dataSource === "lead" ? "Leads" : "Deals"}
            </div>
            <div className="flex items-center gap-4">
              <div className="w-[200px]">
                <CustomSelect
                  options={dataSourceOptions}
                  value={dataSource}
                  handleChange={(value) => setDataSource(value)}
                  placeholder="Select Data Source"
                />
              </div>
              <CustomButton
                className="w-fit mx-auto"
                handleClick={() => {
                  router.push(ADMIN_LEAD_DASHBOARD_SETTINGS);
                }}
              >
                <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                  Settings
                </span>
              </CustomButton>
            </div>
          </div>
          <div className="w-full h-[1px] bg-zinc-800"></div>
        </div>
        {(isLoading || filtering) && (
          <div className="h-[calc(100vh-200px)] w-[calc(100vw-400px)] flex justify-center items-center">
            <LottieWrapper
              animation={loadingAnimation}
              loop
              className="h-20 w-38"
            />
          </div>
        )}
        {!isLoading && !filtering && (
          <div className="overflow-x-auto min-h-[calc(100vh-200px)]">
            <div className="flex flex-row gap-6 lg:gap-12 bg-zinc-100 min-w-max px-4 lg:px-10">
              {(dataSource === "lead" ? leadStatuses : dealTargets).map(
                (status) => (
                  <div key={status.id} className="bg-transparent min-w-[300px]">
                    <div className="mb-4 flex justify-between items-center">
                      <div className="text-2xl font-medium">{status.name}</div>
                      <div className="text-sm opacity-80 border border-zinc-800 border-opacity-5 bg-zinc-200 px-2 py-1">
                        {columns[status.id]?.length || 0}
                      </div>
                    </div>
                    <Droppable droppableId={status.id} type="LEAD">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className={`flex flex-col gap-6 min-h-[150px] p-2 transition-colors ${
                            snapshot.isDraggingOver ? "bg-zinc-100" : ""
                          }`}
                        >
                          {columns[status.id]?.map((user, index) => {
                            return (
                              <Draggable
                                key={user._id}
                                draggableId={user._id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`w-full relative task p-3 md:p-4 bg-white rounded cursor-pointer border border-zinc-800 border-opacity-5 hover:bg-[#F9F4C9] hover:border-[#EDDF65] hover:border-opacity-100 transition-all ${
                                      snapshot.isDragging
                                        ? "shadow-lg bg-[#F9F4C9]"
                                        : ""
                                    }`}
                                  >
                                    <div className="flex items-start gap-6 w-full">
                                      <div className="flex flex-col gap-2 items-center flex-shrink-0">
                                        <div
                                          className="rounded-full size-9 bg-zinc-100 flex items-center justify-center"
                                          style={{
                                            border: "1px solid #2E2B2E4D",
                                          }}
                                        >
                                          {user.firstName?.charAt(0)}
                                        </div>
                                      </div>
                                      <div className="flex-col justify-start items-start gap-2 min-w-0 flex">
                                        <div className="text-base font-medium truncate w-full">
                                          {user?.firstName} {user?.lastName}
                                        </div>
                                        <div className="text-sm opacity-80 truncate w-full">
                                          {user.email || "-"}
                                        </div>
                                        <div className="w-fit">
                                          {user.userType === "Buyer" && (
                                            <div className="text-blue-500 text-xs font-medium bg-blue-50 border border-blue-500 rounded-full px-2 py-1 whitespace-nowrap">
                                              Buyer
                                            </div>
                                          )}
                                          {user.userType === "LeadBroker" && (
                                            <div className="text-green-500 text-xs font-medium bg-green-50 border border-green-500 rounded-full px-2 py-1 whitespace-nowrap">
                                              Lead Broker
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="absolute bottom-2 right-2 text-[#808080] text-xs opacity-80">
                                      {moment(user.createdAt).format(
                                        "MMM DD, YYYY"
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </DragDropContext>
  );
};

export default AdminLeadDashboard;
