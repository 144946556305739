import { DatePicker, message, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { createOrUpdateEvent, deleteImage, getEvents, getImage, uploadImageAPI } from 'store/actions/eventsActions'
import * as Yup from 'yup'
import ConfirmDeleteModal from './components/ConfirmDeleteModal'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { ImageUploaderPrimary } from 'components/ImageUploader'
import { toast } from 'react-hot-toast'
import CustomButton from 'components/CustomButton'

dayjs.extend(customParseFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)

const { RangePicker } = DatePicker

const eventSchema = Yup.object().shape({
  project: Yup.string().required(),
  heroImage: Yup.string().required('Hero Image is required'),
  coverImage: Yup.string().required('Cover Image is required'),
  videoLink: Yup.string().required('Video Link is required'),
  name: Yup.string().required('Event Name is required'),
  startDate: Yup.string().required('Date is required'),
  endDate: Yup.string().required('End Date is required'),
  startTime: Yup.string().required('Start Time is required'),
  endTime: Yup.string().required('End Time is required'),
  generalLocation: Yup.string().required('Landing page URL is required'),
  locationAddress: Yup.string().required('Event Location is required'),
  // shortDescription: Yup.string().required(),
  longDescription: Yup.string().required('Description is required')
})

const AdminCreateEditEventPage = () => {
  const history = useHistory()
  const { eventId } = useParams()
  const appProject = useSelector(state => state.appReducer.appProject)
  const [event, setEvent] = useState({
    project: '',
    heroImage: '',
    coverImage: '',
    videoLink: '',
    name: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    generalLocation: '',
    locationAddress: '',
    shortDescription: '',
    longDescription: ''
  })
  const [landingPageURLPrefix, setLandingPageURLPrefix] = useState('')
  const [landingPageURLPostfix, setLandingPageURLPostfix] = useState('')
  const [heroImageData, setHeroImageData] = useState()
  const [coverImageData, setCoverImageData] = useState()
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState({})
  const [deleteModal, setDeleteModal] = useState()
  const [startingDate, setStartingDate] = useState()
  const [endingDate, setEndingDate] = useState()

  useEffect(() => {
    if (eventId) {
      getEvents(eventId).then((data) => {
        setEvent(data)
        // Date
        var startTime = new Date(data.startDate)
        var endTime = new Date(data.endDate)
        setStartingDate(startTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }))
        setEndingDate(endTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' }))
        // Landing Page URL
        var url = data.generalLocation.split('/')
        url = url[0] + '/' + url[1] + '/'
        var path = data.generalLocation.split(url)[1]
        setLandingPageURLPrefix(url)
        setLandingPageURLPostfix(path)
        // Images
        if (data.heroImage) {
          getImage(data.heroImage).then((res) => setHeroImageData(res))
        }
        if (data.coverImage) {
          getImage(data.coverImage).then((res) => setCoverImageData(res))
        }
      })
    }
  }, [eventId])

  useEffect(() => {
    setEvent({
      ...event,
      project: appProject
    })
  }, [appProject])

  const handleBlur = (e) => {
    setTouched({
      ...touched,
      [e.target.name]: true
    })
    eventSchema
      .validate(event, { abortEarly: false })
      .catch((validationErrors) => {
        const errors = {}
        validationErrors.inner.forEach((err) => {
          errors[err.path] = err.message
        })
        setErrors({ ...errors })
      })
  }

  const handleInputChange = (e) => {
    setEvent({
      ...event,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = () => {
    setTouched({
      heroImage: true,
      coverImage: true,
      videoLink: true,
      name: true,
      startDate: true,
      endDate: true,
      startTime: true,
      endTime: true,
      generalLocation: true,
      locationAddress: true,
      // shortDescription: true,
      longDescription: true
    })
    eventSchema
      .validate(event, { abortEarly: false })
      .then(() => {
        createOrUpdateEvent(event).then((response) => {
          history.push(`/admin/events/preview/${response._id}`)
        })
      })
      .catch((validationErrors) => {
        const errors = {}
        validationErrors.inner.forEach((err) => {
          errors[err.path] = err.message
          message.error(err.message)
        })
        setErrors(errors)
      })
  }

  const uploadImage = (selectedImage, isHeroImage) => {
    const toastId = toast.loading('Uploading Image...')
    uploadImageAPI(selectedImage, appProject)
      .then((data) => {
        toast.dismiss(toastId)
        toast.success('Image uploaded successfully')
        if (isHeroImage) {
          setEvent({
            ...event,
            heroImage: data._id
          })
          setHeroImageData(data)
        } else {
          console.log('hello')
          setEvent({
            ...event,
            coverImage: data._id
          })
          setCoverImageData(data)
        }
      })
      .catch((e) => {
        toast.dismiss(toastId)
      })
  }

  return (
    <>
      <div className='sticky h-[100px] items-center justify-center border-b-2 border-black bg-white top-0 right-0 left-0 z-50'>
        <div className='mx-auto px-4 sm:px-6 lg:px-8 py-2 h-full w-full'>
          <div className='flex items-center justify-between my-auto h-full'>
            <h1 className='mb-0 font-bold text-3xl'>{!eventId ? 'Create New Event' : 'Edit Event'}</h1>
            <div className='flex items-center gap-4'>
              {/* {eventId &&
                <button onClick={makeItArchive} className="text-lg italic bg-[#FCEE76] font-bold py-3 px-7 rounded-md">Archive</button>
              }
              <button onClick={handleSubmit} className="text-lg italic bg-[#FCEE76] font-bold py-3 px-7 rounded-md">{!eventId ? 'Create Event' : 'Save'}</button> */}
              <CustomButton
                className='w-fit m-auto'
                handleClick={() => {
                  // if (eventId) {
                  //   makeItArchive()
                  // } else {
                  handleSubmit()
                  // }
                }}
              >
                <span className='font-medium py-3 px-7 text-base flex gap-2 items-center'>
                  {
                    !eventId ? 'Create Event' : 'Save'
                  }
                </span>
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div className='mx-auto px-4 sm:px-6 lg:px-8 bg-siteColor'>
        <div className='flex flex-col w-full'>
          <div className='grid grid-cols-12 container mx-auto w-full mt-6 gap-x-8 lg:gap-x-14 col-span-12'>
            <div className='col-span-7 gap-y-6 grid grid-cols-6 gap-x-8 lg:gap-x-14'>
              <div className='col-span-6 flex flex-col gap-1 relative'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Event Name *
                </label>
                <div>
                  <input
                    type='text'
                    name='name'
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    value={event.name}
                    placeholder='VIP Realtors Event'
                    className='border border-[rgba(46,43,46,0.3)] rounded-[4px] w-full'
                  />
                  {touched.name && errors.name && <div className='relative h-0'><p className='absolute text-red-500'>{errors.name}</p></div>}
                </div>
              </div>
              <div className='col-span-3 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>Date *</label>
                <div>
                  <RangePicker
                    className={'text-lg text-black p-2.5 border border-[rgba(46,43,46,0.3)] rounded w-full bg-white'}
                    name='startDate'
                    onChange={(date, dateString) => {
                      setEvent({
                        ...event,
                        startDate: dateString[0],
                        endDate: dateString[1]
                      })
                      var startTime = new Date(dateString[0])
                      var endTime = new Date(dateString[1])
                      var formattedStartDate = startTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                      var formattedEndDate = endTime.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })
                      setStartingDate(formattedStartDate)
                      setEndingDate(formattedEndDate)
                    }}
                    value={(startingDate && endingDate) ? [dayjs(startingDate, 'MMM D, YYYY'), dayjs(endingDate, 'MMM D, YYYY')] : ''}
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                    size='large'
                    format='MMM D, YYYY'
                  />
                  {((errors.startDate && !event.startDate) || (errors.endDate && !event.endDate)) && <div className='relative'><p className='absolute text-red-500'>{errors.startDate}</p></div>}
                </div>
              </div>
              <div className='col-span-3 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Type Of Event
                </label>
                <input
                  type='text'
                  placeholder='In person'
                  className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                />
              </div>
              <div className='col-span-3 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Start Time *
                </label>
                <div>
                  <TimePicker
                    className={'text-lg text-black p-2.5 border border-[rgba(46,43,46,0.3)] rounded w-full bg-white'}
                    use12Hours={true}
                    format='h:mm A'
                    name='startTime'
                    onChange={(time, timeString) => {
                      setEvent({
                        ...event,
                        startTime: timeString
                      })
                    }}
                    value={event.startTime ? dayjs(event.startTime, 'h:mm A') : ''}
                    onBlur={handleBlur}
                    placeholder='2:00 PM'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                    size='large'
                  />
                  {touched.startTime && errors.startTime && !event.startTime && <div className='relative'><p className='absolute text-red-500'>{errors.startTime}</p></div>}
                </div>
              </div>
              <div className='col-span-3 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  End Time *
                </label>
                <div>
                  <TimePicker
                    className={'text-lg text-black p-2.5 border border-[rgba(46,43,46,0.3)] rounded w-full bg-white'}
                    use12Hours={true}
                    format='h:mm A'
                    name='endTime'
                    onChange={(time, timeString) => {
                      setEvent({
                        ...event,
                        endTime: timeString
                      })
                    }}
                    value={event.endTime ? dayjs(event.endTime, 'h:mm A') : ''}
                    onBlur={handleBlur}
                    placeholder='5:30 PM'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                    size='large'
                  />
                  {touched.endTime && errors.endTime && !event.endTime && <div className='relative'><p className='absolute text-red-500'>{errors.endTime}</p></div>}
                </div>
              </div>
              <div className='col-span-6 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Event Location *
                </label>
                <div>
                  <input
                    type='text'
                    name='locationAddress'
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    value={event.locationAddress}
                    placeholder='1818 Pacifica Vancouver BC Canada'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                  />
                  {touched.locationAddress && errors.locationAddress && <div className='relative'><p className='absolute text-red-500'>{errors.locationAddress}</p></div>}
                </div>
              </div>
              <div className='col-span-6 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Landing Page URL *
                </label>
                <div className='grid grid-cols-6 gap-x-2'>
                  <div className='col-span-2'>
                    <input
                      type='text'
                      name='generalLocation'
                      onChange={(e) => {
                        setLandingPageURLPrefix(e.target.value)
                        setEvent({
                          ...event,
                          [e.target.name]: e.target.value + landingPageURLPostfix
                        })
                      }}
                      value={landingPageURLPrefix}
                      onBlur={handleBlur}
                      placeholder='1818/pacifica.com/'
                      className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                    />
                    {(touched.generalLocation && landingPageURLPrefix === '') && <div className='relative col-span-6'><p className='absolute text-red-500'>Required</p></div>}
                  </div>
                  <div className='col-span-4'>
                    <input
                      type='text'
                      name='generalLocation'
                      onChange={(e) => {
                        setLandingPageURLPostfix(e.target.value)
                        setEvent({
                          ...event,
                          [e.target.name]: landingPageURLPrefix + e.target.value
                        })
                      }}
                      value={landingPageURLPostfix}
                      onBlur={handleBlur}
                      placeholder='Vip Broker Event'
                      className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                    />
                    {(touched.generalLocation && landingPageURLPostfix === '') && <div className='relative col-span-6'><p className='absolute text-red-500'>Required</p></div>}
                  </div>
                </div>
              </div>
              <div className='col-span-6 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Video Link *
                </label>
                <div>
                  <input
                    type='text'
                    name='videoLink'
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    value={event.videoLink}
                    placeholder='https://www.youtube.com/watch?v=Fi3AJZZregI'
                    className='border border-[#2E2B2E4d] rounded-[4px] w-full'
                  />
                  {touched.videoLink && errors.videoLink && <div className='relative'><p className='absolute text-red-500'>{errors.videoLink}</p></div>}
                </div>
              </div>
              <div className='col-span-6 flex flex-col gap-1'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Event Description *
                </label>
                <div>
                  <textarea
                    rows={7}
                    name='longDescription'
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    value={event.longDescription}
                    placeholder='Enter description'
                    className='border resize-none border-[#2E2B2E4d] rounded-[4px] w-full'
                  />
                  {touched.longDescription && errors.longDescription && <div className='relative'><p className='absolute text-red-500 -mt-1'>{errors.longDescription}</p></div>}
                </div>
              </div>
            </div>
            <div className='flex flex-col col-span-5'>
              <div className='flex flex-col items-start justify-start gap-2'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Upload Hero Image *
                </label>
                <div className='w-full'>
                  <ImageUploaderPrimary
                    onFileChange={(e) => {
                      e.preventDefault()
                      uploadImage(e?.currentTarget?.files?.[0], true)
                    }}
                    onDelete={() => setDeleteModal('heroImage')}
                    value='Import Image Here'
                    isDeleted={!heroImageData}
                    imgsrc={heroImageData ? heroImageData.url : ''}
                  />
                </div>
              </div>
              <div className='flex flex-col items-start justify-start gap-2'>
                <label className='text-[2E2B2E] font-normal text-base italic'>
                  Upload Cover Image *
                </label>
                <div className='w-full'>
                  <ImageUploaderPrimary
                    onFileChange={(e) => {
                      e.preventDefault()
                      uploadImage(e?.currentTarget?.files?.[0], false)
                    }}
                    onDelete={() => setDeleteModal('coverImage')}
                    value='Import Image Here'
                    isDeleted={!coverImageData}
                    imgsrc={coverImageData ? coverImageData.url : ''}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDeleteModal
        deleteId={deleteModal}
        show={deleteModal?.length}
        onClose={() => {
          setDeleteModal(null)
        }}
        onSuccess={(key) => {
          console.log('onSuccess', key, event[key])
          deleteImage(event[key]).then(() => {
            setEvent({
              ...event,
              [key]: ''
            })
            if (key === 'heroImage') {
              setHeroImageData()
            } else if (key === 'coverImage') {
              setCoverImageData()
            }
          }).catch(() => {
          }).finally(() => {
            setDeleteModal(null)
          })
        }}
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminCreateEditEventPage)
