import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { deleteReservationDocument } from 'store/actions/reservationActions'

const useUpdateReservationDocument = () => {
  const [loading, setLoading] = useState(false)
  // @ts-ignore
  const userObject = useSelector((state) => state.authReducer.userObject)

  const deleteAPI = useCallback(
    async (reservationId, documentId) => {
      try {
        setLoading(true)
        const response = await deleteReservationDocument(
          reservationId,
          documentId
        )
        setLoading(false)
        return response
      } catch (e) {
        setLoading(false)
        return null
      }
    },
    [userObject]
  )

  return { deleteAPI, loading }
}

export default useUpdateReservationDocument
