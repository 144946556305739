/* eslint-disable */
import React from 'react'
import * as ReactFlow from '@xyflow/react'
import Container from '../../Container'
const { Handle, Position } = ReactFlow

export default function SalesRepTaskActionNode(_data) {
    const selected = _data.selected
    const data = _data.data
    const config = data.config
    const inputValues = data.inputValues
    const title = inputValues?.title || ''
    const dueDate = inputValues?.dueDate || ''
    const taskType = inputValues?.taskType || ''
    const numberOfDays = parseInt(dueDate.replace('plus', ''))
    const list = [
        ['Title', title],
        ['Task Type', taskType],
        ['Due Date', `${numberOfDays} days`],
    ]
    return (
        <Container
            _data={_data}
            config={config} selected={selected} >
            <Handle type="target" position={Position.Top} />
            <div className='flex flex-col justify-start items-start space-x-1 mt-1' style={{
                fontSize: 8,
            }}>
                {list.map((item, index) => (
                    <div key={index} className='ml-0'>
                        <div className='font-semibold'>{item[0]}</div>
                        {!item[1] &&
                            <div className='font-light ml-0 text-red-500'>
                                Please enter valid {item[0].toLowerCase()}
                            </div>
                        }
                        {item[1] && <div className='capitalize font-light ml-0'>{item[1]}</div>}
                    </div>
                ))}
            </div>
            <Handle type="source" position={Position.Bottom} />
        </Container>
    )
}
