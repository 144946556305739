// @ts-nocheck
/* eslint-disable */
import React from "react";
import '../../assets/css/tailwind.css'

const Input = React.forwardRef(({ className = "", max,min, type = "text", ...props }, ref) => {
  return (
    <input
      type={type}
      max={max}
      min={min}
      className={`flex h-9 w-full rounded-md border border-zinc-200 bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-zinc-500 focus-visible:!outline-none focus-visible:ring-1 focus-visible:ring-gray-400 focus:border-gray-400 disabled:cursor-not-allowed disabled:opacity-50 dark:border-zinc-800 dark:placeholder:text-zinc-400 dark:focus-visible:ring-gray-500 ${className}`}
      ref={ref}
      {...props}
    />
  )
})

Input.displayName = "Input"

export { Input }
