/* eslint-disable */
import React, { useCallback, useEffect } from 'react'
import * as ReactFlow from '@xyflow/react'
import Container from '../../Container'
import humanizeDuration from 'humanize-duration'
import { getEmailTemplates } from 'store/actions/emailTemplateActions'
import EmailTemplatePreview from 'pages/emailCampaignsBuilder/TemplatePreview'
import CustomButton from 'components/CustomButton'
const { Handle, Position } = ReactFlow

export default function EmailTemplatesActionNode(_data) {
    const selected = _data.selected
    const data = _data.data
    const config = data.config
    const inputValues = data.inputValues
    const subject = inputValues?.subject || ''
    const emailTemplateId = inputValues?.emailTemplateId || ''

    const [emailTemplates, setEmailTemplates] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    useEffect(() => {
        setLoading(true)
        getEmailTemplates().then((data) => {
            setEmailTemplates(data)
            setLoading(false)
        })
    }, [])
    const [isEmailsPreviewOpen, setIsEmailsPreviewOpen] = React.useState(false)
    const template = emailTemplates.find((item) => item._id === emailTemplateId)
    return (
        <Container
            _data={_data}
            config={config} selected={selected} >
            <Handle type="target" position={Position.Top} />
            <div className='flex flex-col gap-y-1 justify-start items-start space-x-1 mt-1' style={{
                fontSize: 8,
            }}>
                <div className='ml-0'>
                    <div className='font-semibold'>Subject</div>
                    {!subject &&
                        <div className='font-light ml-0 text-red-500'>
                            Please enter valid subject
                        </div>
                    }
                    {subject && <div className='font-light ml-0'>{subject}</div>}
                </div>
                <div className='ml-0'>
                    <div className='font-semibold ml-0'>Email Template</div>
                    {!emailTemplateId &&
                        <div className='font-light ml-0 text-red-500'>
                            Please select email template
                        </div>
                    }
                    {emailTemplateId &&
                        <div>
                            <div className='font-light ml-0'>
                                {emailTemplates.find((item) => item._id === emailTemplateId)?.name}
                            </div>
                            <CustomButton variant='outline' handleClick={() => setIsEmailsPreviewOpen(true)}
                                className='px-2 py-1'
                            >
                                View  Preview
                            </CustomButton>
                            <EmailTemplatePreview selectedTemplate={template} open={isEmailsPreviewOpen} onClose={() => {
                                setIsEmailsPreviewOpen(false)
                            }} />
                        </div>
                    }
                </div>
            </div>
            <Handle type="source" position={Position.Bottom} />
        </Container>
    )
}
