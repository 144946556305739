/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from "react"
import { Oval } from "react-loader-spinner"
import CustomInput from "components/CustomInput"
import CustomSelect from "components/CustomSelect"
import { createOrUpdateUnit } from "store/actions/unitActions"
import useGetFloorPlans from "hooks/useGetFloorPlans"
import useGetUnitGroups from "hooks/useGetUnitGroups"
import { useSelector } from "react-redux"
import { isObject } from "lodash"
// import useCurrencyList from "hooks/useCurrencyList"
import ProjectFileSelector from "components/ProjectFileSelector"
import { ADMIN_OFFER, ADMIN_PURCHASE_UNIT } from "routes"
import { useHistory } from "react-router-dom"
import { AVAILABILITY_STATUS } from "utils"
// import CustomBadge from './CustomBadge'
import useInventory from "../hooks/useInventory"
import DeleteModal from "components/DeleteModal"
import { Check, ShoppingBag, Trash2 } from "lucide-react"
import { deleteUnitPolygon } from "store/actions/unitPolygonActions"
import UnitPurchaseModal from "./UnitPurchaseModal"
import { Router } from "react-router-dom/cjs/react-router-dom.min"
import QuickForm from "./QuickForm"
import moment from "moment-timezone"
import useDocusealConfigModal from "hooks/useDocusealConfigModal"
import DocusealConfigModel from "pages/desktopMode/docusealConfig/component/DocusealModelConfig"

const UnitEditView = ({
  editDetails,
  setEditDetails,
  refetch,
  building,
  closeUnitInfoDrawer,
  variableName,
}) => {
  const history = useHistory()
  // @ts-ignore
  // const appProject = useSelector((state) => state.appReducer.appProject)
  const userObject = useSelector((state) => state.authReducer.userObject)
  const { floorPlans } = useGetFloorPlans()
  const { unitGroups } = useGetUnitGroups()
  // const { formattedCurrencyList: currencyData } = useCurrencyList()
  const initialEmptyValues = {
    unitNumber: "",
    fullAddress: "",
    strataLotNumber: "",
    status: "",
    price: "",
    // priceCurrencyType: "",
    building,
  }
  const [value, setValues] = useState({
    ...initialEmptyValues,
  })
  const {
    state: { selectedInventoryView },
  } = useInventory()
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isUnitPurchaseModalOpen, setIsUnitPurchaseModalOpen] = useState(false)
  const [isQuickSubmit, setIsQuickSubmit] = useState(false)
  const [defaultSelectedUnit, setDefaultSelectedUnit] = useState(null)
  const { docusealData } = useDocusealConfigModal()
  const [isDocusealModalOpen, setIsDocusealModalOpen] = useState(false)

  const STATUS_OPTIONS = [
    ...Object.values(AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    }),
  ]

  const balconyOptions = [
    { id: true, value: "Yes" },
    { id: false, value: "No" },
  ]

  const filterUniqueValues = useMemo(() => {
    const uniqueValues = {}

    Object.keys(value).forEach((key) => {
      if (value[key] !== editDetails[key]) {
        uniqueValues[key] = value[key]
      }
    })

    return uniqueValues
  }, [value])

  const handleSubmit = () => {
    // if (validate()) {
    setLoading(true)
    // const temp = {
    //   // @ts-ignore
    //   _id: value?._id ?? '',
    //   unitNumber: value?.unitNumber ?? '',
    //   fullAddress: value?.fullAddress ?? '',
    //   strataLotNumber: value?.strataLotNumber ?? '',
    //   status: value?.status ?? '',
    //   price: value?.price ?? '',
    //   priceCurrencyType: value?.priceCurrencyType ?? '',
    //   unitGroup: isObject(value.unitGroup)
    //     ? value.unitGroup?._id
    //     : value.unitGroup,
    //   floorPlan: isObject(value.floorPlan)
    //     ? value.floorPlan?._id
    //     : value.floorPlan,
    //   floorNumber: value?.floorNumber ?? '',
    //   numberOfBedrooms: value?.numberOfBedrooms ?? '',
    //   numberOfBathrooms: value?.numberOfBathrooms ?? '',
    //   balcony: value.balcony ?? false,
    //   building: isObject(value.building)
    //     ? value.building?._id
    //     : value.building,
    //   project: appProject,
    //   contractPdf: value?.contractPdf ?? '',
    //   parkingPdf: value?.parkingPdf ?? ''
    // }
    const updatedValues = { _id: value?._id ?? "", ...filterUniqueValues }

    createOrUpdateUnit(updatedValues)
      .then((data) => {
        refetch()
        setLoading(false)
        setEditDetails(data)
      })
      .catch((err) => {
        setLoading(false)
        console.log(err)
      })
    // }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  // const validate = () => {
  //   for (const key in initialEmptyValues) {
  //     if (!value[key]) {
  //       return false
  //     }
  //   }
  //   return true
  // }

  useEffect(() => {
    if (editDetails?.inventoryType === "Unit") {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id,
        // balcony: editDetails.balcony ? 'true' : 'false'
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange("status", temp.id)
  }

  const handleContractPDFChange = (contractObject) => {
    onChange("contractPcan you fidf", contractObject._id ?? contractObject.id)
  }

  const handleParkingPDFChange = (parkingObject) => {
    onChange("parkingPdf", parkingObject._id ?? parkingObject.id)
  }

  // const handleCurrencyChange = (id) => {
  //   const temp = currencyData.filter((option) => option.id === id)[0]
  //   onChange("priceCurrencyType", temp.id)
  // }

  const handleBalconyChange = (id) => {
    const temp = balconyOptions.filter((option) => option.id === id)[0]
    onChange("balcony", temp.id)
  }

  const handlePurchase = () => {
    // if (!docusealData) {
    //   setIsDocusealModalOpen(true)
    // }
    // else {
    setDefaultSelectedUnit(editDetails?._id)
    setIsUnitPurchaseModalOpen(true)
    // }
  }

  const deletePolygon = () => {
    setLoading(true)
    deleteUnitPolygon(editDetails?.polygonId)
      .then(() => {
        setLoading(false)
        refetch()
        setIsDeleteModal(false)
      })
      .catch((error) => {
        setLoading(false)
        setIsDeleteModal(false)
        console.err(error)
      })
  }

  return (
    <div className="flex flex-col h-full">
      <div className="p-4 relative flex flex-col h-full overflow-hidden rounded-lg border border-black/[20%]">
        <div className="flex-1 overflow-y-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 thin-scrollbar">
            <CustomInput
              label="Name"
              placeholder="Enter name"
              labelClassName="italic"
              value={value.unitNumber}
              onChange={(e) => {
                onChange("unitNumber", e.target.value)
              }}
              // required='required'
              // errorMessage='Please enter name'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomInput
              label={`${variableName} address`}
              placeholder="Enter address"
              labelClassName="italic"
              value={value.fullAddress}
              onChange={(e) => {
                onChange("fullAddress", e.target.value)
              }}
              // required='required'
              // errorMessage='Please enter unit address'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomInput
              label="strata lot number"
              placeholder="Enter number"
              labelClassName="italic"
              value={value.strataLotNumber}
              onChange={(e) => {
                onChange("strataLotNumber", e.target.value)
              }}
              // required='required'
              // errorMessage='Please enter lot number'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomSelect
              label="status"
              inputClasses="h-11"
              labelClasses={undefined}
              options={STATUS_OPTIONS}
              selectedOption={value.status}
              setSelectedOption={(option) => {
                handleStatusChange(option)
              }}
            />
            <CustomInput
              label="price"
              placeholder="Enter amount"
              labelClassName="italic"
              value={value.price}
              onChange={(e) => {
                onChange("price", e.target.value)
              }}
              type="number"
              // required='required'
              // errorMessage='Please enter price'
              containerClasses={undefined}
              classes={undefined}
            />
            {/* <CustomSelect
              label="currency"
              inputClasses="h-11"
              options={currencyData}
              selectedOption={value.priceCurrencyType}
              setSelectedOption={(option) => {
                handleCurrencyChange(option)
              }}
              labelClasses={undefined}
            /> */}
            {/* <CustomSelect
              label={`${variableName} group`}
              inputClasses='h-11'
              labelClasses={undefined}
              options={unitGroups}
              selectedOption={
                // @ts-ignore
                isObject(value.unitGroup) ? value.unitGroup?._id : value.unitGroup
              }
              toIterateField='name'
              setSelectedOption={(newValue) => {
                onChange('unitGroup', newValue)
              }}
            />
            <CustomSelect
              label='floor plan'
              options={floorPlans}
              inputClasses='h-11'
              selectedOption={
                // @ts-ignore
                isObject(value.floorPlan) ? value.floorPlan?._id : value.floorPlan
              }
              toIterateField='name'
              setSelectedOption={(newValue) => {
                onChange('floorPlan', newValue)
              }}
              labelClasses={undefined}
            />
            <CustomInput
              label='floor number'
              placeholder='Enter number'
              labelClassName='italic'
              value={value.floorNumber}
              onChange={(e) => {
                onChange('floorNumber', e.target.value)
              }}
              type='number'
              // required='required'
              // errorMessage='Please enter floor number'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomInput
              label='number of bedrooms'
              value={value.numberOfBedrooms}
              onChange={(e) => {
                onChange('numberOfBedrooms', e.target.value)
              }}
              type='number'
              // required='required'
              // errorMessage='Please enter number of bedrooms'
              containerClasses={undefined}
              classes={undefined}
              placeholder={undefined}
            />
            <CustomInput
              label='number of bathrooms'
              placeholder='Enter number'
              labelClassName='italic'
              value={value.numberOfBathrooms}
              onChange={(e) => {
                onChange('numberOfBathrooms', e.target.value)
              }}
              type='number'
              // required='required'
              // errorMessage='Please enter number of bathrooms'
              containerClasses={undefined}
              classes={undefined}
            />
            <CustomSelect
              label='balcony'
              labelClasses='italic'
              inputClasses='h-11'
              options={balconyOptions}
              setSelectedOption={(value) => {
                handleBalconyChange(value)
              }}
              selectedOption={value.balcony}
            />
            <ProjectFileSelector
              title={'Contract'}
              onSuccess={(selectedFileData) => {
                handleContractPDFChange(JSON.parse(selectedFileData))
              }}
            />
            <ProjectFileSelector
              title={variableName}
              onSuccess={(selectedFileData) => {
                handleParkingPDFChange(JSON.parse(selectedFileData))
              }}
            /> */}
          </div>
        </div>
        <div className="sticky bottom-5 bg-white p-2 mt-2 border-t border-softBlack/[30%]">
          <div className="flex w-full gap-3">
            <button
              onClick={closeUnitInfoDrawer}
              className="w-full lg:basis-1/3 py-2.5 px-5 text-lg rounded-lg font-medium border border-[#E4E4E7]"
            >
              Cancel
            </button>
            <button
              className="w-full py-2.5 lg:basis-1/3 px-5 text-lg bg-[#18181B] border border-[#18181B] text-white rounded-lg font-medium flex gap-2 justify-center"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? (
                <Oval height="23" width="23" color="white" />
              ) : (
                <Check height="23" width="23" color="white" />
              )}
              <p className="mb-0">{value?._id ? "Update" : "Add"}</p>
            </button>
            {userObject?.userType === "DeveloperAdmin" &&
            selectedInventoryView === "map" ? (
              <button
                className="w-full lg:basis-1/3 py-2.5 px-5 text-lg border border-[#E4E4E7] rounded-lg font-medium flex gap-2 items-center justify-center"
                disabled={loading}
                onClick={() => setIsDeleteModal(true)}
              >
                {loading ? (
                  <Oval height="23" width="23" color="black" />
                ) : (
                  <Trash2 height="23" width="23" color="black" />
                )}
                <p className="mb-0">Delete</p>
              </button>
            ) : (
              <button
                className="w-full lg:basis-1/3 py-2.5 px-5 text-lg border border-[#E4E4E7] rounded-lg font-medium flex gap-2 items-center justify-center"
                disabled={loading}
                onClick={handlePurchase}
                // onClick={() => history.push(ADMIN_OFFER)}
              >
                {loading ? (
                  <Oval height="23" width="23" color="black" />
                ) : (
                  <ShoppingBag height="23" width="23" color="black" />
                )}
                <p className="mb-0">Purchase</p>
              </button>
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        title="Confirm deletion?"
        description={`Are you sure? do you want to delete #${value.unitNumber} unit-polygon?`}
        confirmButtonTitle="Yes, delete it"
        cancelButtonTitle="No"
        isOpen={isDeleteModal}
        setIsOpen={setIsDeleteModal}
        onConfirm={deletePolygon}
        loading={false}
      />
      <UnitPurchaseModal
        show={isUnitPurchaseModalOpen}
        setShow={setIsUnitPurchaseModalOpen}
        onMethodSelected={(selectedMethod) => {
          if (selectedMethod === "quick-form") {
            history.push(
              `${ADMIN_PURCHASE_UNIT}?defaultSelectedUnit=${defaultSelectedUnit}`
            )
          } else if (selectedMethod === "wizard") {
            history.push(ADMIN_OFFER)
          }
        }}
      />
      {isQuickSubmit && (
        <QuickForm
          closeInfoDrawer={() => setIsQuickSubmit(false)}
          isInfoDrawerOpen={isQuickSubmit}
          isFullHeight={false}
          defaultSelectedUnit={defaultSelectedUnit}
        />
      )}
      <DocusealConfigModel open={isDocusealModalOpen} close={() => setIsDocusealModalOpen(false)} />
    </div>
  )
}

export default UnitEditView
