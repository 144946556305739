import { useCustomForms } from 'hooks/useCustomForms'
import React, { useEffect, useState } from 'react'
import { ReactFormGenerator, Registry } from 'react-form-builder2'
import bgImage from '../../assets/img/mountain-bg.jpg'
import getDemandNoteOption from './edit/components/demandNotes'
import { GetDemandNotes, getCorporatDemandNotes } from 'store/actions/demandNotes'
import FirstNameConfig from './edit/components/firstName'
import LastNameConfig from './edit/components/lastName'
import EmailConfig from './edit/components/email'
import PhoneNumberConfig from './edit/components/phoneNumber'
import PostalCodeConfig from './edit/components/postalCode'
import { Button } from 'components/ShadcnComponents/button'
import { getProjects } from 'store/actions/projectActions'
import './style/style.css'

const customInputs = [
  FirstNameConfig,
  LastNameConfig,
  EmailConfig,
  PhoneNumberConfig,
  PostalCodeConfig
]

const PublicForm = ({
  formId,
  readOnly = false,
  onSuccessfulSubmit = () => { }
}) => {
  const { getCustomForm, submitCustomForm } = useCustomForms()
  const [form, setForm] = useState(null)
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const [loadingDemandNotes, setLoadingDemandNotes] = React.useState(true)
  const [demandNotes, setDemandNotes] = React.useState([])
  const [setupDone, setSetupDone] = React.useState(false)
  const [projectBgImage, setProjectBgImage] = useState('')

  useEffect(() => {
    setLoading(true)
    getCustomForm(formId).then(({ data }) => {
      setForm(data)
    }).catch((error) => {
      console.log(error)
    }).finally(() => {
      setLoading(false)
    })
  }, [getCustomForm, formId])

  useEffect(() => {
    const promises = []
    promises.push(GetDemandNotes())
    promises.push(getCorporatDemandNotes())
    Promise.all(promises).then((response) => {
      const all = [...response[0].map((item) => {
        return {
          ...item,
          type: 'project'
        }
      })]
      all.push(...response[1].map((item) => {
        return {
          ...item,
          type: 'corporate'
        }
      }))
      setDemandNotes(all)
    }).catch((error) => {
      console.error('error', error)
    }).finally(() => {
      setLoadingDemandNotes(false)
    })
  }, [])

  useEffect(() => {
    customInputs.forEach((input) => {
      // @ts-ignore
      if (!Registry.get(input.id)) {
        Registry.register(input.id, input.component)
      }
    })
    // create custom fields for demand notes
    const demandNoteFields = []
    for (let i = 0; i < demandNotes?.length; i++) {
      const item = demandNotes?.[i]
      const setting = getDemandNoteOption(item)
      if (!Registry.get(setting.id)) {
        Registry.register(setting.id, setting.setting.component)
        demandNoteFields.push(setting.setting)
      }
    }
    setSetupDone(true)
  }, [demandNotes])

  useEffect(() => {
    if (form?.project) {
      getProjects(form?.project).then((res) => {
        if (res?.backgroundImage) {
          setProjectBgImage(res.backgroundImage)
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  }, [form?.project])

  const onSubmit = (data) => {
    setSubmitting(true)
    submitCustomForm(formId, data).then((response) => {
      setSubmitting(false)
      setSubmitted(true)
      if (onSuccessfulSubmit) {
        onSuccessfulSubmit?.()
      }
    }).finally(() => {
      console.log(data)
      setSubmitting(false)
    })
  }

  if (loading || loadingDemandNotes || !setupDone) {
    return <div>Loading...</div>
  }
  if (!form) {
    return <div>Form not found</div>
  }
  return (
    <div className='w-full flex h-full justify-center items-center bg-cover p-2' style={{ backgroundImage: `url(${projectBgImage || bgImage})` }}>
      <div className='w-full md:max-w-[800px] md:max-h-[90vh] overflow-y-auto'>
        {submitted &&
          <div className='py-8 sm:py-16 px-4 flex items-center justify-center flex-col bg-white'>
            <h1 className='text-2xl font-bold text-center'>Form submitted successfully</h1>
          </div>
        }
        {!submitted &&
          <div className='w-full flex flex-col gap-4 rounded-lg bg-white p-4 [&>div]:[&>div]:[&>form]:!w-full'>
            <h1 className='text-2xl font-bold text-center py-3'>{form.name}</h1>
            <ReactFormGenerator
              locale='en'
              read_only={readOnly ?? submitting}
              data={form?.fields || []}
              form_action={`${process.env.REACT_APP_BE_URL}/api/customforms/${formId}/submit`}
              form_method='POST'
              onSubmit={onSubmit}
              submitButton={
                // @ts-ignore
                <Button
                  type='submit'
                  style={{ width: '100%', backgroundColor: 'black', borderColor: 'black', textAlign: 'center', display: 'inline-flex', alignItems: 'center', marginTop: '12px' }}
                  className='btn btn-primary'
                >
                  Submit
                </Button>
              }
            />
          </div>
        }
      </div>
    </div>
  )
}

export default PublicForm
