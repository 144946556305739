/* eslint-disable */
// @ts-nocheck
import React, { useState, useCallback } from 'react'

import { BuyerSideMenu, TMenuIcon } from 'components'
import { Link, useHistory } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import CustomButton from 'components/CustomButton'
import { LOGOUT } from 'routes'
import { LogOut } from 'lucide-react'
import { logout } from 'services/auth'
import { useDispatch } from 'react-redux'
import { CHANGE_USER_LOGGED_STATUS, CLEAR_APP_REDUCER } from 'store/actions/actionTypes'
import CustomModal from 'ui/customModal';

const navItems = [
  { name: 'Dashboard', path: '/buyer/dashboard' },
  { name: 'Offers', path: '/buyer/dashboard' },
  { name: 'Notification', path: '/buyer/dashboard' },
  { name: 'Calendar', path: '/buyer/dashboard' },
  { name: 'Documents', path: '/buyer/documents' },
  { name: 'Deposits', path: '/buyer/dashboard' },
  { name: 'Profile', path: '/buyer/dashboard' },
]

const BuyerContainer = (props) => {
  const { children } = props
  const [showMenu, setShowMenu] = useState(false)
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const OpenMenuButton = () => (
    <button
      onClick={() => {
        setShowMenu(true)
      }}
      className={`text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 z-10 ${showMenu && 'hidden'}`}
    >
      <span className='sr-only'>Open sidebar</span>
      <TMenuIcon className='h-8 w-8' />
    </button>
  )

  const handleLogout = async () => {
    await logout()
    history.push(LOGOUT)
    dispatch({ type: CHANGE_USER_LOGGED_STATUS, payload: false })
    dispatch({ type: CLEAR_APP_REDUCER })
    setShowLogoutModal(false)
  }

  const confirmLogoutModal = useCallback(() => {
    return (
      <CustomModal
        showModal={showLogoutModal}
        onCancel={() => setShowLogoutModal(false)}
        onOk={handleLogout}
        minHeight={false}
      >
        <div className='min-w-[95%] md:min-w-[600px] md:min-h-[250px] flex flex-col gap-3'>
          <h2 className='text-2xl mb-0'>Are you sure?</h2>
          <h4 className='text-xl mb-2'>Do you want to quit?</h4>
          <div className='flex-1 w-full mt-2 flex justify-center md:justify-end items-end gap-3'>
            <CustomButton
              variant='ghost'
              handleClick={() => setShowLogoutModal(false)}
              className='min-w-[150px]'
            >
              <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                Cancel
              </span>
            </CustomButton>
            <CustomButton variant='danger' handleClick={handleLogout} className='min-w-[150px]'>
              <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                Yes
              </span>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    );
  }, [handleLogout, showLogoutModal, setShowLogoutModal]);

  return (
    <>
      <div className='h-screen w-screen flex flex-col' id='buyerWrapper' >
        <div className='w-full px-6 lg:px-12 py-4 lg:py-3.5 bg-[#1f1f1f] flex items-start gap-12 transition-all' >
          {
            isMobile ? (
              <div className='w-full flex items-center justify-between' >
                <div className='h-12 justify-start items-center gap-[17px] inline-flex'>
                  <div className='w-12 h-12 p-2 rounded-[100px] border border-white flex justify-center items-center gap-2' >
                    <div className='text-center text-white text-[8px] font-normal leading-[11.20px]' >
                      YOUR LOGO HERE
                    </div>
                  </div>
                  <div className='flex-col justify-start items-start inline-flex' >
                    <div className='text-white text-base font-medium leading-normal' >
                      Property Name
                    </div>
                    <div className='opacity-50 text-neutral-50 text-sm font-normal leading-tight' >
                      0000 Property Address
                    </div>
                  </div>
                </div>
                <OpenMenuButton />
              </div>
            ) : (
              <div className='w-full flex items-center justify-between' >
                <div className='w-fit flex items-start gap-12' >
                  {
                    navItems.map((item) => (
                      <Link
                        key={item.name}
                        to={item.path}
                        className='text-white text-sm font-medium leading-tight'
                      >
                        {item.name}
                      </Link>
                    ))
                  }
                </div>
                <CustomButton
                  className='flex items-center gap-2 text-base font-medium bg-white'
                  handleClick={() => setShowLogoutModal(true)}
                >
                  <span className='font-medium py-2 px-3 text-sm flex gap-2 items-center text-black'>
                    <LogOut className='w-4 h-4 text-red-500' />
                    Log out
                  </span>
                </CustomButton>
              </div>
            )}
        </div>
        <BuyerSideMenu showMenu={showMenu} setShowMenu={setShowMenu} />
        <div className='w-full flex-1 overflow-x-hidden bg-siteColor' > {children} </div>
      </div>
      {confirmLogoutModal()}
    </>
  )
}

export default BuyerContainer
