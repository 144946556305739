// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "components/ShadcnComponents/card";
import { Button } from "components/ShadcnComponents/button";
import { Input } from "components/ShadcnComponents/input";
import CustomSelect from "components/Inventory/components/CustomSelect";
import LoadingAnimation from "assets/lottieJsons/tableLoading.json";
import {
  getInventorieCountProject,
  getInventorySettings,
  updateInventory,
} from "store/actions/inventoryActions";
import toast from "react-hot-toast";
import Checkbox from "components/Checkbox";
import { CloseIcon, LottieWrapper } from "components";
import { Save, Settings, X, Copy, Plus } from "lucide-react";
import { AgGridReact } from "ag-grid-react";
import CustomModal from "components/Modal";
import { getBuildings, getBuildingsByProjectId } from "store/actions/buildingActions";

function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

const InventoryFieldsTable = ({ staticFields, additionalFields, onFieldToggle, onAddField, onEditField, selectedInventoryType, projectId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopyUrlModalOpen, setIsCopyUrlModalOpen] = useState(false);
  const [buildings, setBuildings] = useState([]);
  const [editingField, setEditingField] = useState(null);
  const [fieldForm, setFieldForm] = useState({
    name: '',
    dataType: 'text'
  });

  const columnDefs = [
    {
      headerName: "Name",
      field: "name",
      width: 340,
      cellRenderer: (params) => (
        <div 
          className={!params.data.isStatic ? "cursor-pointer hover:text-blue-600" : ""}
          onClick={() => {
            if (!params.data.isStatic) {
              setEditingField(params.data);
              setFieldForm({
                name: params.data.name,
                dataType: params.data.dataType.toLowerCase()
              });
              setIsModalOpen(true);
            }
          }}
        >
          {params.data.name}
        </div>
      )
    },
    {
      headerName: "Type",
      field: "type",
      width: 120,
      cellRenderer: (params) => (
        <div className="flex items-center">
          {params.data.isStatic ? (
            <span className="px-2 py-1 text-xs rounded-full bg-blue-100 text-blue-800">
              Default
            </span>
          ) : (
            <span className="px-2 py-1 text-xs rounded-full bg-purple-100 text-purple-800">
              Custom
            </span>
          )}
        </div>
      )
    },
    {
      headerName: "Data type",
      field: "dataType",
      width: 150,
      cellRenderer: (params) => (
        <div className="text-gray-600">{params.data.dataType}</div>
      )
    },
    {
      headerName: "Enabled",
      field: "enabled",
      width: 140,
      cellRenderer: (params) => (
        <Checkbox
          checked={params.data.enabled}
          handleChange={() => onFieldToggle(params.data.index, params.data.isStatic, 'enabled')}
          className="h-5 w-5"
        />
      )
    },
    {
      headerName: "API",
      field: "api",
      width: 100,
      cellRenderer: (params) => (
        <Checkbox
          checked={params.data.api}
          disabled={!params.data.enabled}
          handleChange={() => onFieldToggle(params.data.index, params.data.isStatic, 'api')}
          className="h-5 w-5"
        />
      )
    }
  ];

  const rowData = [
    ...staticFields.map((field, index) => ({
      name: field?.name,
      type: "Default",
      dataType: field?.type,
      enabled: field?.enabled || false,
      api: field?.api || false,
      index,
      isStatic: true
    })),
    ...additionalFields.map((field, index) => ({
      name: field?.name,
      type: "Custom",
      dataType: field?.type || field?.dataType || 'Text',
      enabled: field?.enabled || false,
      api: field?.api || false,
      index,
      isStatic: false
    }))
  ];

  const handleModalSubmit = () => {
    if (editingField) {
      onEditField(editingField.index, fieldForm);
    } else {
      onAddField(fieldForm);
    }
    setIsModalOpen(false);
    setEditingField(null);
    setFieldForm({ name: '', dataType: 'text' });
  };

  const handleCopyApiUrl = (projectId, buildingId) => {
    const allFields = [
      ...staticFields.map((field, index) => ({ ...field, isStatic: true, index })),
      ...additionalFields.map((field, index) => ({ ...field, isStatic: false, index }))
    ];

    const apiFields = allFields.filter(field => field.api);
    const fieldsParam = apiFields
      .map(field => field.id)
      .join(',');

    const baseUrl = `${process.env.REACT_APP_BE_URL}/inventory/list/${projectId}/${buildingId}`;

    const apiUrl = `${baseUrl}`;
    navigator.clipboard.writeText(apiUrl)
      .then(() => {
        toast.success('API URL copied to clipboard');
      })
      .catch(() => {
        toast.error('Failed to copy API URL');
      });
  };

  const handleCopyPublicApiUrl = (projectId, buildingId) => {
    const allFields = [
      ...staticFields.map((field, index) => ({ ...field, isStatic: true, index })),
      ...additionalFields.map((field, index) => ({ ...field, isStatic: false, index }))
    ];

    const apiFields = allFields.filter(field => field.api);
    const fieldsParam = apiFields
      .map(field => field.id)
      .join(',');

    const baseUrl = `${process.env.REACT_APP_BE_URL}/inventory/list/public/${projectId}/${buildingId}`;

    const apiUrl = `${baseUrl}`;
    navigator.clipboard.writeText(apiUrl)
      .then(() => {
        toast.success('API URL copied to clipboard');
      })
      .catch(() => {
        toast.error('Failed to copy API URL');
      });
  };

  useEffect(() => {
    if (isCopyUrlModalOpen) {
      getBuildingsByProjectId(projectId).then(response => {
        setBuildings(response);
      }).catch(error => {
        toast.error('Failed to fetch buildings');
      });
    }
  }, [isCopyUrlModalOpen, projectId]);

  const CopyUrlModal = () => (
    <CustomModal
      isOpen={isCopyUrlModalOpen}
      closeModal={() => setIsCopyUrlModalOpen(false)}
      customStyles={{
        width: '600px'
      }}
    >
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Copy API URL</h3>
          <X 
            className="cursor-pointer" 
            onClick={() => setIsCopyUrlModalOpen(false)}
          />
        </div>
        <div className="space-y-4">
          {buildings.map(building => (
            <div key={building._id} className="flex justify-between items-center p-3 border rounded-lg">
              <span>{building.name}</span>
              <div className="flex items-center gap-2">
              <Button
                variant="outline"
                onClick={() => handleCopyPublicApiUrl(projectId,building._id)}
                className="flex items-center gap-2"
              >
                <Copy className="w-4 h-4" />
                Public URL
              </Button>
              <Button
                variant="outline"
                onClick={() => handleCopyApiUrl(projectId,building._id)}
                className="flex items-center gap-2"
              >
                <Copy className="w-4 h-4" />
                API URL
              </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </CustomModal>
  );

  const handleCopyButtonClick = () => {
    setIsCopyUrlModalOpen(true);
  };

  return (
    <div className="w-full">
      <div className="mb-4 flex justify-between items-center">
        <div className="flex gap-2">
          <Button 
            onClick={() => {
              setEditingField(null);
              setFieldForm({ name: '', dataType: 'text' });
              setIsModalOpen(true);
            }}
            className="flex items-center gap-2"
          >
            <Plus className="w-4 h-4" />
            Add Field
          </Button>
        </div>
        <Button
          variant="outline"
          onClick={handleCopyButtonClick}
          className="flex items-center gap-2"
        >
          <Copy className="w-4 h-4" />
          API URL
        </Button>
      </div>

      <CustomModal
        isOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
          setEditingField(null);
          setFieldForm({ name: '', dataType: 'text' });
        }}
        customStyles={{
          width: '400px'
        }}
      >
        <div className="p-4">
          <div 
          onClick={()=>{
            setIsModalOpen(false);
            setEditingField(null);
            setFieldForm({ name: '', dataType: 'text' });
          }}
          className="absolute right-3 top-2">
            <CloseIcon />
          </div>
          <h3 className="text-lg font-semibold mb-4">
            {editingField ? 'Edit Field' : 'Add New Field'}
          </h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Field Name
              </label>
              <Input
                value={fieldForm.name}
                onChange={(e) => setFieldForm(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Enter field name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Data Type
              </label>
              <CustomSelect
                options={[
                  { value: 'text', label: 'Text' },
                  { value: 'number', label: 'Number' }
                ]}
                value={fieldForm.dataType}
                handleChange={(value) => setFieldForm(prev => ({ ...prev, dataType: value }))}
              />
            </div>
            <div className="flex justify-end gap-2 mt-6">
              <Button
                variant="outline"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={handleModalSubmit}
                disabled={!fieldForm.name.trim()}
              >
                {editingField ? 'Save Changes' : 'Add Field'}
              </Button>
            </div>
          </div>
        </div>
      </CustomModal>

      <CopyUrlModal />

      <div className="ag-theme-alpine h-[500px]">
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true
          }}
          suppressScrollOnNewData={true}
        />
      </div>
    </div>
  );
};

const EditProjectInventoryManagement = () => {
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [selectedInventoryType, setSelectedInventoryType] = useState(null);
  const [inventoryFields, setInventoryFields] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [staticFields, setStaticFields] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [newFieldName, setNewFieldName] = useState("");
  const [inventoryCount, setInventoryCount] = useState(0);

  const [inventoryData, setInventoryData] = useState([]);

  useEffect(() => {
    fetchInventorySettings();
  }, [projectId]);

  useEffect(()=>{
    getInventorieCountProject('project/'+ projectId).then((response) => {
     setInventoryCount(response)
    })
  },[projectId])

  const fetchInventorySettings = async () => {
    setIsLoading(true);
    try {
      const settings = await getInventorySettings(projectId);
      if (settings && settings.length > 0) {
        const data = settings[0];
        setInventoryData(data);
        if (data.type) {
          setSelectedInventoryType(data.type);
          processInventoryData(data.type, data[`${data.type}Data`]);
        }
      }
    } catch (error) {
      console.error("Error fetching inventory settings:", error);
      toast.error("Failed to load inventory settings");
    } finally {
      setIsLoading(false);
    }
  };
  const processInventoryData = (type, fieldData) => {
    const mappedStaticFields = Object.entries(fieldData)
      .filter(([key]) => key !== "additionalFields")
      .map(([key, value]) => ({
        name: key
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase()),
        id: key,
        enabled: value.enabled,
        type: value?.type || "default",
        api: value.api
      }));

    const additionalFieldsData = fieldData.additionalFields || [];

    setStaticFields(mappedStaticFields);
    setAdditionalFields(additionalFieldsData);
  };

  const handleInventoryTypeChange = (value) => {
    setSelectedInventoryType(value);
    if (inventoryData && inventoryData[`${value}Data`]) {
      processInventoryData(value, inventoryData[`${value}Data`]);
    } else {
      setStaticFields([]);
      setAdditionalFields([]);
    }
  };

  const handleFieldToggle = (index, isStatic, field = 'enabled') => {
    if (isStatic) {
      setStaticFields(prev => 
        prev.map((f, i) => {
          if (i === index) {
            if (field === 'enabled' && !f.enabled) {
              return { ...f, enabled: true };
            } else if (field === 'enabled' && f.enabled) {
              return { ...f, enabled: false };
            }
            return { ...f, [field]: !f[field] };
          }
          return f;
        })
      );
    } else {
      setAdditionalFields(prev => 
        prev.map((f, i) => {
          if (i === index) {
            if (field === 'enabled' && !f.enabled) {
              return { ...f, enabled: true };
            } else if (field === 'enabled' && f.enabled) {
              return { ...f, enabled: false };
            }
            return { ...f, [field]: !f[field] };
          }
          return f;
        })
      );
    }
  };

  const handleAddNewField = (fieldData) => {
    const newField = {
      name: fieldData.name.trim(),
      enabled: false,
      api: false,
      type: fieldData.dataType === 'number' ? 'number' : 'text',
      id: makeid(10),
    };
    setAdditionalFields(prev => [...prev, newField]);
  };

  const handleEditField = (index, fieldData) => {
    setAdditionalFields(prev => 
      prev.map((field, i) => 
        i === index 
          ? { 
              ...field, 
              name: fieldData.name.trim(),
              dataType: fieldData.dataType === 'number' ? 'Number' : 'Text'
            }
          : field
      )
    );
  };

  const refetch = async () => {
    await fetchInventorySettings();
  };

  const handleSave = async () => {
    try {
      const updatedStaticFields = staticFields.reduce((acc, field) => {
        acc[field.id] = { enabled: field.enabled, type: field.type, api: field.api };
        return acc;
      }, {});

      console.log(updatedStaticFields)

      const updatedData = {
        id: inventoryData?._id || null,
        type: selectedInventoryType,
        [`${selectedInventoryType}Data`]: {
          ...updatedStaticFields,
          additionalFields: additionalFields,
        },
      };

      await updateInventory(updatedData);
      await refetch();
      toast.success("Inventory settings updated successfully");
    } catch (error) {
      console.error("Error updating inventory settings:", error);
      toast.error("Error updating inventory settings");
    }
  };
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8 py-10">
        <div className="flex flex-col gap-4 mb-6">
          <h1 className="text-3xl font-bold text-gray-900">
            Inventory Management
          </h1>
          <div className="max-w-[400px]">
            <CustomSelect
              options={[
                { value: "lot", label: "Lot" },
                { value: "condo", label: "Condo" },
                { value: "townhome", label: "Townhome" },
              ]}
              value={selectedInventoryType}
              placeholder="Select inventory type"
              handleChange={handleInventoryTypeChange}
              disabled={inventoryCount > 0}
            />
          </div>
        </div>
        <Card className="bg-white rounded-lg overflow-hidden">
          <div className="p-6">{renderContent()}</div>
        </Card>
      </div>
    </div>
  );

  function renderContent() {
    if (!selectedInventoryType) {
      return (
        <div className="flex flex-col items-center justify-center h-64 text-gray-500">
          <Settings className="w-16 h-16 mb-4" />
          <p className="text-xl font-medium">
            Select an inventory type to manage fields
          </p>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="flex flex-col items-center justify-center h-64">
          <LottieWrapper
            animation={LoadingAnimation}
            loop
            className="h-24 w-24"
          />
          <p className="mt-4 text-gray-600 text-xl">Loading fields...</p>
        </div>
      );
    }

    return (
      <div>
        <h2 className="text-2xl font-semibold text-gray-800 mb-6">
          Edit {selectedInventoryType} Fields
        </h2>

        <InventoryFieldsTable 
          staticFields={staticFields}
          additionalFields={additionalFields}
          onFieldToggle={handleFieldToggle}
          onAddField={handleAddNewField}
          onEditField={handleEditField}
          selectedInventoryType={selectedInventoryType}
          projectId={projectId}
        />

        <div className="flex justify-end mt-6">
          <Button
            onClick={handleSave}
            className="bg-blue-600 text-white hover:bg-blue-700 px-6 py-3 rounded-md text-lg font-semibold transition duration-200 flex items-center"
          >
            <Save className="w-5 h-5 mr-2" />
            Save Changes
          </Button>
        </div>
      </div>
    );
  }
};

export default EditProjectInventoryManagement;
