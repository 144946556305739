/* eslint-disable */
// @ts-nocheck
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form, Row, Col, message } from 'antd'
import 'antd/dist/antd.css'
import CustomButton from 'components/CustomButton'
import CustomInput from 'components/CustomInput'
import { AgGridReact } from 'ag-grid-react'
import moment from 'moment'
import CustomModal from 'ui/customModal'
import { useSelector } from 'react-redux'
import TableWithPagination from 'components/TableWithPagination'
import { LottieWrapper } from 'components'
import loadingAnimation from "assets/lottieJsons/tableLoading.json";
import { EditIcon } from 'lucide-react'

const DepositOptionsTable = ({ data, loading, handleCreateOrUpdate }) => {
  const gridRef = useRef(null);
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isActivationStatusUpdateModalOpen, setIsActivationStatusUpdateModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [currentOption, setCurrentOption] = useState(null)
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
    }
  }, [])
  const [formData, setFormData] = useState({
    percentage1: null,
    percentage2: null,
    percentage3: null,
    percentage4: null,
    discountPercentage: null,
    depositOptionName: '',
    project: projectId
  })
  const [errors, setErrors] = useState({});

  const showModal = (record = null) => {
    setIsEdit(!!record)
    setCurrentOption(record)
    if (record) {
      setFormData({
        percentage1: record.optionPercentages[0],
        percentage2: record.optionPercentages[1],
        percentage3: record.optionPercentages[2],
        percentage4: record.optionPercentages[3],
        discountPercentage: record.discountPercentage,
        depositOptionName: record.depositOptionName,
        project: projectId,
        isActivated: record.isActivated
      })
    } else {
      setFormData({
        percentage1: null,
        percentage2: null,
        percentage3: null,
        percentage4: null,
        discountPercentage: null,
        depositOptionName: '',
        project: projectId,
        isActivated: true
      })
    }
    setIsModalOpen(true)
  }

  const showActivationStatusUpdateModal = (record = null) => {
    setCurrentOption(record)
    setIsActivationStatusUpdateModalOpen(true)
  }

  const handleSubmit = () => {
    if (isValid) {
      const percentages = [
        formData.percentage1 || 0,
        formData.percentage2 || 0,
        formData.percentage3 || 0,
        formData.percentage4 || 0,
      ]

      const formValues = {
        optionPercentages: percentages,
        discountPercentage: formData.discountPercentage,
        depositOptionName: formData.depositOptionName,
        project: projectId
      }

      let temp = {}
      if (currentOption?._id) {
        temp = { _id: currentOption?._id, ...formValues }
      } else {
        temp = { ...formValues }
      }

      handleCreateOrUpdate(temp)

      setErrors({})
      setIsModalOpen(false)
    }
  }

  const handleActivationStatusUpdate = () => {
    const temp = { _id: currentOption?._id, isActivated: !currentOption?.isActivated }

    handleCreateOrUpdate(temp)
    setCurrentOption(null)
    setIsActivationStatusUpdateModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
    setErrors({})
    setFormData({
      percentage1: null,
      percentage2: null,
      percentage3: null,
      percentage4: null,
      discountPercentage: null,
      depositOptionName: '',
      project: projectId
    })
  }

  const handleActivationStatusUpdateModalClose = () => {
    setIsActivationStatusUpdateModalOpen(false)
    setTimeout(() => {
      setCurrentOption(null)
    }, 300)
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name?.toLowerCase()?.search('percentage') >= 0) {
      setFormData({ ...formData, [name]: Number(value) })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }

  const columnDefs = [
    {
      headerName: 'Deposit Option Name',
      field: 'depositOptionName',
      sortable: true,
      filter: true,
      flex: 1,
      minWidth: 400,
      // pinned: 'left'
    },
    {
      headerName: 'Discount Percentage',
      field: 'discountPercentage',
      sortable: true,
      filter: true,
      // pinned: 'left',
      // flex: 1,
      cellRenderer: (params) => (params.data?.discountPercentage + '%')
    },
    {
      headerName: 'Option Percentages',
      field: 'optionPercentages',
      sortable: true,
      filter: true,
      width: 350,
      cellRenderer: (params) => (params.data?.optionPercentages?.map((e) => (`${e}%`))?.join(', '))
    },
    {
      headerName: 'Activation Status',
      field: 'isActivated',
      sortable: true,
      filter: true,
      cellRenderer: (params) => (<span className={`h-fit px-3 my-1 rounded-full leading-6 ${params.data?.isActivated ? 'bg-green-500' : 'bg-red-500'} text-white`}>{params.data?.isActivated ? 'Activated' : 'Deactivated'}</span>)
    },
    {
      headerName: 'Action',
      field: '_id',
      sortable: true,
      filter: true,
      width: 300,
      cellRenderer: (params) => (
        <div className='w-full justify-center items-center flex gap-2'>
          <CustomButton variant='outline' handleClick={() => showActivationStatusUpdateModal(params.data)} className='!border-softBlack'>
            <span className='font-normal px-4 py-2 text-base flex items-center gap-2'>
              {params?.data?.isActivated ? 'Deactivate' : 'Activate'}
            </span>
          </CustomButton>
          <CustomButton handleClick={() => showModal(params.data)} disabled={!params?.data?.isActivated}>
            <span className='font-normal p-2 text-base flex items-center gap-2'>
              <EditIcon />
            </span>
          </CustomButton>
        </div>
      ),
      cellStyle: { textAlign: 'right' }
    }
  ]

  const isValid = useMemo(() => {
    let tempIsValid = true

    const percentages = [
      formData.percentage1,
      formData.percentage2,
      formData.percentage3,
      formData.percentage4
    ];

    if (percentages.some(percentage => percentage === undefined || percentage === null || percentage < 0 || percentage > 100)) {
      tempIsValid = false
    }

    if (Number(percentages.reduce((sum, val) => sum + val, 0)) !== 100) {
      tempIsValid = false
    }

    if (formData.discountPercentage === undefined || formData.discountPercentage === null || formData.discountPercentage < 0 || formData.discountPercentage > 100) {
      tempIsValid = false
    }

    if (!formData.depositOptionName || formData.depositOptionName.trim() === '') {
      tempIsValid = false
    }

    return tempIsValid
  }, [formData, currentOption])

  return (
    <div className='w-full flex flex-col gap-y-5 overflow-hidden'>
      {
        loading
          ? <div className='w-full h-[500px] bg-white flex flex-col gap-2 justify-center items-center rounded-b-md'>
            <LottieWrapper
              animation={loadingAnimation}
              autoplay
              loop={true}
              className="-mt-40 md:mt-0 max-w-sm h-[80px] w-[80px]"
            />
            <p className='text-center mb-0'>Loading...</p>
          </div>
          : <>
            {
              data.length > 0
                ? <>
                  <div className='w-full flex justify-end'>
                    <CustomButton handleClick={() => showModal()}>
                      <span className='font-medium px-4 py-2 text-base flex items-center gap-2'>
                        Add New Option
                      </span>
                    </CustomButton>
                  </div>
                  <div className='w-full flex flex-col gap-4'>
                    <TableWithPagination
                      columns={columnDefs}
                      rowData={data}
                      rowHeight={50}
                      hidePagination
                    />
                  </div>
                </>
                : <div className='w-full min-h-[150px] flex flex-col gap-4 justify-center items-center rounded-xl border bg-card text-lg font-medium text-card-foreground bg-white overflow-hidden p-3'>
                  No deposit options found, add new one
                  <div className='w-full flex justify-center'>
                    <CustomButton handleClick={() => showModal()}>
                      <span className='font-medium px-4 py-2 text-base flex items-center gap-2'>
                        Add New Option
                      </span>
                    </CustomButton>
                  </div>
                </div>
            }
          </>
      }
      <CustomModal showModal={isModalOpen} onCancel={handleCancel} minHeight={false}>
        <div className='w-[500px] flex flex-col fle-col gap-6'>
          <h5 className='mb-0 font-medium text-2xl'>{isEdit ? 'Edit' : 'Create'} Deposit Option</h5>
          <div>
            <CustomInput
              name='depositOptionName'
              label='Deposit Option Name *'
              value={formData.depositOptionName}
              onChange={handleChange}
              placeholder='Deposit Option Name'
              containerClasses='w-full'
            />
          </div>
          <div>
            <CustomInput
              type='number'
              name='discountPercentage'
              label='Discount Percentage (0% to 100%) *'
              value={formData.discountPercentage || null}
              onChange={handleChange}
              placeholder='Discount Percentage'
              containerClasses='w-full'
            />
          </div>
          <div className='relative mb-2'>
            <p class="mt-0 mb-2 text-softBlack70 text-sm font-light uppercase">Enter Option Percentages in four parts *</p>
            <div className='flex gap-2'>
              {[1, 2, 3, 4].map(num => (
                <CustomInput
                  key={num}
                  type='number'
                  name={`percentage${num}`}
                  value={formData[`percentage${num}`] || null}
                  onChange={handleChange}
                  placeholder='Enter'
                  containerClasses='flex-1'
                />
              ))}
            </div>
            <div className='absolute'>
              <p className='my-0 text-xs mt-1 text-zinc-800'>
                * Note: Total percentage must not exceed 100%
              </p>
            </div>
          </div>
          <div className='w-full flex justify-end items-center gap-3'>
            <CustomButton variant='outline' handleClick={handleCancel}>
              <span className='font-medium px-4 py-2 text-base flex items-center gap-2'>
                Cancel
              </span>
            </CustomButton>
            <CustomButton handleClick={handleSubmit} disabled={!isValid}>
              <span className='font-medium px-4 py-2 text-base flex items-center gap-2'>
                {isEdit ? 'Update' : 'Create'}
              </span>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        showModal={isActivationStatusUpdateModalOpen}
        onCancel={handleActivationStatusUpdateModalClose}
        minHeight={false}
      >
        <div className='w-[500px] flex flex-col fle-col gap-6'>
          <h5 className='mb-0 font-medium text-2xl'>Do you want to {!currentOption?.isActivated ? 'activate' : 'deactivate'} deposit option?</h5>
          <div className='w-full flex justify-end items-center gap-3'>
            <CustomButton
              variant='outline'
              handleClick={handleActivationStatusUpdateModalClose}
            >
              <span className='font-medium px-4 py-2 text-base flex items-center gap-2'>
                Cancel
              </span>
            </CustomButton>
            <CustomButton handleClick={handleActivationStatusUpdate}>
              <span className='font-medium px-4 py-2 text-base flex items-center gap-2'>
                {!currentOption?.isActivated ? 'Activate' : 'Deactivate'}
              </span>
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default DepositOptionsTable
