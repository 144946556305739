/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
// @ts-nocheck
import CustomButton from 'components/CustomButton'
import React, { useEffect, useState } from 'react'
import { api } from 'services/api'

// eslint-disable-next-line space-before-function-paren
export default function PauseResumeQueue(props) {
    const queueId = props.queue
    const [loading, setLoading] = useState(true)
    const [isQueuePaused, setQueueStatus] = useState(null)
    const [count, setCount] = useState(0)
    useEffect(() => {
        api.get(`/queues/${queueId}`).then((response) => {
            console.log(response)
            setQueueStatus(response.data?.isPaused)
        }).then(() => {
            setLoading(false)
        })
    }, [queueId, count])

    function pauseQueue() {
        api.post(`/queues/${queueId}/pause`, {
            time: new Date()
        }).then((response) => {
            refresh()
        }).catch((error) => {
            console.error(error)
            alert(error)
        })
    }
    function resumeQueue() {
        api.post(`/queues/${queueId}/resume`, {
            time: new Date()
        }).then((response) => {
            refresh()
        }).catch((error) => {
            console.error(error)
            alert(error)
        })
    }

    function refresh() {
        setCount(e => e + 1)
    }
    if (loading) {
        return <div></div>
    }

    return (
        <div>
            {isQueuePaused !== null && (
                <CustomButton
                    className={`px-2 py-2 mx-2 ${isQueuePaused ? 'bg-green-500' : 'bg-red-500'}`}
                    handleClick={() => {
                        // eslint-disable-next-line no-restricted-globals
                        const confirmOp = confirm('Are you sure you want to change the queue status?')
                        if (!confirmOp) {
                            return
                        }
                        if (isQueuePaused) {
                            resumeQueue()
                        } else {
                            pauseQueue()
                        }
                    }}>
                    {isQueuePaused ? 'Resume Queue' : 'Pause Queue'}
                </CustomButton>
            )}
        </div>
    )
}
