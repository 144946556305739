/* eslint-disable */
import React, { useContext, useEffect, useRef } from 'react'
import { classNames } from 'utils'
import FlowContext from '../flowContext'
import PlusButton from '../Utils/components/PlusButton'

const CONTAINER_WIDTH = 250

const Container = ({ children, _data, config, selected }) => {
    const context = useContext(FlowContext)
    const iconStyle = config?.iconStyle || {}
    const ref = useRef(null)

    useEffect(() => {
        try {
            const height = ref.current?.offsetHeight || 0
            // @ts-ignore
            context?.setNodeHeight(_data.id, height)
        } catch (e) {
            console.error('setNodeHeight error', e)
        }
    })

    return (
        <FlowContext.Consumer>
            {/* @ts-ignore */}
            {({ lastNode, clearNodeSelection }) => (
                <div
                    ref={ref}
                    className={classNames(
                        'bg-white p-1.5 rounded-lg shadow-md min-w-[250px] ',
                        selected ? 'border-1.5 border-pink-300' : 'border border-border',
                    )}
                    style={{
                        width: CONTAINER_WIDTH,
                        ...config.style || {}
                    }}
                >
                    <div>
                        <div className='flex h-min items-center space-x-1'>
                            <div className={classNames(
                                'border border-border rounded-lg p-[3px]',
                            )}
                                style={{
                                    backgroundColor: iconStyle.backgroundColor || 'white',
                                    color: iconStyle.color || 'black',
                                }}
                            >
                                <img src={config.icon} alt={config.label} className='w-2.5 h-2.5' />
                            </div>
                            <div>
                                <div className='text-xs font-semibold'>{config.label}</div>
                                {/* <div className='text-xs'>{config.id}</div> */}
                            </div>
                        </div>
                    </div>
                    {children}
                    {lastNode?.id === _data.id && (
                        <PlusButton
                            left='43.5%'
                            bottom='-50px'
                            translateY={0}
                            translateX={"50%"}
                            callback={(e) => {
                                e?.stopPropagation()
                                if (clearNodeSelection && selected) {
                                    clearNodeSelection?.()
                                }
                            }}
                        />
                    )}
                </div>
            )}
        </FlowContext.Consumer>
    )
}

export default Container
