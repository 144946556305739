import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getCrmReportData } from 'store/actions/usersActions'
import AllAgentsRatingByContactsRating from './components/AllAgentsRatingByContactsRating'
import AllAgentsSalesReport, { ItemType } from './components/AllAgentsSalesReport'
import { DataForAllReports, DataForRickDavis } from './data'
import { PageContainer } from './styles'
import moment from 'moment'

const AdminReports = () => {
  // @ts-ignore
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [reportData, setReportData] = useState([])
  const [reports, setReports] = useState(null)
  const fetchReports = useRef((id) => {})

  fetchReports.current = async (id) => {
    const data = await getCrmReportData(id)
    setReports(data)
  }

  useEffect(() => {
    if (projectId) fetchReports.current(projectId)
  }, [projectId])

  const computeActivityData = useCallback((titles = [], reportsKey = [], dataTitle = []) => {
    let temp = [...DataForAllReports]
    titles.forEach((title, idx) => {
      const isAllTime = idx === 1
      const mainObject = {
        title,
        type: ItemType.NORMAL,
        date: `${moment().format('MMMM DD')}th ${moment().format('YYYY')}`,
        data: []
      }
      reportsKey[idx].forEach((reportKey, index) => {
        mainObject.data.push({
          title: dataTitle[idx][index],
          value: isAllTime ? reports[reportKey]?.all ?? reports[reportKey]?.day + reports[reportKey]?.week + reports[reportKey]?.month : reports[reportKey]?.day,
          type: ItemType.NORMAL
        })
      })
      temp = temp.filter(data => data.title !== title)
      temp = isAllTime ? [...temp.slice(0, 9), mainObject, ...temp.slice(9)] : [...temp.slice(0, 3), mainObject, ...temp.slice(3)]
    })
    return temp
  }, [reports])

  const computeLeads = useCallback((data = [], titles = [], reportKeys = []) => {
    let temp = [...data]
    temp = temp.map(data => {
      let mainData = { ...data }
      titles.forEach((title, index) => {
        if (data.title === title) {
          if (!reports) return data
          const leadSourceData = reports?.[reportKeys?.[index]].map?.(val => {
            const mapping = { type: ItemType.NORMAL, title: val?._id, value: val?.count }
            return mapping
          })
          data.data = leadSourceData
        }
        mainData = data
      })
      return mainData
    })
    return temp
  }, [reports])

  const computeMaleFemale = useCallback((data = [], titles = [], reportKeys = []) => {
    let temp = [...data]
    temp = temp?.map?.(data => {
      titles?.forEach((title, index) => {
        if (data.title === title) {
          if (!reports) return data
          if (!data?.data?.length) return data
          let parentArray = [...data.data]
          parentArray = parentArray.map(itm => {
            if (!itm.value?.data?.length) return itm
            let arr = [...itm.value?.data]
            arr = arr?.map?.(item => {
              if (item.title === 'FEMALE') item.value = reports?.[reportKeys?.[index]]?.femaleLeadToday ?? reports?.[reportKeys?.[index]]?.[1]?.female
              else if (item.title === 'MALE') item.value = reports?.[reportKeys?.[index]]?.maleLeadToday ?? reports?.[reportKeys?.[index]]?.[0]?.male
              return item
            })
            itm.value.data = arr
            return itm
          })
          data.data = parentArray
          return data
        }
      })
      return data
    })
    return temp
  }, [reports])

  const computeTodayData = useCallback((data = [], titles = [], reportKeys = [], dataKeys = []) => {
    let temp = [...data]
    temp = temp.map(data => {
      titles.forEach((title, idx) => {
        if (data.title === title) {
          if (!reports) return data
          if (!data?.data?.length) return data
          data.data = data.data.map(item => {
            dataKeys[idx].forEach((dataKey, index) => {
              if (item.title === dataKey) item.value = JSON.stringify(reports[reportKeys[idx][index]]?.today)?.includes?.('{') ? 0 : (reports[reportKeys[idx][index]]?.today || 0)
            })
            return item
          })
        }
      })
      return data
    })
    return temp
  }, [reports])

  useEffect(() => {
    if (!reports) return
    const dataWithActivities = computeActivityData(
      ['Today\'s Activity - All Sales Reps', 'All Time\'s Activity - All Sales Reps'],
      [['totalCallsUrl', 'totalTasksUrl', 'totalMeetingsUrl'], ['allTimeCalls', 'allTimeTasks']],
      [['CALLS', 'TASK', 'VIRTUAL MEETING BOOKED'], ['CALLS', 'TASKS']]
    )
    const dataWithLeadSoruces = computeLeads(
      dataWithActivities,
      [
        'Today\'s Leads By Source - All Sales Reps',
        'All Time\'s Leads By Source - All Sales Reps',
        'All Time\'s Leads By Stage - All Sales Reps',
        'All Time\'s Demand Units - All Sales Reps',
        'All Time\'s Demand Budget - All Sales Reps',
        'All Time\'s Project Interests - All Sales Reps',
        'All Time\'s Floor Interests - All Sales Reps',
        'All Time\'s Timeline to Purchase - All Sales Reps',
        'All Time\'s Needs Financing - All Sales Reps',
        'Today\'s Buyer Rating - All Sales Reps'
      ],
      [
        'leadsBySource',
        'allTimeLeadsBySource',
        'allTimeLeadsByStage',
        'allTimeDemandUnits',
        'allTimeDemanddBudget',
        'allTimeProjectInterests',
        'allTimeFloorInterests',
        'allTimeTimelineToPurchase',
        'allTimeTimelineToPurchase',
        'allTimeNeedsFinancing',
        'todaysBuyerRating'
      ]
    )
    const dataWithMaleAndFemale = computeMaleFemale(
      dataWithLeadSoruces,
      ['Today\'s Summary - All Sales Reps', 'All time\'s Summary - All Sales Reps'],
      ['maleFemaleLeadsToday', 'allTimeMaleFemaleLeads']
    )
    const dataWithLastThreePropsAllSalesRep = computeTodayData(
      dataWithMaleAndFemale,
      ['Today\'s Summary - All Sales Reps', 'All time\'s Summary - All Sales Reps'],
      [
        ['opportunities', 'reservations', 'totalContractsToday', 'totalBuyerLeads', 'totalBrokerLeads', 'totalProspectsToday'],
        ['allTimeOpportunities', 'allTimeReservations', 'allTimeContracts', 'allTimeBuyerLeads', 'allTimeBrokerLeads', 'allTimeProspects', 'allTimeContactSum']
      ],
      [
        ['OPPORTUNITIES', 'RESERVATIONS', 'CONTRACTS', 'BUYER NEW LEADS', 'BROKER NEW LEAD', 'PROSPECT - 2 WAY COMMUNICATIONS'],
        ['OPPORTUNITIES', 'RESERVATIONS', 'CONTRACTS', 'BUYER NEW LEADS', 'BROKER NEW LEAD', 'PROSPECT - 2 WAY COMMUNICATIONS', 'CONTACTS']
      ]
    )
    setReportData(dataWithLastThreePropsAllSalesRep)
  }, [reports, computeActivityData, computeLeads, computeMaleFemale, computeTodayData])

  const Header = () => (
    <div className="relative">
      <div
        style={{ paddingTop: 40 }}
        className="p-6 flex justify-between items-center"
      >
        <span className="font-bold text-3xl font-openSans">
          One Zen - CRM Report - Daily Summary - All Sales Reps
        </span>
      </div>
      <div className="border-b-2 border-black" />
    </div>
  )

  return (
    <>
      <PageContainer>
        <Header />
        {reports
          ? <>
          <AllAgentsSalesReport Data={reportData} Title={`${moment().format('MMMM DD')}th ${moment().format('YYYY')}`}/>
        <AllAgentsSalesReport Data={DataForRickDavis} Title={`${moment().format('MMMM DD')}th ${moment().format('YYYY')} Rick Davis (static)` }isStatic />
        <AllAgentsRatingByContactsRating />
        </>
          : <div className='flex justify-center items-center text-lg p-10'>loading...</div>}
      </PageContainer>
    </>
  )
}

export default AdminReports
