// @ts-nocheck
/* eslint-disable */
import { Modal } from "@material-ui/core"
import { AgGridReact } from "ag-grid-react"
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-alpine.css"
import "../../../../assets/css/ag-theme-balham.css"
import CustomButton from "components/CustomButton"
import { Oval } from "react-loader-spinner"
import { Button } from "components/ShadcnComponents/button"
import { Input } from "components/ShadcnComponents/input"
import { Cross, CrossIcon, Plus } from "lucide-react"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Card } from "components/ShadcnComponents/card"
import {
  createOrUpdateWebsiteConfig,
  deleteTaskWebsiteConfig,
} from "store/actions/websiteReportConfigActions"

const ReportConfig = ({ title, configData, type, resetAllData, projectId }) => {
  const [show, setShow] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const [currentData, setCurrentData] = useState({
    name: null,
    domain: null,
    propertyId: null,
  })
  const gridRef = useRef(null)

  const columnDefs = [
    { headerName: "Name", field: "name", sortable: true, filter: true },
    { headerName: "Domain", field: "domain", sortable: true },
    { headerName: "PropertyId", field: "propertyId", sortable: true },
    {
      headerName: "action",
      cellRenderer: ({ data }) => {
        return (
          <div className="flex gap-2">
            <Button
              onClick={() => {
                setShow(true)
                setCurrentData(data)
              }}
            >
              Update
            </Button>
            <Button
              className="!bg-red-500"
              onClick={() => {
                setDeleteData(true)
                setCurrentData(data)
              }}
            >
              Delete
            </Button>
          </div>
        )
      },
    },
  ]

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    resizable: true,
  }
  return (
    <div>
      <div className="px-6 !py-4 flex justify-between items-center bg-white">
        <span className="font-bold text-3xl font-openSans">{title}</span>
        <div className="flex flex-row items-center">
          <CustomButton handleClick={() => setShow(true)}>
            <span className="font-medium py-2 px-4 text-base flex gap-2 items-center capitalize">
              <Plus className="w-5 h-5" />
              Add Config
            </span>
          </CustomButton>
        </div>
      </div>
      <Card className="bg-white p-2 m-2">
        <div className="ag-theme-alpine" style={{ width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            rowData={configData}
            columnDefs={columnDefs}
            domLayout="autoHeight"
            rowHeight={50}
            defaultColDef={defaultColDef}
            className="bg-white"
          />
        </div>
      </Card>

      <AddReport
        show={show}
        setShow={setShow}
        title={title}
        data={currentData}
        setData={setCurrentData}
        type={type}
        projectId={projectId}
        resetAllData={resetAllData}
      />

      <DeleteConfigData
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        data={currentData}
        type={type}
        setData={setCurrentData}
        projectId={projectId}
        resetAllData={resetAllData}
      />
    </div>
  )
}

export default ReportConfig

const AddReport = ({
  show,
  setShow,
  title,
  data,
  setData,
  type,
  projectId,
  resetAllData,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const domainRegex =
    /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/

  const validateField = (field, value) => {
    switch (field) {
      case 'name':
        return value === "" ? "Name must required*" : null
      case 'domain':
        if (value === "") return "Domain must required*"
        if (!domainRegex.test(value)) return "Invalid Domain"
        return null
      case 'propertyId':
        if (value === "" || value === null) return "Property ID must required*"
        return null
      default:
        return null
    }
  }


  const handleChange = (field) => (e) => {
    const value = e.target.value
    setData((prevData) => {
      const updatedData = { ...prevData, [field]: value }
      const errorMessage = validateField(field, value)
      setError((prevErrors) => ({
        ...prevErrors,
        [field]: errorMessage,
      }))
      return updatedData
    })
  }

  const [error, setError] = useState({
    name: null,
    domain: null,
    propertyId: null,
  })

  const isValid = !Object.values(error).some((val) => val !== null)

  const handleClose = () => {
    setData({ name: "", domain: "", propertyId: null })
    setShow(false)
  }

  const handleSave = async () => {
    if (isValid) {
      try {
        setIsLoading(true)
        await createOrUpdateWebsiteConfig(data, type, projectId)
          .then((response) => {
            handleClose()
            resetAllData()
            setIsLoading(false)
          })
          .catch((error) => console.error(error))
      } catch {
        message.error("")
        setIsLoading(false)
      }
    }
  }

  return (
    <Modal open={show} onClose={handleClose} className="!z-[100]">
      <div
        className="relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        style={{ width: "500px" }}
      >
        <button className="absolute right-10" onClick={handleClose}>
          <Plus className="rotate-45" />
        </button>
        <h1 className="font-bold text-xl text-center capitalize">
          {!data?._id ? "Create" : "Update"} {`Config`}
        </h1>
        <div className="relative w-full h-full flex flex-col gap-2 pt-3">
          <div className="w-full flex flex-col gap-1">
            <div>Name*</div>
            <Input
              className="!h-10 w-full mb-0"
              value={data.name}
              onChange={handleChange('name')}
            />
            {error.name && <div className="text-red-500 text-xs">{error.name}</div>}
          </div>
          <div className="w-full flex flex-col gap-1">
            <div>Domain*</div>
            <Input
              className="!h-10 w-full mb-0"
              value={data.domain}
              onChange={handleChange('domain')}
            />
            {error.domain && <div className="text-red-500 text-xs">{error.domain}</div>}
          </div>
          <div className="w-full flex flex-col gap-1">
            <div>Property Id*</div>
            <Input
              className="!h-10 w-full mb-0 border border-gray-300 rounded-md p-2 focus:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
              type="number"
              value={data.propertyId}
              onWheel={(e) => e.target.blur()}
              onChange={handleChange('propertyId')}
            />
            {error.propertyId && (
              <div className="text-red-500 text-xs">{error.propertyId}</div>
            )}
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <Button
            onClick={handleSave}
            disabled={!isValid || isLoading}
            className="flex gap-2"
          >
            {isLoading && <Oval height="18" width="18" color="white" />}
            {!data?._id ? "Create" : "Save"}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

const DeleteConfigData = ({
  deleteData,
  setDeleteData,
  data,
  setData,
  type,
  resetAllData,
  projectId
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setData({ name: "", domain: "", propertyId: "" })
    setDeleteData(false)
  }

  const handleDelete = async () => {
    try {
      setIsLoading(true)
      await deleteTaskWebsiteConfig(data?._id, type,projectId)
        .then((response) => {
          handleClose()
          resetAllData()
          setIsLoading(false)
        })
        .catch((error) => console.error(error))
    } catch {
      message.error("")
      setIsLoading(false)
    }
  }
  return (
    <Modal open={deleteData} onClose={handleClose}>
      <div
        className="relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        style={{ width: "500px" }}
      >
        <h1 className="font-bold text-xl text-center">Are you sure?</h1>
        <p className="text-base text-center mb-0 my-2">
          Do you want to delete this config ?
        </p>
        <div className="flex justify-center items-center mt-4 gap-2">
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            disabled={isLoading}
            className="!bg-red-500"
          >
            {isLoading ? "Deleting..." : "Delete"}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
