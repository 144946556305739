import * as React from 'react'

const badgeVariants = {
  variant: {
    default:
      'border border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
    secondary:
      'border border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
    destructive:
      'border border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
    white:
      'bg-white text-black',
    black:
      'bg-black text-white',
    success:
      'bg-green-800 text-white',
    pending:
      'bg-yellow-500 text-black',
    failed:
      'bg-red-500 text-black',
    outline: 'text-foreground border'
  }
}

const Badge = ({ className = '', variant = 'default', ...props }) => {
  const baseClasses = 'inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2'
  const variantClasses = badgeVariants.variant[variant] || ''

  const combinedClasses = `${baseClasses} ${variantClasses} ${className}`.trim()

  return (
    <div className={combinedClasses} {...props} />
  )
}

export { Badge, badgeVariants }
