/*eslint-disable */
// @ts-nocheck
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getListDetails, getUserLists, addContactsToList, removeContactsFromList, getSendGridPendingJobs, getListAnalytics, getUserStaticLists, getUsersFromList } from 'store/actions/usersActions'

const useGetLists = (filters = {}) => {
  const userId = useSelector((state) => state.authReducer.userObject)
  const [refetchIndex, setRefetchIndex] = useState(0);

  const { data, isLoading, refetch } = useQuery(
    ["getLists", { ...filters, refetchIndex }],
    () => getUserLists(filters),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!userId,
    }
  )

  const forceRefetch = () => setRefetchIndex((prev) => prev + 1);

  return { lists: data?.data ?? [], totalLists: data?.totalRecords || 0, totalSmartList: data?.totalSmartList || 0, isLoading, refetch, forceRefetch }
}

const useGetStaticLists = (project) => {
  const userId = useSelector((state) => state.authReducer.userObject)
  const projectId = useSelector((state) => state.appReducer.appProject)
  const { data, isLoading, refetch } = useQuery(
    ['getStaticLists'],
    () => getUserStaticLists(project ? project : projectId),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!userId }
  )
  return { lists: data ?? null, isLoading, refetch }
}

// const useGetListsAll = () => {
//   const userId = useSelector((state) => state.authReducer.userObject)
//   const { data, isLoading, refetch } = useQuery(
//     ['getLists'],
//     () => getUserLists(),
//     { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!userId }
//   )
//   return { lists: data ?? null, isLoading, refetch }
// }

const useGetList = (listId) => {
  const userId = useSelector((state) => state.authReducer.userObject)
  const projectId = useSelector((state) => state.appReducer.appProject)
  const { data, isLoading, refetch } = useQuery(
    [`getListDetails-${listId}`],
    () => getListDetails(listId, projectId),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!userId }
  )
  return { data: data?.data, isLoading, refetch }
}

const useListController = () => {
  const projectId = useSelector((state) => state.appReducer.appProject)
  function addToList(listId, contactIds) {
    return addContactsToList(projectId, listId, contactIds)
  }
  function removeFromList(listId, contactIds) {
    return removeContactsFromList(projectId, listId, contactIds)
  }
  return { addToList, removeFromList }
}

const useGetPendingSendGridJobs = (
  type, id
) => {
  const userId = useSelector((state) => state.authReducer.userObject)
  const { data, isLoading, refetch } = useQuery(
    ['getSendGridPendingJobs'],
    () => getSendGridPendingJobs(type, id),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!userId }
  )
  return { data, isLoading, refetch }
}

const useListHistoryController = (listId) => {
  const { data, isLoading, refetch } = useQuery(
    [`getListHistory-${listId}`],
    () => getListAnalytics(listId),
    { refetchOnMount: true, refetchOnReconnect: true, refetchOnWindowFocus: false, retry: true }
  )
  return { data, isLoading, refetch }
}

const useGetUsersFromList = (listId, filters = {}) => {
  const userId = useSelector((state) => state.authReducer.userObject)
  const [refetchIndex, setRefetchIndex] = useState(0);

  const { data, isLoading, refetch } = useQuery(
    [`getUsersFromList-${listId}`, { ...filters, refetchIndex }],
    () => getUsersFromList(listId, filters),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!userId,
    }
  )

  const forceRefetch = () => setRefetchIndex((prev) => prev + 1);

  return { users: data?.data ?? [], totalUsers: data?.totalRecords || 0, isLoading, refetch, forceRefetch }
}

export { useGetLists, useListHistoryController, useGetPendingSendGridJobs, useListController, useGetList, useGetStaticLists, useGetUsersFromList }
export default useGetLists
