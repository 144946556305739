/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState } from 'react'
import {
  cancelOffer,
  completeOffer,
  rejectOffer,
  updateOffers,
  deleteOffers,
  getOffers,
  uploadOfferDocument
} from 'store/actions/offerActions'

const useUpdateOffers = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const update = useCallback(async (data) => {
    setLoading(true)
    await updateOffers(data)
      .then((data) => {
        setData(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const deleteOffer = useCallback(async (data) => {
    setLoading(true)
    await deleteOffers(data)
      .then(() => {
        setData(null)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const updateContractStatus = useCallback(({ _id, status }) => {
    setLoading(true)
    let PromiseFunc = null
    if (status === 'cancel') {
      PromiseFunc = cancelOffer(_id)
    } else if (status === 'reject') {
      PromiseFunc = rejectOffer(_id)
    } else if (status === 'complete') {
      PromiseFunc = completeOffer(_id)
    }
    if (PromiseFunc) {
      PromiseFunc.then((data) => {
        setData(data)
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [])

  const refetch = useCallback(async (offerId, filter) => {
    setLoading(true)
    getOffers(offerId, filter)
      .then((data) => {
        setData(data)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const uploadDocument = useCallback(
    async ({ projectId, docName, file, type }) => {
      return await uploadOfferDocument({
        projectId,
        docName,
        file,
        type
      })
    },
    []
  )

  return {
    data,
    loading,
    refetch,
    update,
    updateContractStatus,
    uploadDocument,
    deleteOffer
  }
}

export default useUpdateOffers
