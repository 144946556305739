/* eslint-disable */
import { Drawer } from 'antd'
import CustomButton from 'components/CustomButton'
import TableGrid from 'components/TableGrid/Table'
import useProjectId from 'hooks/useProjectId'
import { ListIcon, Loader2, PlusIcon } from 'lucide-react'
import { useEffect, useId, useState } from 'react'
import { api } from 'services/api'

export default function CreateListFromUser(props) {
    const defaultListName = props.defaultListName
    const { projectId } = useProjectId()
    const [listName, setListName] = useState(defaultListName)
    const [users] = useState(props.users)
    const [creating, setCreating] = useState(false)
    const [open, setOpen] = useState(false)
    const inputId = useId()
    const [createdList, setCreatedList] = useState(null)
    useEffect(() => {
        document.getElementById(inputId)?.focus()
        if (!open) {
            setCreatedList(null)
        }
    }, [open])

    function createList() {
        const name = String(listName).trim()
        if (name === '') {
            alert('Please enter a valid name')
            return document.getElementById(inputId)?.focus()
        }
        // {"title":"Dharmik Test 20 jan - 002","projectId":"607605e70de444001a71cfc8","users":["678a0c7e6eb9e125c7dd6d21","678a0bb312abe70afc2d51cb"]}
        const newList = {
            title: name,
            projectId: projectId,
            users: users.map((user) => user.userId)
        }
        setCreating(true)
        api.post('/lists', newList).then((response) => {
            alert('List created successfully')
            if (response?.data?._id) {
                setCreatedList(response?.data)
            }
            // setOpen(false)
        }).catch((error) => {
            alert(error)
        }).finally(() => {
            setCreating(false)
        })
    }

    return <div>
        <div>
            <CustomButton
                onClick={() => {
                    setOpen(true)
                }}
                className='bg-black-500 text-white px-2.5 py-1.5 flex items-center'
            >
                <ListIcon size={16} />
                Export List
            </CustomButton>
        </div>
        <Drawer
            closable={true}
            placement='right'
            onClose={() => {
                setOpen(false)
            }}
            open={open} className='min-w-[500px]' width={800}>
            {createdList && (
                <div>
                    <h1>
                        List Created Successfully
                    </h1>
                    <a href={`/admin/corporate/lists/${createdList._id}`} target='_blank' rel='noreferrer'>
                        <button className='px-2 py-1 bg-black text-white rounded'>
                            View List
                        </button>
                    </a>
                </div>
            )}
            {!createdList && (
                <div className='flex flex-col gap-y-2'>
                    <div>
                        <h1>
                            Create List from Users
                        </h1>
                        <div>
                            <label>
                                List Name
                            </label>
                            <input
                                id={inputId}
                                onChange={(e) => {
                                    let value = String(e.target.value).trim()
                                    setListName(value)
                                }}
                                placeholder='Write the name of the list'
                                value={listName}
                            />
                        </div>
                    </div>
                    <div>
                        You are about to create a list with
                        <span className='font-bold text-3xl px-1.5'>
                            {users.length}
                        </span>
                        users from the selected list.
                    </div>
                    <div>
                        <CustomButton
                            disabled={users.length === 0 || listName === '' || creating}
                            className='px-3 py-2 flex items-center gap-x-1'
                            handleClick={() => {
                                createList()
                            }}
                        >
                            {creating && <Loader2 size={16} className='animate-spin' />}
                            {!creating && <PlusIcon size={16} />}
                            Create and Save List ({users.length} users)
                        </CustomButton>
                        {creating &&
                            <p className='text-sm text-red-400'>
                                Creating list may take a few seconds to complete the process. depends on the number of users.
                            </p>
                        }
                    </div>
                    <div className='mt-4'>
                        <TableGrid
                            columnDefs={[
                                {
                                    headerName: '#',
                                    field: 'index',
                                    width: 50,
                                    cellRenderer: (params) => {
                                        return <span className='max-w-[30px]'>
                                            {params.rowIndex + 1}
                                        </span>
                                    }
                                },
                                {
                                    headerName: 'Name',
                                    field: 'name',
                                    flex: 1
                                },
                                {
                                    headerName: 'Phone',
                                    field: 'phoneNumber',
                                },
                                {
                                    headerName: 'status',
                                    field: 'status',
                                    cellRenderer: ({ data }) => {
                                        return <span className={data.notesColor}>{data.notes}</span>
                                    }
                                }
                            ]}
                            getData={() => {
                                return new Promise((resolve) => {
                                    resolve(users)
                                })
                            }}
                            pagination={false}
                        />
                    </div>
                </div>
            )}
        </Drawer>
    </div>
}