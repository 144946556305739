// @ts-nocheck
import React, { useState } from 'react'
import TemplatesView from './components/TemplatesView'
import ReportBuilderView from './components/ReportBuilderView'

const TabView = () => {
  const [activeTab, setActiveTab] = useState('templates')

  return (
    <div className="w-full">
      <div className="grid w-full grid-cols-2 h-12">
        <button
          onClick={() => setActiveTab('templates')}
          className={`${activeTab === 'templates'
            ? 'bg-black text-white'
            : 'bg-white text-black'
            }`}
        >
          Templates
        </button>
        <button
          onClick={() => setActiveTab('builder')}
          className={`${activeTab === 'builder'
            ? 'bg-black text-white'
            : 'bg-white text-black'
            }`}
        >
          Report Builder
        </button>
      </div>

      <div className="mt-6">
        {activeTab === 'templates' && (
          <TemplatesView />
        )}

        {activeTab === 'builder' && (
          <ReportBuilderView />
        )}
      </div>
    </div>
  )
}

const ReportBuilderPage = () => {
  return (
    <div className="min-h-screen">
      <div className="max-w-7xl mx-auto px-4 py-6 flex justify-between items-center">
        <h1 className="text-3xl font-bold">Reports</h1>
      </div>
      <div className="max-w-7xl mx-auto px-4 pt-2 pb-8">
        <TabView />
      </div>
    </div>
  )
}

export default ReportBuilderPage
