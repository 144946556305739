/* eslint-disable */
// @ts-nocheck
import React, {
  useEffect,
  createContext,
  useRef,
  useCallback,
  useState
} from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import PaymentReceipt from './components/PaymentReceipt'
import { useSelector } from 'react-redux'
import useFileManager from 'hooks/useFileManager'
import { DeleteIcon } from 'components/Icons'
import { EyeIcon } from '@heroicons/react/outline'
import { classNames } from 'utils'
import FilterBySearch from 'pages/contacts/FilterBySearch'
import { EmailAttachmentSVGIcon } from 'pages/contacts/EmailModal'
import DeleteModal from 'components/DeleteModal'
import CustomButton from 'components/CustomButton'
import { ImageUploaderPrimary } from 'components/ImageUploader'
import { uploadFile } from 'store/actions/fileActions'
import { createOrUpdateUnit, updateUnitField } from 'store/actions/unitActions'

const LoadingContext = createContext()

const Letter = ({
  children,
  onClick,
  isDisabled = false,
  isSelected = false
}) => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={classNames(
        'flex-grow text-sm text-white w-8.25 h-8.25 font-medium flex justify-center items-center rounded leading-none opacity-100',
        isSelected ? 'bg-softBlack' : 'bg-softBlack_70',
        isDisabled ? 'bg-softBlack_70' : 'hover:bg-softBlack_70/[50%]'
      )}
    >
      {children}
    </button>
  )
}

const DocumentItem = ({
  value,
  onDelete,
  onChangeFile,
  setShowModal,
  handleClose = () => {},
  index
}) => {
  const inputRef = React.useRef(null)
  const [deleteDocumentId, setDeleteDocumentId] = useState(0)
  const [isDeleteModal, setIsDeleteModal] = useState(false)

  const onDeleteClick = useCallback((id) => {
    setIsDeleteModal(true)
    setDeleteDocumentId(id)
  }, [])

  const onDeleteConfirm = useCallback(() => {
    setIsDeleteModal(false)
    onDelete?.(index)
  }, [deleteDocumentId])

  const handleChange = async (event) => {
    const file = event.target.files[0]
    if (file) {
     let data =  await onChangeFile(file, index)
      event.target.value = ''
    }
  }

  if (value?.length > 0) {
    return (
      <>
        <DeleteModal
          title='Confirm deletion?'
          description='Are you sure to delete this Document?'
          confirmButtonTitle='Yes'
          cancelButtonTitle='No'
          isOpen={isDeleteModal}
          setIsOpen={setIsDeleteModal}
          onConfirm={onDeleteConfirm}
          loading={false}
        />
        <div className='border relative'>
          <div className='p-1 font-bold'>{index + 1}</div>
          <div className='min-h-48 h-48 flex flex-col items-center p-1 bg-white font-openSans rounded border border-dashed border-softBlack_30 overflow-hidden'>
            <div className={'absolute bottom-2 right-2 flex flex-col gap-2'}>
              <a
                className='p-[6px] bg-white leading-[0] rounded border shadow-sm w-auto flex text-center items-center justify-center content-center gap-1'
                href={value}
                target='_blank'
                style={{
                  textDecoration: 'none'
                }}
              >
                <EyeIcon className='h-3' />
                <span>Preview</span>
              </a>
              <button
                onClick={() => onDeleteClick(value._id || value.id)}
                className='border text-xs p-[6px] leading-[0] bg-white rounded shadow-sm w-auto flex text-center items-center justify-center content-center gap-1 text-red-500'
              >
                <DeleteIcon className='' />
                <span>Delete</span>
              </button>
            </div>
            <div
              className={
                'absolute bottom-2 left-2 shadow-md px-2 bg-black rounded'
              }
            >
              <p className='m-0 text-white'>{value?.substring(0, 20)}</p>
            </div>
            <div className={'absolute top-2 right-2'}>
              <input
                type='file'
                className='hidden'
                ref={inputRef}
                onChange={handleChange}
                accept='.jpg,.png,.jpeg,.pdf'
              />
              <button
                className='flex flex-row gap-2 items-center bg-white rounded shadow-sm border text-xs p-1'
                onClick={() => inputRef.current.click()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z'
                  />
                </svg>
                <span>Change</span>
              </button>
            </div>
            {!value.includes('pdf') && (
              <img src={value} className='object-fill' />
            )}
          </div>
        </div>
      </>
    )
  }
  // return (
  //   <div className='bg-white border border-dashed border-softBlack_30 flex items-center justify-center'>
  //     <div className='flex flex-col items-center p-6 font-openSans rounded'>
  //       <svg
  //         width='26'
  //         height='26'
  //         viewBox='0 0 26 26'
  //         fill='none'
  //         xmlns='http://www.w3.org/2000/svg'
  //       >
  //         <path
  //           d='M21.8333 7.33329V11.32C21.8333 11.32 19.18 11.3333 19.1667 11.32V7.33329H15.1667C15.1667 7.33329 15.18 4.67996 15.1667 4.66663H19.1667V0.666626H21.8333V4.66663H25.8333V7.33329H21.8333ZM17.8333 12.6666V8.66663H13.8333V4.66663H3.16667C1.7 4.66663 0.5 5.86663 0.5 7.33329V23.3333C0.5 24.8 1.7 26 3.16667 26H19.1667C20.6333 26 21.8333 24.8 21.8333 23.3333V12.6666H17.8333ZM3.16667 23.3333L7.16667 18L9.83333 22L13.8333 16.6666L19.1667 23.3333H3.16667Z'
  //           fill='#2E2B2E'
  //         />
  //       </svg>
  //       <h1 className='font-normal text-sm mt-4 font-openSans'>
  //         {'Upload File Here'}
  //       </h1>
  //       <div className='font-semibold font-openSans'>
  //         {/* <CustomButton handleClick={() => setShowModal(value.id)}> */}
  //           <div className='font-semibold font-openSans'>
  //             <button
  //               // disabled={disabled}
  //               onClick={() => inputRef.current.click()}
  //               className='px-8 py-2 border border-softBlack_70 font-light italic mt-1 font-openSans'
  //               style={{
  //                 borderRadius: '4px',
  //                 border: '1px solid rgba(46, 43, 46, 0.7)'
  //               }}
  //             >
  //               <input
  //                 type='file'
  //                 className='hidden'
  //                 ref={inputRef}
  //                 onChange={handleChange}
  //                 accept='.jpg,.png,.jpeg,.pdf'
  //               />
  //               Browse
  //             </button>
  //           </div>
  //         {/* </CustomButton> */}
  //       </div>
  //     </div>
  //   </div>
  // )
}

const DocumentUploadButton = ({
  title,
  projectId,
  onUploadDocument,
  onDocumentDeleteClicked,
  onUploadDocumentDone,
  documentList,
  userObject,
  loading,
  fileTags,
  setDocumentList,
  unit,
  handleClose = () => {},
  refetch
}) => {
  const [showModal, setShowModal] = useState(null)
  const [selectedTag, setSelectedTag] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const refFilterBySearch = useRef(null)
  const fileInputRef = useRef(null)

  const handleFileUpload = async (event) => {
    const file = event.target.files[0]
    if (!file) return

    try {
      const uploadedFileUrl =  await onUploadDocument({
        projectId,
        docName: file.name,
        file,
        userId: userObject?._id
      })

      setDocumentList([...documentList, uploadedFileUrl.url])
          } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const handleChangeFile = async (file, index) => {
    try {
      const response = await onUploadDocument({
        projectId,
        docName: file.name,
        file,
        userId: userObject?._id
      })

      let newList = [...documentList]
      newList[index] = response.url

      setDocumentList(newList)

      // onUploadDocumentDone?.(response)
    } catch (error) {
      console.error('Error changing file:', error)
    }
  }

  const handleDelete = async (index) => {
    let newList = [...documentList]
    newList.splice(index, 1)
    newList = newList.filter(item => item !== null)
    setDocumentList(newList)
    await updateUnitField(unit._id, {
      images: newList
    })
    // refetch()
  }

  return (
    <div className='flex-1 flex-col'>
      <div className='flex flex-row justify-between items-center content-center h-9 w-full gap-4 m-2'>
        <div className='w-96'>
          {/* <FilterBySearch
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
            label={'Search Email'}
            loading={loading}
            ref={refFilterBySearch}
          /> */}
        </div>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileUpload}
          accept=".jpg,.png,.jpeg,.pdf"
        />

        <CustomButton
          handleClick={() => {
            fileInputRef.current.click()
          }}
          className='w-fit'
        >
          <span className='font-bold py-2 px-8 text-lg flex gap-2 items-center'>
            {title}
          </span>
        </CustomButton>
      </div>
      <div className='mb-6 col-span-2 grid grid-cols-5 gap-2'>
        {documentList?.map((item, index) => (
          <DocumentItem
            key={index}
            value={item}
            onDelete={() => handleDelete(index)}
            onChangeFile={(file) => handleChangeFile(file, index)}
            handleClose={handleClose}
            index={index}
          />
        ))}
      </div>
      {!documentList?.length && !loading && (
        <div className='flex w-full h-9 justify-center items-center content-center text-center my-4'>
          <span className='flex-1 font-thin text-sm p-4'>
            No documents found, Please add one
          </span>
        </div>
      )}
      {loading && (
        <div className='flex w-full h-9 justify-center items-center content-center text-center my-4'>
          <span className='flex-1 font-thin text-sm p-4'>
            Loading documents...
          </span>
        </div>
      )}
    </div>
  )
}

const UnitFiles = ({
  appProject: projectId,
  handleClose = () => {},
  images = [],
  setDocumentList,
  unit,
  refetch
}) => {
  const mountedRef = useRef(true)
  const userObject = useSelector((state) => state.authReducer.userObject)
  // const getAllFiles = useCallback(() => {
  //   getAllFilesAPI(projectId)
  // }, [projectId])

  // useEffect(() => {
  //   getAllFiles()
  //   return () => {
  //     mountedRef.current = false
  //   }
  // }, [getAllFiles])

  const uploadDocument = async ({ projectId, docName, file, tag, userId }) => {
      return await uploadFile({
        projectId,
        docName,
        file,
        tag,
        userId
    })
  }
  // const onDocumentUploaded = useCallback(() => {
  //   getAllFiles()
  // }, [documentList, getAllFiles])

  // const onDocumentDeleteClicked = useCallback(
  //   async (id) => {
  //     try {
  //       await deleteFile(id)
  //       getAllFiles()
  //     } catch (e) {}
  //   },
  //   [documentList, getAllFiles, deleteFile]
  // )

  return (
    <LoadingContext.Provider>
      <div>
        <div className='flex w-full flex-col gap-2 mt-5'>
          <DocumentUploadButton
            projectId={projectId}
            userObject={userObject}
            title='Upload File'
            onUploadDocument={uploadDocument}
            type='File'
            // onUploadDocumentDone={onDocumentUploaded}
            // onDocumentDeleteClicked={onDocumentDeleteClicked}
            documentList={images}
            setDocumentList={setDocumentList}
            // loading={loading}
            // fileTags={fileTags}
            handleClose={handleClose}
            unit={unit}
            refetch={refetch}
          />
        </div>
      </div>
    </LoadingContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UnitFiles)

UnitFiles.propTypes = {
  appProject: propTypes.string,
  handleClose: propTypes.func
}
