import React, { useContext, useEffect, useRef, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { TFilledTrashIcon, TPlusPrimaryIcon, TFilledPenIcon } from 'components/Icons'
import { FadedAnimatedDiv } from 'components'
import Searching from 'pages/contacts/Searching'

import { CREATE_PROJECT_UPGRADE_STEP } from '../constant'
import { ReactComponent as Done } from '../../../../assets/icons/done.svg'
import { useSelector } from 'react-redux'
import {
  createOrUpdateProject,
  getProjects
} from 'store/actions/projectActions'
import CreateProjectContext from '../context/CreateProjectProvider'
import { useHistory } from 'react-router'
import { ADMIN_DASHBOARD } from 'routes'

const ProjectPayments = ({ setStep, type = 'create' }) => {
  const mountedRef = useRef(true)
  const [payments, setPaymentList] = useState([{ days: 0, perc: 0, edit: 1 }])
  const [isLoading, setIsLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [projectData, setProjectData] = useState({})
  const projectcontext = useContext(CreateProjectContext)
  const newProjectId = projectcontext.newProjectId
  const appProject = useSelector((state) => state.appReducer.appProject)
  const projectId = newProjectId || appProject
  const history = useHistory()
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...payments]
    list[index][name] = value
    setPaymentList(list)
  }
  const handleEditChange = (value, index) => {
    const list = [...payments]
    const name = 'edit'
    list[index][name] = value
    setPaymentList(list)
  }
  const handleRemoveClick = index => {
    const list = [...payments]
    list.splice(index, 1)
    setPaymentList(list)
  }

  const handleAddClick = () => {
    setPaymentList([...payments, { days: 0, perc: 0, edit: 1 }])
  }
  const onSubmit = () => {
    setLoading(true)

    const params = { ...projectData, paymentRounds: payments }
    createOrUpdateProject({ params })
      .then((projectData) => {
        setLoading(false)
        history.push(ADMIN_DASHBOARD)
      })
      .catch(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getProjects(projectId).then((project) => {
      if (!mountedRef.current) return null
      const { paymentRounds } = project
      setProjectData(project)
      setIsLoading(false)
      setPaymentList(paymentRounds)
    })
  }, [projectId])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const LoadingProjectPayments = () => (
    <FadedAnimatedDiv className='h-full w-full flex flex-col items-center mt-20'>
      <Searching title='Loading Project Payments...' />
    </FadedAnimatedDiv>
  )
  return (
    <div className='min-h-screen relative mb-4'>
      <div className='py-6 bg-grey-8 rounded font-openSans'>
        <h1 className='font-black text-lg font-openSans px-6'>Payments</h1>
        <div className='border-b-2 border-black my-2 ml-6' style={{ marginRight: '1.5rem' }}></div>
        <h2 className="font-openSans font-normal text-base px-6">Set up the payment rounds here</h2>
        <div className='mt-10 col-span-2 font-openSans font-light italic text-softBlack_70'>
          <div className='grid grid-cols-3 text-xs px-6 '>
              <span className='flex items-center space-x-2'>
                <span className='leading-none font-openSans'>DAYS</span>
              </span>
              <span className="font-openSans">PERCENT %</span>
          </div>
          <div className='px-6 mt-4' style={{ height: '415px', overflowY: 'auto' }}>
          {isLoading && <LoadingProjectPayments />}
          {payments.map((payment, i) => {
            return (
              <div
                className='relative group grid grid-cols-3 font-light font-openSans text-softBlack italic items-center py-4 border-b text-base border-gray-700'
                key={i}>
                <span className="font-openSans text-softBlack"><input type='number' name="days" min='0' className='italic bg-transparent border-none focus:ring-0 focus:ring-offset-0 appearance-none' value={Number(payment.days) || 0} onChange={e => handleInputChange(e, i)} disabled={!payment.edit} /></span>
                <span className="font-openSans text-softBlack"><input type='number' name="perc" min='0' className='italic bg-transparent border-none focus:ring-0 focus:ring-offset-0 appearance-none' value={Number(payment.perc) || 0} onChange={e => handleInputChange(e, i)} disabled={!payment.edit} /></span>
                <div className='flex justify-end'>
                  {
                    payment.edit
                      ? (
                        <button className='px-4' onClick={e => handleEditChange(0, i)}>
                          <Done />
                        </button>
                        )
                      : (
                        <button className='px-4' onClick={e => handleEditChange(1, i)}>
                          <TFilledPenIcon className='w-4 h-4 text-softBlack_70' />
                        </button>
                        )}
                      <button
                        className='px-4'
                        onClick={() => handleRemoveClick(i)}>
                      <TFilledTrashIcon className='w-4 h-4 text-softBlack_70' />
                    </button>
                  </div>
                </div>
            )
          })}
            <div className='my-8 flex items-center justify-left'>
              <button className='flex items-center font-openSans italic font-light text-base font-softBlack'
                onClick={handleAddClick}
              >
                <TPlusPrimaryIcon className='w-5 h-5 mr-1' />
                Add Payment
              </button>
            </div>
          </div>
        </div>
        <div className="px-6">
          {type === 'create' && (
            <div className='flex justify-end'>
              <button
                onClick={() => setStep(CREATE_PROJECT_UPGRADE_STEP)}
                className='px-6 py-3 mt-6 rounded font-semibold flex items-center border border-softBlack_70 font-openSans italic font-light'>
                Back
              </button>
              <button
                onClick={onSubmit}
                className='ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'>
                {loading && <Oval height="18" width="18" color="black" />}
                Create Project
              </button>
            </div>
          )}
          {type !== 'create' && (
            <button
              onClick={onSubmit}
              className='col-span-2 ml-auto bg-volt px-6 py-3 mt-6 rounded font-semibold flex items-center font-openSans italic font-light'>
                {loading && <Oval height="18" width="18" color="black" />}
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectPayments
