/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo, useRef, forwardRef, useImperativeHandle, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../../assets/css/ag-theme-balham.css";
import { classNames } from "utils";
import { Card, Modal } from "@material-ui/core";
import useGetProjectUnits from "hooks/useGetProjectUnits";
import { groupBy } from "..";
import { getFilteredDeals } from "store/actions/dealAction";
import {
  createOrUpdateUnit,
  getProjectUnitGroups,
  getUnitsByBuildingId,
  getUnitsByBuildingIdTableView,
  updateUnitField,
  updateUnitStatus,
} from "store/actions/unitActions";
import { useState } from "react";
import moment from "moment";
import { IoMdClose } from "react-icons/io";
import CustomSelect from "components/CustomSelect";
import CustomInput from "components/CustomInput";
import { getParkingInventory } from "store/actions/parkingActions";
import InventoryEditDrawer from "./InventoryEditDrawer";
import Skeleton from "react-loading-skeleton";
import { api } from "services/api";
import Select from 'react-select';
import { Select as AntSelect } from "antd";
import { param } from "jquery";
import { getUnitGroups } from "store/actions/unitGroupsActions";
import { getFloorPlans } from "store/actions/floorPlanActions";
import useInventory from "../hooks/useInventory";
// Add DropdownCellEditor component
const DropdownCellEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const options = [
    "Available",
    "Soldfirm",
    "Open",
    "Assigned",
    "Under Contract",
    "Pending",
    "Coming Soon",
  ].map(option => ({
    value: option,
    label: option
  }));

  const tableRef = props.tableRef;

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
    };
  });

  

  const handleChange = (event) => {
    setValue(event.value);
    if (props.onValueChange) {
      props.onValueChange(event.value, props.data);
    }
    setTimeout(() => {
      props.api.stopEditing();
    }, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      props.api.stopEditing();
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.api]);


  return (
    <Select
      ref={ref}
      value={value}
      onChange={handleChange}
      options={options}
      className="w-full"
      isSearchable={false}
      menuPortalTarget={document.body}
      value={options.find((option) => option.value === value)}
      menuIsOpen={props.api.getEditingCells().length > 0}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
          marginBottom: 0,
        }),
        menuList: (provided) => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: "transparent",
          color: "inherit",
          cursor: "pointer",
          ":hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        }),
        control: (provided) => ({
          ...provided,
          minWidth: 120, // Adjust as needed
        }),
      }}
    ></Select>
  );
});


const DropdownCellEditorUnitGroup = forwardRef((props, ref) => {
  const originalValue = useRef(props?.value);
  const [value, setValue] = useState(props?.value);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const options = props.options.map((item)=>{
    return {
      label: item.name,
      value: item._id,
      _id: item._id
    }
  })
  const tableRef = props.tableRef;

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
  }));

  const handleChange = (event) => {
    setValue(event);
    if (props.onValueChange) {
      props.onValueChange(event.value, props.data);
    }
    props.api.stopEditing();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      props.api.stopEditing();
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.api]);

  useEffect(() => {
    setDropdownOpen(true);
  }, []);

  return (
    <Select
    ref={ref}
    value={value}
    onChange={handleChange}
    options={options}
    className="w-full"
    isSearchable={false}
    menuPortalTarget={document.body}
    value={options.find((option) => option.value === value)}
    menuIsOpen={props.api.getEditingCells().length > 0}
    styles={{
      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
      menu: (provided) => ({
        ...provided,
        marginTop: 0,
        marginBottom: 0,
      }),
      menuList: (provided) => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
      }),
      option: (provided) => ({
        ...provided,
        backgroundColor: "transparent",
        color: "inherit",
        cursor: "pointer",
        ":hover": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }),
      control: (provided) => ({
        ...provided,
        minWidth: 120,
      }),
    }}
  ></Select>
  );
});

export default function TableViewUnit({
  selectedBuilding,
  projectId,
  showCards = true,
}) {
  const [filteredUnits, setFilteredUnits] = useState([]);
  const [tempTypesOfAllocatedParking, setTempTypesOfAllocatedParking] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [typesOfAllocatedParking, setTypesOfAllocatedParking] = useState([]);
  const [parkingType, setParkingType] = useState([]);
  const [allocationModal, setAllocationModal] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [tempRefetch, setTempRefetch] = useState(false);
  const [parkingTypeData, setParkingTypeData] = useState([{}]);
  const [parkingAllocationValues, setParkingAllocationValues] = useState({});
  const parkingColor = ["#59b099", "red", "yellow", "black"];
  const [totalAssignedParkingAllocate, setTotalAssignedParkingAllocate] =
    useState(null);
  const [saveStatus, setSaveStatus] = useState("");
  const gridRef = useRef(null);

  const [floorPlans, setFloorPlans] = useState([])
  const [unitGroups, setUnitGroups] = useState([])
  const {
    state: { selectedInventoryView, statusColors },
  } = useInventory();

  useEffect(() => {
    getUnitGroups().then((data) => {
      let mapped = data.filter((item)=>{
        return item.project._id === projectId
      }).map((item) => {
        return {
          ...item,
          _id: item._id,
          value: item._id,
          label: item.name
        }
      })
      setUnitGroups(mapped)
    })
    getFloorPlans().then((data) => {
      let mapped = data.filter((item)=>{
        return item
      }).map((item) => {
        return {
          ...item,
          _id: item._id,
          value: item._id,
          label: item.name
        }
      })
      setFloorPlans(mapped)
    })
  }, [projectId])

  const parkingTypeArray = () => {
    const parkingStatus = [
      { name: "Available", value: value.totalParkings || 0 },
      { name: "Sold", value: 0 },
      { name: "Allocated", value: 0 },
      { name: "Assigend", value: 0 },
    ];
    const parkingData = parkingStatus.map((parking, index) => {
      const color = parkingColor[index % parkingColor.length];
      return { name: parking.name, value: parking.value, color: color };
    });
    setParkingTypeData(parkingData);
  };
  //   const handleFilteredDeals = async () => {
  //     let data = await getFilteredDeals({});
  //     console.log(data);
  //   };
  const initialEmptyValues = {
    building: selectedBuilding,
    options: [],
    parkingLevels: ["P1", "P2"],
    totalParkings: null,
  };
  const [value, setValues] = useState({
    ...initialEmptyValues,
  });

  const handleTotalParkingsAllocate = () => {
    const totalParking = parkingAllocationValues?.options?.reduce(
      (sum, obj) => {
        const objSum = obj.parkingTypes.reduce((objSum, parkingType) => {
          const typeSum = parkingType.options.reduce(
            (typeSum, option) => typeSum + Number(option.value),
            0
          );
          return objSum + typeSum;
        }, 0);
        return sum + objSum;
      },
      0
    );
    setTotalAssignedParkingAllocate(totalParking);
  };

  const parkingAllocationStatus = [
    {
      name: "Allocated",
      value: totalAssignedParkingAllocate,
    },
    {
      name: "Unallocated",
      value:
        parkingAllocationValues.allocatedParkings -
        totalAssignedParkingAllocate,
    },
  ];

  useEffect(() => {
    const handleParkingInventory = async () => {
      const { data } = await api.get(
        `/parking/${selectedBuilding}/allocateInventory`
      );
      const parkingLevelsAllocate = data.data[0].parkingLevels;
      const parkingLevels = value.parkingLevels;
      const fetchedOptions = data.data[0].options;

      setParkingAllocationValues({
        ...data.data[0],
      });
    };
    const handleBuildingInventory = async () => {
      try {
        const { data } = await api.get(
          `/parking/${selectedBuilding}/inventory`
        );
        if (data.data && data.data.length > 0) {
          setValues(data.data[0]);
        } else {
          setValues({ ...initialEmptyValues });
        }
      } catch (error) {
        console.error("Error fetching building inventory:", error);
      }
    };
    handleBuildingInventory();
    handleParkingInventory();
    parkingTypeArray();
  }, []);

  useEffect(() => {
    handleTotalParkingsAllocate();
  }, [parkingAllocationValues]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      filter: true,
      resizable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      editable: true
    };
  }, []);

  const _getParkingType = async (parking) => {
    let parkingInventory = await getParkingInventory(selectedBuilding);
    parkingInventory = parkingInventory.map((parking) => ({
      parkingType: parking.options.map((op) => op.name),
    }));
    if (parkingInventory.length) {
      setParkingType(
        parkingInventory[0].parkingType.map((pt) => ({
          value: pt,
          id: pt,
        }))
      );
    }
  };

  useEffect(() => {
    _getParkingType();
  }, [selectedBuilding]);

  const handleAddParking = () => {
    const newId = tempTypesOfAllocatedParking.length + 1;
    const newBgColor = colors[newId % colors.length]; // Rotate through colors
    const newAllocation = {
      id: newId,
      count: 1,
      title: "",
      bgColor: newBgColor,
    };

    setTempTypesOfAllocatedParking((prev) => [...prev, newAllocation]);
  };

  const openAllocationModal = () => {
    setAllocationModal(true);
  };
  const colors = ["#FFBE9A", "#16A34A40", "#FFD700", "#FF0000"];
  useEffect(() => {
    setIsLoading(true);
    const units = getUnitsByBuildingIdTableView(selectedBuilding).then(
      (data) => {
        let mapped = data.map((unit) => {
          return {
            ...unit,
            id: unit._id,
            unitNumber: unit.unitNumber,
            buyerName: unit.buyerName,
            soldAt: unit.avesdoData?.acceptedDate,
            floorNumber: unit.floorNumber,
            unitType: unit.unitType,
            status: unit.status,
            area: unit.area,
            isOnWaitlist: unit?.avesdoData,
            acceptedDate: unit?.avesdoData?.acceptedDate,
            totalParkingStalls:
              (unit?.numberParkingIncluded || 0) +
              (unit?.numberParkingOptional || 0),
          };
        });
        setFilteredUnits(mapped);
        setIsLoading(false);
      }
    );
  }, [selectedBuilding, tempRefetch]);

  // Add column order functions
  const loadColumnOrder = () => {
    const savedOrder = localStorage.getItem('unitTableColumnOrder');
    return savedOrder ? JSON.parse(savedOrder) : null;
  };

  const getRowId = useCallback((params) => String(params.data._id), []);

  const saveColumnOrder = (columnState) => {
    localStorage.setItem('unitTableColumnOrder', JSON.stringify(columnState));
  };

  const columnDefs = [
    {
      headerName: "Unit Name",
      field: "unitNumber",
      filter: true,
      width: 140,
      editable: false,
      pinned: "left",
      headerCheckboxSelection: true,
    },
    {
      headerName: "Strata Lot Number",
      field: "strataLotNumber",
      width: 120,
      editable: true
    },
    {
      headerName: "Status",
      field: "status",
      width: 150,
      editable: true,
      cellEditor: DropdownCellEditor,
      cellEditorParams: {
        tableRef: gridRef,
        onValueChange: (newValue, data) => {
          gridRef.current.api.applyTransaction({
            update: [{
              ...data,
              _id: data._id,
              status: newValue
            }]
          });
          const rowNode = gridRef.current.api.getRowNode(data._id);
          gridRef.current.api.refreshCells({
            rowNodes: [rowNode],
            columns: ['status'],
            force: true
          });
          updateUnitStatus({
            ...data,
            status: newValue
          }).then(() => {
            // setTempRefetch(!tempRefetch);
          });
        },
      },
      cellRenderer: ({ value }) => {
        const status = value;
        let color = statusColors[value?.toLowerCase()];
        return (
          <div>
            <div
              style={{
                backgroundColor: color?.bgColor,
                color: color?.textColor,
              }}
              className={`capitalize px-2 py-1 rounded-full text-xs`}
            >
              {status}
            </div>
          </div>
        );
      },
      onCellClicked: (params) => {
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.getColId(),
        });
      },
    },
    {
      headerName: "Bedrooms",
      field: "numberOfBedrooms",
      width: 100,
    },
    {
      headerName: "Bathrooms",
      field: "numberOfBathrooms",
      width: 100,
    },
    {
      headerName: "Inventory Type",
      field: "inventoryType",
      cellRenderer: ({ value }) => {
        return (
          <div>
            <div
              className={`px-3 border bg-gray-300 py-0.5 rounded-md md:rounded-md justify-center items-center gap-1 inline-flex`}
            >
              <div className="text-center text-xs font-medium">
                {value ?? ""}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      headerName: "Price",
      field: "price",
      width: 120,
    },
    {
      headerName: "Lot Group",
      field: "unitGroup",
      filter: true,
      cellEditor: DropdownCellEditorUnitGroup,
      cellEditorParams: {
        tableRef: gridRef,
        options: unitGroups,
        onValueChange: (newValue, data) => {
          let filteredGroups = unitGroups.filter((item) =>
            item._id === newValue
          )[0]
          console.log(filteredGroups)
          gridRef.current.api.applyTransactionAsync({
            update: [{
              ...data,
              unitGroup: filteredGroups
            }]
          });
          gridRef.current.api.refreshCells({ force: true });
          updateUnitField(data._id, {
            'unitGroup': newValue
          })
        },
      },
      cellRenderer: (params) => {
        return (
          <div>
            {params.data?.unitGroup?.name || "-"}
          </div>
        )
      },
      onCellClicked: (params) => {
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.getColId(),
        });
      },
    },
    {
      headerName: "Floor Plan",
      field: "floorPlan",
      cellRenderer: (params) => {
        return <div>{params.value?.name}</div>;
      },
      cellEditor: DropdownCellEditorUnitGroup,
      cellEditorParams: {
        tableRef: gridRef,
        options: floorPlans,
        onValueChange: (newValue, data) => {
          let floorPlansFiltered = floorPlans.filter((item) =>
            item._id === newValue
          )[0]
          gridRef.current.api.applyTransactionAsync({
            update: [{
              ...data,
              floorPlan: floorPlansFiltered
            }]
          });
          gridRef.current.api.refreshCells({ force: true });
          updateUnitField(data._id, {
            'floorPlan': newValue
          })
        },
      },
      onCellClicked: (params) => {
        params.api.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: params.column.getColId(),
        });
      },
    },
    {
      headerName: "Floor Number",
      field: "floorNumber",
      width: 100,
    },
    {
      headerName: "Leasing Term",
      field: "leasingTerm",
      width: 140,
    },
    {
      headerName: "Area",
      field: "area",
      width: 120,
    },
    {
      headerName: "Plat",
      field: "lotData.plat",
    },
    {
      headerName: "Sub Phase",
      field: "lotData.subphase",
    },
    {
      headerName: "Block",
      field: "lotData.block",
    },
    {
      headerName: "Plat Lot Number",
      field: "lotData.platLotNumber",
    },
    {
      headerName: "Sales Lot Number",
      field: "lotData.salesLotNumber",
    },
    {
      headerName: "Lot Type",
      field: "lotData.lotType",
    },
    {
      headerName: "Lot Width",
      field: "lotData.lotWidth",
    },
    { headerName: "Square Footage", field: "lotData.squareFootage" },
    { headerName: "Dimensions", field: "lotData.dimensions" },
    { headerName: "Buildable Square Footage", field: "lotData.buildableSquareFootage" },
    { headerName: "Buildable Dimensions", field: "lotData.buildableDimensions" },
    { headerName: "Front Yard Dimension", field: "lotData.frontYardDimension" },
    { headerName: "Left Yard Dimension", field: "lotData.leftYardDimension" },
    { headerName: "Right Yard Dimension", field: "lotData.rightYardDimension" },
    { headerName: "Rear Yard Dimension", field: "lotData.rearYardDimension" },
    { headerName: "Mooring Line Type", field: "lotData.mooringLineType" },
    { headerName: "Mooring Line", field: "lotData.mooringLine" },
    { headerName: "Mooring Dimension", field: "lotData.mooringDimension" },
    { headerName: "Water Access", field: "lotData.waterAccess" },
    { headerName: "Dock Type", field: "lotData.dockType" },
    { headerName: "Dock Allowed", field: "lotData.dockAllowed" },
    { headerName: "Home Height", field: "lotData.homeHeight" },
    { headerName: "Pool", field: "lotData.pool" },
    { headerName: "Sector", field: "lotData.sector" },
    { headerName: "STR", field: "lotData.STR" },
    { headerName: "Neighborhood", field: "lotData.neighborhood" },
    {
      headerName: "Building Number",
      field: "buildingNumber",
    },
    {
      headerName: "BLDG Type Key",
      field: "bldgeTypeKey",
    },
    {
      headerName: "Floor Plan Key",
      field: "floorPlanKey",
    },
    {
      headerName: "Stage",
      field: "stage",
    },
    {
      headerName: "Max HOuse Width Ft",
      field: "maxHouseWidthFt",
    },
    {
      headerName: "Max House Width M",
      field: "maxHouseWidthM",
    },
    {
      headerName: "Area Sq Ft",
      field: "areaSqFt",
    },
    {
      headerName: "Area Sq M",
      field: "areaSqM",
    },
    {
      headerName: "Style Key",
      field: "styleKey",
    },
    {
      headerName: "Style",
      field: "style",
    },
    {
      headerName: "Grade Key",
      field: "gradeKey",
    },
    {
      headerName: "Grade",
      field: "grade",
    },
    {
      headerName: "List Price",
      field: "listPrice",
    },
    {
      headerName: "Status Key",
      field: "statusKey",
    },
    {
      headerName: "Notes",
      field: "notes",
    },
    {
      headerName: "ETA",
      field: "ETA",
    },
    {
      headerName: "ETA month",
      field: "ETAMonth",
    },
  ];

  return (
    <div className="flex w-full h-full">
      {isLoading ? (
        <div className="h-full w-full flex flex-col gap-y-4">
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
          <Skeleton height={130} />
        </div>
      ) : (
        <div className="w-full flex flex-col gap-y-5">
          <div
            className={classNames(
              "h-full ag-theme-alpine w-full transition-all relative",
              isDrawerOpen ? "mr-[350px]" : "mr-0"
            )}
          >
            <AgGridReact
              ref={gridRef}
              domLayout="autoHeight"
              defaultColDef={defaultColDef}
              rowHeight={40}
              columnDefs={columnDefs}
              rowData={filteredUnits}
              onColumnMoved={(event) => {
                const columnState = event.columnApi.getColumnState();
                saveColumnOrder(columnState);
              }}
              getRowId={getRowId}
              onGridReady={(params) => {
                const savedOrder = loadColumnOrder();
                if (savedOrder) {
                  params.columnApi.applyColumnState({
                    state: savedOrder,
                    applyOrder: true,
                  });
                }
              }}
              onCellValueChanged={(e) => {
                if (e.column.colId !== 'unitGroup' && e.column.colId !== 'floorPlan') {
                  let newValue = e.newValue
                  let colId = e.column.colId
                  e.api.applyTransaction({
                    update: [{
                      ...e.data,
                      [colId]: newValue
                    }],
                  });

                  e.api.refreshCells({
                    force: true,
                  });

                  updateUnitField(e.data._id, {
                    [colId]: newValue
                  })
                  return
                }
              }}
            />
          </div>
        </div>
      )}
      <div className="absolute right-0 top-0 bottom-0 z-[999]">
        <InventoryEditDrawer
          editDetails={editDetails}
          setEditDetails={setEditDetails}
          closeInfoDrawer={() => setIsDrawerOpen(false)}
          isInfoDrawerOpen={isDrawerOpen}
          isAbleToPurchase
          refetch={() => {
            setTempRefetch(!tempRefetch);
          }}
          type={"Unit"}
          selectedBuilding={selectedBuilding}
          isFullHeight={false}
        />
      </div>
    </div>
  );
}
