/* eslint-disable */
// @ts-nocheck
import { Button } from "components/ShadcnComponents/button"
import { Card } from "components/ShadcnComponents/card"
import { Plus, Upload } from "lucide-react"
import DocumentUploadModal from "pages/offerPreview/components/PaymentReceipt"
import React, { useEffect, useState } from "react"
import { ReactComponent as PDFIcon } from "assets/icons/icn_file_pdf.svg"

const DocumentUpload = ({
  title,
  projectId,
  onUploadDocument,
  onDocumentDeleteClicked,
  onUploadDocumentDone,
  type,
  offerData,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [documentList, setDocumentList] = useState([])

  useEffect(() => {
    const temp = offerData?.signedContracts?.filter((signedContract) =>
      signedContract?.tags?.includes(type)
    )
    if (temp) {
      setDocumentList([...temp])
    }
  }, [offerData])

  const DocumentItem = ({ value, onDelete }) => {
    if (value?.url?.length > 0) {
      return (
        <div className="flex items-center p-2 bg-white w-full gap-4">
          {!value.fileName.includes("pdf") ? (
            <div className="w-fit flex justify-center rounded-md overflow-hidden">
              <img src={value.url} className="object-cover h-[50px] w-[50px]" />
            </div>
          ) : (
            <div className="flex flex-col items-center gap-4">
              <PDFIcon className="w-[50px] h-[50px]" />
            </div>
          )}
          <div className="flex gap-4 flex-1">{value.documentName}</div>
          <div className="flex gap-2 text-center justify-end">
            <Button>
              <a
                href={value.url}
                target="_blank"
                style={{
                  textDecoration: "none",
                }}
                className="text-white"
              >
                Preview
              </a>
            </Button>
            <Button
              onClick={() => onDelete?.(value?._id || value?.id)}
              className="text-sm font-openSans rounded-lg font-bold !bg-red-500"
            >
              Delete
            </Button>
          </div>
        </div>
      )
    }
    return (
      <div className="border">
        <div className="flex flex-col items-center p-6 bg-white font-openSans rounded border border-dashed border-softBlack_30">
          <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.8333 7.33329V11.32C21.8333 11.32 19.18 11.3333 19.1667 11.32V7.33329H15.1667C15.1667 7.33329 15.18 4.67996 15.1667 4.66663H19.1667V0.666626H21.8333V4.66663H25.8333V7.33329H21.8333ZM17.8333 12.6666V8.66663H13.8333V4.66663H3.16667C1.7 4.66663 0.5 5.86663 0.5 7.33329V23.3333C0.5 24.8 1.7 26 3.16667 26H19.1667C20.6333 26 21.8333 24.8 21.8333 23.3333V12.6666H17.8333ZM3.16667 23.3333L7.16667 18L9.83333 22L13.8333 16.6666L19.1667 23.3333H3.16667Z"
              fill="#2E2B2E"
            />
          </svg>
          <h1 className="font-light text-sm mt-4 font-openSans italic">
            {"Upload Files Here"}
          </h1>
          <div className="font-semibold font-openSans">
            <Button
              onClick={() => setShowModal(true)}
              className="px-8 py-2 border border-softBlack_70 font-light italic mt-1 font-openSans bg-black text-white"
              style={{
                borderRadius: "4px",
                border: "1px solid rgba(46, 43, 46, 0.7)",
              }}
            >
              Browse
            </Button>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div className="flex-col bg-white border rounded-md p-2">
      <div className="flex flex-row justify-between items-center h-9 w-full gap-4">
        <div className="mb-2 flex items-center justify-between">
          <span className="text-lg font-semibold">{title}</span>
        </div>
        {/* <Button
          onClick={() => {
            setDocumentList([
              ...documentList,
              {
                id: `${type}-${documentList.length + 1}`,
              },
            ])
          }}
          className="text-[20px]"
        >
          <Plus />
        </Button> */}
      </div>
      <DocumentUploadModal
        title={`Document Upload (${type})`}
        type={type}
        show={showModal}
        onClose={() => {
          setShowModal(false)
        }}
        onSucess={(data) => {
          setShowModal(false)
          onUploadDocumentDone?.(type, data)
        }}
        projectId={projectId}
        onUploadDocument={onUploadDocument}
      />
      {!documentList?.length && (
        <div className="flex w-full h-9 justify-center items-center content-center text-center my-4">
          <span className="flex-1 font-thin text-sm p-4">
            No documents found, Please add one
          </span>
        </div>
      )}
      <div className="col-span-2 grid grid-cols-1 gap-2">
        {documentList?.length > 0 &&
          documentList.map((item) => {
            return (
              <DocumentItem
                value={item}
                onDelete={(id) => {
                  onDocumentDeleteClicked?.(id)
                }}
              />
            )
          })}
        <Button variant="outline" className="mx-auto min-w-[300px]" onClick={()=>setShowModal(true)}>
          <Upload className="mr-2 h-4 w-4" />
          Upload
        </Button>
      </div>
    </div>
  )
}

export default DocumentUpload
