/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from '@material-ui/core'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import UnitFiles from 'components/UnitFiles'
import { EyeIcon } from '@heroicons/react/outline'
import { TButton } from 'ui'

const UnitFileUploader = ({ onSuccess, title, labelClassName = '', isRequired = false, defaultValue = '', images = [], setDocumentList = () => {}, unit, refetch }) => {
  const [show, setShow] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const handleClose = useCallback(async () => {
    setShow(false)
    // const copiedValue = (await navigator.clipboard.readText()) ?? ''
    // onSuccess?.(copiedValue)
    // setSelectedItem(JSON.parse(copiedValue))
  }, [onSuccess])

  // useEffect(() => {
  //   if (defaultValue?.length > 0) {
  //     setSelectedItem({ url: defaultValue })
  //   } else {
  //     setSelectedItem(null)
  //   }
  // }, [defaultValue])

  return (
    <div className='flex flex-col'>
      <span className={`pb-2 ${labelClassName}`}>Select {title ?? 'File'}{isRequired && ' *'}:</span>
      <div className='flex flex-row w-full gap-2 items-center justify-center content-center'>
        <TButton
          className='w-full'
          onClick={() => {
            setShow(true)
          }}
        >
          Open {title ?? 'File'} Selector
        </TButton>
        {Object.keys(selectedItem ?? {})?.length > 0 && (
          <div className='self-stretch w-1/2'>
            <a
              className='p-[6px] bg-white leading-[0] rounded border shadow-sm h-full flex justify-center items-center text-center w-full gap-2 py-3'
              // @ts-ignore
              href={selectedItem?.url}
              target='_blank'
              style={{
                textDecoration: 'none'
              }}
            >
              Preview {title ?? 'File'}
            </a>
          </div>
        )}
      </div>
      <Modal
        open={show}
        aria-labelledby='Select OR Upload File Modal'
        aria-describedby='Select OR Upload File Modal Description'
      >
        <div
          className='relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
          style={{ width: 'calc(100% - 50px)', height: 'calc(100% - 50px)' }}
        >
          <button className='absolute right-10' onClick={handleClose}>
            <CrossIcon />
          </button>
          <h1 className='font-bold text-xl text-center'>
            Upload OR Select {title ?? 'File'} From
          </h1>
          <div className='relative w-full h-full'>
            <UnitFiles
              images={images}
              handleClose={handleClose}
              setDocumentList={setDocumentList}
              unit={unit}
              refetch={refetch}
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UnitFileUploader
