/*eslint-disable */
// @ts-nocheck
import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import TableBody from "./TableBody/TableBody";
import TableHead from "./TableHead/TableHead";
import { ArrowDownIcon, FadedAnimatedDiv, LoadingIcon } from "components";
import Searching from "pages/contacts/Searching";
import "../../../../assets/css/ag-theme-balham.css";
import TablePagination from "./TablePagination/TablePaginationV2";
import { parsePhoneNumber } from "libphonenumber-js";
import useContactContext from "pages/newContacts/hooks/useContactContext";
// import {
//   filterAssignmentsBySaleRepId,
//   sortContacts
// } from 'pages/newContacts/context/MainContactContext'
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import buyer from "components/CreateOffer/buyer";
import { useHistory, Link, useParams } from "react-router-dom";
import { BiCheck, BiDownArrow } from "react-icons/bi";
import { ReactComponent as CallsIcon } from "../../../../assets/icons/icn_phone.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/icn_email.svg";
import { ReactComponent as SMSIcon } from "../../../../assets/icons/icn_text_snippet.svg";
import { ReactComponent as TasksIcon } from "../../../../assets/icons/icn_check_circle.svg";
import { ReactComponent as NotesIcon } from "../../../../assets/icons/icn_write.svg";
import {
  ArrowDownFromLine,
  ArrowDownNarrowWide,
  CheckCheckIcon,
  CheckIcon,
  FullscreenIcon,
  ListChecks,
  ListFilter,
  ListIcon,
  LucideArrowDown,
  LucideArrowDownAz,
  LucideArrowDownNarrowWide,
  LucideArrowDownWideNarrow,
  MenuIcon,
} from "lucide-react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import TablePaginationV2 from "./TablePagination/TablePaginationV2";
import useContactContextV2 from "pages/newContacts/hooks/useContactContextV2";
import successAnimation from "../../../../assets/lottieJsons/avesdoLoading.json";
import { LottieWrapper } from "components";
import useLeadStatus from "hooks/useLeadStatus";
import {
  getFilterContacts,
  getUsers,
  updateContactType,
  updateLeadStatus,
  updateRating,
  updateSalesRep,
} from "store/actions/usersActions";
import TablePaginationV2Bottom from "./TablePagination/TablePaginationV2Bottom";
import {
  getCorporatDemandNotes,
  GetDemandNotes,
} from "store/actions/demandNotes";
import useUpdateUsers from "hooks/useUpdateUsers";
import useBuyerScore from "hooks/useBuyerScore";
import { on } from "events";
import { FullscreenExitOutlined, ShrinkOutlined } from "@ant-design/icons";
import Select from "react-select";
import { ReactComponent as FullScreen } from "assets/icons/full-screen-arrow.svg";
import { ADMIN_CONTACTS, ADMIN_NEW_CONTACTS_V2 } from "routes";
import { Checkbox, Modal } from "@material-ui/core";
import { Divider, Tag } from "antd";
import CreateNewList from "pages/newContacts/AddToListDrawer/CreateNewList/CreateNewList";
import CreateNewListV2 from "pages/newContacts/AddToListDrawer/CreateNewList/CreateNewListFromTable";
import useGetLists from "hooks/useGetLists";
import { RiDragMove2Line, RiPencilFill } from "react-icons/ri";
import useListsMutation from "hooks/useListsMutation";
import { MdClose } from "react-icons/md";
import useContactTypes from "hooks/useContactTypes";
import { Select as AntSelect } from "antd";
import useGetSalesReps from "hooks/useGetSalesReps";
import { Phone, MessageSquare, Mail, Edit } from "lucide-react"; // Import the icons you want to use
import zIndex from "@material-ui/core/styles/zIndex";
import { Badge } from "@tremor/react";
import { BsPeopleFill } from "react-icons/bs";
import { getProjects } from "store/actions/projectActions";
import useGetProject from "hooks/useGetProjects";
import useGetCurrentProject from "hooks/useGetCurrentProject";
import { ContactContext } from "pages/contacts/context/Contacts";
import { RiDraggable } from "@remixicon/react";
export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return "-";
  try {
    const phoneNumber = parsePhoneNumber(phoneNumberString, "US");
    if (phoneNumber) {
      const countryCode = phoneNumber.countryCallingCode;
      const nationalNumber = phoneNumber.nationalNumber;
      return `+${countryCode}-${nationalNumber.slice(
        0,
        3
      )}-${nationalNumber.slice(3, 6)}-${nationalNumber.slice(6)}`;
    }
  } catch (error) {}
  return phoneNumberString;
};

export const checkIsValidPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return false;
  try {
    const phoneNumber = parsePhoneNumber(phoneNumberString, "US");
    if (phoneNumber) {
      return phoneNumber.isValid();
    }
  } catch (error) {
    return false;
  }
  return false;
};
const UserTableV2 = () => {
  const {
    state,
    isUserLoading: isLoading,
    filtering: isFiltering,
    setSelectedContact,
    setFilters,
    toggleContactsSortingType,
    toggleCheckContact,
    refetchFilteredUser,
    emptyCheckedSelections,
    toggleCheckAllContacts,
    toggleCheckList,
    view,
    setState,
    updateSortedContactIds,
    initializeSortedContactIds,
    closeContactDetailDrawer,
    page
  } = useContactContext();

  const {
    setSelectedContact: setSelectedContactV2,
  } = useContext(ContactContext);

  // @ts-ignore
  const [localState, setLocalState] = React.useState("asc");
  const bodyRef = useRef(null);
  const project = useSelector((state) => state.appReducer.appProject);
  const { createOrUpdateUser } = useUpdateUsers(true);
  const history = useHistory();
  const userObject = useSelector((state) => state.authReducer.userObject);
  const gridRef = React.useRef(null);
  const [listButtonManage, setListButtonManage] = React.useState([]);
  const [tempSave, setTempSave] = React.useState(null);
  const [demandNotes, setDemandNotes] = React.useState([]);
  const [corporatDemandNotes, setCorporatDemandNotes] = React.useState([]);
  const [rowHeight, setRowHeight] = React.useState(
    state?.isContactDetailDrawerOpen || isMobile ? 100 : 40
  );
  const { leadStatuses } = useLeadStatus();
  const { contactTypeData } = useContactTypes();
  const { scores } = useBuyerScore();
  const [salesReps, setSalesReps] = useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [saveStatus, setSaveStatus] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [menuOpen, setIsMenuOpen] = useState(false);
  const [listId, setListId] = useState();
  const [listSearch, setListSearch] = useState("");
  const { id } = useParams();
  const { addUsersToLists } = useListsMutation();
  const {
    lists,
    isLoading: isListLoading,
    refetch: listRefetch,
  } = useGetLists();

  const getSalesReps = () => {
    getUsers("", "?userType=SalesRep").then((users) => {
      const salesReps = users?.map((user) => ({
        id: user._id,
        value: `${user?.firstName} ${user?.lastName}`,
      }));
      setSalesReps(salesReps);
    });
  };
  const [createListModal, setCreateListModal] = useState(false);
  const [addToListModal, setAddToListModal] = useState(false);
  const { data: currentProject } = useGetCurrentProject();
  const [forceRefetch, setForceRefetch] = useState(false);
  const [projects, setProjects] = useState([]);
  const { projects: projectList } = useGetProject();
  const LoadingContacts = useCallback(
    () => (
      <FadedAnimatedDiv className="h-full w-full min-h-[600px] flex flex-col items-center mt-20">
        <Searching title="Loading contacts..." />
      </FadedAnimatedDiv>
    ),
    []
  );
  useEffect(() => {
    getSalesReps();
  }, []);

  const handleContainerClick = (event) => {
    if (!state.isContactDetailDrawerOpen) return;
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('_id');
      const newSearch = searchParams.toString();
      const newPath = history.location.pathname 
      closeContactDetailDrawer(newPath);
      setSelectedContactV2(null);
  };


  useEffect(() => {
    if (projectList?.length > 0) {
      let mapped = projectList.map((project) => ({
        ...project,
        value: project._id,
        label: project.projectName,
      }));
      setProjects(mapped);
    }
  }, [projectList]);

  const [selectedContactsCount, setSelectedContactsCount] = useState(0);

  const handleContactSelection = (contactId, isSelected) => {
    setSelectedContactsCount((prevCount) =>
      isSelected ? prevCount + 1 : prevCount - 1
    );
  };
  const handleAddToList = () => {
    let filters = state.filters;
    delete filters["page"];
    delete filters["limit"];
    delete filters["search"];
    getFilterContacts({
      ...filters,
      userType: state?.contactUserType,
    }).then((data) => {
      const contacts = data?.data;
      if (contacts.length === 0) {
        toast.error("No contacts found");
        return;
      }
      const listOfUsers = contacts?.map((contact) => contact?._id);
      const listOfIds = Object.keys(state?.checkedLists).filter(
        (key) => state?.checkedLists[key] === true
      );
      const previousData = {};
      lists
        ?.filter((list) => listOfIds.includes(list?._id))
        .forEach((list) => {
          previousData[list?._id] = list.users;
        });
      addUsersToLists({ listOfIds, listOfUsers, previousData });
      setAddToListModal(false);
    });
  };
  useEffect(() => {
    setRowHeight(state?.isContactDetailDrawerOpen || isMobile ? 100 : 40);
  }, [state?.isContactDetailDrawerOpen || isMobile]);

  useEffect(() => {
    if (saveStatus === "Saved") {
      const timeoutId = setTimeout(() => {
        setSaveStatus("");
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [saveStatus]);

  useEffect(() => {
    if (Object.keys(state?.checkedContacts).length === 0) {
      if (gridRef.current?.api) {
        gridRef.current.api.applyTransaction({
          update: contacts.map((contact) => ({
            ...contact,
            select: false,
          })),
        });
        gridRef.current?.api.refreshCells({ force: true });
      }
    }
  }, [state?.checkedContacts]);

  const colors = [
    { text: "#dc2626", bg: "#FDE8E8" }, // Red
    { text: "#f97316", bg: "#FFEDD5" }, // Orange
    { text: "#16a34a", bg: "#D1FAE5" }, // Green
    { text: "#2563eb", bg: "#DBEAFE" }, // Blue
    { text: "#7c3aed", bg: "#EDE9FE" }, // Purple
    { text: "#171717", bg: "#D1D5DB" }, // Dark Grey
    { text: "#facc15", bg: "#FEF9C3" }, // Yellow
    { text: "#e11d48", bg: "#FEE2E2" }, // Pink
  ];
  const leadStatusBadgeColorFinder = useCallback((title) => {
    if (
      title.includes("Attempted To Contact") ||
      title.includes("attempted to Contact") ||
      title.includes("Attempted to contact")
    ) {
      return colors[0];
    }

    if (title.includes("Connected") || title.includes("connected")) {
      return colors[1];
    }

    if (title.includes("New") || title.includes("new")) {
      return colors[2];
    }
    if (title.includes("Open Deal") || title.includes("open deal")) {
      return colors[3];
    }
    if (title.includes("Reservation") || title.includes("reservation")) {
      return colors[4];
    }
    if (title.includes("Unqualified") || title.includes("unqualified")) {
      return colors[5];
    }
    if (title.includes("New Lead") || title.includes("new lead")) {
      return colors[6];
    }

    if (title.includes("Owner") || title.includes("owner")) {
      return colors[7];
    }
    return {
      text: "#111928",
      bg: "#F3F4F6",
    };
  }, []);

  const onBtShowNoRows = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay();
  }, []);

  const onBtHide = useCallback(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.hideOverlay();
    } else {
      return;
    }
  }, []);

  const onBtShowLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, []);

  const userTypeBadgeColor = useCallback((title) => {
    if (title === "LeadBroker") {
      return colors[3];
    }

    if (title === "Buyer") {
      return colors[5];
    }
    return {
      text: "#111928",
      bg: "#F3F4F6",
    };
  }, []);

  const ContactTypeBadge = ({ value }) => {
    let options = contactTypeData?.values || [];

    const badgeColor = colors[options.findIndex((option) => option === value)];
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text,
    };
    if (badgeColor) {
      return (
        <>
          {value && (
            <div
              className="px-3 py-0.5 rounded-md md:rounded-md justify-center items-center gap-1 inline-flex"
              style={badgeStyle}
            >
              <div className="text-center text-xs font-medium">
                {value || "-"}
              </div>
            </div>
          )}
        </>
      );
    }
    return null;
  };

  const DropDownSalesRep = forwardRef((props, ref) => {
    const [value, setValue] = useState(props?.value);
    const options = salesReps || [];
    const selectOptions = options.map((option) => ({
      value: option?._id || option?.id,
      label: option?.name || option?.value,
    }));
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const handleChange = (event) => {
      setValue(event.value);
      if (props.onValueChange) {
        props.onValueChange(event.value, props.data);
      }
      if (props.api) {
        props.api.stopEditing();
      }
    };

    const tableRef = props.tableRef;
    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);

    return (
      <Select
        ref={ref}
        value={value}
        onChange={handleChange}
        options={selectOptions}
        className="w-full"
        isSearchable={false}
        menuPortalTarget={document.body}
        value={selectOptions.find((option) => option.value === value)}
        menuIsOpen={props.api.getEditingCells().length > 0}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
      ></Select>
    );
  });

  const DropdownCellEditor = forwardRef((props, ref) => {
    let type = props.type;
    let salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    const [value, setValue] = useState(props.value);
    const options = props.options || [];
    const selectOptions = options.map((option) => ({
      value: option,
      label: option,
    }));
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const CustomOption = ({ innerProps, label, isFocused, isSelected }) => (
      <div
        {...innerProps}
        className={`flex items-center justify-center py-2 cursor-pointer ${
          isFocused || isSelected ? "bg-gray-100" : "bg-white"
        }`}
      >
        <LeadStatusBadge title={label} haveDotIcon={false} />
      </div>
    );

    const handleChange = (event) => {
      setValue(event.value);
      if (props.onValueChange) {
        props.onValueChange(event.value, props.data);
      }
      if (props.api) {
        props.api.stopEditing();
      }
    };

    const tableRef = props.tableRef;
    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);

    return (
      <Select
        ref={ref}
        onChange={handleChange}
        className="w-full"
        options={selectOptions}
        isSearchable={false}
        components={{ Option: CustomOption }}
        value={selectOptions.find((option) => option.value === value)}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
        formatOptionLabel={({ label }) => (
          <LeadStatusBadge title={label} haveDotIcon={false} />
        )}
        menuIsOpen={props.api.getEditingCells().length > 0}
      />
    );
  });

  const DropdownCellEditorContactType = forwardRef((props, ref) => {
    let type = props.type;
    let salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    const [value, setValue] = useState(props.value);
    const options = props.options || [];
    const selectOptions = options.map((option) => ({
      value: option,
      label: option,
    }));
    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const CustomOption = ({ innerProps, label, isFocused, isSelected }) => (
      <div
        {...innerProps}
        className={`flex items-center justify-center py-2 cursor-pointer ${
          isFocused || isSelected ? "bg-gray-100" : "bg-white"
        }`}
      >
        <ContactTypeBadge value={label} />
      </div>
    );

    const handleChange = (event) => {
      setValue(event.value);
      if (props.onValueChange) {
        props.onValueChange(event.value, props.data);
      }
      if (props.api) {
        props.api.stopEditing();
      }
    };

    const tableRef = props.tableRef;
    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);

    return (
      <Select
        ref={ref}
        onChange={handleChange}
        className="w-full"
        options={selectOptions}
        isSearchable={false}
        components={{ Option: CustomOption }}
        value={selectOptions.find((option) => option.value === value)}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
        formatOptionLabel={({ label }) => <ContactTypeBadge value={label} />}
        menuIsOpen={props.api.getEditingCells().length > 0}
      />
    );
  });

  const DropdownCellEditorCorporateDemands = forwardRef((props, ref) => {
    let type = props.type;
    let salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    let values = props.value;
    const options = props.options || [];
    let selectedValues =
      values?.map((item) => {
        return options.find((option) => option.value === item)?.value;
      }) || [];

    let filterUndefined = selectedValues.filter((item) => item !== undefined);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [value, setValue] = useState(filterUndefined);
    const selectOptions = options.map((option) => ({
      value: option.value,
      label: option.label,
    }));
    const isMulti = props.isMulti;

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const updateCell = useCallback(
      (newValue) => {
        if (props.api) {
          const updatedData = { ...props.data };
          updatedData[props.column.colId] = newValue;
          props.api.applyTransactionAsync({ update: [updatedData] });
          props.api.refreshCells({ force: true });
        }
      },
      [props.api, props.data, props.column.colId]
    );
    const handleChange = useCallback(
      (newValue) => {
        let updatedValue;
        if (isMulti) {
          updatedValue = newValue;
        } else {
          updatedValue =
            newValue.length > 0 ? [newValue[newValue.length - 1]] : [];
        }
        setValue(updatedValue);
        if (props.onValueChange) {
          props.onValueChange(updatedValue, props.data, props.column.colId);
        }
        updateCell(updatedValue);
      },
      [props.onValueChange, props.data, props.column.colId, updateCell, isMulti]
    );

    const tableRef = props.tableRef;
    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);

    useEffect(() => {
      setDropdownOpen(true);
    }, []);
    return (
      <AntSelect
        mode={"multiple"}
        value={value}
        onChange={(value) => {
          handleChange(value);
        }}
        options={selectOptions}
        className="w-full"
        style={{ width: "100%" }}
        open={dropdownOpen}
        onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        dropdownRender={(menu) => (
          <div onMouseDown={(e) => e.stopPropagation()}>{menu}</div>
        )}
        tagRender={(props) => {
          const { label, value: val, closable, onClose } = props;
          return (
            <Tag
              color="#3682eb"
              closable={closable && isMulti}
              onClose={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const newValue = value.filter((v) => v !== val);
                handleChange(newValue);
              }}
              style={{ marginRight: 3 }}
            >
              {label}
            </Tag>
          );
        }}
      />
    );
  });

  const DropdownCellEditorCustomDemands = forwardRef((props, ref) => {
    let type = props.type;
    let salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    const [value, setValue] = useState(props.value);
    const options = props.options || [];
    const selectOptions = options.map((option) => ({
      value: option,
      label: option,
    }));

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return value;
        },
        isCancelBeforeStart() {
          return false;
        },
        isCancelAfterEnd() {
          return false;
        },
      };
    });

    const handleChange = (event) => {
      setValue(event.value);
      if (props.onValueChange) {
        props.onValueChange(event.value, props.data, props.column.colId);
      }
      if (props.api) {
        props.api.stopEditing();
      }
    };

    return (
      <Select
        ref={ref}
        value={value}
        onChange={handleChange}
        options={selectOptions}
        className="w-full"
        isSearchable={false}
        menuPortalTarget={document.body}
        value={selectOptions.find((option) => option.value === value)}
        menuIsOpen={props.api.getEditingCells().length > 0}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            marginTop: 0,
            marginBottom: 0,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
          }),
          option: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "inherit",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          }),
          control: (provided) => ({
            ...provided,
            minWidth: 120, // Adjust as needed
          }),
        }}
      >
        {/* {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))} */}
      </Select>
    );
  });

  const LeadStatusBadge = ({ title, haveDotIcon = false, onClick }) => {
    const badgeColor = leadStatusBadgeColorFinder(title);
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text,
    };
    if (badgeColor) {
      return (
        <div
          onClick={onClick}
          className="px-3 py-0.5 rounded-md md:rounded-md justify-center items-center gap-1 inline-flex"
          style={badgeStyle}
        >
          {haveDotIcon && !isMobile && (
            <div
              className="w-2 h-2 rounded-full"
              style={{
                backgroundColor: badgeColor?.text,
              }}
            />
          )}
          <div className="text-center text-xs font-medium">{title}</div>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const getCorporateCustomDemandFields = async () => {
      const data = await getCorporatDemandNotes();
      if (data) {
        const formatedData = await data
          .filter((item) => item.label !== "")
          .map((item) => {
            return {
              label: {
                name: item.label,
                _id: item._id,
              },
              values: [
                ...item.options.map((option) => {
                  return {
                    label: option.label,
                    value: option.id,
                  };
                }),
              ],
              isMulti: item.isMulti,
            };
          });
        setCorporatDemandNotes(formatedData);
      }
    };
    getCorporateCustomDemandFields();
    const getCustomDemandFields = async () => {
      const data = await GetDemandNotes();
      if (data) {
        const formatedData = await data
          .filter((item) => item.label !== "")
          .map((item) => {
            return {
              label: item.label,
              values: item.options,
            };
          });
        setDemandNotes(formatedData);
      }
    };
    getCustomDemandFields();
  }, [view]);

  const [hoveredRowId, setHoveredRowId] = useState(null);

  const UserImage = (props) => {
    const { hoveredRowId, setHoveredRowId } = props;
    const [isHovered, setIsHovered] = useState(false);
    const nameRef = useRef(null);
    let colors = getHighlightStyle(props);
    const fullName = `${props?.data?.firstName} ${props?.data?.lastName}`;
    const handleIconClick = (tab) => {
      history.push({
        pathname: history.location.pathname,
        search: `?_id=${props.data._id}&tab=${tab}`,
      });
      setSelectedContact(props?.data);
    };
    return (
      <div
        style={{
          backgroundColor: colors ? colors?.backgroundColor : "transparent",
        }}
        className="relative w-full h-full"
        onMouseEnter={() => setIsHovered(props?.data?._id)}
        onMouseLeave={() => setIsHovered(null)}
      >
        <div
          ref={nameRef}
          className={`transition-opacity duration-300 ${
            isHovered ? "opacity-40" : "opacity-100"
          }`}
        >
          {fullName}
        </div>
        {!state.isContactDetailDrawerOpen && isHovered && (
          <div className="absolute right-0 top-0 bottom-0 h-full flex items-center gap-x-2 p-1 rounded">
            <CallsIcon
              onClick={() => handleIconClick("calls")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
            <EmailIcon
              onClick={() => handleIconClick("email")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
            <SMSIcon
              onClick={() => handleIconClick("sms")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
            <TasksIcon
              onClick={() => handleIconClick("tasks")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
            <NotesIcon
              onClick={() => handleIconClick("notes")}
              className="w-5 h-5 cursor-pointer text-gray-600"
            />
          </div>
        )}
      </div>
    );
  };

  const UserTypeBadge = (props) => {
    const badgeColor = userTypeBadgeColor(props?.data?.userType);
    const badgeStyle = {
      backgroundColor: badgeColor?.bg,
      color: badgeColor?.text,
    };
    return (
      <div
        className="px-3 py-0.5 rounded-md md:rounded-md justify-center items-center gap-1 inline-flex"
        style={badgeStyle}
      >
        <div className="text-center text-xs font-medium">
          {props?.data?.userType === 'LeadBroker'
            ? 'Realtor'
            : props?.data?.userType
          }
        </div>
      </div>
    );
  };

  const salesRepKey = (data) => {
    if (data === "Buyer") return "buyerData";
    if (data === "LeadBroker") return "leadBrokerData";
    return "";
  };

  // const isToday = (date) => {
  //   return moment(date).isSame(moment(), "isoWeek");
  // };

  const isLastContactInBetweenCurrentWeek = (date) => {
    const startOfWeek = moment().startOf('isoWeek');
    const endOfWeek = moment().endOf('isoWeek');
    return moment(date).isBetween(startOfWeek, endOfWeek);
  };

  const last7Days = (date) => {
    return moment(date).isAfter(moment().subtract(7, "days"));
  }

  const getHighlightStyle = (params) => {
    if (
      params.data[salesRepKey(params.data.userType)]?.lastContactedAt !==
      undefined || params.data.lastContactedAt !== undefined
    ) {
      if (
        last7Days(params.data[salesRepKey(params.data.userType)]?.lastContactedAt || params.data.lastContactedAt)
      ) {
        return { backgroundColor: "#eddf65" };
      }
    }
    return null;
  };

  const LeadStatusComponent = (props) => {
    const [click, setClick] = React.useState(false);
    let leadStatus = props?.data[salesRepKey(props?.data.userType)]?.leadStatus;
    return (
      <LeadStatusBadge
        onClick={() => {
          setClick(true);
        }}
        title={
          leadStatus || '-'
        }
      />
    );
  };

  const CustomDateFilter = (props) => {
    const {
      toggleContactsSortingType,
      setFilters,
      state,
      localState,
      setLocalState,
    } = props;
    const handleClick = () => {
      toggleContactsSortingType();
      setFilters({
        ...state.filters,
        sortType: localState === "desc" ? "asc" : "desc",
      });
      setLocalState(localState === "desc" ? "asc" : "desc");
    };

    return (
      <div onClick={handleClick} className="">
        Date Register
        <span className="ml-2 text-[16px] font-[500]">
          {localState === "asc" ? "↓" : "↑"}
        </span>
      </div>
    );
  };

  const MobileUserCard = (props) => {
    return (
      <div className="flex items-start w-[100%] gap-x-3">
        <div className="flex items-start gap-x-2">
          <div className="flex flex-col gap-y-1">
            <div className="text-[12px] leading-[14px]">
              {props.data.firstName} {props.data.lastName}
            </div>
            <div className="flex gap-x-3 items-center">
              <span className="text-xs">User Type:</span>
              <div className="px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex bg-[#F3F4F6]">
                <div className="text-center text-xs font-medium">
                  {props.data.userType}
                </div>
              </div>
            </div>
            <div className="flex gap-x-3 items-center">
              <span className="text-xs">Lead Status:</span>
              <LeadStatusBadge
                title={
                  props?.data?.buyerData?.leadStatus ||
                  props?.data?.leadBrokerData?.leadStatus ||
                  "-"
                }
                haveDotIcon={false}
              />
            </div>
            <div className="text-xs  text-zinc-400">
              {props.data.dateRegistered}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingCellRenderer;
  }, []);

  const components = useMemo(() => {
    return {
      leadStatus: LeadStatusComponent,
      userImage: UserImage,
      mobileUserCard: MobileUserCard,
      userTypeBadge: UserTypeBadge,
    };
  }, [UserImage, LeadStatusComponent, MobileUserCard]);

  const getOrderedColumns = (columns, savedOrder) => {
    if (columns.length !== savedOrder.length) return columns;
    const orderedColumns = [];
    const columnMap = {};

    columns.forEach((col) => {
      columnMap[col.field] = {
        ...col,
      };
    });

    savedOrder.forEach((col) => {
      if (columnMap[col.colId]) {
        const orderedCol = {
          ...col,
          ...columnMap[col.colId],
        };
        orderedColumns.push(orderedCol);
        delete columnMap[col.colId];
      }
    });

    Object.values(columnMap).forEach((col) => {
      orderedColumns.push(col);
    });

    return orderedColumns;
  };

  const DropdownCellEditorProject = forwardRef((props, ref) => {
    let values = props.value;
    const options = props.options || [];
    let selectedValues =
      values?.map((item) => {
        return options.find((option) => option.value === item._id)?.value;
      }) || [];

    let filterUndefined = selectedValues.filter((item) => item !== undefined);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [value, setValue] = useState(filterUndefined);
    const selectOptions = options.map((option) => ({
      value: option.value,
      label: option.label,
    }));

    useEffect(() => {
      setDropdownOpen(true);
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        return false;
      },
      isCancelAfterEnd() {
        return false;
      },
    }));

    const handleChange = useCallback(
      (newValue) => {
        let updatedValue;
        updatedValue = newValue;
        setValue(updatedValue);
        if (props.onValueChange) {
          props.onValueChange(updatedValue, props.data, props.column.colId);
        }
      },
      [props.onValueChange, props.data, props.column.colId]
    );

    useEffect(() => {
      const handleClickOutside = (event) => {
        props.api.stopEditing();
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [props.api]);

    return (
      <AntSelect
        mode={"multiple"}
        value={value}
        onChange={(value) => {
          handleChange(value);
        }}
        options={selectOptions}
        className="w-full"
        style={{ width: "100%" }}
        open={dropdownOpen}
        onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        dropdownRender={(menu) => (
          <div onMouseDown={(e) => e.stopPropagation()}>{menu}</div>
        )}
        tagRender={(props) => {
          const { label, value: val, closable, onClose } = props;
          return (
            <Tag
              color="#3682eb"
              closable={closable}
              onClose={(e) => {
                e.preventDefault();
                e.stopPropagation();
                const newValue = value.filter((v) => v !== val);
                handleChange(newValue);
              }}
              style={{ marginRight: 3 }}
            >
              {label}
            </Tag>
          );
        }}
      />
    );
  });

  const loadColumnOrder = () => {
    const orderKeyNew = `newColumnOrder_${view}`;
    const savedOrderNew = localStorage.getItem(orderKeyNew);
    if (savedOrderNew) {
      return JSON.parse(savedOrderNew);
    }
    return null;
  };

  const columns = useCallback((userType) => {
    const salesRepKey = (data) => {
      if (data === "Buyer") return "buyerData";
      if (data === "LeadBroker") return "leadBrokerData";
      return "";
    };
    let result = [
      {
        headerName: "",
        hiddenName: "Open",
        field: "open",
        width: 60,
        pinned: "left",
        sortable: false,
        cellRenderer: (props) => (
          <FullScreen className=" scale-[.8] flex items-center justify-center" />
        ),
        cellStyle: getHighlightStyle,
        highlightToday: true,
      },
      {
        headerName: "",
        field: "checkbox",
        hiddenName: "Checkbox",
        width: 70,
        sortable: false,
        pinned: "left",
        cellRenderer: (params) => {
          return (
            <CustomCheckbox
              contactId={params?.data?._id}
              rowIndex={params?.rowIndex}
            />
          );
        },
        headerComponent: (params) => (
          <Checkbox
            color="primary"
            type="checkbox"
            checked={
              Object.values(state?.checkedContacts).filter(
                (val) => val === true
              ).length === contacts.length && contacts.length > 0
            }
            onChange={(e) => {
              toggleCheckAllContacts();
              params.api.applyTransaction({
                update: contacts.map((contact) => ({
                  ...contact,
                  select: e.target.checked,
                })),
              });
              params.api.refreshCells({ force: true });
            }}
          />
        ),
        cellStyle: getHighlightStyle,
        highlightToday: true,
      },
      {
        headerName: "Name",
        field: "fullName",
        width: 300,
        resizable: true,
        pinned: "left",
        editable: true,
        cellStyle: getHighlightStyle,
        cellRenderer: (params) => (
          <UserImage
            {...params}
            hoveredRowId={hoveredRowId}
            setHoveredRowId={setHoveredRowId}
          />
        ),
        // onCellMouseEnter: (params) => {
        //   setHoveredRowId(params.data._id);
        //   params.api.refreshCells({
        //     force: true,
        //     columns: ["fullName"],
        //   });
        // },
        // onCellMouseLeave: (params) => {
        //   setHoveredRowId(null);
        //   params.api.refreshCells({
        //     force: true,
        //     columns: ["fullName"],
        //   });
        // },
        onCellClicked: (function () {
          let lastEditTime = 0;
          let clickTimeout = null;
          let clickCount = 0;
          return (params) => {
            if (params.event.target.closest(".absolute")) {
              return; // Do nothing if the click was on an icon
            }
            const currentTime = new Date().getTime();
            if (currentTime - lastEditTime < 500) {
              return;
            }
            if (isEditing) return;
            clickCount++;
            if (clickCount === 1) {
              clickTimeout = setTimeout(() => {
                clickCount = 0;
                params.api.refreshCells({ force: true });
                const updatedRowNode = params.api.getRowNode(params.node.id);
                const updatedData = updatedRowNode.data;
                setSelectedContact(updatedData);
                history.push({
                  pathname: history.location.pathname,
                  search: `?_id=${updatedData._id}`,
                });
              }, 300);
            } else if (clickCount === 2) {
              clearTimeout(clickTimeout);
              clickCount = 0;
              setIsEditing(true);
              params.api.startEditingCell({
                rowIndex: params.node.rowIndex,
                colKey: params.column.getColId(),
              });
            }
          };
        })(),
        onCellValueChanged: (function () {
          let lastEditTime = 0;
          return (params) => {
            lastEditTime = new Date().getTime();
          };
        })(),
      },
      {
        headerName: "Date Registered",
        field: "createdAt",
        width: 200,
        sortable: false,
        headerComponent: (params) => (
          <CustomDateFilter
            toggleContactsSortingType={toggleContactsSortingType}
            setFilters={setFilters}
            state={state}
            localState={localState}
            setLocalState={setLocalState}
          />
        ),
        valueFormatter: (p) => {
          if (p.value) {
            return moment(p.value).format("MM/DD/YYYY");
          } else {
            return "-";
          }
        },
      },
      {
        headerName: "Last Contacted At",
        width: 200,
        field: "lastContactedAt",
        resizable: true,
        cellRenderer: (params) => {
          return (
            <div>
              {params.value
                ? moment(params?.value).format("MM/DD/YYYY hh:mm A")
                : ""}
            </div>
          );
        },
      },
      {
        headerName: "User Type",
        field: "userType",
        width: 150,
        resizable: true,
        cellRenderer: "userTypeBadge",
      },
      {
        headerName: "Project",
        width: 200,
        field: "project",
        valueGetter: (params) => {
          return params?.data?.[
            salesRepKey(params?.data?.userType)
          ]?.assignments
            ?.map((item) => item?.project)
            .filter((item) => item !== null && item !== undefined);
        },
        resizable: true,
        cellRenderer: (params) => {
          return (
            <div className="flex gap-x-2">
              {params?.value?.length !== 0 &&
                params.value?.map((project, index) => (
                  <div
                    key={index}
                    className="px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex bg-[#c5ddff]"
                  >
                    <div className="text-center text-black text-[12px] leading-[14px] font-normal">
                      {project.projectName}
                    </div>
                  </div>
                ))}
            </div>
          );
        },
        sortable: true,
        editable: true,
        cellEditor: DropdownCellEditorProject,
        cellEditorParams: {
          options: projects,
          onValueChange: (newValue, data, colId) => {
            setSaveStatus("Saving...");
            let updatedProjects = projects.filter((project) =>
              newValue.includes(project._id)
            );
            const updatedData = {
              ...data,
              id: data._id,
              [salesRepKey(data.userType)]: {
                ...data[salesRepKey(data.userType)],
                assignments: updatedProjects.map((project, index) => ({
                  ...data[salesRepKey(data.userType)]?.assignments[index],
                  project: project,
                })),
              },
            };
            // do not reset row width on update
            gridRef.current.api.applyTransaction({
              update: [updatedData],
              resetRowWidth: false,
            });
            gridRef.current.api.refreshCells({
              force: true,
              columns: ["project"],
            });
            createOrUpdateUser(updatedData, false).then(() => {
              setSaveStatus("Saved");
              refetchFilteredUser();
            });
          },
        },
        onCellClicked: (params) => {
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.getColId(),
          });
        },
      },
      ...corporatDemandNotes?.map((demand) => {
        return {
          headerName: demand?.label?.name,
          field: demand?.label?._id,
          width: 200,
          resizable: true,
          uqKey: "corporateDemands",
          editable: true,
          cellRenderer: (params) => {
            let values = params.value;
            let options = demand.values?.map((item) => item);
            let selectedValues;
            if (Array.isArray(values)) {
              selectedValues =
                values?.map((item) => {
                  return options.find((option) => option.value === item)?.label;
                }) || [];
            } else {
              selectedValues = [values];
              selectedValues = selectedValues.filter(
                (item) => item !== undefined
              );
            }

            let filterUndefined = selectedValues.filter(
              (item) => item !== undefined
            );
            return (
              <div className="flex gap-x-2">
                {filterUndefined &&
                  filterUndefined?.length > 0 &&
                  filterUndefined?.map((tag) => (
                    <div
                      key={tag}
                      className="px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex bg-[#3682eb]"
                    >
                      <div className="text-center text-white text-[10px] leading-[14px] font-normal">
                        {tag}
                      </div>
                    </div>
                  ))}
              </div>
            );
          },
          cellEditor: DropdownCellEditorCorporateDemands,
          cellEditorParams: {
            type: "demand",
            options: demand.values,
            isMulti: demand.isMulti,
            onValueChange: (newValue, data, colId) => {
              setSaveStatus("Saving...");
              const salesRepDataKey = salesRepKey(data.userType);
              const newUserData = {
                ...data,
              };
              const newCustomDemands =
                newUserData[salesRepDataKey].corporateDemands || [];
              const demandIndex = newCustomDemands.findIndex(
                (customDemand) => customDemand.label === colId
              );
              if (demandIndex === -1) {
                newCustomDemands.push({
                  label: colId,
                  values: [...newValue],
                });
              } else {
                newCustomDemands[demandIndex] = {
                  label: colId,
                  values: [...newValue],
                };
              }
              newUserData[salesRepDataKey].corporateDemands = newCustomDemands;
              gridRef.current.api.applyTransactionAsync({
                update: [
                  {
                    ...newUserData,
                  },
                ],
              });
              gridRef.current.api.refreshCells({ force: true });
              createOrUpdateUser(newUserData, false).then(() => {
                setSaveStatus("Saved");
                refetchFilteredUser();
              });
            },
          },
          onCellClicked: (params) => {
            params.api.startEditingCell({
              rowIndex: params.rowIndex,
              colKey: params.column.getColId(),
            });
          },
        };
      }),

      {
        headerName: "Sales Rep Owner",
        field: "salesRep",
        width: 180,
        resizable: true,
        valueGetter: (params) => {
          return params?.data?.salesRep;
        },
        resizable: true,
        editable: true,
        cellEditor: DropDownSalesRep,
        cellEditorParams: {
          type: "salesRep",
          tableRef: bodyRef,
          options: salesReps?.values || [],
          onValueChange: (newValue, data) => {
            const updatedData = new Object({
              ...data,
              salesRep: newValue,
            });
            // update the current row only
            gridRef.current.api.applyTransaction({
              update: [updatedData],
            });
            gridRef.current.api.refreshCells({ force: true });
            updateSalesRep({
              salesRep: newValue,
              contactId: data?._id,
              project: project
            }).then(() => {
              refetchFilteredUser();
              setSaveStatus("Saved");
            });
          },
        },
        onCellClicked: (params) => {
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.getColId(),
          });
        },
      },
      {
        headerName: "Email",
        field: "email",
        width: 250,
        editable: true,
        resizable: true,
        valueFormatter: (p) => {
          if (!p.value) {
            return "-";
          }
        },
      },
      {
        headerName: "Phone Number",
        field: "phoneNumber",
        width: 180,
        resizable: true,
        editable: true,
        cellStyle: (params) => {
          let salesRepKey = (data) => {
            if (data === "Buyer") return "buyerData";
            if (data === "LeadBroker") return "leadBrokerData";
            return "";
          };
          const _number =
            params?.data?.[salesRepKey(params?.data?.userType)]?.phoneNumber;
          const isValid = checkIsValidPhoneNumber(_number);
          return {
            backgroundColor: isValid ? "" : "#ffcccc",
          };
        },
        valueFormatter: (p) => formatPhoneNumber(p?.value),
      },

      {
        headerName: "Buyer Score",
        width: 180,
        field: "rating",
        valueGetter: (params) => {
          return (
            params?.data?.[salesRepKey(params?.data?.userType)]?.rating || "-"
          );
        },
        resizable: true,
        editable: true,
        cellEditor: DropdownCellEditor,
        cellEditorParams: {
          type: "rating",
          tableRef: bodyRef,
          options: scores?.values || [],
          onValueChange: (newValue, data) => {
            const userType = contacts[0]?.userType;
            const key = salesRepKey(userType);
            const updatedData = {
              ...data,
              [key]: {
                ...data?.[key],
                rating: newValue,
              },
            };
            gridRef.current.api.applyTransaction({
              update: [updatedData],
            });
            gridRef.current.api.refreshCells({ force: true });
            updateRating(newValue, data?._id).then(() => {
              refetchFilteredUser();
              setSaveStatus("Saved");
            });
          },
        },
        onCellClicked: (params) => {
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.getColId(),
          });
        },
      },
      {
        headerName: "Lead Status",
        width: 200,
        field: "leadStatus",
        resizable: true,
        valueGetter: (params) => {
          return (
            params?.data?.[salesRepKey(params?.data?.userType)]?.leadStatus ||
            "-"
          );
        },
        cellRenderer: "leadStatus",
        editable: true,
        cellEditor: DropdownCellEditor,
        cellEditorParams: {
          type: "leadStatus",
          tableRef: gridRef,
          options: leadStatuses?.values || [],
          onValueChange: (newValue, data) => {
            const userType = contacts[0]?.userType;
            const key = salesRepKey(userType);
            const updatedData = {
              ...data,
              [key]: {
                ...data?.[key],
                leadStatus: (newValue).trim(),
              },
            };
            gridRef.current.api.applyTransaction({
              update: [updatedData],
            });
            gridRef.current.api.refreshCells({ force: true });
            updateLeadStatus(newValue.trim(), data?._id).then(() => {
              refetchFilteredUser();
              setSaveStatus("Saved");
            });
          },
        },
        onCellClicked: (params) => {
          setIsEditing(true);
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.getColId(),
          });
        },
        // valueFormatter: (p) => currencyFormate(p.value),
      },

    
      {
        headerName: "Last Contacted Email",
        width: 200,
        field: "lastContacted.email",
        resizable: true,
        cellRenderer: (params) => {
          return (
            <div>
              {params.value
                ? moment(params?.value).format("MM/DD/YYYY hh:mm A")
                : ""}
            </div>
          );
        },
      },
      {
        headerName: "Contact Type",
        width: 200,
        field: "contactType",
        resizable: true,
        valueGetter: (params) => {
          return (
            params?.data?.[salesRepKey(params?.data?.userType)]?.contactType ||
            "-"
          );
        },
        cellRenderer: ContactTypeBadge,
        editable: true,
        cellEditor: DropdownCellEditorContactType,
        cellEditorParams: {
          type: "contactType",
          tableRef: gridRef,
          options: contactTypeData?.values || [],
          onValueChange: (newValue, data) => {
            const userType = contacts[0]?.userType;
            const key = salesRepKey(userType);
            const updatedData = {
              ...data,
              [key]: {
                ...data[key],
                contactType: newValue,
              },
            };
            gridRef.current.api.applyTransaction({
              update: [updatedData],
            });
            gridRef.current.api.refreshCells({ force: true });
            updateContactType(newValue, data?._id).then(() => {
              refetchFilteredUser();
              setSaveStatus("Saved");
            });
          },
        },
        onCellClicked: (params) => {
          setIsEditing(true);
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.getColId(),
          });
        },
        // valueFormatter: (p) => currencyFormate(p.value),
      },
      {
        headerName: "Tags",
        field: "tags",
        width: 240,
        resizable: true,
        cellRenderer: (params) => {
          return (
            <div className="flex gap-x-2">
              {params?.data?.buyerData?.tags?.map((tag) => (
                <div
                  key={tag}
                  className="px-3 py-0.5 rounded-md justify-center items-center gap-1 inline-flex bg-[#3682eb]"
                >
                  <div className="text-center text-white text-[10px] leading-[14px] font-normal">
                    {tag}
                  </div>
                </div>
              ))}
            </div>
          );
        },
      },
      {
        headerName: "Company Name",
        field: "companyName",
        width: 250,
        resizable: true,
        cellRenderer: (params) => {
          console.log('params: ', params);
          return (
            <>
              {params?.data?.buyerData?.companyName || ""}
            </>
          );
        },
      },

      ...demandNotes?.map((demand) => {
        return {
          headerName: demand?.label,
          field: demand?.label,
          width: 150,
          resizable: true,
          editable: true,
          uqKey: "customDemands",
          cellEditor: DropdownCellEditorCustomDemands,
          cellEditorParams: {
            type: "demand",
            options: demand.values?.map((item) => item.value),
            onValueChange: (newValue, data, colId) => {
              setSaveStatus("Saving...");
              const salesRepDataKey = salesRepKey(data.userType);
              const newUserData = {
                ...data,
              };
              newUserData[colId] = newValue;

              const newCustomDemands =
                newUserData[salesRepDataKey].customDemands || [];

              const demandIndex = newCustomDemands?.findIndex(
                (customDemand) => customDemand.label === colId
              );
              if (demandIndex === -1) {
                newCustomDemands.push({
                  label: colId,
                  value: newValue,
                });
              } else {
                newCustomDemands[demandIndex] = {
                  label: colId,
                  value: newValue,
                };
              }
              newUserData[salesRepDataKey].customDemands = newCustomDemands;
              gridRef.current.api.applyTransactionAsync({
                update: [
                  {
                    ...newUserData,
                  },
                ],
              });
              gridRef.current.api.refreshCells({ force: true });
              createOrUpdateUser(newUserData, false).then(() => {
                setSaveStatus("Saved");
                refetchFilteredUser();
              });
            },
          },
          onCellClicked: (params) => {
            params.api.startEditingCell({
              rowIndex: params.rowIndex,
              colKey: params.column.getColId(),
            });
          },
        };
      }),
    ];

    if (userType === "LeadBroker") {
      result = result.filter((item) => (!(["tags", "companyName"].includes(item?.field))));
    }

    if (currentProject && view === "project") {
      if (currentProject?.showProjectDemandNote === false) {
        result = result.filter((item) => item?.uqKey !== "customDemands");
      }
      if (currentProject?.showCorporateDemandNote === false) {
        result = result.filter((item) => item?.uqKey !== "corporateDemands");
      }
    }

    const savedOrder = loadColumnOrder();
    return savedOrder ? getOrderedColumns(result, savedOrder) : result;
  }, [loadColumnOrder, getOrderedColumns, view]);

  const style = {
    height: "460px",
    width: "100%",
    overflow: "auto",
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
    };
  }, []);

  const filteredContacts = useMemo(() => {
    const _contacts = state.contacts.map((contact) => {
      let customDemads = {};
      if (contact.userType === "Buyer") {
        contact?.buyerData?.customDemands?.forEach((demand) => {
          customDemads[demand.label] = demand.value;
        });
      }
      if (contact.userType === "LeadBroker") {
        contact?.leadBrokerData?.customDemands?.forEach((demand) => {
          customDemads[demand.label] = demand.value;
        });
      }

      let corporateDemands = {};
      if (contact.userType === "Buyer") {
        contact?.buyerData?.corporateDemands?.forEach((demand) => {
          corporateDemands[demand.label] = demand.values;
        });
      }
      if (contact.userType === "LeadBroker") {
        contact?.leadBrokerData?.corporateDemands?.forEach((demand) => {
          corporateDemands[demand.label] = demand.values;
        });
      }
      const salesRepKey =
        contact?.userType?.toLowerCase?.() === "buyer"
          ? "buyerData"
          : "leadBrokerData";
      return {
        ...contact,
        // dateRegistered: moment(contact?.createdAt).isValid()
        //   ? moment(contact?.createdAt).format('MM/DD/YYYY')
        //   : '',
        salesRep:
          contact[salesRepKey]?.assignments?.find((item) => item.project?._id === project)?.salesRep?.firstName || "-",
        // name: contact?.firstName + ' ' + contact?.lastName,
        select: false,
        phoneNumber: contact[salesRepKey]?.phoneNumber,
        ...customDemads,
        ...corporateDemands,
        lastContactedAt: contact?.lastContactedAt || contact[salesRepKey]?.lastContactedAt || "",
      };
    });

    const _filterContacts = _contacts.filter((contact) => {
      const salesType = contact?.userType?.toLowerCase?.();

      if (state?.contactUserType?.toLowerCase?.() === "leadbroker") {
        return (
          salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 &&
          (contact?.deleted === false || contact?.deleted === undefined)
        );
      } else if (state?.contactUserType?.toLowerCase?.() === "buyer") {
        return (
          salesType?.indexOf(state?.contactUserType?.toLowerCase?.()) !== -1 &&
          (contact?.deleted === false || contact?.deleted === undefined)
        );
      } else if (state?.contactUserType?.toLowerCase?.() === "default") {
        return true;
      } else {
        return false;
      }
    });
    if (gridRef.current?.api) {
      onBtHide();
    }
    emptyCheckedSelections();
    setContacts(_filterContacts);
    return _filterContacts ?? [];
  }, [
    refetchFilteredUser,
    userObject,
    state?.sortType,
    state?.contacts,
    state?.contactUserType,
    state?.filters?.salesRepOwner,
    state.filters,
    state.refreshUsers,
  ]);

  useEffect(() => {
    if (isFiltering) {
      if (gridRef.current?.api) {
        onBtShowLoading();
      }
    }
    if (!isFiltering) {
      if (gridRef.current?.api) {
        onBtHide();
      }
    }
  }, [isFiltering]);

  const [sortedContacts, setSortedContacts] = useState([]);
  const [tempDuplicate, setTempDuplicate] = useState(0);

  /* useEffect(() => {
    const getDuplicateContacts = async () => {
      try {
        let url = `/users/contacts/duplicates/count?projectId=${projectId}`;
        if(view === 'corporate') {
          url = `/users/contacts/duplicates/count?projectId=`
        }
        const response = await api.get(
          url
        );
        setDuplicateContacts(response?.data?.totalDuplicates || 0);
      } catch (error) {
        console.error(rror);
      }
    };

    if (projectId) {
      getDuplicateContacts();
    }
  }, [projectId]); */
  // useEffect(() => {
  //   const searchParams = new URLSearchParams(history.location.search);
  //   let id = searchParams.get('id');
  //   if (id !== ':id' && id && id !== 'newcontacts-v2') {
  //     setSelectedContact(id);
  //   }
  // }, []);

  const contactIds = Object.keys(state.checkedContacts).filter(
    (key) => state.checkedContacts[key]
  );

  const findUserById = async (id) => {
    const user = await getUsers(id)
    return user;
  }

  useEffect(() => {
    if (filteredContacts.length > 0) {
      const params = new URLSearchParams(window.location.search);
      const id = params.get("_id");
      if (id) {
        const matched = filteredContacts.find((contact) => {
          return contact._id === id;
        });
        if(!matched) {
          const user = findUserById(id).then((user) => {
            setSelectedContact(user);
          });
        } else {
          setSelectedContact(matched);
        }
      }
    }
  }, [filteredContacts, window.location.search]);

  useEffect(() => {
    if (filteredContacts?.length) {
      // toast.success(`Found ${filteredContacts?.length} contacts`);
    }
  }, [filteredContacts?.length]);

  useEffect(() => {
    if (state.contacts?.length) {
      initializeSortedContactIds(state.contacts);
    }
  }, [state.contacts]);

  const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState(null);
  const getRowId = useCallback((params) => String(params.data._id), []);
  const saveColumnOrder = (columnState) => {
    const orderKey = `newColumnOrder_${view}`
    const safeState = columnState.map(({ cellEditorParams, ...col }) => {
      if (!cellEditorParams) return col
      const { tableRef, ...restParams } = cellEditorParams
      return {
        ...col,
        cellEditorParams: restParams
      }
    })
    localStorage.setItem(orderKey, JSON.stringify(safeState))
  }
  useEffect(() => {
    const savedOrder = loadColumnOrder();
    if (savedOrder && gridRef?.current?.api) {
      gridRef.current.columnApi.applyColumnState({
        state: savedOrder,
        applyOrder: true,
      });
    }
  }, [view]);

  const [showManageColumns, setShowManageColumns] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({})
  const [internalCols, setInternalCols] = useState([])

  const dynamicColumns = useCallback(() => {
    return internalCols
  }, [internalCols])

  useEffect(() => {
    const baseCols = columns(state.contactUserType)
    const savedCols = loadColumnOrder()
    if (savedCols) {
      const merged = baseCols.map((col) => {
        const match = savedCols.find((s) => s.field === col.field)
        return match ? { ...col, hide: match.hide } : col
      })
      const ordered = savedCols
        .map((s) => merged.find((m) => m.field === s.field))
        .filter(Boolean)
      const finalCols = ordered.concat(
        merged.filter((m) => !ordered.includes(m))
      )
      setInternalCols(finalCols)
    } else {
      setInternalCols(baseCols)
    }
  }, [state.contactUserType, corporatDemandNotes, demandNotes])

  const toggleColumn = (field) => {
    setInternalCols((prev) => {
      const updated = prev.map((col) =>
        col.field === field ? { ...col, hide: !col.hide } : col
      )
      saveColumnOrder(updated)
      return updated
    })
  }

  // const columns = [
  //   {
  //     headerName: 'Name',
  //     field: 'name',
  //     hide: !columnVisibility.name
  //   },
  //   {
  //     headerName: 'Email',
  //     field: 'email',
  //     hide: !columnVisibility.email
  //   },
  //   {
  //     headerName: 'Phone',
  //     field: 'phone',
  //     hide: !columnVisibility.phone
  //   }
  // ]

  const contactShowingCount = useMemo(() => {
    const tempLimit = Number(state.filters.limit)
    const start = ((page - 1) * tempLimit) + 1;
    const end = Math.min((start + tempLimit) - 1, state.totalRecords);

    return {
      start,
      end
    }
  }, [page, state.filters.limit, state.totalRecords])

  return (
    <div
      onClick={handleContainerClick}
      className="flex flex-col justify-center"
    >
      <div
        className="absolute bg-[white] z-[9999] font-semibold text-[14px] top-4 right-4 "
        style={{
          color: saveStatus === "Saved" ? "green" : "",
        }}
      >
        <div className="flex items-center">
          {saveStatus === "Saved" && (
            <span>
              <CheckIcon className="mr-2" color="green" />
            </span>
          )}
          {saveStatus === "Saving..." && (
            <span>
              <LoadingIcon className="mr-2" />
            </span>
          )}
          {saveStatus}
        </div>
      </div>
      <div
        // style={{ paddingLeft: '2.7rem', paddingRight: '1.9rem' }}
        className={`inline-block align-middle ${
          !state.isContactDetailDrawerOpen || isMobile ? "w-full" : "w-[260px]"
        }`}
      >
        {isLoading ? (
          <LoadingContacts />
        ) : (
          <>
            <TablePaginationV2
              loadingAnimation={onBtShowLoading}
              closeLoadingAnimation={onBtHide}
              customContacts={null}
              contacts={contacts}
            />
            <div
              className={`flex ${
                state.isContactDetailDrawerOpen
                  ? "flex-col"
                  : "flex-row items-center justify-between"
              } relative w-full gap-4 transition-all`}
            >
              {contactIds?.length > 0 ? (
                <Badge
                  className="font-semibold ml-2 flex items-center gap-2 px-3 py-1 rounded-lg shadow-sm"
                  color="black"
                  size="sm"
                >
                  <div className="flex flex-row items-center gap-2">
                    <BsPeopleFill className="text-black" />
                    <div className="">{contactIds.length} Selected</div>
                    <MdClose
                      className="cursor-pointer h-4 w-4"
                      onClick={() => {
                        emptyCheckedSelections();
                        setSelectedContactsCount(0);
                      }}
                    />
                  </div>
                </Badge>
              ) : (
                <div></div>
              )}
              <div className="flex flex-row items-center gap-2">
                <div className="text-sm text-[#393939] text-end my-2 w-full flex items-center gap-1">
                  Showing
                  <span className='font-medium text-softBlack'>{contactShowingCount?.start || 0}</span>
                  to
                  {
                    isFiltering || isLoading
                      ? <span className='animate-pulse rounded-sm bg-zinc-200 w-[20px] h-[20px]' />
                      : <span className='font-medium text-softBlack'>{contactShowingCount?.end || 0}</span>
                  }
                  of
                  {
                    isFiltering || isLoading
                      ? <span className='animate-pulse rounded-sm bg-zinc-200 w-[20px] h-[20px]' />
                      : <span className='font-medium text-softBlack'>{state?.totalRecords || 0}</span>
                  }
                  contacts
                </div>
                <div
                  onClick={() => {
                    setIsMenuOpen(!menuOpen);
                  }}
                  className="cursor-pointer hover:bg-[#dddddd] rounded p-1"
                >
                  <MenuIcon />
                </div>
                {menuOpen && (
                  <div className="absolute top-12 z-[9999] right-0 w-64 bg-white rounded-lg shadow-lg border border-black/10 overflow-hidden">
                    <div className="flex flex-col py-1">
                      <div
                        onClick={() => {
                          setCreateListModal(true);
                          setIsMenuOpen(false);
                        }}
                        className="flex items-center cursor-pointer w-full px-4 py-2.5 text-sm text-black hover:bg-black/5 transition-colors"
                      >
                        Create List
                      </div>

                      <div className="h-px bg-black/10" />

                      <div
                        onClick={() => {
                          setAddToListModal(true);
                          setIsMenuOpen(false);
                        }}
                        className="flex items-center cursor-pointer w-full px-4 py-2.5 text-sm text-black hover:bg-black/5 transition-colors"
                      >
                        Add To List
                      </div>

                      <div className="h-px bg-black/10" />

                      <div
                        onClick={() => {
                          setFilters({
                            ...state.filters,
                            duplicate: !tempDuplicate,
                          });
                          setTempDuplicate(!tempDuplicate);
                        }}
                        className="flex items-center cursor-pointer w-full px-4 py-2.5 text-sm text-black hover:bg-black/5 transition-colors"
                      >
                        Show Duplicate Contacts
                      </div>

                      <div className="h-px bg-black/10" />

                      <div
                        onClick={() => setShowManageColumns(!showManageColumns)}
                        className={`
                          "flex items-center cursor-pointer w-full px-4 py-2.5 text-sm text-black hover:bg-black/5 transition-colors",
                          ${showManageColumns && "bg-black/5"}
                        `}
                      >
                        Manage Columns
                      </div>
                      <div className="h-px bg-black/10" />
                      {showManageColumns && (
                        <div
                          className="p-3 space-y-1.5 bg-black/5 max-h-[400px] overflow-y-auto"
                        >
                          {internalCols.map((col, index) => (
                            <div className="group flex items-center bg-white p-2 rounded border border-black/10 hover:border-black/20 transition-all duration-200">
                              <div className="flex items-center gap-3 w-full">
                                <input
                                  type="checkbox"
                                  checked={!col.hide}
                                  onChange={() => toggleColumn(col.field)}
                                  className="h-4 w-4 !mb-0 rounded border-black/20 text-black focus:ring-black/20"
                                />
                                <span className="text-sm text-black font-medium">
                                  {col?.headerName || col?.hiddenName}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {createListModal && (
                  <Modal
                    open={createListModal}
                    onClose={() => setCreateListModal(false)}
                  >
                    <div className="top-1/2 left-1/2 flex flex-col items-center justify-center -translate-x-1/2 -translate-y-1/2 relative">
                      <CreateNewListV2
                        closeModal={() => setCreateListModal(false)}
                      />
                    </div>
                  </Modal>
                )}
                {addToListModal && (
                  <Modal
                    open={addToListModal}
                    onClose={() => setAddToListModal(false)}
                  >
                    <div className="top-1/2 left-1/2 flex flex-col items-center h-[90%]  justify-center -translate-x-1/2 -translate-y-1/2 relative">
                      <div className="bg-white relative rounded p-6 flex flex-col gap-5 border-2 border-gray-2 h-full overflow-auto">
                        <MdClose
                          onClick={() => {
                            setAddToListModal(false);
                          }}
                          className="absolute cursor-pointer right-3 top-3 h-6 w-6"
                        />
                        <div className="text-3xl font-bold mt-6">
                          ADD TO LISTS
                        </div>
                        <div className="flex w-full">
                          <input
                            placeholder="search"
                            onChange={(e) =>
                              setListSearch(e.currentTarget.value)
                            }
                          />
                        </div>
                        <div className="w-full flex items-center justify-end">
                          <button
                            disabled={
                              !Object.values(state.checkedLists).filter(
                                (val) => val === true
                              ).length
                            }
                            onClick={handleAddToList}
                            className="py-2 px-4 text-lg bg-[#18181B] text-white rounded-lg font-medium flex gap-2 items-center justify-center"
                          >
                            Add to list
                          </button>
                        </div>
                        <div className="flex flex-col gap-2">
                          {isLoading && "loading lists...."}
                          {!isLoading &&
                            lists?.length &&
                            lists
                              .filter((list) =>
                                listSearch
                                  ? list.title
                                      .toLowerCase()
                                      .includes(listSearch.toLowerCase().trim())
                                  : true
                              )
                              .reverse()
                              .map((list) => (
                                <div className="flex flex-row w-full justify-between items-center gap-5 border-2 border-gray-2 rounded p-2">
                                  <div className="flex flex-row items-center gap-2">
                                    <input
                                      checked={
                                        state.checkedLists?.[list?._id] ?? false
                                      }
                                      onChange={(e) =>
                                        toggleCheckList(list?._id)
                                      }
                                      type="checkbox"
                                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6 hover:cursor-pointer"
                                    />
                                    <div>{list.title}</div>
                                  </div>
                                </div>
                              ))}
                        </div>
                      </div>
                    </div>
                  </Modal>
                )}
              </div>
            </div>
            <div className="w-full overflow-hidden ag-theme-alpine ">
              <AgGridReact
                domLayout="autoHeight"
                ref={gridRef}
                rowHeight={40}
                headerHeight={40}
                pagination={true}
                onCellValueChanged={async (e) => {
                  setSaveStatus("Saving...");
                  if (e.column.colId === "fullName") {
                    const firstName = e.newValue?.split(" ")[0] || "";
                    const lastName =
                      e.newValue?.split(" ").slice(1).join(" ") || "";
                    const fullName = e.newValue;

                    const updatedData = {
                      ...e.data,
                      _id: e.data._id,
                      firstName,
                      lastName,
                      fullName,
                    };
                    e.api.applyTransaction({
                      update: [updatedData],
                    });
                    await e.api.refreshCells({
                      force: true,
                    });
                    const params = {
                      id: e.data._id,
                      firstName,
                      lastName,
                    };
                    await createOrUpdateUser(params, false)
                      .then(() => {
                        refetchFilteredUser();
                        setSaveStatus("Saved");
                      })
                      .catch((err) => {
                        e.api.applyTransaction({
                          update: [e.data],
                        });
                      });
                  }
                  if (e.column.colId === "email") {
                    let params = {
                      id: e.data._id,
                      email: e.newValue,
                      firstName: e.data.firstName,
                    };
                    e.api.applyTransaction({
                      update: [
                        {
                          _id: e.data._id,
                          [e.column.colId]: e.newValue,
                          ...e.data,
                        },
                      ],
                    });
                    await e.api.refreshCells({
                      force: true,
                    });
                    await createOrUpdateUser(params, false).then(
                      async (data) => {
                        refetchFilteredUser();
                        setSaveStatus("Saved");
                      }
                    );
                  }
                  if (e.column.colId === "phoneNumber") {
                    let key = {
                      Buyer: "buyerData",
                      LeadBroker: "leadBrokerData",
                    };
                    let userKey = key[e.data.userType];
                    let params = {
                      id: e.data._id,
                      firstName: e.data.firstName,
                    };
                    params[userKey] = {
                      ...e.data[userKey],
                      phoneNumber: e.newValue,
                    };
                    e.api.applyTransaction({
                      update: [
                        {
                          _id: e.data._id,
                          [e.column.colId]: e.newValue,
                          ...e.data,
                        },
                      ],
                    });
                    await e.api.refreshCells({
                      force: true,
                      rowNodes: [e.node],
                    });
                    await createOrUpdateUser(params, false).then(() => {
                      refetchFilteredUser();
                      setSaveStatus("Saved");
                    });
                  }
                  setSaveStatus("Saved");
                }}
                onColumnMoved={(params) => {
                  const columnState = params.columnApi.getColumnState();
                  saveColumnOrder(columnState);
                }}
                getRowId={getRowId}
                suppressDragLeaveHidesColumns={true}
                suppressRowClickSelection={true}
                suppressPaginationPanel={true}
                loadingOverlayComponent={loadingOverlayComponent}
                columnDefs={dynamicColumns(state.contactUserType)}
                rowData={contacts}
                onCellEditingStopped={(e) => {
                  setIsEditing(false);
                }}
                paginationPageSize={state?.filters?.limit}
                defaultColDef={defaultColDef}
                components={components}
                onCellClicked={(e) => {
                  if (
                    e.column.colId === "open" ||
                    e.column.colId === "createdAt"
                  ) {
                    e.api.refreshCells({ force: true });
                    const updatedRowNode = e.api.getRowNode(e.node.id);
                    const updatedData = updatedRowNode.data;
                    setSelectedContact(updatedData);
                    history.push({
                      pathname: `${history.location.pathname}`,
                      search: `?_id=${updatedData._id}`,
                    });
                  }
                }}
                onSortChanged={(params) => {
                  const sortedNodes = [];
                  params.api.forEachNodeAfterFilterAndSort((node) =>
                    sortedNodes.push(node)
                  );
                  const newSortedIds = sortedNodes.map((node) => node.data._id);
                  updateSortedContactIds(newSortedIds);
                }}
                onGridReady={(params) => {
                  const savedOrder = loadColumnOrder();
                  if (savedOrder) {
                    params.columnApi.applyColumnState({
                      state: savedOrder,
                      applyOrder: true,
                    });
                  }
                }}
              />
            </div>

            {/* <TablePaginationV2Bottom
              customContacts={null}
              contacts={contacts}
            /> */}
          </>
        )}
      </div>
    </div>
  );
};

export default UserTableV2;

const CustomLoadingCellRenderer = (props) => {
  return (
    <div role="presentation">
      <div
        role="presentation"
        style={{
          height: 100,
          width: 100,
          margin: "0 auto",
        }}
      >
        <LottieWrapper
          animation={successAnimation}
          autoplay
          loop={true}
          className="-mt-40 md:mt-0 max-w-sm"
        />
        {/* <h1 className={fade ? 'fade mt-4 text-[16px]' : 'mt-4 text-[16px]'}>
          {messages[currentIndex]}
        </h1> */}
      </div>
    </div>
  );
};

const CustomCheckbox = ({ contactId, rowIndex }) => {
  const { toggleCheckContact, state, sortedContactIds } = useContactContext();

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const currentIndex = sortedContactIds.indexOf(contactId);
    toggleCheckContact(contactId, false, currentIndex, true);
  };

  return (
    <Checkbox
      color="primary"
      type="checkbox"
      checked={state.checkedContacts[contactId] || false}
      onChange={handleCheckboxChange}
      onClick={(e) => e.stopPropagation()} // Prevent row selection on checkbox click
    />
  );
};
