/* eslint multiline-ternary: ["error", "never"] */
// @ts-nocheck
/* eslint-disable */
import { api, ApiErrors } from 'services/api';

export const getUserActivityByProject = (_id = '', skip = 0, limit = 0) =>
  new Promise((resolve, reject) => {
    api.get(`useractivity/project/${_id}/${skip}/${limit}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

  export const getUserActivityAll = (skip = 0, limit = 0, projectId = '') =>
  new Promise((resolve, reject) => {
    api.get(`useractivity/project-wise/${projectId}/${skip}/${limit}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getUserActivityAllByGroup = (type = 'today') =>
  new Promise((resolve, reject) => {
    api.get(`useractivity/group?type=${type}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getUserActivityBySalesRepByGroup = (type = 'today', id) =>
  new Promise((resolve, reject) => {
    api.get(`useractivity/group/salesrep/${id}?type=${type}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getCallsActivity = (start, end) =>
  new Promise((resolve, reject) => {
    api.get(`useractivity/calls?start=${start}&end=${end}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getUserActivityStatsByDateRange = (start, end) =>
  new Promise((resolve, reject) => {
    api.get(`useractivity/stats?start=${start}&end=${end}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });

export const getUserActivityOffers = (projectId, filters = {}) => {
  let params = ''
  if (filters && Object.keys(filters).length > 0) {
    const tempFilters = Object.fromEntries(
      Object.entries(filters).filter(
        (elm) => elm[1] !== null && elm[1] !== '' && elm[1] !== undefined
      )
    )
    params = '?' + new URLSearchParams(tempFilters).toString()
  }

  return new Promise((resolve, reject) => {
    api.get(`useractivity/offers/project/${projectId}${params}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });
}
