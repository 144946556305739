// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useRef, useState } from "react"
import ReportConfig from "../components/ReportConfig"
import { getAllWebsiteConfig } from "store/actions/websiteReportConfigActions"
import { useSelector } from "react-redux";
import useGoogleAdsAccounts from "hooks/useGoogleAdsAccounts";
import CustomButton from "components/CustomButton"
import { Card } from "components/ShadcnComponents/card"
import { Plus, RefreshCcw } from "lucide-react";
import TableWithPagination from "components/TableWithPagination";
import CustomModal from "ui/customModal";
import { Oval } from "react-loader-spinner";
import { getGoogleAdsAuth } from "store/actions/googleAdsActions";

const WebsiteAnalyticsAdvertiseConfig = () => {
  const projectId = useSelector((state) => state.appReducer.appProject);
  const userData = useSelector((state) => state.authReducer.userObject);
  const gridRef = useRef(null)
  const { loading, accounts, refetch } = useGoogleAdsAccounts(projectId)
  const [advertiseData, setAdvertiseData] = useState(null)
  const [isGettingAuthUrl, setIsGettingAuthUrl] = useState(false)
  const [authenticationRedirectUrl, setAuthenticationRedirectUrl] = useState('')

  const columnDefs = [
    {
      headerName: "Name",
      flex: 1,
      minWidth: 400,
      field: "name",
      sortable: true,
      filter: true
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      cellRenderer: ({ value }) => (
        <span className={`!text-sm first-letter:capitalize badge ${value === 'enabled' ? 'badge-success' : 'badge-danger'} py-1 !px-2.5 !rounded-full`}>
          {value}
        </span>
      ),
    },
    { headerName: "Timezone", field: "timeZone", sortable: true },
    { headerName: "Currency code", field: "currencyCode", sortable: true },
  ]

  const handleGetGoogleAdsAuth = async () => {
    setIsGettingAuthUrl(true)
    await getGoogleAdsAuth(projectId, userData?._id).then((res) => {
      if (res?.redirectUrl) {
        setAuthenticationRedirectUrl(res.redirectUrl)
      }
    }).catch((error) => {
      console.error('error at handleGetGoogleAdsAuth: ', error)
    }).finally(() => {
      setIsGettingAuthUrl(false)
    })
  }

  return (
    <>
      <div>
        <div className="px-6 !py-4 flex justify-between items-center bg-white">
          <span className="font-bold text-3xl font-openSans">Advertisements Report Configs</span>
          <div className="flex flex-row items-center gap-3">
            <CustomButton variant="outline" handleClick={refetch} disabled={!projectId || !userData?._id || loading}>
              <span className="font-medium py-2 px-4 text-base flex gap-2 items-center capitalize">
                {
                  loading
                    ? <Oval height='20' width='20' color='white' />
                    : <RefreshCcw className="w-5 h-5" />
                }
                Refresh
              </span>
            </CustomButton>
            <CustomButton handleClick={handleGetGoogleAdsAuth} disabled={!projectId || !userData?._id || isGettingAuthUrl}>
              <span className="font-medium py-2 px-4 text-base flex gap-2 items-center capitalize">
                {
                  isGettingAuthUrl
                    ? <Oval height='20' width='20' color='white' />
                    : <Plus className="w-5 h-5" />
                }
                Add Config
              </span>
            </CustomButton>
          </div>
        </div>
        <Card className="bg-white p-2 m-4">
          <div className="ag-theme-alpine" style={{ width: "100%" }}>
            <TableWithPagination
              columns={columnDefs}
              rowData={accounts}
              rowHeight={50}
              hidePagination
              loading={loading}
            />
          </div>
        </Card>
      </div>
      <AddNewConfigModal
        open={!!(authenticationRedirectUrl?.length > 0)}
        onClose={() => setAuthenticationRedirectUrl('')}
        redirectUrl={authenticationRedirectUrl}
      />
    </>
  )
}

const AddNewConfigModal = ({
  open = false,
  onClose = () => { },
  redirectUrl = ''
}) => {
  return (
    <CustomModal
      showModal={open}
      onCancel={onClose}
      minHeight={false}
    >
      <div className="min-w-[95%] md:min-w-[600px] md:min-h-[200px] flex flex-col gap-3">
        <h2 className="text-2xl mt-4 mb-0 text-center">Authenticate your Google Account</h2>
        <h4 className="text-xl mb-2 text-center">Please authenticate to continue</h4>
        <div className="flex-1 w-full mt-2 flex items-end justify-center gap-3">
          <CustomButton
            variant="ghost"
            handleClick={onClose}
            className="min-w-[150px]"
          >
            <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
              Cancel
            </span>
          </CustomButton>
          <a href={redirectUrl} target="_blank" className="hover:!no-underline">
            <CustomButton handleClick={() => setTimeout(onClose, 100)} className="min-w-[150px]" >
              <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                Authenticate
              </span>
            </CustomButton>
          </a>
        </div>
      </div>
    </CustomModal>
  )
}

export default WebsiteAnalyticsAdvertiseConfig
