// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from "react"
import CustomButton from "components/CustomButton"
import { Plus } from "lucide-react"
import { Card } from "components/ShadcnComponents/card"
import { Modal } from "@material-ui/core"
import { Button } from "components/ShadcnComponents/button"
import { Input } from "components/ShadcnComponents/input"
import { Oval } from "react-loader-spinner"
import {
  createOrUpdateDocusealConfig,
  deleteDocusealConfig,
  getDocusealConfig,
} from "store/actions/docusealConfigAction"
import { useSelector } from "react-redux"
import { message } from "antd"
import DocusealModelConfig from "./component/DocusealModelConfig"

const DocusealConfig = () => {
  const [show, setShow] = useState(false)
  const [deleteData, setDeleteData] = useState(false)
  const projectId = useSelector((state) => state.appReducer.appProject)
  const [currentData, setCurrentData] = useState({
    apiUrl: "https://api.docuseal.co",
    authToken: "",
    email: "",
    _id: null,
  })
  const [error, setError] = useState({
    apiUrl: "",
    authToken: "",
    email: "",
  })
  const [isLoading, setIsLoading] = useState(false)
  const [docusealData, setDocusealData] = useState(null)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const urlRegex = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\/\w-]*)*\/?$/
  const validateField = (field, value) => {
    console.log("value: ", value)
    console.log("field: ", field)
    switch (field) {
      case "authToken":
        return value === "" || value === null
          ? "authToken must be required*"
          : null
      case "apiUrl":
        if (value === "" || value === null) return "API URL must be required*"
        if (!urlRegex.test(value)) return "Invalid API URL"
        return null
      case "email":
        if (value === "" || value === null) return "Email must be required*"
        if (!emailRegex.test(value)) return "Invalid email"
        return null
      case "propertyId":
        if (value === "" || value === null)
          return "Property ID must be required*"
        return null
      default:
        return null
    }
  }

  const isValid = !Object.values(error).some(
    (val) => val !== "" && val !== null
  )

  const handleChange = (field) => (e) => {
    const value = e.target.value
    setCurrentData((prevData) => {
      const updatedData = { ...prevData, [field]: value }
      const errorMessage = validateField(field, value)
      setError((prevErrors) => ({
        ...prevErrors,
        [field]: errorMessage,
      }))
      return updatedData
    })
  }

  const handleSave = async () => {
    if (isValid) {
      try {
        await createOrUpdateDocusealConfig(currentData, projectId)
          .then((response) => {
            setIsLoading(false)
            getData()
          })
          .catch((error) => console.error(error))
      } catch {
        message.error("")
        setIsLoading(false)
      }
    }
  }

  const getData = async () => {
    try {
      setIsLoading(true)
      const response = await getDocusealConfig(projectId)
      setIsLoading(false)

      console.log("response:", response)

      if (response.status === 404) {
        setCurrentData({
          apiUrl: "https://api.docuseal.co",
          authToken: "",
          email: "",
          _id: null,
        })
      } else {
        setDocusealData(response)
        setCurrentData({
          apiUrl: response.apiUrl,
          authToken: response.authToken,
          email: response.email,
          _id: response._id,
        })
      }
    } catch (error) {
      setIsLoading(false)
      setCurrentData({
        apiUrl: "https://api.docuseal.co",
        authToken: "",
        email: "",
        _id: null,
      })
      setDocusealData(null)
      console.error("Error fetching Docuseal config:", error)
    }
  }

  useEffect(() => {
    getData()
  }, [projectId])

  return (
    <div>
      <div className="px-6 !py-4 flex justify-between items-center bg-white">
        <span className="font-bold text-3xl font-openSans">
          Docuseal config
        </span>
        {/* <div className="flex flex-row items-center">
          <CustomButton handleClick={() => setShow(true)}>
            <span className="font-medium py-2 px-4 text-base flex gap-2 items-center capitalize">
              <Plus className="w-5 h-5" />
              Add Config
            </span>
          </CustomButton>
        </div> */}
      </div>
      <Card className="bg-white p-2 m-2 flex justify-center">
        <div className="relative flex w-[50%] flex-col font-openSans p-10 rounded">
          <div className="relative w-full h-full flex flex-col gap-5 pt-3">
            <div className="w-full flex flex-col gap-1">
              <div className="flex justify-between">
                <div>API Url*</div>
                {error.apiUrl && (
                  <div className="text-red-500 text-xs">{error.apiUrl}</div>
                )}
              </div>
              <Input
                className="!h-10 w-full mb-0"
                value={
                  currentData?.apiUrl !== null
                    ? currentData?.apiUrl
                    : "https://api.docuseal.co"
                }
                onChange={handleChange("apiUrl")}
              />
              <div className="text-yellow-500 font-medium text-sm">
                Note: copy API url from{" "}
                <a
                  href="https://www.docuseal.com/docs/api"
                  target="_blank"
                  className="text-yellow-500 font-medium text-sm"
                >
                  https://www.docuseal.com/docs/api{" "}
                </a>{" "}
                according to your region
              </div>
            </div>
            <div className="w-full flex flex-col gap-1">
              <div className="flex justify-between">
                <div>Auth Token*</div>
                {error.authToken && (
                  <div className="text-red-500 text-xs">{error.authToken}</div>
                )}
              </div>
              <Input
                className="!h-10 w-full mb-0"
                value={
                  currentData?.authToken !== "" ? currentData?.authToken : ""
                }
                onChange={handleChange("authToken")}
              />
              <div className="text-yellow-500 font-medium text-sm">
                Note: copy X-Auth-Token from{" "}
                <a
                  href="https://console.docuseal.com/api"
                  target="_blank"
                  className="text-yellow-500 font-medium text-sm"
                >
                  https://console.docuseal.com/api{" "}
                </a>
              </div>
            </div>
            <div className="w-full flex flex-col gap-1">
              <div className="flex justify-between">
                <div>Email*</div>
                {error.email && (
                  <div className="text-red-500 text-xs">{error.email}</div>
                )}
              </div>
              <Input
                className="!h-10 w-full mb-0 border border-gray-300 rounded-md p-2 focus:outline-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                type="email"
                value={currentData?.email ? currentData?.email : ""}
                onWheel={(e) => e.target.blur()}
                onChange={handleChange("email")}
              />
              <div className="text-yellow-500 font-medium text-sm">
                Note: Enter your docuseal account's email, you can get it from{" "}
                <a
                  href="https://docuseal.com/settings/profile"
                  target="_blank"
                  className="text-yellow-500 font-medium text-sm"
                >
                  https://docuseal.com/settings/profile{" "}
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4 gap-4">
            <Button
              onClick={handleSave}
              disabled={
                !isValid ||
                isLoading ||
                currentData.authToken === "" ||
                currentData.apiUrl === "" ||
                currentData.email === ""
              }
              className="flex gap-2"
            >
              {isLoading && <Oval height="18" width="18" color="white" />}
              {docusealData ? "Update" : "Create"}
            </Button>
            <Button
              onClick={handleSave}
              disabled={isLoading || !docusealData}
              className="flex gap-2 !bg-red-500"
              onClick={() => setDeleteData(true)}
            >
              Delete
            </Button>
          </div>
        </div>
      </Card>
      <DeleteConfigData
        deleteData={deleteData}
        setDeleteData={setDeleteData}
        data={currentData}
        setData={setCurrentData}
        projectId={projectId}
        resetAllData={getData}
      />
    </div>
  )
}

export default DocusealConfig

const DeleteConfigData = ({
  deleteData,
  setDeleteData,
  data,
  setData,
  resetAllData,
  projectId,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClose = () => {
    setData({ apiUrl: "https://api.docuseal.co", authToken: "", email: "" })
    setDeleteData(false)
  }

  const handleDelete = async () => {
    setIsLoading(true)
    try {
      const response = await deleteDocusealConfig(projectId)
      handleClose()
      await resetAllData()
    } catch (error) {
      resetAllData()
      console.error("Error deleting config:", error)
      message.error("An error occurred while deleting the configuration.")
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <Modal open={deleteData} onClose={handleClose}>
      <div
        className="relative flex flex-col font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
        style={{ width: "500px" }}
      >
        <h1 className="font-bold text-xl text-center">Are you sure?</h1>
        <p className="text-base text-center mb-0 my-2">
          Do you want to delete this config ?
        </p>
        <div className="flex justify-center items-center mt-4 gap-2">
          <Button onClick={handleClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            disabled={isLoading}
            className="!bg-red-500"
          >
            {isLoading ? "Deleting..." : "Delete"}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
