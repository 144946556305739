/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { ImageUploadButtonWithThumbnail } from 'components'
import propTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'
import { H7 } from 'ui'

import { UploadWrapper, CustomUpload } from './styles'
import { createUploadFileList } from 'utils'

const ImageUpload = ({
  // Uploader props
  uploadProps,
  uploadWrapperStyle,

  // Button props
  title,
  uploadingImage,
  uploadButtonIcon,
  uploadButtonText,
  uploadButtonStyle = {},
  onClick = () => {},
  showUploadButton,

  // Other props
  loading,
  skeletonHeight
}) => {
  const [showImageViewer, setShowImageViewer] = useState(false)
  const [imagesToCarousel, setImagesToCarousel] = useState([])
  const [localFileList, setLocalFileList] = useState([])

  const { fileList } = uploadProps
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = (error) => reject('FR error', error)
    })

  useEffect(() => {
    if (fileList) {
      const imagesToSet = fileList
        .filter((file) => (file.type ? !file.type.includes('pdf') : true))
        .map(async (image) => {
          let tmpItem = { ...image }
          if (!tmpItem.url) {
            const objUrl = await getBase64(image.originFileObj)
            tmpItem = createUploadFileList(objUrl, false)
          }

          return tmpItem.url
        })

      Promise.all(imagesToSet).then((completed) => {
        setImagesToCarousel(completed)
      })
    }
  }, [fileList])

  useEffect(() => {
    setLocalFileList(fileList || [])
  }, [fileList])

  const uploadButton = () => (
    <ImageUploadButtonWithThumbnail
      uploadingImage={uploadingImage}
      uploadButtonIcon={uploadButtonIcon}
      uploadButtonText={uploadButtonText}
      uploadButtonStyle={uploadButtonStyle}
      onClick={onClick}
      // image={images}
    />
  )

  return (
    <UploadWrapper style={uploadWrapperStyle || {}}>
      {loading ? (
        <Skeleton height={skeletonHeight} />
      ) : (
        <>
          {title && <H7>{`${title}:`}</H7>}

          <CustomUpload
            fileList={localFileList}
            {...uploadProps}
            // onPreview={() => setShowImageViewer(true)}
          >
            {showUploadButton ? uploadButton() : null}
          </CustomUpload>

          {/* <Modal
                  visible={showImageViewer}
                  footer={null}
                  onCancel={() => setShowImageViewer(false)}
                  centered
                  width='70%'
                  bodyStyle={{ height: '70vh', overflow: 'hidden' }}
                >
                  <ImageCarousel images={imagesToCarousel} />
                </Modal> */}
        </>
      )}
    </UploadWrapper>
  )
}

ImageUpload.defaultProps = {
  showUploadButton: true,
  title: 'Images',
  skeletonHeight: 100
}

ImageUpload.propTypes = {
  uploadProps: propTypes.object,
  uploadWrapperStyle: propTypes.object,
  title: propTypes.string,
  uploadingImage: propTypes.bool,
  uploadButtonIcon: propTypes.node,
  uploadButtonText: propTypes.string,
  uploadButtonStyle: propTypes.object,
  onClick: propTypes.func,
  showUploadButton: propTypes.bool,
  loading: propTypes.bool,
  skeletonHeight: propTypes.number
}

export default ImageUpload
