import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

const getPath = (projectId) => {
  return `/docusealConfigs/${projectId}`
}

export const getDocusealConfig = (projectId) => new Promise((resolve, reject) => {
  api.get(getPath(projectId))
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateDocusealConfig = (props, projectId) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'

  api[verb](getPath(projectId), params)
    .then((response) => {
      if (response.ok) {
        message.success(`Docuseal config ${_id ? 'updated successfully!' : 'created successfully!'}`)
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteDocusealConfig = (projectId) => new Promise((resolve, reject) => {
  api.delete(getPath(projectId))
    .then((response) => {
      if (response.ok) {
        message.success('Docuseal config deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
