// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { formatMoney } from 'utils/formatters';
import { Tooltip } from '@material-ui/core';
import { DollarSign, Ruler } from 'lucide-react';
import useInventory from 'components/Inventory/hooks/useInventory';

const InventoryItem = ({
  heatMapData,
  data,
  isDisabled,
  onSelect,
  isAllocateInventory = false,
  type = '',
}) => {
  const [inventoryItem, setInventoryItem] = useState(data);
  const [isSelected, setIsSelected] = useState(false);
  const { selectedInventoryItem } = useInventory();
  const {
    state: { selectedInventoryView, statusColors },
  } = useInventory();

  useEffect(
    () => handleSelect(inventoryItem, selectedInventoryItem),
    [selectedInventoryItem]
  );

  useEffect(() => {
    setInventoryItem({ ...data });
  }, [data]);

  const handleSelect = (inventoryItem, selectedInventoryItemsTemp) => {
    if (isAllocateInventory) {
      let inventoryItemFound = false;
      if (selectedInventoryItemsTemp.length > 0) {
        for (let i = 0; i < selectedInventoryItemsTemp.length; i++) {
          const inventoryItem2 = selectedInventoryItemsTemp[i];
          if (inventoryItem.id === inventoryItem2.id) {
            setIsSelected(true);
            inventoryItemFound = true;
            break;
          }
        }
      }
      if (!inventoryItemFound) setIsSelected(false);
    }
  };

  const buttonContent = (inventoryItem) => (
    <div
      className='w-full min-h-[132px] p-4 rounded-[7.07px] flex-col justify-between items-start inline-flex cursor-pointer'
      style={
        selectedInventoryView === 'availabilityStatus'
          ? {
              backgroundColor:
                statusColors?.[inventoryItem?.status?.toLowerCase()]?.bgColor ?? '#cccccc',
              border: `1px solid ${
                statusColors?.[inventoryItem?.status?.toLowerCase()]?.borderColor ?? '#cccccc'
              }`,
              color:
                statusColors?.[inventoryItem?.status?.toLowerCase()]?.textColor ?? 'black',
            }
          : {
              backgroundColor:
                heatMapData?.[inventoryItem?._id]?.bgColor ?? '#cccccc',
              border: `1px solid ${
                heatMapData?.[inventoryItem?._id]?.bgColor ?? '#cccccc'
              }`,
              color: heatMapData?.[inventoryItem?._id]?.textColor ?? '#black',
            }
        // : {
        //   backgroundColor: demandHeatColors[randomNumber].bgColor,
        //   border: `1px solid ${demandHeatColors[randomNumber].borderColor}`,
        //   color: demandHeatColors[randomNumber].textColor
        // }
      }
    >
      <div className='flex-col justify-start items-start gap-1 flex'>
        <div className='text-base font-semibold leading-normal truncate'>
          {type === 'Unit' ? '' : type}  #
          {(type === 'Unit' || type === 'Storage') && inventoryItem?.unitNumber}
          {type === 'Parking' && inventoryItem?.stall}
        </div>
        <div className='justify-start items-start gap-4 inline-flex'>
          <div className='justify-start items-center gap-1 flex'>
            <DollarSign className='w-4 h-4 relative' />
            <div className='text-xs font-normal leading-none truncate'>
              {formatMoney(inventoryItem?.price)}
            </div>
          </div>
          {/* <div className='justify-start items-center gap-1 flex'>
            <Ruler className='w-4 h-4 relative' />
            <div className='text-xs font-normal leading-none truncate'>1133 Sqft</div>
          </div> */}
        </div>
      </div>
      {inventoryItem?.status?.toLowerCase().includes('reserved') && (
        <div className='px-3 py-1 bg-white rounded-md justify-end items-center gap-2 inline-flex'>
          <div className='text-zinc-950 text-xs font-bold leading-none truncate'>
            Reserved
          </div>
          <div className='justify-end items-center gap-[7.07px] flex'>
            <div className='justify-start items-center gap-[3.54px] flex'>
              <svg
                width='15'
                height='15'
                viewBox='0 0 15 15'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clip-path='url(#clip0_2009_82)'>
                  <path
                    d='M7.74197 13.2888C10.9974 13.2888 13.6363 10.6499 13.6363 7.39449C13.6363 4.13907 10.9974 1.50018 7.74197 1.50018C5.98138 1.50018 4.40113 2.272 3.32109 3.49576C2.40407 4.5348 1.84766 5.89965 1.84766 7.39449C1.84766 10.6499 4.48654 13.2888 7.74197 13.2888Z'
                    stroke='#D4D4D8'
                    strokeWidth='1.17591'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M13.636 7.39449C13.636 4.13907 10.9971 1.50018 7.7417 1.50018'
                    stroke='#047857'
                    strokeWidth='1.17591'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_2009_82'>
                    <rect
                      width='14.1463'
                      height='14.1463'
                      fill='white'
                      transform='translate(0.668701 0.321228)'
                    />
                  </clipPath>
                </defs>
              </svg>
              <div className='text-zinc-500 text-xs font-normal leading-[14.15px] truncate'>
                4 Days Left
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className='group flex-1 py-4'>
        <div
          className={`flex w-full h-full items-center justify-center text-center ${
            isSelected && 'border-2 rounded border-solid border-softBlack'
          }`}
          disabled={
            !isAllocateInventory
              ? isDisabled
              : inventoryItem.status === 'Open'
              ? false
              : true
          }
          onClick={() => onSelect(inventoryItem)}
        >
          {isAllocateInventory && inventoryItem.status !== 'Open' ? (
            <Tooltip
              title={
                <h1
                  style={{
                    color: 'rgba(255, 255, 255, 1)',
                    fontSize: 14,
                    paddingTop: 4,
                  }}
                >
                  This {type?.toLowerCase()} is already reserved by another
                  buyer
                </h1>
              }
              arrow
              placement='bottom'
            >
              {buttonContent(inventoryItem)}
            </Tooltip>
          ) : (
            buttonContent(inventoryItem)
          )}
        </div>
      </div>
    </>
  );
};

export default InventoryItem;
