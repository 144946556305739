import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

const getPath = (projectId, type, id) => {
  return `/websiteReportConfigs/${projectId}/${type}/${id}`
}

export const getWebsiteConfigById = (id = '', type, projectId) => new Promise((resolve, reject) => {
  api.get(getPath(projectId, type, id))
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const getAllWebsiteConfig = (type, projectId) => new Promise((resolve, reject) => {
  api.get(getPath(projectId, type, 'all'))
    .then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const createOrUpdateWebsiteConfig = (props, type, projectId) => new Promise((resolve, reject) => {
  const { _id, ...params } = props

  // If id, so we have to update the record, or we're gonna create
  const verb = _id ? 'put' : 'post'
  const url = getPath(projectId, type, _id ? `${_id}` : '')

  api[verb](url, params)
    .then((response) => {
      if (response.ok) {
        message.success(`Website config ${_id ? 'updated successfully!' : 'created successfully!'}`)
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
})

export const deleteTaskWebsiteConfig = (id, type, projectId) => new Promise((resolve, reject) => {
  api.delete(getPath(projectId, type, id))
    .then((response) => {
      if (response.ok) {
        message.success('Website config deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
})
