/* eslint-disable */
// @ts-nocheck
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import './customStyle/style.css'
import { AdjustmentsIcon, PlusIcon } from '@heroicons/react/solid'
import { LottieWrapper, PageHeader } from 'components'
import CustomButton from 'components/CustomButton'
import CustomModal from 'ui/customModal'
import CustomInput from 'components/CustomInput'
import { createOrUpdateDocuSealContractTemplate, deleteContractTemplate, getDocuSealContractTemplateByProjectId } from 'store/actions/contractTemplateActions'
import { Eye, Pen, Trash } from 'lucide-react'
import successAnimation from 'assets/lottieJsons/avesdoLoading.json'
import { useHistory } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import { useSelector } from 'react-redux'
import { Dropdown, Space } from 'antd'
import CustomSelect from 'pages/reservation/NewReservation/components/CustomSelect'
import { userTypesData } from 'utils'
import { getProjects } from 'store/actions/projectActions'
import DocusealConfigModel from 'pages/desktopMode/docusealConfig/component/DocusealModelConfig'
import useDocusealConfigModal from 'hooks/useDocusealConfigModal'

const DEFAULT_CONTRACT_TYPES = [
  'reservation',
  'offer',
  'unit',
  'parking',
  'storage',
  'upgrades',
  'colors',
  'singularContract'
]

const CONTRACT_CATEGORIES_OPTIONS = [
  {
    value: 'compliance',
    label: 'Compliance'
  },
  {
    value: 'contract',
    label: 'Contract'
  },
  {
    value: 'addendum',
    label: 'Addendum'
  },
]

const IS_MANDATORY_OPTIONS = [
  {
    value: true,
    label: 'Yes'
  },
  {
    value: false,
    label: 'No'
  }
]

const RightContent = memo(({ handleContractTemplate }) => (
  <div className="flex flex-row items-center gap-2">
    <CustomButton handleClick={handleContractTemplate} disabled={false}>
      <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
        <PlusIcon className="h-5 w-5 text-white" />
        New Contract Template
      </span>
    </CustomButton>
  </div>
))


const NewContractTemplateModal = memo(({ showModal, onCancel, onOk, newContract, setNewContract, isLoading, contractTypesOption }) => {
  const isValidate = useMemo(() => {
    if (!newContract.name || newContract.name.trim() === '') {
      return false
    } else if (!newContract.contractType || newContract.contractType.trim() === '') {
      return false
    } else if (!Array.isArray(newContract.allowedToSee) || newContract.allowedToSee.length === 0) {
      return false
    } else if (!Array.isArray(newContract.allowedToSign) || newContract.allowedToSign.length === 0) {
      return false
    } else {
      return true
    }
  }, [newContract])

  return (
    <CustomModal
      showModal={showModal}
      onCancel={onCancel}
      onOk={onOk}
    >
      <div className='min-w-[95%] md:min-w-[600px] h-[55vh] flex flex-col gap-4'>
        <h2 className='text-2xl mb-0'>New Contract Template</h2>
        <div className='grid grid-cols-2 gap-4'>
          <CustomInput
            label='Contract Name *'
            placeholder='Enter contract name'
            labelClassName='text-xs mb-[6px]'
            value={newContract.name ?? ''}
            onChange={(e) => {
              setNewContract({
                ...newContract,
                name: e.target.value
              })
            }}
            type='text'
            disabled={isLoading}
          />
          <CustomSelect
            value={newContract?.contractType ?? ''}
            handleChange={(newValue) => {
              setNewContract({
                ...newContract,
                contractType: newValue
              })
            }}
            options={contractTypesOption}
            showSearch
            placeholder='Select Contract Type'
            label='Select Contract Type *'
            className='border-none'
          />
          <CustomSelect
            value={newContract?.contractCategory ?? ''}
            handleChange={(newValue) => {
              setNewContract({
                ...newContract,
                contractCategory: newValue
              })
            }}
            options={CONTRACT_CATEGORIES_OPTIONS}
            showSearch
            placeholder='Select Contract Category'
            label='Select Contract Category *'
            className='border-none'
          />
          <CustomSelect
            value={IS_MANDATORY_OPTIONS.filter(elm => elm.value === newContract?.isMandatory)[0]?.label}
            handleChange={(newValue) => {
              setNewContract({
                ...newContract,
                isMandatory: newValue
              })
            }}
            options={IS_MANDATORY_OPTIONS}
            placeholder='Is Mandatory'
            label='Is Mandatory *'
            className='border-none'
          />
          <CustomSelect
            label='Who must sign the document? *'
            value={newContract?.allowedToSign ?? ''}
            handleChange={(newValue) => {
              setNewContract({
                ...newContract,
                allowedToSign: newValue
              })
            }}
            options={userTypesData.map((userType) => ({
              label: userType.value,
              value: userType.id
            }))}
            mode='multiple'
            placeholder='Who will need to sign?'
            className='border-none'
          />
          <CustomSelect
            label='Who can view the document? *'
            value={newContract?.allowedToSee ?? ''}
            handleChange={(newValue) => {
              setNewContract({
                ...newContract,
                allowedToSee: newValue
              })
            }}
            options={userTypesData.map((userType) => ({
              label: userType.value,
              value: userType.id
            }))}
            mode='multiple'
            placeholder='Who is gonna view the document?'
            className='border-none'
          />
        </div>
        <div className='flex-1 w-full mt-2 flex items-end justify-center md:justify-end gap-3'>
          <CustomButton variant='outline' handleClick={onCancel} disabled={isLoading}>
            <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
              Cancel
            </span>
          </CustomButton>
          <CustomButton handleClick={onOk} disabled={!isValidate || isLoading}>
            <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
              {isLoading && <Oval height='18' width='18' color='white' />}
              Create template
            </span>
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  )
})

const ConfirmDeleteContractTemplateModal = memo(({ showModal, onCancel, onOk, isLoading }) => (
  <CustomModal
    showModal={showModal}
    onCancel={onCancel}
    onOk={onOk}
    minHeight={false}
  >
    <div className='min-w-[95%] md:min-w-[400px] flex flex-col gap-4'>
      <h2 className='text-2xl mb-0'>Are you sure?</h2>
      <h4 className='text-xl mb-2'>Do you want to delete contract template?</h4>
      <div className='w-full mt-5 flex items-center justify-center md:justify-end gap-3'>
        <CustomButton variant='outline' handleClick={onCancel} disabled={isLoading}>
          <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
            Cancel
          </span>
        </CustomButton>
        <CustomButton handleClick={onOk} disabled={isLoading}>
          <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
            {isLoading && <Oval height='18' width='18' color='white' />}
            Yes, delete it.
          </span>
        </CustomButton>
      </div>
    </div>
  </CustomModal>
))

export const PageLoader = () => {
  return (
    <div className='w-full h-[calc(100vh-164px)] flex flex-col items-center justify-center'>
      <div
        role='presentation'
        style={{
          height: 150,
          width: 150,
          margin: '0 auto'
        }}
      >
        <LottieWrapper
          animation={successAnimation}
          autoplay
          loop={true}
          className='-mt-40 md:mt-0 max-w-sm'
        />
      </div>
      <h1 className='mt-4 text-[16px] truncate text-center'>
        Loading...
      </h1>
    </div>
  )
}

const ContractTemplates = () => {
  const history = useHistory()
  const [isNewContractTemplateModalOpen, setIsNewContractTemplateModalOpen] = useState(false)
  const [newContract, setNewContract] = useState({
    name: '',
    contractType: '',
    contractCategory: '',
    isMandatory: null,
    allowedToSee: ['Buyer', 'SuperAdmin', 'DeveloperAdmin'],
    allowedToSign: ['Buyer', 'DeveloperAdmin']
  })
  const [templates, setTemplates] = useState([])
  const [loading, setLoading] = useState(true)
  const [isCreating, setIsCreating] = useState(false)
  const projectId = useSelector((state) => state.appReducer.appProject)
  const userObject = useSelector((state) => state.authReducer.userObject)
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState()
  const customSections = useSelector((state) => state.appReducer.customSections)
  const [customSectionOptions, setCustomSectionsOptions] = useState([])
  const [individualOptions, setIndividualOptions] = useState([])
  const { docusealData } =useDocusealConfigModal()
  const [isDocusealModalOpen, setIsDocusealModalOpen] = useState(false)
  const contractTypesOption = useMemo(() => {
    const isReservationContractExists = templates.filter((template) => template?.contractType === 'reservation')?.[0]
    return isReservationContractExists
      ? [
        {
          value: 'offer',
          label: 'Offer'
        },
        {
          value: 'singularContract',
          label: 'Singular Contract'
        },
        {
          value: 'unit',
          label: 'Unit/Lot'
        },
        {
          value: 'parking',
          label: 'Parking'
        },
        {
          value: 'storage',
          label: 'Storage'
        },
        {
          value: 'upgrades',
          label: 'Upgrades'
        },
        ...customSectionOptions,
        ...individualOptions
      ]
      : [
        {
          value: 'reservation',
          label: 'Reservation'
        },
        {
          value: 'offer',
          label: 'Offer'
        },
        {
          value: 'singularContract',
          label: 'Singular Contract'
        },
        {
          value: 'unit',
          label: 'Unit/Lot'
        },
        {
          value: 'parking',
          label: 'Parking'
        },
        {
          value: 'storage',
          label: 'Storage'
        },
        {
          value: 'upgrades',
          label: 'Upgrades'
        },
        ...customSectionOptions,
        ...individualOptions
      ]
  }, [templates, customSectionOptions, individualOptions])

  useEffect(() => {
    const tempCustomSectionMenus = customSections?.map((section) => {
      return {
        value: section._id,
        label: section.title
      }
    })
    setCustomSectionsOptions(tempCustomSectionMenus)
  }, [customSections])

  // useEffect(()=>{
  //   if(!docusealData){
  //     setIsDocusealModalOpen(true)
  //   }
  // },[docusealData])

  const getContracts = () => {
    setLoading(true)
    getDocuSealContractTemplateByProjectId(projectId)
      .then((templates) => {
        setTemplates(templates)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        console.error('Error:', error)
      })
  }

  const getProject = () => {
    getProjects(projectId).then((project) => {
      const { options = [] } = project
      const temp = options.map((elm) => {
        if (DEFAULT_CONTRACT_TYPES.filter((e) => e === elm.type)?.length > 0) {
          return {
            label: `${elm.name} | ${elm.type}`,
            value: elm._id,
            type: elm.type
          }
        }
        const tempType = customSections.filter((section) => (section._id === elm.type))?.[0]?.title
        return {
          label: `${elm.name} | ${tempType}`,
          value: elm._id,
          type: elm.type
        }
      })
      setIndividualOptions([...temp])
    })
  }

  useEffect(() => {
    if (projectId) {
      getProject()
    }
  }, [projectId])

  useEffect(() => {
    getContracts()
  }, [])

  const handleTemplateCreate = (e) => {
    setIsCreating(true)
    let params = {
      ...newContract,
      project: projectId
    }
    if (individualOptions.filter((elm) => (elm.value === newContract.contractType))?.length > 0) {
      params = {
        ...params,
        contractType: individualOptions.filter((elm) => (elm.value === newContract.contractType))[0].type,
        attachmentId: individualOptions.filter((elm) => (elm.value === newContract.contractType))[0].value
      }
    }
    createOrUpdateDocuSealContractTemplate(params)
      .then((data) => {
        setIsNewContractTemplateModalOpen(false)
        if (data?.token && data?.contractTemplate?._id) {
          history.push(`/admin/contract_templates/new/${data.token}/${data.contractTemplate._id}/${newContract.contractType}`)
        }
        setIsCreating(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setIsNewContractTemplateModalOpen(false)
        setIsCreating(false)
      })
  }

  const handleContractDelete = (_id) => {
    setIsDeleting(true)
    deleteContractTemplate(_id)
      .then(() => {
        setSelectedTemplate()
        setIsDeleting(false)
        getContracts()
        setIsConfirmDeleteModalOpen(false)
      })
      .catch(() => {
        setSelectedTemplate()
        setIsDeleting(false)
        setIsConfirmDeleteModalOpen(false)
      })
  }

  const items = [
    {
      key: 'view',
      label: <div className='w-[120px] flex items-center gap-2'>
        <Eye className='w-4 h-4' />
        <span className='text-base font-medium'>View</span>
      </div>,
      onClick: () => history.push(`/admin/contract_templates/${selectedTemplate.id}?type=preview`)
    },
    {
      key: 'edit',
      label: <div className='w-[120px] flex items-center gap-2'>
        <Pen className='w-4 h-4' />
        <span className='text-base font-medium'>Edit</span>
      </div>,
      onClick: () => history.push(`/admin/contract_templates/${selectedTemplate.id}?type=edit`)
    },
    {
      key: 'delete',
      danger: true,
      label: <div className='w-[120px] flex items-center gap-2'>
        <Trash className='w-4 h-4' />
        <span className='text-base font-medium'>Delete</span>
      </div>,
      onClick: () => setIsConfirmDeleteModalOpen(true)
    }
  ]

  const getContractType = useCallback((type) => {
    if (contractTypesOption.filter((elm) => elm.value === type)?.length > 0) {
      return contractTypesOption.filter((elm) => elm.value === type)?.[0]?.label ?? '-'
    }
    if (individualOptions.filter((elm) => (elm.value === type))?.length > 0) {
      return individualOptions.filter((elm) => (elm.value === type))[0].label
    }
    const tempType = customSections.filter((section) => (section._id === type))?.[0]?.title
    return tempType || type || 'Untitled'
  }, [customSections, individualOptions])

 const handleContractTemplate = () => {
    if (docusealData) {
      setIsNewContractTemplateModalOpen(true)
    }
    else {
      setIsDocusealModalOpen(true)
    }
  }
  return (
    <>
      <NewContractTemplateModal
        showModal={isNewContractTemplateModalOpen}
        onCancel={() => {
          setIsNewContractTemplateModalOpen(false)
          setNewContract({
            name: '',
            contractType: '',
            contractCategory: '',
            isMandatory: null,
            allowedToSee: ['Buyer', 'SuperAdmin', 'DeveloperAdmin'],
            allowedToSign: ['Buyer', 'DeveloperAdmin']
          })
        }}
        onOk={handleTemplateCreate}
        newContract={newContract}
        setNewContract={setNewContract}
        isLoading={isCreating}
        contractTypesOption={contractTypesOption}
      />
      <ConfirmDeleteContractTemplateModal
        showModal={isConfirmDeleteModalOpen}
        onCancel={() => {
          setSelectedTemplate()
          setIsConfirmDeleteModalOpen(false)
        }}
        onOk={() => handleContractDelete(selectedTemplate._id)}
        isLoading={isDeleting}
      />
      <PageHeader
        className='md:h-[74px]'
        title='Contract Templates'
        showImage={false}
        rightContent={
          <RightContent
          handleContractTemplate={handleContractTemplate}
          />
        }
      />
      <div className='mx-auto px-4 sm:px-6 bg-siteColor lg:px-8 min-h-[calc(100%-100px)]'>
        {
          loading
            ? <PageLoader />
            : templates.length === 0
              ? <div className='h-[calc(100vh-164px)] w-full'>
                <div className='h-full hero-content text-center'>
                  <div className='h-full w-full flex flex-col justify-center items-center gap-4'>
                    <h2 className='text-5xl font-bold mb-0'>No templates found</h2>
                    <p className='mb-0'>
                      Unfortunately, there are no templates available at the moment.
                    </p>
                    <CustomButton 
                    handleClick={handleContractTemplate} 
                    disabled={false}>
                      <span className='font-medium py-2 px-4 text-base flex gap-2 items-center'>
                        <PlusIcon className='h-5 w-5 text-white' />
                        New Contract Template
                      </span>
                    </CustomButton>
                  </div>
                </div>
              </div>
              : <div className='py-6 grid grid-cols-3 gap-4'>
                {templates.map((template, index) => {
                  return (
                    <div
                      className='card card-compact w-full bg-white border border-zinc-300 rounded-md overflow-hidden'
                      key={`template-${index}`}
                    >
                      <div className='h-36'>
                        {
                          template?.external_id && template?.external_id?.length > 0
                            ? <>
                              {template.documents?.[0]?.preview_image_url && (
                                <img
                                  className='h-full w-full object-cover object-top'
                                  src={template.documents[0].preview_image_url}
                                  alt={template.name}
                                />
                              )}
                            </>
                            : <div className='h-full w-full flex flex-col justify-center items-center font-normal'>
                              <h4 className='mb-0 text-red-500'>
                                Contract template unavailable
                              </h4>
                              <p className='mb-0 text-sm text-red-500'>
                                It may have been removed from Docuseal's dashboard
                              </p>
                            </div>
                        }
                      </div>
                      <div className='w-full bg-white border-t border-zinc-300 p-3 flex items-center justify-between gap-2 over'>
                        <div className='flex-1 flex flex-col overflow-hidden'>
                          <h2 className='flex-1 text-lg mb-0 truncate'>{template.name}</h2>
                          <p className='mb-0 text-xs font-medium capitalize'>
                            Type: {template?.attachmentId?.length > 0 && 'Individual '}{getContractType(template?.contractType) ?? '-'}
                          </p>
                        </div>
                        {
                          userObject?.userType === 'DeveloperAdmin' && template?.external_id &&
                          <Dropdown
                            onOpenChange={(open) => {
                              if (open) {
                                setSelectedTemplate(template)
                              } else {
                                setSelectedTemplate()
                              }
                            }}
                            menu={{ items }}
                            placement='bottomRight'
                            className='w-fit flex items-center gap-x-2 rounded bg-white cursor-pointer'
                          >
                            <Space className='text-base text-softBlack flex items-center'>
                              <AdjustmentsIcon className='w-5 h-5' />
                            </Space>
                          </Dropdown>
                        }
                      </div>
                    </div>
                  )
                })}
              </div>
        }
      </div>
      <DocusealConfigModel open={isDocusealModalOpen} close={()=>setIsDocusealModalOpen(false)} />
    </>
  )
}

export default ContractTemplates
