// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api } from "services/api";
import { Building2Icon, CalendarCheck, Download } from "lucide-react";
import { BsPeople } from "react-icons/bs";
import useContactContext from "pages/newContacts/hooks/useContactContext";
import { getBuildingsByProjectId } from "store/actions/buildingActions";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from "antd";
import { RiCheckboxIndeterminateFill } from "react-icons/ri";
import { CheckCircleOutlined } from "@ant-design/icons";
import { getAllEmailCampaigns } from "store/actions/customEmailCampaignActions";
import moment from "moment-timezone";
import { MdReport } from "react-icons/md";
import { DocumentReportIcon } from "@heroicons/react/solid";
import { getProjectOffers } from "store/actions/offerActions";
import { formatMoney } from "utils";
import { Button } from "components/ShadcnComponents/button";
import DailyReportModal from "./DailyReportModal";
import { getDailyReport, getDailyReportById } from "store/actions/reportAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { ADMIN_MASTER_REPORT_ID, ADMIN_MASTER_REPORT_NEW } from "routes";
import toast from "react-hot-toast";
import { getProjects } from "store/actions/projectActions";
import CustomModal from "components/Modal";
import { Modal } from "@material-ui/core";
import { CloseIcon } from "@nylas/react";
import CustomInput from "components/CustomInput";
import { DatePicker, DateRangePicker } from "rsuite";
const ReportCard = ({ icon, title, description, handleDownload }) => {
  return (
    <div className="bg-white relative col-span-12 lg:col-span-4 border p-4 md:p-6">
      <div className="flex flex-col gap-y-3">
        <div>{icon}</div>
        <div className="flex flex-col gap-y-1">
          <div className="font-semibold text-lg">{title}</div>
          <p>{description}</p>
        </div>

        <div
          onClick={handleDownload}
          className="absolute right-4 bottom-4 cursor-pointer h-7 w-auto"
        >
          <Download />
        </div>
      </div>
    </div>
  );
};

const DailyReportCard = ({
  icon,
  title,
  description,
  handleOpen,
  handleDownload,
}) => {
  return (
    <div className="bg-white relative col-span-12 lg:col-span-4 border p-4 md:p-6">
      <div className="flex flex-col gap-y-3">
        <div>{icon}</div>
        <div className="flex flex-col gap-y-1">
          <div className="font-semibold text-lg">{title}</div>
          <p>{description}</p>
        </div>
        <div className="flex gap-x-2 self-end">
          <Button onClick={handleOpen} className="w-fit flex self-end">
            Open
          </Button>
          <Button
            onClick={handleDownload}
            variant="outline"
            className="w-fit flex self-end"
          >
            <Download />
          </Button>
        </div>
      </div>
    </div>
  );
};

const ReportCardBuilding = ({
  icon,
  title,
  description,
  handleDownloadParking,
  handleDownloadUnit,
  handleDownloadStorage,
}) => {
  return (
    <div className="bg-white relative col-span-12 lg:col-span-4 border p-4 md:p-8">
      <div className="flex flex-col gap-y-3">
        <div>{icon}</div>
        <div className="flex flex-col gap-y-1">
          <div className="font-semibold text-lg">{title}</div>
          <p>{description}</p>
        </div>
        <div className="flex gap-x-2 absolute right-4 bottom-2">
          <Tooltip title="Download Units Report">
            <div
              className="border text-[18px] rounded-md flex justify-center items-center h-8 w-8 font-bold cursor-pointer"
              onClick={handleDownloadUnit}
            >
              U
            </div>
          </Tooltip>
          <Tooltip title="Download Parking Report">
            <div
              className="border text-[18px] rounded-md flex justify-center items-center h-8 w-8 font-bold cursor-pointer"
              onClick={handleDownloadParking}
            >
              P
            </div>
          </Tooltip>
          <Tooltip title="Download Storage Report">
            <div
              className="border text-[18px] rounded-md flex justify-center items-center h-8 w-8 font-bold cursor-pointer"
              onClick={handleDownloadStorage}
            >
              S
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const ReportContainer = (props) => {
  const buttonStyle = {
    backgroundColor: "#EDDF65",
    height: "45px",
    paddingLeft: "24px",
    paddingRight: "24px",
  };
  const router = useHistory();
  // const [waitingModal, setWaitingModal] = useState(true);
  const userId = useSelector((state) => state.authReducer.userObject);
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [isLoading, setIsLoading] = useState(false);
  const { waitingModal, setWaitingModal } = useContactContext();
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [selectedBuilding, setSelectedBuilding] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const [isLoadingCampaign, setIsLoadingCampaign] = useState(false);
  const [projectOffers, setProjectOffers] = useState([]);
  let id = localStorage.getItem("device_id");
  const [dailyReportModal, setDailyReportModal] = useState(false);
  const [dailyReport, setDailyReport] = useState([]);
  const [selectedDailyReport, setSelectedDailyReport] = useState(null);
  const [isLoadingDailyReport, setIsLoadingDailyReport] = useState(false);
  const [isTodayReportExists, setIsTodayReportExists] = useState(false);
  const [salesRepReportType, setSalesRepReportType] = useState("");
  const [salesRepRangeSelectModal, setSalesRepRangeSelectModal] =
    useState(false);
  const [projects, setProjects] = useState([]);
  const { view } = useContactContext();
  useEffect(() => {
    setIsLoadingDailyReport(true);
    getDailyReport(projectId).then((report) => {
      let mapped = report.map((r) => ({
        ...r,
        interactions: r?.interactions?.map((interaction) => ({
          ...interaction,
          user: interaction.user,
          type: interaction.type,
          userId: interaction.user?._id,
          notes: interaction.notes,
        })),
      }));
      setDailyReport(mapped);
      setIsLoadingDailyReport(false);
      const today = moment().startOf("day");
      const todayReport = mapped.find((report) =>
        moment(report.createdAt).isSame(today, "day")
      );
      setIsTodayReportExists(!!todayReport);
    });
  }, [projectId]);
  useEffect(() => {
    if (projectId.length > 0) {
      setIsLoading(true);
      getBuildingsByProjectId(projectId)
        .then((buildings) => {
          const temp = buildings?.map((building) => ({
            value: building?._id,
            label: building?.name,
          }));
          setBuildingOptions(temp);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  }, [projectId]);

  const getCampaigns = () => {
    setIsLoadingCampaign(true);
    getAllEmailCampaigns().then((campaigns) => {
      setCampaigns(campaigns);
      setIsLoadingCampaign(false);
    });
  };

  const handleDownloadReportCampaign = async (campaignId) => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    const response = await api.get(
      `/reports/email-campaigns/${campaignId}?deviceId=${id}`,
      {},
      { responseType: "blob" }
    );
  };

  const handleDownloadSalesReport = async () => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);

    const response = await api.get(
      `/reports/sales-transaction/${projectId}?deviceId=${id}`,
      {},
      { responseType: "blob" }
    );
  };

  useEffect(() => {
    getCampaigns();
  }, []);

  const handleDownloadLeadReport = async (type, projectId) => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    try {
      let query;
      if (type === "project_contacts" || type === "last_7_days") {
        query = `?report_type=${type}&projectId=${projectId}&deviceId=${id}`;
      } else {
        query = `?report_type=${type}&deviceId=${id}`;
      }
      const response = await api.get(
        `/reports/contacts${query}`,
        {},
        { responseType: "blob" }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadSalesRepReport = async (type, projectId) => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    try {
      let query;
      const response = await api.get(
        `/reports/salesReport/daily-reports?deviceId=${id}&type=${type}`,
        {},
        { responseType: "blob" }
      );
    } catch (e) {
      console.log(e);
    }
  };
  const handleDownloadInventoryReport = async (type, buildingId) => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    try {
      const response = await api.get(
        `/reports/${type}/${buildingId}?deviceId=${id}`,
        {},
        { responseType: "blob" }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadReservationReport = async () => {
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    try {
      const response = await api.get(
        `/reports/reservation/${projectId}?deviceId=${id}`,
        {},
        { responseType: "blob" }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownloadDailyReport = async (reportId) => {
    try {
      let data = await getDailyReportById(reportId, projectId);
      let filtered = data[0].interactions.filter((d) => d.isChecked);
      data[0].interactions = filtered;
      let reportData = data[0];
      const formatNotes = (notes) => {
        return notes
          .split("\n")
          .map((line) => {
            if (
              line.startsWith("Task:") ||
              line.startsWith("Notes:") ||
              line.startsWith("Called at")
            ) {
              return `<p style="margin-bottom: 5px;">${line}</p>`;
            } else if (
              line.startsWith("- Notes:") ||
              line.startsWith("- Status:") ||
              line.startsWith("- Call Notes:")
            ) {
              return `<p style="margin-left: 15px; margin-bottom: 5px;">${line}</p>`;
            } else {
              return `<p style="margin-bottom: 24px;">${line}</p>`;
            }
          })
          .join("");
      };
      const reportHtml = `
        <div id="report-container" style="padding: 40px; font-family: Arial, sans-serif; background-color: white;">
          <h1 style="text-align: left; font-size: 28px; margin-bottom: 30px; color: black;">Presentation Center Report | ${moment(
            reportData.date
          ).format("YYYY-MM-DD")}</h1>
          <div style="margin-bottom: 40px; border-top: 1px solid #e0e0e0; padding-top: 40px;">
            <h2 style="font-size: 20px; margin-bottom: 20px; color: black;">Sales Update</h2>
            <h3 style="font-size: 18px; margin-bottom: 15px; color: black;">Offers</h3>
            <div style="border: 1px solid #e0e0e0; padding: 15px; margin-bottom: 20px;">
              <p style="color: black;">${reportData.rescissions || "-"}</p>
            </div>
            <div style="border: 1px solid #e0e0e0; padding: 15px; margin-bottom: 20px;">
              <p style="color: black;">${
                reportData.summary_of_sales_transactions || "-"
              }</p>
            </div>
            <div style="border: 1px solid #e0e0e0; padding: 15px;">
              <p style="color: black;">${
                reportData.deposits_dropped_off || "-"
              }</p>
            </div>
          </div>
  
          <div style="border-top: 1px solid #e0e0e0; padding-top: 40px;">
            <h2 style="font-size: 20px; margin-bottom: 20px; color: black;">Contact Interactions</h2>
            ${reportData.interactions
              .map(
                (interaction) => `
             <div style="border: 1px solid #e0e0e0; padding: 20px; margin-bottom: 20px;">
                <div style="display: flex; flex-wrap: wrap; align-items: center; gap: 10px; margin-bottom: 16px;">
                  <div style="font-size: 18px; line-height: 20px; color: black; margin: 0; display: flex; align-items: center;">
                  <div>
                    ${interaction.user.firstName} ${interaction.user.lastName}
                    </div>
                  <div style="font-size: 14px; height: 32px; line-height: 18px; background-color: ${
                    interaction.user.userType === "leadBroker"
                      ? "#e6e4ea"
                      : "#e6f4ea"
                  }; padding-left: 12px; padding-right: 12px; margin-top: 16px; border-radius: 6px; font-weight: 500; color: ${
                  interaction.user.userType === "leadBroker"
                    ? "#8a28a7"
                    : "#28a745"
                }; margin-left: 16px;">
    ${interaction.user.userType || "N/A"}
</div>
                    ${
                      interaction.user.userType === "leadBroker" &&
                      interaction.user.brokerageCompany
                        ? `<div style="font-size: 14px; line-height: 18px; margin-top: 12px; font-weight: 500; color: #000000; margin-left: 16px;">

                        ${interaction.user.brokerageCompany}
                      </div>`
                        : ""
                    }
                  </div>
                </div>
                <p style="color: black; border: 1px solid #e0e0e0; padding: 12px;">${
                  interaction.type || "-"
                }</p>
                <div style="border: 1px solid #e0e0e0; padding: 15px; margin-top: 15px;">
                  ${formatNotes(interaction.notes)} 
                </div>
              </div>
            `
              )
              .join("")}
          </div>
        </div>
      `;

      // (removed HTML part from reportHtml string)
      // <div style="display: flex; justify-content: space-between; margin-bottom: 40px;">
      //   <div style="width: 48%; border: 1px solid #e0e0e0; padding: 20px; border-radius: 4px;">
      //     <h2 style="font-size: 20px; margin-bottom: 20px; color: black;">Daily Visitors</h2>
      //     <table style="width: 100%; border-collapse: separate; border-spacing: 0 12px;">
      //       ${Object.entries(reportData.dailyVisitors)
      //         .map(
      //           ([key, value]) => `
      //         <tr>
      //           <td style="padding: 5px 0; color: black;">${key
      //             .replace(/([A-Z])/g, " $1")
      //             .replace(/^./, (str) => str.toUpperCase())}</td>
      //           <td style="padding: 5px 0; text-align: right; font-weight: bold; color: black;">${value}</td>
      //         </tr>
      //       `
      //         )
      //         .join("")}
      //     </table>
      //   </div>
      //   <div style="width: 48%; border: 1px solid #e0e0e0; padding: 20px; border-radius: 4px;">
      //     <h2 style="font-size: 20px; margin-bottom: 20px; color: black;">Daily Inventory Summary</h2>
      //     <table style="width: 100%; border-collapse: separate; border-spacing: 0 12px;">
      //       ${Object.entries(reportData.dailyInventorySummary)
      //         .map(
      //           ([key, value]) => `
      //         <tr>
      //           <td style="padding: 5px 0; color: black;">${key
      //             .replace(/([A-Z])/g, " $1")
      //             .replace(/^./, (str) => str.toUpperCase())}</td>
      //           <td style="padding: 5px 0; text-align: right; font-weight: bold; color: black;">${value}</td>
      //         </tr>
      //       `
      //         )
      //         .join("")}
      //     </table>
      //   </div>
      // </div>

      const opt = {
        margin: 10,
        filename: `Daily_Report_${moment(reportData.date).format(
          "YYYY-MM-DD"
        )}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        // html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      html2pdf().from(reportHtml).set(opt).save();
      toast.success("Daily report downloaded successfully");
    } catch (error) {
      console.error("Error downloading daily report:", error);
    }
  };

  useEffect(() => {
    getProjects().then((res) => {
      setProjects(res);
    });
  }, []);

  return (
    <div className="min-h-full relative flex flex-col">
      <div className="px-6 py-3 flex justify-between items-center bg-white">
        <span className="font-semibold text-3xl">Reports</span>
      </div>
      <div className="border-b-2 border-black" />
      <div className="flex flex-col gap-y-4 px-5 sm:px-10 ">
        <div className="flex flex-col gap-y-3 py-4 mt-4">
          <div className="text-[20px] font-semibold mb-4">Lead Report</div>
          {view === "corporate" ? (
            <>
              {projects.map((project) => (
                <div className="grid grid-cols-12 col-span-12 gap-2 mb-4">
                  <div className="text-lg font-semibold col-span-12">
                    {project.projectName}
                  </div>
                  <div className="grid grid-cols-8 col-span-12 gap-4">
                    <ReportCard
                      icon={<BsPeople size={24} />}
                      title="Last 7 days"
                      description="Download the report for the last 7 days to see the number of leads"
                      handleDownload={() => {
                        handleDownloadLeadReport("last_7_days", project._id);
                      }}
                    />
                    <ReportCard
                      icon={<BsPeople size={24} />}
                      title="Project contacts"
                      description={`Download the report for all contacts in the project`}
                      handleDownload={() => {
                        handleDownloadLeadReport(
                          "project_contacts",
                          project._id
                        );
                      }}
                    />
                  </div>
                </div>
              ))}
              <ReportCard
                icon={<BsPeople size={24} />}
                title="All contacts"
                description="Download the report for all contacts"
                handleDownload={() => {
                  handleDownloadLeadReport("all_contacts", projectId);
                }}
              />
            </>
          ) : (
            <div className="grid grid-cols-12 col-span-12 gap-6">
              <ReportCard
                icon={<BsPeople size={24} />}
                title="Last 7 days"
                description="Download the report for the last 7 days to see the number of leads"
                handleDownload={() => {
                  handleDownloadLeadReport("last_7_days", projectId);
                }}
              />
              <ReportCard
                icon={<BsPeople size={24} />}
                title="Project contacts"
                description={`Download the report for all contacts in the project`}
                handleDownload={() => {
                  handleDownloadLeadReport("project_contacts", projectId);
                }}
              />
              <ReportCard
                icon={<BsPeople size={24} />}
                title="All contacts"
                description="Download the report for all contacts"
                handleDownload={() => {
                  handleDownloadLeadReport("all_contacts", projectId);
                }}
              />
            </div>
          )}
        </div>
        {view !== "corporate" && <div className="h-[1px] bg-[#a1a1a1]" />}
        {view !== "corporate" && (
          <div className="flex flex-col gap-y-3 py-4 mt-4">
            <div className="text-[20px] font-semibold flex justify-between">
              <div>Presentation Center Report</div>
              <Button
                onClick={() => {
                  router.push(ADMIN_MASTER_REPORT_NEW);
                }}
              >
                Create Report
              </Button>
            </div>
            {isLoadingDailyReport ? (
              <Skeleton height={130} className="col-span-12" count={1} />
            ) : dailyReport.length > 0 ? (
              <div className="grid grid-cols-12 col-span-12 gap-6">
                {dailyReport.map((report) => (
                  <DailyReportCard
                    icon={<BsPeople size={24} />}
                    title={moment(report.createdAt).format("MMM DD, YYYY")}
                    description="Download the daily report"
                    handleOpen={() => {
                      router.push(
                        ADMIN_MASTER_REPORT_ID.replace(":id", report._id)
                      );
                    }}
                    handleDownload={() => {
                      handleDownloadDailyReport(report._id);
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="flex justify-center items-center h-32">
                <span className="text-lg">
                  No Presentation Center Report found
                </span>
              </div>
            )}
          </div>
        )}

        <div className="h-[1px] bg-[#a1a1a1]" />
        <div className="flex flex-col gap-y-3 py-4 mt-4">
          <div className="text-[20px] font-semibold flex justify-between">
            <div>Salesrep Activity Report</div>
          </div>
          {isLoadingDailyReport ? (
            <Skeleton height={130} className="col-span-12" count={1} />
          ) : (
            <div className="grid grid-cols-12 col-span-12 gap-6">
              <ReportCard
                icon={<BsPeople size={24} />}
                title="Daily Report"
                description="Download the daily report"
                handleDownload={() => {
                  setSalesRepRangeSelectModal(true);
                  setSalesRepReportType("daily");
                }}
              />
              <ReportCard
                icon={<BsPeople size={24} />}
                title="Weekly Report"
                description="Download the weekly report"
                handleDownload={() => {
                  setSalesRepRangeSelectModal(true);
                  setSalesRepReportType("weekly");
                }}
              />
              {salesRepRangeSelectModal && (
                <SalesRepRangeSelectModal
                  type={salesRepReportType}
                  projectId={projectId}
                  deviceId={id}
                  open={salesRepRangeSelectModal}
                  onClose={() => setSalesRepRangeSelectModal(false)}
                  setWaitingModal={setWaitingModal}
                />
              )}
            </div>
          )}
        </div>
        <div className="h-[1px] bg-[#a1a1a1]" />

        <div className="flex flex-col gap-y-3 py-4">
          <div className="text-[20px] font-semibold">Inventory Report</div>
          {isLoading ? (
            <Skeleton height={130} count={1} />
          ) : buildingOptions.length > 0 ? (
            <div className="grid grid-cols-12 col-span-12 gap-6">
              {buildingOptions.map((building) => (
                <ReportCardBuilding
                  icon={<Building2Icon size={28} />}
                  title={building.label}
                  description={`Download the report for ${building.label}`}
                  handleDownloadParking={() => {
                    handleDownloadInventoryReport("parking", building.value);
                  }}
                  handleDownloadUnit={() => {
                    handleDownloadInventoryReport("units", building.value);
                  }}
                  handleDownloadStorage={() => {
                    handleDownloadInventoryReport("storage", building.value);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center h-32">
              <span className="text-lg">No buildings found</span>
            </div>
          )}
        </div>
        <div className="h-[1px] bg-[#a1a1a1]" />
        <div className="flex flex-col gap-y-3 py-4">
          <div className="text-[20px] font-semibold">Reservation Report</div>
          <div className="grid grid-cols-12 col-span-12 gap-6">
            <ReportCard
              icon={<CalendarCheck size={28} />}
              title="Reservation Report"
              description="Download the Reservation Report"
              handleDownload={() => {
                handleDownloadReservationReport();
              }}
            />
          </div>
        </div>
        <div className="h-[1px] bg-[#a1a1a1]" />
        <div className="flex flex-col gap-y-3 py-4">
          <div className="text-[20px] font-semibold">
            Sales Transaction Report
          </div>
          <div className="grid grid-cols-12 col-span-12 gap-6">
            <ReportCard
              icon={<DocumentReportIcon height={28} />}
              title="Sales Report"
              description="Download the Sales Transaction Report"
              handleDownload={() => {
                handleDownloadSalesReport();
              }}
            />
          </div>
        </div>
        <div className="h-[1px] bg-[#a1a1a1]" />
        <div className="flex flex-col gap-y-3 py-4">
          <div className="text-[20px] font-semibold">Email campaign Report</div>
          {isLoadingCampaign ? (
            <Skeleton className="col-span-12" height={130} count={1} />
          ) : campaigns.length > 0 ? (
            <div className="grid grid-cols-12 col-span-12 gap-6">
              {campaigns.map((campaign) => (
                <ReportCard
                  icon={<DocumentReportIcon height={28} />}
                  title={campaign.name}
                  description={
                    campaign?.emailTemplateId?.name +
                    " - " +
                    new Date(campaign?.createdAt).toLocaleDateString()
                  }
                  handleDownload={() => {
                    handleDownloadReportCampaign(campaign._id);
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex justify-center items-center h-32">
              <span className="text-lg">No buildings found</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportContainer;

const SalesRepRangeSelectModal = ({
  open,
  onClose,
  type,
  projectId,
  setWaitingModal,
  deviceId,
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [selectedDateRange, setSelectedDateRange] = useState({
    startDate:
      type === "daily" ? moment().startOf("day") : moment().startOf("week"),
    endDate: type === "daily" ? moment().endOf("day") : moment().endOf("week"),
  });

  const handleDateChange = (name, date) => {
    setSelectedDateRange({ ...selectedDateRange, [name]: date });
  };

  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ startDate, endDate });
  };

  const handleDownloadSalesRepReport = async (
    type,
    projectId,
    startDate,
    endDate
  ) => {
    onClose();
    setWaitingModal(true);
    setTimeout(() => {
      setWaitingModal(false);
    }, 10000);
    try {
      let query;
      let url = `/reports/salesReport/daily-reports?deviceId=${deviceId}&type=${type}&startDate=${startDate}&endDate=${endDate}`;

      if (type === "daily") {
        url = `/reports/salesReport/daily-reports?deviceId=${deviceId}&type=${type}&startDate=${startDate}`;
      } else {
        url = `/reports/salesReport/daily-reports?deviceId=${deviceId}&type=${type}&startDate=${startDate}&endDate=${endDate}`;
      }
      const response = await api.get(url, {}, { responseType: "blob" });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDownload = () => {
    handleDownloadSalesRepReport(
      type,
      projectId,
      selectedDateRange.startDate,
      selectedDateRange.endDate
    );
  };

  return (
    // <Button onClick={() => setShowCalendar(true)}>Select Date</Button>
    <Modal open={open} onClose={onClose} className="">
      <div className="relative flex flex-col font-openSans min-w-[400px] bg-white p-8 w-fit rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <button className="absolute right-4 top-4" onClick={onClose}>
          <CloseIcon />
        </button>
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <div className="text-xl font-semibold">
              {type === "daily" ? "Daily Report" : "Weekly Report"}
            </div>
            <div className="text-sm text-black">
              {type === "daily"
                ? `Download the ${type} report for the date`
                : `Download the ${type} report for the week range`}
            </div>
          </div>
          <div className="flex flex-col gap-y-2">
            {type === "daily" ? (
              <div className="flex flex-col gap-y-1">
                <div className="text-sm font-semibold">Date</div>
                <DatePicker
                  placeholder="select date"
                  value={selectedDateRange.startDate.toDate()}
                  oneTap
                  onChange={(e) => {
                    console.log(e);
                    if (new Date(e) > new Date()) {
                      toast.error("You cannot select a future date");
                    } else {
                      handleDateChange("startDate", moment(e).startOf("day"));
                    }
                  }}
                  cleanable={false}
                />
              </div>
            ) : (
              <div className="flex flex-col gap-y-4">
                <div className="flex gap-x-4 w-full">
                  <div className="flex flex-col gap-y-1 w-full">
                    <div className="text-sm font-semibold">Start Date</div>
                    <DateRangePicker
                      placeholder="Week picker"
                      hoverRange="week"
                      showOneCalendar
                      oneTap
                      value={[
                        selectedDateRange.startDate.toDate(),
                        selectedDateRange.endDate.toDate(),
                      ]}
                      onChange={(value) => {
                        if (value[0] > new Date()) {
                          toast.error("Date cannot be greater than today");
                          return;
                        }
                        handleDateRangeChange(
                          moment(value[0]).startOf("week"),
                          moment(value[1]).endOf("week")
                        );
                      }}
                      ranges={["week", "month"]}
                      renderExtraFooter={() => (
                        <div className="flex justify-end gap-x-2">
                          <Button onClick={onClose}>Cancel</Button>
                          <Button onClick={handleDownload}>Download</Button>
                        </div>
                      )}
                      cleanable={false}
                    />
                  </div>
                  {/* <div className="flex flex-col gap-y-1 w-full">
                    <div className="text-sm font-semibold">End Date</div>
                    <CustomInput
                      type="date"
                      value={selectedDateRange.endDate.format("YYYY-MM-DD")}
                      onChange={(e) => {
                        let date = moment(e.target.value);
                        handleDateChange("endDate", date);
                      }}
                    />
                  </div> */}
                </div>
              </div>
            )}
          </div>
          <div className="flex justify-end gap-x-2">
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={handleDownload}>Download</Button>
          </div>
        </div>
      </div>
    </Modal>
    // </div>
  );
};
