// @ts-nocheck
/* eslint-disable */
import React from "react"
import { Modal } from "@material-ui/core"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import { ADMIN_DOCUSEAL_CONFIGS } from "routes"
import { Button } from "components/ShadcnComponents/button"
import { useSelector } from "react-redux"
import { Plus } from "lucide-react"
import useDocusealConfigModal from "hooks/useDocusealConfigModal"
import DocusealModelContent from "./DocusealModelContent"

const DocusealConfigModel = ({ open, close }) => {
  const { docusealData, userObject, setIsModalOpen } = useDocusealConfigModal()

  const history = useHistory()

  return (
    <div>
      <Modal
        open={open}
        // onClose={close}
        className="p-0"
      >
        <div
          className="relative flex flex-col text-center font-openSans bg-grey-8 !p-8 rounded shadow-lg gap-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          style={{ width: "550px" }}
        >
          <button className="absolute top-3 right-3" onClick={close}>
            <Plus className="rotate-45" />
          </button>

          <DocusealModelContent/>
        </div>
      </Modal>
    </div>
  )
}

export default DocusealConfigModel
