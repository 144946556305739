/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import UnitEditView from './UnitEditView';
import ParkingEditView from './ParkingEditView';
import StorageEditView from './StorageEditView';
import {
  KeyRound,
  Bell,
  ChevronsUpDown,
  Pen,
  Check,
  Delete,
} from 'lucide-react';
import CustomTable from './CustomTable';
import Checkbox from './Checkbox';
import { MenuItem, Modal, Select } from '@material-ui/core';
import { CloseIcon } from 'components/Icons';
import {
  createOrUpdateParking,
  getAllParkingForBuildingByParkingType,
  getAvailableParkingsForBuildingByParkingType,
  getParkingInventory,
} from 'store/actions/parkingActions';
import CustomSelect from 'components/CustomSelect';
import CustomInput from 'components/CustomInput';
import { createOrUpdateUnit } from 'store/actions/unitActions';
import toast from 'react-hot-toast';
import DeleteButton from 'components/HorizontalCardProfile/MobileCardComponents/DeleteButton';
import { getFilteredDeals } from 'store/actions/dealAction';
import { StorageAllocatePage } from './StorageAllocatePage';
import { useSelector } from 'react-redux';
import { getProjects } from 'store/actions/projectActions';
import { getReservationByUnitChoice } from 'store/actions/reservationActions';
import moment from 'moment';

export const AllocationBadges = ({ type: { count, title, bgColor } }) => (
  <div
    className='px-6 py-3 rounded-lg shadow border-2 border-zinc-200 justify-center items-center gap-3.5 inline-flex'
    style={{ backgroundColor: bgColor }}
  >
    <div className='text-black text-xl font-medium'>
      {count} {title}
    </div>
  </div>
);

const HorizontalLine = ({ className }) => (
  <div className={`w-full h-[1px] bg-zinc-200 ${className}`} />
);

const TabWrapper = ({ tabMenus, setSelectedTab, selectedTab }) => (
  <div className='w-full flex items-center md:justify-center rounded-lg bg-white p-1 text-zinc-600'>
    {tabMenus.map((tab, index) => (
      <div
        key={index}
        onClick={() => {
          setSelectedTab(tab.id);
        }}
        className={`flex flex-1 w-full min-w-[120px] md:w-fit md:min-w-[180px] max-w-[200px] text-center justify-center items-center just px-4 py-[8px] cursor-pointer rounded-[4px] font-semibold text-[12px] ${
          selectedTab === tab.id
            ? 'bg-[#18181B] text-white shadow-sm'
            : 'text-zinc-600 hover:bg-zinc-100'
        } transition-all`}
      >
        {tab.name}
      </div>
    ))}
  </div>
);

const ShadCNWrapper = ({ children, className = '' }) => (
  <div
    className={`rounded-xl border bg-card text-card-foreground shadow bg-white overflow-hidden ${className}`}
  >
    {children}
  </div>
);

const CustomCheckBox = () => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Checkbox
      checked={isChecked}
      handleChange={() => setIsChecked(!isChecked)}
      iconClasses='self-start my-1'
    />
  );
};

const InventoryEditDrawer = ({
  editDetails,
  setEditDetails,
  closeInfoDrawer,
  isInfoDrawerOpen,
  isAbleToPurchase = false,
  refetch,
  type = '',
  isFullHeight = false,
  selectedBuilding,
}) => {
  const projectId = useSelector((state) => state.appReducer.appProject);
  let unitVar = localStorage.getItem(`projectId_${projectId}`);
  const [isAdditionalDrawerOpen, setIsAdditionalDrawerOpen] = useState(false);
  const colors = ['#FFBE9A', '#16A34A40', '#FFD700', '#FF0000'];
  const [allocationModal, setAllocationModal] = useState(false);
  const [parkingType, setParkingType] = useState([]);
  const [reservationData, setReservationData] = useState([]);
  const [typesOfAllocatedParking, setTypesOfAllocatedParking] = useState([]);
  const [tempTypesOfAllocatedParking, setTempTypesOfAllocatedParking] =
    useState([]);
  const [assignParkingModal, setAssignParkingModal] = useState(false);
  const [pendingParkingAssignment, setPendingParkingAssignment] = useState(0);
  const [parkingAssignedOption, setParkingAssignedOption] = useState('');
  const [parkings, setParkings] = useState([]);
  const [selectedParking, setSelectedParking] = useState('');
  const [reassignedSelectedParking, setReassignedSelectedParking] =
    useState('');
  const [assignedParkingTableDataSource, setAssignedParkingTableDataSource] =
    useState([]);
  const [editParkingModal, setEditParkingModal] = useState(false);
  const [unassignParkingModal, setUnassignParkingModal] = useState(false);
  const [reassignParkingModal, setReassignParkingModal] = useState(false);
  const [avesdoParkings, setAvesdoParkings] = useState([]);
  const [unitVariable, setUnitVariable] = useState(unitVar || 'Unit');
  const [avesdoWaitlistedParkings, setAvesdoWaitlistedParkings] = useState([]);
  const [
    avesdoUnAssignedWaitlistParkings,
    setAvesdoUnAssignedWaitlistParkings,
  ] = useState([]);
  const [avesdoSelectedParking, setAvesdoSelectedParking] = useState(null);
  const [avesdoUnit, setAvesdoUnit] = useState([]);
  const [tempAvesdoRefetct, setTempAvesdoRefetct] = useState(false);
  const tabMenus = [
    { id: 'tab-1', name: 'Information' },
    { id: 'tab-2', name: 'Parking' },
    { id: 'tab-3', name: 'Storage' },
    { id: 'tab-4', name: 'Reservation' },
  ];
  const [selectedTab, setSelectedTab] = useState('tab-1');
  const currencyFormate = (value) => {
    if (value == null) return undefined;
    else {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      });

      const formattedValue = formatter.format(value);
      return formattedValue;
    }
  };

  useEffect(() => {
    getProjects(projectId).then((project) => {
      const { unitVariable } = project;
      if (unitVariable) {
        setUnitVariable(unitVariable || 'Unit');
      }
    });
  }, [projectId]);

  useEffect(() => {
    if (typesOfAllocatedParking.length === 0)
      return setPendingParkingAssignment(0);
    let total = typesOfAllocatedParking.reduce(
      (acc, curr) => acc + Number(curr.count),
      0
    );
    setPendingParkingAssignment(total);
  }, [typesOfAllocatedParking, editDetails]);
  const handleFilteredDeals = async () => {
    let data = await getFilteredDeals({
      unitNumber: editDetails?.unitNumber || null,
    });
    setAvesdoUnit(data);
    let allParkings = [];
    data.forEach((deal) => {
      const { options } = deal;
      const modifiedParkings = options
        .filter((option) => {
          return (
            option.label.toLowerCase().includes('parking') &&
            option.header !== 'Parking Waitlist'
          );
        })
        .map((option, index) => ({
          ...option,
          option: option.label,
          price:
            option.price === 0 ? 'Included' : currencyFormate(option.price),
          type: '-',
          id: option.id,
          flag: 'avesdo',
        }));
      allParkings = allParkings.concat(modifiedParkings);
    });
    const filteredDeals = data.filter(
      (deal) =>
        deal.options.some((option) => option.header === 'Parking Waitlist') &&
        deal.formatedAcceptedDate !== ''
    );
    let waitlistedParkings = [];
    filteredDeals.forEach((deal) => {
      const { options } = deal;
      const modifiedParkings = options
        .filter((option) => option.header === 'Parking Waitlist')
        .map((option, index) => ({
          ...option,
          option: option.label,
          price:
            option.price === 0 ? 'Included' : currencyFormate(option.price),
          type: '-',
          id: option.id,
          flag: 'avesdo',
        }));
      waitlistedParkings = waitlistedParkings.concat(modifiedParkings);
    });
    let unassignedWaitlistedParking = [];
    let assignedParkings = editDetails?.parkings?.assigned || [];
    waitlistedParkings.forEach((waitlistParking) => {
      const isAssigned = assignedParkings.some((assigned) => {
        return (
          assigned.isAvesdo && Number(assigned.avesdoId) === waitlistParking.id
        );
      });

      if (!isAssigned) {
        unassignedWaitlistedParking.push({
          ...waitlistParking,
        });
      }
    });
    setAvesdoWaitlistedParkings(waitlistedParkings);
    setAvesdoUnAssignedWaitlistParkings(unassignedWaitlistedParking);
    setAvesdoParkings(allParkings);
  };
  useEffect(() => {
    handleFilteredDeals();
  }, [editDetails, tempAvesdoRefetct]);
  useEffect(() => {
    let assignedParkings = editDetails?.parkings?.assigned || [];
    let allocatedParking = editDetails?.parkings?.allocated || [];
    let mappedAllocated = allocatedParking.map((parking, index) => {
      return {
        _id: parking._id,
        id: index,
        count: parking.quantity,
        title: parking.name,
        bgColor: colors[index % colors.length],
      };
    });
    setTypesOfAllocatedParking(mappedAllocated);
    setTempTypesOfAllocatedParking(mappedAllocated);
    let mappedAssigned = assignedParkings.map((parking, index) => {
      if (parking.isAvesdo) {
        let concatWaitlistAndAvesdo = [
          ...avesdoWaitlistedParkings,
          ...avesdoParkings,
        ];
        let avesdoParking = concatWaitlistAndAvesdo.filter((item) => {
          return item.id === Number(parking.avesdoId);
        })[0];
        return {
          avesdoId: parking.avesdoId,
          id: parking._id,
          stall: (parking?.stall || '').toString(),
          type: 'Avesdo',
          option: avesdoParking?.label || '',
          price:
            avesdoParking?.price === '$0.00'
              ? 'Included'
              : avesdoParking?.price || 0,
          isAssigned: true,
        };
      } else {
        return {
          id: parking._id,
          stall: (parking?.stall || '').toString(),
          type: (parking?.parkingType || '').toString(),
          price:
            parking.price === 0
              ? 'Included'
              : currencyFormate(parking?.price).toString() || '',
          isAssigned: true,
        };
      }
    });
    let unassignedParking = [];
    let totalCount = mappedAllocated.reduce((acc, curr) => acc + curr.count, 0);
    let assignedCount = mappedAssigned.length;
    let unassignedCount = totalCount - assignedCount;
    for (let i = 0; i < unassignedCount; i++) {
      unassignedParking.push({
        id: `pending${i}_${type.title}`,
        stall: 'N/a',
        type: '-',
        floor: 'N/a',
        price: 'N/a',
        isAssigned: false,
      });
    }

    let avesdoUnassigned = avesdoParkings.filter((item) => {
      return !mappedAssigned.some((t) => Number(t.avesdoId) === item.id);
    });

    setAssignedParkingTableDataSource([
      ...mappedAssigned,
      ...unassignedParking,
      ...avesdoUnassigned,
    ]);
  }, [editDetails, selectedBuilding, avesdoParkings]);

  const handleAddParking = () => {
    const newId = tempTypesOfAllocatedParking.length + 1;
    const newBgColor = colors[newId % colors.length]; // Rotate through colors
    const newAllocation = {
      id: newId,
      count: 1,
      title: '',
      bgColor: newBgColor,
    };

    setTempTypesOfAllocatedParking((prev) => [...prev, newAllocation]);
  };
  const _getParkingType = async (parking) => {
    let parkingInventory = await getParkingInventory(selectedBuilding);
    parkingInventory = parkingInventory.map((parking) => ({
      parkingType: parking.options.map((op) => op.name),
    }));
    if (parkingInventory.length) {
      setParkingType(
        parkingInventory[0].parkingType.map((pt) => ({
          value: pt,
          id: pt,
        }))
      );
    }
  };

  const handleClose = () => {
    closeInfoDrawer();
    setIsAdditionalDrawerOpen(false);
  };
  const openAllocationModal = () => {
    setAllocationModal(true);
  };

  const openAssignParkignModal = () => {
    setAssignParkingModal(true);
  };

  useEffect(() => {
    _getParkingType();
  }, [selectedBuilding]);

  const handleParkingTypeChange = (id) => {
    const temp = parkingType.filter((option) => option.id === id)[0];
    onChange('parkingType', temp.title);
  };

  const onChange = (fieldName, newValue) => {
    setParkingType((prev) => {
      const newParkingType = prev.map((option) => {
        if (option.title === fieldName) {
          return { ...option, title: newValue };
        }
        return option;
      });
      return newParkingType;
    });
  };

  const getAvailableParkings = async () => {
    if (editParkingModal) {
      let data = await getAllParkingForBuildingByParkingType(
        selectedBuilding,
        'parkingType=' + parkingAssignedOption
      );
      let filtered = data?.map((parking) => {
        return {
          value: parking.stall,
          id: parking._id,
        };
      });
      setParkings(filtered);
    } else {
      let data = await getAvailableParkingsForBuildingByParkingType(
        selectedBuilding
      );
      let filtered = data?.map((parking) => {
        return {
          value: parking.stall,
          id: parking._id,
        };
      });
      if (filtered.length === 0)
        toast.error(
          'No parking spots available for' + ' ' + parkingAssignedOption
        );
      setParkings(filtered);
    }
  };

  // useEffect(() => {
  //   handleFilteredDeals();
  // }, [editDetails]);

  useEffect(() => {
    if (assignParkingModal || editParkingModal) {
      getAvailableParkings();
    }
  }, [assignParkingModal, editParkingModal]);
  const [datesData, setDatesData] = useState([]);

  const handleWhishlistDate = (date) => {
    let datesData = avesdoUnit.filter((unit) =>
      unit?.options?.some((option) => option.header === 'Parking Waitlist')
    );
    setDatesData(datesData);
  };
  useEffect(() => {
    handleWhishlistDate();
  }, [avesdoUnit]);

  const headersForStatic = [
    {
      name: 'Reservation #',
      id: 'reservationNumber',
    },
    {
      name: 'Prospective Purchaser',
      id: 'prospectivePurchaser',
    },
    {
      name: 'Lot Choice',
      id: 'lotChoiceName',
    },
    {
      name: 'Date Reserved',
      id: 'dateReserved',
    },
    {
      name: 'Title Company: Deposit',
      id: 'titleCompanyDeposit',
    },
    {
      name: 'Broker',
      id: 'broker',
    },
    {
      name: 'Notes',
      id: 'notes',
    },
  ];

  useEffect(() => {
    if (selectedTab === 'tab-3') {
      getReservationByUnitChoice({
        lotChoice: editDetails?._id,
      }).then((data) => {
        let updatedRes = data.map((reservation) => {
          const temBuyers = reservation.reservation.reservationHolders.map(
            (user) =>
              user.firstName && user.lastName
                ? user.firstName + ' ' + user.lastName
                : '-'
          );
          return {
            ...reservation.reservation,
            reservation_holders: temBuyers.join(', '),
            lotChoiceName: reservation.foundIn,
          };
        });
        setReservationData(updatedRes);
      });
    }
  }, [selectedTab, projectId]);
  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <div className='items-center inline-flex'>
            <CustomCheckBox />
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Parking stall
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'stall',
        dataIndex: 'stall',
        Cell: (props) => (
          <div className='items-center flex'>
            <CustomCheckBox />
            <p className='mb-0 text-black text-base font-bold truncate'>
              {props?.cell?.value || 'N/a'}
            </p>
          </div>
        ),
      },
      {
        Title: (
          <div className='items-center max-w- inline-flex'>
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Avesdo Name
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'option',
        dataIndex: 'otion',
        Cell: (props) => (
          <div className='items-center max-w-[200px] flex-wrap flex'>
            <p className='mb-0 text-black whitespace-normal text-base font-[500]'>
              {props?.cell?.value || 'N/a'}
            </p>
          </div>
        ),
      },
      {
        Title: (
          <div className='items-center inline-flex'>
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Type
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'type',
        dataIndex: 'type',
        Cell: (props) => (
          <div className='px-3 py-1 rounded-md border border-zinc-200 justify-center items-center gap-2 inline-flex'>
            <div className='text-zinc-950 text-sm font-medium leading-none capitalize'>
              {props?.cell?.value || '-'}
            </div>
          </div>
        ),
      },
      {
        Title: (
          <div className='items-center inline-flex'>
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Price
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'price',
        dataIndex: 'price',
        Cell: (props) => (
          <p className='mb-0 text-zinc-950 text-sm font-normal leading-tight truncate capitalize'>
            {props.cell?.value?.length ? props.cell.value : '—'}
          </p>
        ),
      },
      {
        Title: '',
        accessor: 'isAssigned',
        Cell: (props) => (
          <div className='w-full flex flex-row justify-end items-center gap-2 text-center'>
            {props.cell.value ? (
              <>
                <button
                  onClick={() => {
                    if (props.row.original.flag === 'avesdo') {
                      setAvesdoSelectedParking(props.row.original.id);
                    }
                    setEditParkingModal(true);
                    setSelectedParking(props.row.original.id);
                    setReassignedSelectedParking(props.row.original.id);
                    setParkingAssignedOption(props.row.original.type);
                  }}
                  className='self-stretch p-2.5 text-sm font-medium bg-white border border-zinc-200 text-black rounded-lg flex gap-2 items-center justify-center'
                >
                  <Pen className='w-4 h-4' />
                </button>
                <button className='py-2.5 px-4 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'>
                  <Check className='w-4 h-4' />
                  <p className='mb-0'>Assigned</p>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    if (props.row.original.flag === 'avesdo') {
                      setAvesdoSelectedParking(props.row.original.id);
                    }
                    openAssignParkignModal();
                  }}
                  className='py-2.5 px-4 text-sm font-medium bg-[#FFBE9A] border border-[#E4E4E7] text-black rounded-lg flex gap-2 items-center justify-center'
                >
                  <Bell height='16' width='16' color='black' />
                  <p className='mb-0'>Assign Parking spot</p>
                </button>
              </>
            )}
          </div>
        ),
      },
    ],
    []
  );
  const tableColumnsAvesdoParking = useMemo(
    () => [
      {
        Title: (
          <div className='items-center inline-flex'>
            <CustomCheckBox />
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Options
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'option',
        dataIndex: 'otion',
        Cell: (props) => (
          <div className='items-center flex'>
            <CustomCheckBox />
            <p className='mb-0 text-black text-base font-[500]'>
              {props.cell.value}
            </p>
          </div>
        ),
      },
      {
        Title: (
          <div className='items-center inline-flex'>
            <p
              className='text-center text-zinc-500 text-[15px] font-medium leading-none capitalize mb-0'
              label='Document'
            >
              Price
            </p>
            <ChevronsUpDown className='w-4 h-4 text-zinc-500 dark:text-zinc-300' />
          </div>
        ),
        accessor: 'price',
        dataIndex: 'price',
        Cell: (props) => (
          <p className='mb-0 text-zinc-950 text-sm font-normal leading-tight truncate capitalize'>
            {props.cell.value.length ? props.cell.value : '—'}
          </p>
        ),
      },
      {
        Title: '',
        accessor: 'isAssigned',
        Cell: (props) => (
          <div className='w-full flex flex-row justify-end items-center gap-2 text-center'>
            {props.cell.value ? (
              <>
                <button
                  onClick={() => {
                    setEditParkingModal(true);
                    setSelectedParking(props.row.original.id);
                    setReassignedSelectedParking(props.row.original.id);
                    setParkingAssignedOption(props.row.original.type);
                  }}
                  className='self-stretch p-2.5 text-sm font-medium bg-white border border-zinc-200 text-black rounded-lg flex gap-2 items-center justify-center'
                >
                  <Pen className='w-4 h-4' />
                </button>
                <button className='py-2.5 px-4 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'>
                  <Check className='w-4 h-4' />
                  <p className='mb-0'>Assigned</p>
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={() => {
                    if (props.row.original.flag === 'avesdo') {
                      console.log(props.row.original.id);
                      setAvesdoSelectedParking(props.row.original.id);
                    }
                    openAssignParkignModal();
                  }}
                  className='py-2.5 px-4 text-sm font-medium bg-[#FFBE9A] border border-[#E4E4E7] text-black rounded-lg flex gap-2 items-center justify-center'
                >
                  <Bell height='16' width='16' color='black' />
                  <p className='mb-0'>Assign Parking spot</p>
                </button>
              </>
            )}
          </div>
        ),
      },
    ],
    []
  );
  const EditParkingModal = () => {
    return (
      <Modal open={editParkingModal} onClose={() => setEditParkingModal(false)}>
        <div className='relative w-fit flex flex-col gap-y-4 min-w-[500px] font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <button
            onClick={() => {
              setEditParkingModal(false);
              setReassignParkingModal(false);
              setUnassignParkingModal(false);
              setParkingAssignedOption('');
              setSelectedParking('');
            }}
            className='absolute right-4 top-4'
          >
            <IoMdClose fontSize='22px' />
          </button>
          {!unassignParkingModal && !reassignParkingModal && (
            <div className='flex flex-col gap-y-3'>
              <div className='text-[20px] text-center'>
                Select a option to reassign the parking spot
              </div>
              <div className='flex gap-4 items-center justify-center'>
                <button
                  onClick={() => {
                    setUnassignParkingModal(true);
                  }}
                  className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
                >
                  Unassign Parking
                </button>
                <button
                  onClick={() => {
                    setReassignParkingModal(true);
                    setParkingAssignedOption('');
                  }}
                  className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
                >
                  Reassign Parking
                </button>
              </div>
            </div>
          )}
          {unassignParkingModal && (
            <div className='flex flex-col items-center gap-y-4'>
              <div className='text-[20px] text-center'>
                Are you sure you want to unassign this parking spot?
              </div>
              <div className='flex gap-x-4 items-center'>
                <button
                  onClick={async () => {
                    await createOrUpdateParking({
                      _id: selectedParking,
                      status: 'Available',
                      unit: null,
                      isAvesdo: false,
                      avesdoId: null,
                    });
                    await createOrUpdateUnit({
                      ...editDetails,
                      parkings: {
                        ...editDetails.parkings,
                        assigned: editDetails.parkings.assigned.filter(
                          (item) => item._id !== selectedParking
                        ),
                      },
                    }).then(async (data) => {
                      refetch();
                      setEditDetails(data);
                      setUnassignParkingModal(false);
                      setEditParkingModal(false);
                    });
                  }}
                  className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
                >
                  Yes
                </button>
                <button
                  onClick={() => {
                    setUnassignParkingModal(false);
                  }}
                  className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
                >
                  No
                </button>
              </div>
            </div>
          )}
          {reassignParkingModal && (
            <div className='flex flex-col gap-y-4'>
              <div className='text-[20px] text-center'>Reassign Parking</div>
              <CustomSelect
                label={'Select Parking Stall For'}
                options={parkings}
                selectedOption={reassignedSelectedParking}
                setSelectedOption={(value) => {
                  setReassignedSelectedParking(value);
                }}
              />
              <button
                onClick={async () => {
                  if (
                    assignedParkingTableDataSource.filter(
                      (t) =>
                        t.id === reassignedSelectedParking &&
                        t.isAssigned === true
                    ).length > 0
                  ) {
                    toast.error(
                      'Parking spot is already assigned to a unit please select another or unassign it first'
                    );
                    return;
                  }
                  let newAssignedParkingArr = editDetails.parkings.assigned.map(
                    (item) => {
                      if (item._id === selectedParking) {
                        return reassignedSelectedParking;
                      }
                      return item._id;
                    }
                  );
                  let concatWaitlistAndAvesdo = [
                    ...avesdoWaitlistedParkings,
                    ...avesdoParkings,
                  ];
                  let avesdoParking = concatWaitlistAndAvesdo.find((item) => {
                    return item.id === avesdoSelectedParking;
                  });
                  await createOrUpdateParking({
                    _id: selectedParking,
                    status: 'Available',
                    unit: null,
                    isAvesdo: false,
                    avesdoId: null,
                  });
                  await createOrUpdateParking({
                    _id: reassignedSelectedParking,
                    status: 'Assigned',
                    unit: editDetails._id,
                    isAvesdo: avesdoParking ? true : false,
                    avesdoId: avesdoParking ? avesdoParking.id : null,
                  });

                  await createOrUpdateUnit({
                    ...editDetails,
                    parkings: {
                      ...editDetails.parkings,
                      assigned: newAssignedParkingArr,
                    },
                  }).then(async (data) => {
                    refetch();

                    setEditDetails(data);
                    setSelectedParking('');
                    setReassignedSelectedParking('');
                    setReassignParkingModal(false);
                    setEditParkingModal(false);
                  });
                }}
                className='py-2.5 px-4  text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
              >
                Save
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const AssignParkingModal = () => {
    return (
      <Modal
        open={assignParkingModal}
        onClose={() => setAssignParkingModal(false)}
      >
        <div className='relative w-fit flex flex-col gap-y-4 min-w-[500px] font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <button
            onClick={() => {
              setAssignParkingModal(false);
              setParkingAssignedOption('');
              setSelectedParking('');
            }}
            className='absolute right-4 top-4'
          >
            <IoMdClose fontSize='22px' />
          </button>
          <div className='text-[20px] text-center'>Assigned Parking</div>
          <CustomSelect
            disabled={parkings.length === 0}
            label={'Select Parking Stall'}
            options={parkings}
            selectedOption={selectedParking}
            setSelectedOption={(value) => {
              setSelectedParking(value);
            }}
          />
          <button
            onClick={async () => {
              if (selectedParking === '') {
                toast.error('Please select a parking stall');
                return;
              }
              let concatWaitlistAndAvesdo = [
                ...avesdoWaitlistedParkings,
                ...avesdoParkings,
              ];
              let avesdoParking = concatWaitlistAndAvesdo.find((item) => {
                return item.id === avesdoSelectedParking;
              });
              await createOrUpdateParking({
                _id: selectedParking,
                status: 'Assigned',
                unit: editDetails._id,
                isAvesdo: avesdoParking ? true : false,
                avesdoId: avesdoParking ? avesdoParking.id : null,
              });
              await createOrUpdateUnit({
                ...editDetails,
                parkings: {
                  ...editDetails.parkings,
                  assigned: [...editDetails.parkings.assigned, selectedParking],
                },
              }).then(async (data) => {
                setEditDetails(data);
                refetch();
                setSelectedParking('');
                setParkingAssignedOption('');
                setAssignParkingModal(false);
                setTempAvesdoRefetct(!tempAvesdoRefetct);
              });
            }}
            className='py-2 px-2 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
          >
            <Check />
            Save
          </button>
        </div>
      </Modal>
    );
  };

  const AllocationModal = () => {
    return (
      <Modal open={allocationModal} onClose={() => setAllocationModal(false)}>
        <div className='relative w-fit flex flex-col gap-y-4 min-w-[500px] font-openSans bg-grey-8 p-10 rounded shadow-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <button
            onClick={() => {
              setTempTypesOfAllocatedParking(typesOfAllocatedParking);
              setAllocationModal(false);
            }}
            className='absolute right-4 top-4'
          >
            <IoMdClose fontSize='22px' />
          </button>
          <h3 className='text-[20px] text-center'>Allocate Parking</h3>
          {tempTypesOfAllocatedParking.map((type) => (
            <div key={type.id} className='flex items-center gap-3'>
              <div className='w-1/2'>
                <CustomSelect
                  inputClasses='h-11'
                  labelClasses={undefined}
                  options={parkingType}
                  selectedOption={type.title}
                  setSelectedOption={(option) => {
                    setTempTypesOfAllocatedParking((prev) => {
                      prev.find((t) => t.id === type.id).title = option;
                      return [...prev];
                    });
                  }}
                />
              </div>
              <CustomInput
                type='number'
                value={type.count}
                onChange={(e) => {
                  if (
                    e.target.value <
                    assignedParkingTableDataSource.filter((item) => {
                      return (
                        item.type === type.title && item.isAssigned === true
                      );
                    }).length
                  ) {
                    toast.error(
                      'Can not reduce the count below the number of assigned parking spots for'
                    );
                    return;
                  }
                  let newTypes = tempTypesOfAllocatedParking.map((t) => {
                    if (t.id === type.id) {
                      return {
                        ...t,
                        count: e.target.value,
                      };
                    }
                    return t;
                  });
                  setTempTypesOfAllocatedParking(newTypes);
                }}
              />
              <button
                onClick={() => {
                  if (
                    assignedParkingTableDataSource.filter(
                      (item) =>
                        item.type === type.title && item.isAssigned === true
                    ).length > 0
                  ) {
                    toast.error(
                      'Parking spots are already assigned for' +
                        ' ' +
                        type.title +
                        '. Please unassign them first'
                    );
                    return;
                  }
                  setTempTypesOfAllocatedParking((prev) => {
                    return prev.filter((t) => t.id !== type.id);
                  });
                }}
                className='h-full'
              >
                <svg
                  width='12'
                  height='16'
                  viewBox='0 0 14 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.50016 15.4167C1.50016 16.425 2.32516 17.25 3.3335 17.25H10.6668C11.6752 17.25 12.5002 16.425 12.5002 15.4167V4.41667H1.50016V15.4167ZM13.4168 1.66667H10.2085L9.29183 0.75H4.7085L3.79183 1.66667H0.583496V3.5H13.4168V1.66667Z'
                    fill='#2E2B2E'
                  />
                </svg>
              </button>
            </div>
          ))}
          <div
            className='text-[16px] cursor-pointer'
            onClick={() => {
              handleAddParking();
            }}
          >
            + Allocate Parking
          </div>
          <button
            onClick={async () => {
              if (
                tempTypesOfAllocatedParking.some((type) => type.count === 0)
              ) {
                toast.error('Please allocate parking');
                return;
              }
              const titles = new Set();
              for (const type of tempTypesOfAllocatedParking) {
                if (titles.has(type.title)) {
                  toast.error('Duplicate parking title found');
                  return;
                }
                titles.add(type.title);
              }
              await createOrUpdateUnit({
                ...editDetails,
                parkings: {
                  ...editDetails.parkings,
                  allocated: tempTypesOfAllocatedParking.map((type) => ({
                    quantity: type.count,
                    name: type.title,
                  })),
                  totalAllocatedParking: tempTypesOfAllocatedParking.reduce(
                    (acc, curr) => acc + Number(curr.count),
                    0
                  ),
                },
              }).then((data) => {
                refetch();
                setAllocationModal(false);
                let total = tempTypesOfAllocatedParking.reduce(
                  (acc, curr) => acc + Number(curr.count),
                  0
                );
                setPendingParkingAssignment(total);
                setEditDetails(data);
              });
            }}
            className='py-2 px-2 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
          >
            <Check />
            Save
          </button>
        </div>
      </Modal>
    );
  };

  return (
    <div
      className={`right-0 fixed shadow-lg flex ${
        isFullHeight ? 'h-[calc(100vh-64px)] bottom-0' : 'h-[calc(100vh-100px)]'
      } overflow-hidden ${
        isAdditionalDrawerOpen ? 'w-[1360px]' : 'w-[350px]'
      } bg-white border-t border-l border-[#E4E4E7] ${
        isInfoDrawerOpen ? 'translate-x-0' : 'translate-x-full'
      } transition-all duration-300 ease-in-out max-w-[calc(100vw-80px)]`}
    >
      {type !== 'Unit' && (
        <div className='flex flex-col w-[350px]'>
          <div className='relative p-4 pt-8'>
            {type === 'Storage' && (
              <div className='w-full text-xl font-semibold uppercase text-center'>
                {type}
              </div>
            )}
            {type === 'Parking' && (
              <div className='w-full text-xl font-semibold uppercase text-center'>
                Parking Stall
              </div>
            )}
            {type === 'Unit' && (
              <div className='w-full flex justify-between items-center pt-4 pl-2'>
                <div className='text-xl font-semibold uppercase text-center'>
                  {unitVariable || type}
                </div>
                <button
                  className='py-2.5 px-5 text-lg bg-[#18181B] text-white rounded-lg font-medium flex gap-2 items-center justify-center'
                  onClick={() =>
                    setIsAdditionalDrawerOpen(!isAdditionalDrawerOpen)
                  }
                >
                  <p className='mb-0'>
                    {!isAdditionalDrawerOpen ? 'View more' : 'See less'}
                  </p>
                </button>
              </div>
            )}
            <button
              className='absolute top-3 left-3'
              onClick={() => handleClose()}
            >
              <IoMdClose fontSize='22px' />
            </button>
          </div>
          <div className='flex-1 p-4 overflow-hidden'>
            {type === 'Unit' && (
              <UnitEditView
                editDetails={{ ...editDetails, inventoryType: type }}
                setEditDetails={setEditDetails}
                refetch={() => {
                  handleClose();
                  refetch();
                }}
                building={null}
                closeUnitInfoDrawer={handleClose}
                variableName={unitVariable}
              />
            )}
            {type === 'Parking' && (
              <ParkingEditView
                editDetails={{ ...editDetails, inventoryType: type }}
                setEditDetails={setEditDetails}
                refetch={() => {
                  handleClose();
                  refetch();
                }}
                building={null}
                closeParkingInfoDrawer={handleClose}
              />
            )}
            {type === 'Storage' && (
              <StorageEditView
                editDetails={{ ...editDetails, inventoryType: type }}
                setEditDetails={setEditDetails}
                refetch={() => {
                  handleClose();
                  refetch();
                }}
                building={null}
                closeStorageInfoDrawer={handleClose}
              />
            )}
          </div>
        </div>
      )}
      {isAdditionalDrawerOpen && (
        <>
          <div className='flex-1 border-l overflow-auto  border-[#E4E4E7] px-6 py-6 flex flex-col gap-6'>
            <div className='text-xl font-semibold uppercase text-center'>
              {unitVariable || type}
            </div>
            <button
              className='absolute top-3 left-3'
              onClick={() => handleClose()}
            >
              <IoMdClose fontSize='22px' />
            </button>
            <div className='w-fit'>
              <TabWrapper
                tabMenus={tabMenus}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
              />
            </div>
            {selectedTab === 'tab-1' && (
              <div className='flex-1 p-4 overflow-hidden'>
                {type === 'Unit' && (
                  <UnitEditView
                    editDetails={{ ...editDetails, inventoryType: type }}
                    setEditDetails={setEditDetails}
                    refetch={() => {
                      handleClose();
                      refetch();
                    }}
                    building={null}
                    closeUnitInfoDrawer={handleClose}
                    variableName={unitVariable}
                  />
                )}
                {type === 'Parking' && (
                  <ParkingEditView
                    editDetails={{ ...editDetails, inventoryType: type }}
                    setEditDetails={setEditDetails}
                    refetch={() => {
                      handleClose();
                      refetch();
                    }}
                    building={null}
                    closeParkingInfoDrawer={handleClose}
                  />
                )}
                {type === 'Storage' && (
                  <StorageEditView
                    editDetails={{ ...editDetails, inventoryType: type }}
                    setEditDetails={setEditDetails}
                    refetch={() => {
                      handleClose();
                      refetch();
                    }}
                    building={null}
                    closeStorageInfoDrawer={handleClose}
                  />
                )}
              </div>
            )}
            {selectedTab === 'tab-2' && (
              <div className='flex flex-col gap-10'>
                <div className='flex flex-col border border-[#E4E4E7] rounded-lg overflow'>
                  <div className='border-b border-[#E4E4E7] bg-[#F3F4F6] py-2 px-4 flex justify-between items-center'>
                    <div className='flex gap-2 items-center'>
                      <div className='bg-volt p-[6px] rounded-full'>
                        <KeyRound />
                      </div>
                      <h6 className='mb-0 text-sm font-semibold'>
                        {pendingParkingAssignment +
                          avesdoParkings.length +
                          avesdoWaitlistedParkings.length -
                          (assignedParkingTableDataSource.filter((item) => {
                            return item.isAssigned === true;
                          }).length || 0)}{' '}
                        Parking Spot Pending Assignment
                      </h6>
                    </div>
                  </div>
                  {avesdoWaitlistedParkings.length > 0 && (
                    <div className='p-4 flex flex-col gap-y-4 h-full'>
                      <p className='text-black text-lg font-semibold underline mb-0'>
                        Additional Parking Waitlist
                      </p>
                      <div>
                        {datesData?.map((unit) => {
                          return (
                            <p className='text-stone-950 text-lg font-bold mb-0'>
                              Joined Waitlist on {unit.formatedAcceptedDate}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex flex-col gap-4'>
                  <div className='flex gap-x-4 items-center'>
                    <h3 className='mb-0 text-3xl font-semibold'>
                      Allocated Parking
                    </h3>
                    <button
                      onClick={openAllocationModal}
                      className='py-2.5 px-4 text-sm font-medium bg-[#18181B] border border-[#18181B] text-white rounded-lg flex gap-2 items-center justify-center'
                    >
                      <Pen className='w-4 h-4' />
                      <p className='mb-0'>Edit</p>
                    </button>
                  </div>
                  <div className='flex items-center gap-7.5'>
                    {typesOfAllocatedParking.map((type) => (
                      <AllocationBadges key={type.id} type={type} />
                    ))}
                  </div>
                </div>
                <div className='flex flex-col mb-4 gap-4'>
                  <h3 className='mb-0 text-3xl font-semibold'>
                    Assigned Parking
                  </h3>
                  {assignedParkingTableDataSource.length === 0 ? (
                    <div className='flex flex-col items-start gap-4'>
                      <p className='mb-0 text-lg font-normal'>
                        No parking spots have been assigned yet.
                      </p>
                    </div>
                  ) : (
                    <div className='w-full'>
                      <CustomTable
                        className='rounded-lg'
                        dataSource={assignedParkingTableDataSource}
                        columns={tableColumns}
                        tailwindTable
                        loading={false}
                        idBeingDeleted={''}
                        deleting={false}
                        isFromContracts
                        showTablePagination={false}
                        noMargin
                      />
                    </div>
                  )}
                </div>
                {process.env.REACT_APP_AVESDO_FLAG === 'true' && (
                  <div className='flex flex-col gap-y-3'>
                    {/* <div className='flex flex-col mb-4 gap-4'>
                <h3 className='mb-0 text-3xl font-semibold'>Avesdo Parkings</h3>
                <CustomTable
                  className='rounded-lg'
                  dataSource={avesdoParkings}
                  columns={tableColumnsAvesdoParking}
                  tailwindTable
                  loading={false}
                  idBeingDeleted={''}
                  deleting={false}
                  isFromContracts
                  showTablePagination={false}
                  noMargin
                />
              </div> */}
                    {avesdoUnAssignedWaitlistParkings.length > 0 && (
                      <div className='flex flex-col mb-4 gap-4'>
                        <h3 className='mb-0 text-3xl font-semibold'>
                          Parking waitlists
                        </h3>
                        <CustomTable
                          className='rounded-lg'
                          dataSource={avesdoUnAssignedWaitlistParkings}
                          columns={tableColumnsAvesdoParking}
                          tailwindTable
                          loading={false}
                          idBeingDeleted={''}
                          deleting={false}
                          isFromContracts
                          showTablePagination={false}
                          noMargin
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {selectedTab === 'tab-3' && (
              <StorageAllocatePage
                type={type}
                selectedBuilding={selectedBuilding}
                refetch={refetch}
                editDetails={editDetails}
                setEditDetails={setEditDetails}
              />
            )}
            {selectedTab === 'tab-4' && (
              <>
                <div className='w-full flex mb-8 overflow-hidden flex-col gap-y-6'>
                  <ShadCNWrapper>
                    <div className='flex flex-col gap-y-[12px] p-6 w-full '>
                      <div className='text-2xl font-semibold'>Reservations</div>
                      <div className='overflow-auto mt-1'>
                        <table className='w-full '>
                          <tr className='text-[#777777] relative uppercase w-full'>
                            {headersForStatic.map((header, index) => {
                              return (
                                <th className='whitespace-nowrap' key={index}>
                                  <div className='leading-none text-left text-sm font-normal text-[#2E2B2E] text-opacity-70 capitalize'>
                                    {header.name}
                                  </div>
                                </th>
                              );
                            })}
                            <HorizontalLine className='absolute -bottom-1 left-0' />
                          </tr>
                          {reservationData === [] && 'No Data Available'}
                          {reservationData.map((reservation, index) => {
                            return (
                              <tr
                                key={index}
                                className='font-[300] pt-6 text-[#2E2B2E] text-[16px]'
                              >
                                <td className='text-[#2E2B2E] font-[400] '>
                                  #{index}
                                </td>
                                <td className='truncate'>
                                  {reservation.reservation_holders}
                                </td>
                                <td>{reservation?.lotChoiceName}</td>
                                <td className='text-[#2E2B2E] font-[400] '>
                                  {moment(
                                    reservation.manualReservationDate
                                      ? reservation.manualReservationDate
                                      : reservation.createdAt
                                  ).format('MM/DD/YYYY')}
                                </td>

                                <td className='max-w-[140px] truncate'>
                                  {reservation?.esscrowAgent?.escrowAgentData
                                    ?.companyName || '-'}
                                </td>
                                <td className='max-w-[140px] truncate'></td>

                                <td className='truncate'>
                                  {reservation?.notes ?? '-'}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                  </ShadCNWrapper>
                </div>
              </>
            )}
          </div>
        </>
      )}
      {allocationModal && <AllocationModal />}
      {assignParkingModal && <AssignParkingModal />}
      {editParkingModal && <EditParkingModal />}
    </div>
  );
};

export default InventoryEditDrawer;
