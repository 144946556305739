import { useMutation, useQueryClient } from '@tanstack/react-query'
import { createList, updateUserListsNew } from 'store/actions/usersActions'
import { toast } from 'react-hot-toast'
import useContactContext from 'pages/newContacts/hooks/useContactContext'

const useListsMutation = () => {
  const client = useQueryClient()
  const { toggleCheckList, emptyCheckedSelections } = useContactContext()
  let id = null
  let updateId = null

  const onSuccess = (data) => {
    console.log(data)
    toast.dismiss(id)
    toggleCheckList(data?._id)
    toast.success('Successfully created list')
  }

  const onMutate = () => {
    id = toast.loading('please wait while we create a list for you')
  }

  const onError = () => {
    toast.dismiss(id)
    toast.error('Unable to create list')
  }

  const onUpdateSuccess = () => {
    toast.dismiss(updateId)
    toast.success('Successfully added selected users to lists')
    emptyCheckedSelections()
    client.invalidateQueries(['getLists'])
  }

  const onUpdateMutate = () => {
    updateId = toast.loading('adding selected users to lists')
  }

  const { mutateAsync } = useMutation(createList, { onSuccess, onMutate, onError })
  const { mutateAsync: addUsersToLists } = useMutation(updateUserListsNew, { onSuccess: onUpdateSuccess, onMutate: onUpdateMutate })
  return { createList: mutateAsync, addUsersToLists }
}

export default useListsMutation
