import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getBuildings = (id = '', isPublicPage) =>
  new Promise((resolve, reject) => {
    api.get(`buildings/${id}?isPublicPage=${isPublicPage}`).then((response) => {
      if (response.ok) {
        if (id === '') {
          resolve(response.data.data)
        } else {
          resolve(response.data)
        }
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const createOrUpdateBuilding = (props) =>
  new Promise((resolve, reject) => {
    const { _id, ...params } = props

    const formData = new FormData()

    Object.entries(params).forEach(([key, value]) => {
      formData.append(key, value)
    })

    // If id, so we have to update the record, or we're gonna create
    const verb = _id ? 'put' : 'post'
    const url = _id ? `buildings/${_id}` : 'buildings'

    api[verb](url, formData).then((response) => {
      if (response.ok) {
        message.success(
          `${params.name} was ${_id ? 'successfully updated' : 'created successfully'
          }`
        )
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteBuilding = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`/buildings/${id}`).then((response) => {
      if (response.ok) {
        message.success('Building deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getBuildingsByProjectId = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`/buildings/project/${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
