import { api, ApiErrors } from 'services/api'

export const createNewWorkflow = (data) => {
  return new Promise((resolve, reject) => {
    api.post('/workflows', data).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const createNewFormWorkflow = (formId, data) => {
  return new Promise((resolve, reject) => {
    api.post(`/workflows/form/${formId}`, data).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getWorkflows = (filters = {}) => {
  return new Promise((resolve, reject) => {
    let params = ''
    // @ts-ignore
    if (filters !== {} && filters !== null && filters !== undefined) {
      const tempFilters = Object.fromEntries(
        Object.entries(filters).filter(
          (elm) => elm[1] !== null && elm[1] !== '' && elm[1] !== undefined
        )
      )
      params = '?' + new URLSearchParams(tempFilters).toString()
    }

    api.get(`/workflows${params}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getWorkflow = (workflowId) => {
  return new Promise((resolve, reject) => {
    api.get(`/workflows/${workflowId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getFormWorkflow = (formId) => {
  return new Promise((resolve, reject) => {
    api.get(`/workflows/form/${formId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const updateWorkflow = (workflowId, data) => {
  return new Promise((resolve, reject) => {
    api.patch(`/workflows/${workflowId}`, data).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const deactivateWorkflow = (workflowId) => {
  return new Promise((resolve, reject) => {
    api.patch(`/workflows/${workflowId}`, {
      status: 'inactive'
    }).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const activateWorkflow = (workflowId) => {
  return new Promise((resolve, reject) => {
    api.patch(`/workflows/${workflowId}`, {
      status: 'active'
    }).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const publishWorkflow = (workflowId) => {
  return new Promise((resolve, reject) => {
    api.post(`/workflows/${workflowId}/publish`, {}).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const saveWorkflowDraft = (workflowId, data) => {
  return new Promise((resolve, reject) => {
    api.patch(`/workflows/${workflowId}/draft`, data).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getWorkflowTriggers = (projectId) => {
  return new Promise((resolve, reject) => {
    let query = ''
    if (projectId) {
      query = `projectId=${projectId}`
    }
    api.get(`/workflows/triggers?${query}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getWorkflowActions = (projectId) => {
  return new Promise((resolve, reject) => {
    let query = ''
    if (projectId) {
      query = `projectId=${projectId}`
    }
    api.get(`/workflows/actions?${query}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getWorkflowLogs = (workflowId) => {
  return new Promise((resolve, reject) => {
    api.get(`/workflows/${workflowId}/logs`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getWorkflowActionsLogs = (workflowId, workflowInstanceId) => {
  return new Promise((resolve, reject) => {
    api.get(`/workflows/${workflowId}/${workflowInstanceId}/actions/logs`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}
