/* eslint-disable space-before-function-paren */
import CustomButton from 'components/CustomButton'
import TableGrid from 'components/TableGrid/Table'
import { ArrowLeft } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { api } from 'services/api'
import CreateListFromUser from './createList'

export default function SMSBroadcastDetails() {
  const [loading, setLoading] = useState(true)
  const [broadCastData, setBroadCastData] = useState(null)
  const randomId = ''
  useEffect(() => {
    const url = window.location.pathname
    const id = url.split('/').pop()
    api.get(`/sms/campaigns/${id}`).then((response) => {
      setBroadCastData(response.data)
      setLoading(false)
    }).catch((error) => {
      console.log(error)
      alert(error)
    })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  const broadCastUsers = broadCastData?.users
  const broadCastReport = broadCastData?.report

  const users = []
  if (broadCastUsers && broadCastUsers.length > 0) {
    broadCastUsers.forEach((item) => {
      users.push({
        userId: item.userId,
        name: item.name,
        phoneNumber: item.phoneNumber
      })
    })
  } else {
    broadCastReport.forEach((item) => {
      const userId = typeof item.user === 'object' ? item.user?._id?.toString() : item?.user?.toString()
      users.push({
        userId,
        name: item.name,
        phoneNumber: item.ToNumber
      })
    })
  }
  const usersWithStatus = users.map((item) => {
    const reportItem = broadCastReport.find((reportItem) => {
      if (reportItem.user) {
        const reportUserId = typeof reportItem.user === 'object' ? reportItem.user?._id?.toString() : reportItem?.user?.toString()
        return reportUserId === item.userId
      } else {
        return false
      }
    })
    if (!reportItem) {
      console.log('No report item found for user:', item)
      return {
        ...item,
        name: item.name || '-',
        phoneNumber: item.phoneNumber,
        status: 'pending',
        success: false,
        pending: true,
        notes: 'Pending',
        notesColor: 'text-yellow-500'
      }
    }
    const reportUserName = typeof reportItem?.user === 'object' ? reportItem?.user?.fullName : ''
    let finalName = (item.name || reportUserName || '-').trim()
    if (finalName === '') {
      finalName = '-'
    }
    const status = reportItem?.status || '-'
    const reportMessage = reportItem?.message || ''
    let notes = ''
    let success = false
    let pending = false
    let notesColor = ''
    if (status === 'undelivered') {
      notes = reportMessage || 'Undelivered'
      success = false
      notesColor = 'text-red-500'
    } else if (status === 'delivered') {
      notes = reportMessage || 'Delivered'
      success = true
      notesColor = 'text-green-500'
    } else if (status === 'sent') {
      notes = reportMessage || 'Sent'
      success = true
      notesColor = 'text-blue-500'
    } else if (status === 'delivery_unknown') {
      notes = reportMessage || 'Delivery Unknown'
      notesColor = 'text-yellow-500'
      success = false
    } else if (status === 'failed') {
      notes = reportMessage || 'Failed'
      notesColor = 'text-red-500'
      success = false
    } else if (status === 'accepted') {
      notes = reportMessage || 'Accepted'
      notesColor = 'text-blue-500'
    } else if (status === 'scheduled') {
      notes = reportMessage || 'Scheduled'
      notesColor = 'text-blue-500'
      success = false
    } else if (status === 'queued') {
      notes = reportMessage || 'Queued'
      notesColor = 'text-blue-500'
      success = false
    } else if (status === 'sending') {
      notes = reportMessage || 'Sending'
      notesColor = 'text-blue-500'
      success = false
    } else {
      if (!status) {
        notes = reportMessage || 'Pending'
        notesColor = 'text-yellow-500'
        success = false
        pending = true
      } else {
        notes = reportMessage || status
        notesColor = 'text-blue-500'
        success = false
      }
    }
    const result = {
      ...item,
      name: finalName,
      phoneNumber: reportItem?.ToNumber || item.phoneNumber,
      status,
      success,
      pending,
      notes,
      notesColor
    }
    console.log(result)
    return result
  })

  const gridData = [{
    title: 'Selected Contacts',
    allowList: false,
    listTitle: `${broadCastData.campaignName} - Selected Contacts for SMS Campaign ${randomId}`,
    value: broadCastUsers.length || broadCastReport.length,
    className: '',
    users: broadCastUsers
  }, {
    title: 'Delivered',
    allowList: false,
    listTitle: `${broadCastData.campaignName} - Delivered SMS Campaign Users ${randomId}`,
    value: usersWithStatus.filter((item) => item.success).length,
    className: 'text-green-500',
    users: usersWithStatus.filter((item) => item.success)
  }, {
    title: 'Pending',
    allowList: true,
    listTitle: `${broadCastData.campaignName} - Pending SMS Campaign Users ${randomId}`,
    value: usersWithStatus.filter((item) => item.pending).length,
    className: 'text-yellow-500',
    users: usersWithStatus.filter((item) => item.pending)
  }, {
    title: 'Failed',
    allowList: true,
    listTitle: `${broadCastData.campaignName} - Failed SMS Campaign Users ${randomId}`,
    value: usersWithStatus.filter((item) => !item.success && !item.pending).length,
    className: 'text-red-500',
    users: usersWithStatus.filter((item) => !item.success && !item.pending)
  }]

  const groupByStatus = usersWithStatus.reduce((acc, item) => {
    if (!acc[item.status]) {
      acc[item.status] = 0
    }
    acc[item.status] += 1
    return acc
  }, {
    delivered: 0,
    undelivered: 0,
    failed: 0,
    sent: 0,
    delivery_unknown: 0,
    pending: 0
  })

  return (
    <div className='px-8 py-4 sm:px-16 sm:py-8'>
      <div className='pb-3'>
        <div>
          <CustomButton
            className='mb-2 py-1 px-2'
            handleClick={() => {
              window.history.back()
            }}
          >
            <ArrowLeft size={16} /> Go Back
          </CustomButton>
        </div>
        <div className='font-bold text-2xl'>
          {broadCastData.campaignName}
        </div>
        <div>
          {new Date(broadCastData.createdAt).toLocaleString()}
        </div>
      </div>
      {broadCastData.message && (
        <div className='mb-1.5 bg-white p-3 rounded-md shadow-sm'>
          <div className='text-lg font-semibold'>
            Message Content ({String(broadCastData.message.length)} characters)
          </div>
          <div className='border border-border px-1'>
            <pre className='py-1 overflow-y-auto max-h-[150px]'>
              {broadCastData.message}
            </pre>
          </div>
        </div>
      )}
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-1 sm:gap-2'>
        {gridData.map((item, index) => {
          return (
            <div key={index} className={`flex justify-between items-center border border-border rounded-md shadow-sm px-4 py-3.5 my-2 bg-white ${item.className}`}>
              <div>
                <div className='font-bold'>
                  {item.title}
                </div>
                <div className='font-extrabold text-2xl'>
                  {item.value}
                </div>
              </div>
              <div>
                {item.allowList && item?.users?.length > 0 &&
                  <CreateListFromUser defaultListName={item.listTitle} users={item.users} />
                }
              </div>
            </div>
          )
        })}
      </div>
      <div className='my-3'>
        <div className='text-lg'>
          SMS by Status
        </div>
        <div className='flex flex-wrap gap-x-2'>
          {Object.keys(groupByStatus)
            .map((key, index) => {
              return (
                <div key={index} className='rounded-sm border border-border flex items-center justify-between border-b border-border py-2 px-4 gap-y-4 flex-1'>
                  <div className='capitalize'>
                    {key}
                  </div>
                  <div className='font-bold'>
                    {groupByStatus[key]}
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div>
        <TableGrid
          pagination={false}
          getData={() => {
            return new Promise((resolve) => {
              resolve(usersWithStatus)
            })
          }}
          columnDefs={[
            {
              headerName: 'Name',
              field: 'name',
              sortable: true,
              filter: true,
              flex: 1
            },
            {
              headerName: 'Phone Number',
              field: 'phoneNumber',
              sortable: true,
              filter: true,
              flex: 1
            },
            {
              headerName: 'Status',
              field: 'status',
              sortable: true,
              filter: true,
              flex: 1
            }, {
              headerName: 'Notes',
              field: 'notes',
              sortable: true,
              filter: true,
              flex: 1,
              cellRenderer: ({ data }) => {
                return <span className={data.notesColor}>{data.notes}</span>
              }
            }
          ]}
        />
      </div>
    </div >
  )
}
