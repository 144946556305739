// @ts-nocheck
/* eslint-disable */

import { Badge } from "components/ShadcnComponents/badge"
import CustomSelect from "../../../pages/reservation/NewReservation/components/CustomSelect"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "components/ShadcnComponents/card"
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "components/ShadcnComponents/chart"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ShadcnComponents/table"
import { ArrowDown, ArrowUp } from "lucide-react"
import React, { useEffect, useMemo, useState } from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from "recharts"
import { getAllWebsiteConfig } from "store/actions/websiteReportConfigActions"
import { Button } from "components/ShadcnComponents/button"
import { ADMIN_ADS_REPORT_CONFIGS } from "routes"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import AdminWebsiteTrafficReport from "../websiteTrafficReport"
import useContactContext from "pages/newContacts/hooks/useContactContext"
import { PageLoader } from "pages/contractTemplates"
import useGoogleAdsAccounts from "hooks/useGoogleAdsAccounts"
import { getGoogleAdsReportById } from "store/actions/googleAdsActions"
import TableWithPagination from "components/TableWithPagination"

const MetricCard = ({ title, value }) => (
  <Card className="p-3 flex flex-col gap-3 bg-white border-0">
    <CardHeader className="flex flex-row items-center justify-between !space-y-0 p-0">
      <CardTitle className="text-sm font-semibold mb-0 text-zinc-500">
        {title}
      </CardTitle>
    </CardHeader>
    <CardContent className="p-0">
      <div className="text-xl font-bold">{value}</div>
    </CardContent>
  </Card>
)

const AdminGoogleAdsReports = ({ isDashboard = false }) => {
  const history = useHistory()
  const [selectedAdAccountId, setSelectedAdAccountId] = useState(null)
  const [currentAdAccountCurrencyCode, setCurrentAdAccountCurrencyCode] = useState(null)
  const listOfProjects = useSelector((state) => state.appReducer.listOfProjects)
  const projectId = useSelector((state) => state.appReducer.appProject)
  const userObject = useSelector((state) => state.authReducer.userObject)
  const { view } = useContactContext()
  const [projectList, setProjectList] = useState([])
  const [selectedProjectId, setSelectedProjectId] = useState(null)
  const [reportData, setReportData] = useState([])
  const [isReportDataLoading, setIsReportDataLoading] = useState(false)
  const { loading: isAdsAccountsLoading, accounts, googleAdsConnectionId } = useGoogleAdsAccounts(selectedProjectId || projectId)
  const [metrics, setMetrics] = useState([])

  const [chartData, setChartData] = useState(null)
  const [totalValues, setTotalValues] = useState(null)
  const [totalConversionRate, setTotalConversionRate] = useState(null)

  useEffect(() => {
    let tempMapped = listOfProjects.map((item) => {
      return {
        label: item.projectName,
        value: item._id,
        _id: item._id,
      }
    })

    if (tempMapped?.length > 0 && view === "corporate") {
      setSelectedProjectId(tempMapped?.[0]?._id)
    }

    setProjectList(tempMapped)
  }, [listOfProjects, view])

  const accountOptions = useMemo(() => {
    const tempAccountList = accounts?.filter((account) => (account?.status === "enabled"))?.map((account) => ({
      label: account.name,
      value: account.id,
      _id: account.id,
    })) || []

    if (tempAccountList?.length) {
      setSelectedAdAccountId(tempAccountList[0]._id)
      setCurrentAdAccountCurrencyCode(accounts?.filter((account) => (account?.status === "enabled"))?.[0]?.currencyCode)
    }
    return tempAccountList
  }, [accounts, projectId])

  const getAdsReport = async () => {
    try {
      setIsReportDataLoading(true)
      const response = await getGoogleAdsReportById(
        googleAdsConnectionId,
        selectedAdAccountId,
      )
      if (response?.data?.length) {
        setReportData(response.data)
        setMetrics(calculateMetrics(response.data, currentAdAccountCurrencyCode))
        setChartData(processChartData(response.data))
        const totals = calculateTotals(response.data)
        setTotalValues(totals)
        setTotalConversionRate((totals.totalConversions / totals.totalClicks) * 100)
      }
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setIsReportDataLoading(false)
    }
  }

  useEffect(() => {
    if (googleAdsConnectionId && selectedAdAccountId) {
      getAdsReport()
    }
  }, [googleAdsConnectionId, selectedAdAccountId, currentAdAccountCurrencyCode])

  const columnDefs = [
    {
      headerName: "Name",
      flex: 1,
      minWidth: 400,
      field: "name",
      sortable: true,
      filter: true,
      pinned: 'left'
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      cellRenderer: ({ value }) => (
        <Badge variant={getStatusBadgeVariant(value)}>
          {getStatusText(value)}
        </Badge>
      ),
    },
    {
      headerName: "Daily budget",
      field: "budget",
      sortable: true,
      cellRenderer: ({ value }) => (
        <>
          {formatMoney(value.amount.toFixed(2), currentAdAccountCurrencyCode)}
        </>
      )
    },
    {
      headerName: "Spent",
      field: "metrics",
      sortable: true,
      cellRenderer: ({ value }) => (
        <>
          {formatMoney(value.cost.toFixed(2), currentAdAccountCurrencyCode)}
        </>
      )
    },
    {
      headerName: "Clicks",
      field: "metrics",
      sortable: true,
      cellRenderer: ({ value }) => (
        <>
          {value.clicks.toLocaleString()}
        </>
      )
    },
    {
      headerName: "Conversions",
      field: "metrics",
      sortable: true,
      cellRenderer: ({ value }) => (
        <>
          {Math.round(value.conversions || 0)}
        </>
      )
    },
  ]

  return (
    <div className={`flex flex-col gap-4 container min-h-full w-full pb-6 ${isDashboard ? "pb-6" : "py-6"}`} >
      <div className="flex flex-col gap-3">
        {!isDashboard && (
          <p className="text-2xl font-semibold">Website Ads Reports</p>
        )}
        <div className="flex justify-between">
          {view === "corporate" && (
            <div className="flex flex-col gap-1">
              <div className={`font-bold ${isDashboard && "text-white"}`}>
                Select Project
              </div>
              <CustomSelect
                value={selectedProjectId}
                handleChange={setSelectedProjectId}
                options={projectList}
                showSearch
                placeholder="Select Project"
                className="w-[300px]"
                disabled={isReportDataLoading || isAdsAccountsLoading}
              />
            </div>
          )}
          {accounts?.length > 0 && (
            <div className="flex flex-col gap-1">
              <div className={`font-bold ${isDashboard && "text-white"}`}>
                Select Account
              </div>
              <CustomSelect
                value={selectedAdAccountId}
                handleChange={(value) => {
                  setSelectedAdAccountId(value)
                  setCurrentAdAccountCurrencyCode(accounts?.filter((account) => (account?.id === value))?.[0]?.currencyCode || null)
                }}
                options={accountOptions}
                showSearch
                placeholder="Select Account"
                className="w-[300px]"
                disabled={isReportDataLoading || isAdsAccountsLoading}
              />
            </div>
          )}
        </div>
      </div>

      {
        !(isReportDataLoading || isAdsAccountsLoading)
          ? <>
            {accounts?.length > 0 ? (
              <div className="flex flex-col gap-4">
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                  {metrics.map((metric, index) => (
                    <MetricCard
                      key={index}
                      title={metric.title}
                      value={metric.value}
                    />
                  ))}
                </div>

                <Card className="p-3 flex flex-col gap-4 bg-white border-0">
                  <CardHeader className="p-0">
                    <CardTitle className="text-2xl">Campaign Performance</CardTitle>
                  </CardHeader>
                  <CardContent className="p-0">
                    <ChartGroup
                      chartData={chartData}
                      totalClicks={totalValues.totalClicks}
                      totalBudget={totalValues.totalBudget}
                      totalSpend={totalValues.totalSpend}
                      totalConversionRate={totalConversionRate}
                      currencyCode={currentAdAccountCurrencyCode}
                    />
                  </CardContent>
                </Card>

                <Card className="p-0 flex flex-col bg-white border-0">
                  <CardHeader className="p-3 border-b">
                    <CardTitle className="text-2xl">Recent Campaigns</CardTitle>
                  </CardHeader>
                  <CardContent className="p-0">
                    <div className="ag-theme-alpine" style={{ width: "100%" }}>
                      <TableWithPagination
                        columns={columnDefs}
                        rowData={reportData}
                        rowHeight={50}
                        hidePagination
                        loading={isReportDataLoading}
                      />
                    </div>
                  </CardContent>
                </Card>
              </div>
            ) : (
              <div className="flex-1 flex justify-center items-center flex-col h-[calc(100vh-200px)] bg-white !rounded-[10px]">
                {userObject.userType !== "SalesRep" ? (
                  <>
                    <p>No Config is being set for site, Please do it now</p>
                    <Button onClick={() => history.push(ADMIN_ADS_REPORT_CONFIGS)}>
                      Set Configs
                    </Button>
                  </>
                ) : (
                  <p>
                    No Config is being set for site, Please contact admin to do so
                  </p>
                )}
              </div>
            )}
          </>
          : <PageLoader />
      }
    </div>
  )
}

export default AdminGoogleAdsReports

const calculateMetrics = (data, currencyCode) => {
  const totalCampaigns = data.length;
  const totalSpend = data.reduce((sum, campaign) => sum + campaign.metrics.cost, 0);
  const totalClicks = data.reduce((sum, campaign) => sum + campaign.metrics.clicks, 0);
  const totalConversions = data.reduce((sum, campaign) => sum + (campaign.metrics.conversions || 0), 0);
  const conversionRate = totalClicks > 0 ? (totalConversions / totalClicks) * 100 : 0;

  return [
    {
      title: "Total Campaigns",
      value: totalCampaigns,
    },
    {
      title: "Total Spend",
      value: formatMoney(totalSpend.toFixed(2), currencyCode),
    },
    {
      title: "Total Clicks",
      value: totalClicks.toLocaleString(),
    },
    {
      title: "Conversion Rate",
      value: `${conversionRate.toFixed(2)}%`,
    }
  ];
}

const getStatusBadgeVariant = (status) => {
  switch (status) {
    case 2:
      return "success";
    case 3:
      return "pending";
    default:
      return "outline";
  }
}

const getStatusText = (status) => {
  switch (status) {
    case 2:
      return "Active";
    case 3:
      return "Paused";
    default:
      return "Unknown";
  }
}

export const formatMoney = (amount, currencyCode = '') => {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
    }).format(amount);
  } catch (error) {
    console.error("Invalid currency code:", currencyCode, error);
    return amount;
  }
}

const processChartData = (data) => {
  return data.map(campaign => ({
    name: campaign.name,
    clicks: campaign.metrics.clicks,
    budget: campaign.budget.amount,
    spend: campaign.metrics.cost,
    conversionRate: campaign.metrics.conversions / campaign.metrics.clicks * 100
  }));
}

const calculateTotals = (data) => {
  return data.reduce((acc, campaign) => {
    acc.totalClicks += campaign.metrics.clicks;
    acc.totalBudget += campaign.budget.amount;
    acc.totalSpend += campaign.metrics.cost;
    acc.totalConversions += campaign.metrics.conversions;
    return acc;
  }, {
    totalClicks: 0,
    totalBudget: 0,
    totalSpend: 0,
    totalConversions: 0
  });
}

const ChartGroup = ({
  chartData,
  totalClicks,
  totalBudget,
  totalSpend,
  totalConversionRate,
  isFetching = false,
  currencyCode
}) => {
  const [activeChart, setActiveChart] = React.useState("clicks")

  const chartConfig = {
    clicks: {
      label: "Clicks",
      color: "hsl(var(--chart-1))",
    },
    budget: {
      label: "Budget",
      color: "hsl(var(--chart-2))",
    },
    spend: {
      label: "Spend",
      color: "hsl(var(--chart-3))",
    },
    conversionRate: {
      label: "Conversion Rate",
      color: "hsl(var(--chart-4))",
    },
  }

  const formatLabel = (value) => {
    return new Date(value).toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    })
  }

  return (
    <Card className="md:col-span-2 flex flex-col gap-5 bg-white">
      <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
        <div className="w-full grid grid-cols-4">
          {Object.keys(chartConfig).map((key) => {
            return (
              <div
                key={key}
                data-active={activeChart === key}
                className="flex flex-1 flex-col justify-center gap-1 p-3 text-left odd:border-none even:border-x data-[active=true]:bg-zinc-100 sm:border-t-0 sm:px-5 sm:py-4 cursor-pointer"
                onClick={() => setActiveChart(key)}
              >
                <span className="text-xs">{chartConfig[key].label}</span>
                <span className="text-lg font-bold leading-none md:text-2xl">
                  {key === "clicks" && totalClicks.toLocaleString()}
                  {key === "budget" && formatMoney(totalBudget.toFixed(2), currencyCode)}
                  {key === "spend" && formatMoney(totalSpend.toFixed(2), currencyCode)}
                  {key === "conversionRate" && `${totalConversionRate.toFixed(2)}%`}
                </span>
              </div>
            )
          })}
        </div>
      </CardHeader>
      {isFetching ? (
        <div className="h-[414px] w-full flex justify-center items-center">
          <span className="text-base dark:text-white">Loading...</span>
        </div>
      ) : (
        <>
          {chartData?.length > 0 ? (
            <CardContent className="px-2 py-3.5">
              <ChartContainer
                config={chartConfig}
                className="h-[400px] w-full"
                id="campaignMetricsChart"
              >
                <BarChart
                  accessibilityLayer
                  data={chartData}
                  margin={{
                    left: 12,
                    right: 12,
                    bottom: 100
                  }}
                >
                  <CartesianGrid vertical={false} />
                  <XAxis
                    dataKey="name"
                    tickLine={false}
                    axisLine={false}
                    tickMargin={8}
                    interval={0}
                    tick={{ angle: -45, textAnchor: 'end', fontSize: 12 }}
                  />
                  {activeChart === "conversionRate" ? (
                    <YAxis
                      domain={[0, 100]}
                      tickLine={false}
                      axisLine={false}
                      tickFormatter={(value) => `${value}%`}
                    />
                  ) : (
                    <YAxis
                      tickLine={false}
                      axisLine={false}
                    />
                  )}
                  <ChartTooltip
                    content={
                      <ChartTooltipContent
                        className="w-[150px]"
                      />
                    }
                  />
                  <Bar
                    dataKey={activeChart}
                    fill={`var(--color-${activeChart})`}
                  />
                </BarChart>
              </ChartContainer>
            </CardContent>
          ) : (
            <div className="text-center h-[414px] w-full text-base font-medium text-gray-400 flex justify-center items-center">
              There's no information to display at this time
            </div>
          )}
        </>
      )}
    </Card>
  )
}
