import { useState, useEffect } from 'react'
import { getAllGoogleAdsAccounts, getAllGoogleAdsCustomerAccounts } from 'store/actions/googleAdsActions'

const useGoogleAdsAccounts = (projectId) => {
  const [loading, setLoading] = useState(true)
  const [accounts, setAccounts] = useState([])
  const [googleAdsConnectionId, setGoogleAdsConnectionId] = useState('')

  useEffect(() => {
    if (projectId) {
      getData()
    }
  }, [projectId])

  const getData = async () => {
    setLoading(true)
    try {
      const googleAdsConnection = await getAllGoogleAdsAccounts(projectId)

      const connectionId = googleAdsConnection?.[0]?._id || null
      setGoogleAdsConnectionId(connectionId)

      if (connectionId) {
        const data = await getAllGoogleAdsCustomerAccounts(connectionId)
        setAccounts(data?.data || [])
      } else {
        setAccounts([])
      }

      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return { loading, accounts, refetch: getData, googleAdsConnectionId }
}

export default useGoogleAdsAccounts
