// @ts-nocheck
import React, { useState } from 'react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ShadcnComponents/card'
import { Button } from 'components/ShadcnComponents/button'
import { FileText } from 'lucide-react'
import Checkbox from 'pages/offer/components/Checkbox'

const ReportBuilderView = () => {
  const [activeTab, setActiveTab] = useState('crm')
  return (
    <div className='grid md:grid-cols-2 gap-6 mt-4'>
      <div className='bg-white shadow-sm !rounded-lg p-4 border'>
        <CardTitle className='text-2xl font-bold mb-2'>Generate Report</CardTitle>
        <CardDescription className='mb-6'>Select options to customize your report</CardDescription>

        <Card className='bg-zinc-100 shadow-sm !rounded-lg !p-4'>
          <CardHeader className='p-0'>
            <CardTitle className='text-base font-medium'>Report Configuration Summary</CardTitle>
          </CardHeader>
          <CardContent className='grid gap-3 p-0'>
            <div>
              <h4 className='text-sm font-medium text-blue-600 mb-1'>CRM Functions</h4>
              <p className='text-sm text-gray-600'>
                Inventory, Compliance, Sales Transactions, Workflows, SMS Marketing
              </p>
            </div>
            <div>
              <h4 className='text-sm font-medium text-green-600 mb-1'>Data Sources</h4>
              <p className='text-sm text-gray-600'>None selected</p>
            </div>
            <div>
              <h4 className='text-sm font-medium text-purple-600 mb-1'>Recipients</h4>
              <p className='text-sm text-gray-600'>None added</p>
            </div>
            <div>
              <h4 className='text-sm font-medium text-orange-600 mb-1'>Schedule</h4>
              <p className='text-sm text-gray-600'>Weekly on friday at 16:00</p>
            </div>
            <div>
              <h4 className='text-sm font-medium text-red-600 mb-1'>Export Format</h4>
              <p className='text-sm text-gray-600'>PDF</p>
            </div>
          </CardContent>
        </Card>

        <div className='mt-6'>
          <div className='flex border-b'>
            <button
              onClick={() => setActiveTab('crm')}
              className={`px-4 py-2 text-sm font-medium ${activeTab === 'crm' ? 'text-primary bg-muted' : 'text-muted-foreground'
                }`}
            >
              CRM Functions
            </button>
            <button
              onClick={() => setActiveTab('data')}
              className={`px-4 py-2 text-sm font-medium ${activeTab === 'data' ? 'text-primary bg-muted' : 'text-muted-foreground'
                }`}
            >
              Data Sources
            </button>
            <button
              onClick={() => setActiveTab('delivery')}
              className={`px-4 py-2 text-sm font-medium ${activeTab === 'delivery' ? 'text-primary bg-muted' : 'text-muted-foreground'
                }`}
            >
              Delivery
            </button>
            <button
              onClick={() => setActiveTab('export')}
              className={`px-4 py-2 text-sm font-medium ${activeTab === 'export' ? 'text-primary bg-muted' : 'text-muted-foreground'
                }`}
            >
              Export Format
            </button>
          </div>

          <div className='grid gap-4 p-4'>
            <div className='flex items-center space-x-2'>
              <Checkbox label='Inventory' checked />
            </div>
            <div className='flex items-center space-x-2'>
              <Checkbox label='Compliance' checked />
            </div>
            <div className='flex items-center space-x-2'>
              <Checkbox label='SMS Marketing' checked />
            </div>
            <div className='flex justify-between'>
              <div className='flex items-center space-x-2'>
                <Checkbox label='Sales Transactions' checked />
              </div>
              <div className='flex items-center space-x-2'>
                <Checkbox label='Workflows' checked />
              </div>
            </div>
          </div>

          <div className='flex gap-4 mt-4'>
            <Button className='flex-1 bg-[#14171F] hover:bg-[#14171F]/90'>
              <FileText className='mr-2 h-4 w-4' />
              Standard Report
            </Button>
            <Button className='flex-1' variant='outline'>
              <FileText className='mr-2 h-4 w-4' />
              Generate PDF
            </Button>
          </div>
        </div>
      </div>

      <div className='bg-white shadow-sm !rounded-lg p-4 border'>
        <CardTitle className='text-2xl font-bold mb-2'>Report Preview</CardTitle>
        <CardDescription className='mb-6'>Preview of the report based on your selections.</CardDescription>

        <Card className='h-[600px] flex items-center justify-center text-zinc-500 bg-zinc-100 border-none'>
          <p className='text-center'>Click on 'Standard Report' or 'Generate PDF' to generate a report preview.</p>
        </Card>
      </div>
    </div>
  )
}

export default ReportBuilderView
