// @ts-nocheck
/* eslint-disable */
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import useContactContext from "../hooks/useContactContext";
import SearchBox from "./SearchBox/SearchBox";
import FilterToggle from "./FilterToggle/FilterToggle";
import { HiOutlineDownload } from "react-icons/hi";
import UserTable from "./UserTable/UserTable";
import ContactModal from "./ContactModal/ContactModal";
import FilterDrawer from "../FilterDrawer/FilterDrawer";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { AiFillCaretUp } from "react-icons/ai";
import { getLeadsCountByProject } from "store/actions/usersActions";
import UserTableV2 from "./UserTable/UserTableV2";
import ContactModalV2 from "./ContactModal/ContactModalV2";
import useContactContextV2 from "../hooks/useContactContextV2";
import { api } from "services/api";
import { useParams } from "react-router-dom";
import { useGetLeadsCount } from "hooks/useGetLeadsCount";
import { ContactContext } from "pages/contacts/context/Contacts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Skeleton from "react-loading-skeleton";

const ContactsTableV2 = () => {
  const {
    state,
    openAddToListDrawer,
    openAssignSalesRep,
    refetchFilteredUser,
    setFilters,
    setSearch,
    resetFilters,
    setState,
    closeContactDetailDrawer,
    view,
  } = useContactContext();

  const {
    setSelectedContact,
  } = useContext(ContactContext);
  const history = useHistory();
  const show = useSelector((state) => state.appReducer.show);
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [leadsCountData, setLeadsCountData] = useState([]);
  const [tempDuplicate, setTempDuplicate] = useState(false);
  const [totalLeads, setTotalLeads] = useState(0);
  const [last30DaysContacts, setLast30DaysContacts] = useState(0);
  const [duplicateContacts, setDuplicateContacts] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const buttonsList = [
    // { title: 'Change Status' },
    // { title: 'Assignments' },
    { title: "Manage List", onClick: () => openAddToListDrawer() },
    { title: "Assign Sales Rep", onClick: () => openAssignSalesRep() },
    // { title: 'Send Email' },
    // { title: 'Archive' }
  ];

  useEffect(()=>{
    if(!state.filters.leadStatus){
      setSelectedStatus(null)
    }
  },[state.filters])

  useEffect(() => {
    const fetchLeadsCount = async () => {
      setIsLoading(true);
      try {
        let data;
        if (view === "corporate") {
          data = await getLeadsCountByProject('');
        } else {
          data = await getLeadsCountByProject(state.filters?.project);
        }
        const response = await api.get(
          `/reports/contacts/count?projectId=${projectId}`
        );
        let total = 0;
        data.forEach((item) => (total += item.count));
        let map = data.map((item) => {
          if (item?._id === null || item?._id === undefined) {
            return {
              ...item,
              _id: "Unassigned  ",
            }
          } else {
            return item
          }
        })
        setLeadsCountData([...map]);
        setLast30DaysContacts(response?.data?.last30DaysContacts);
        if (view === "corporate") {
          setTotalLeads(response?.data?.allContacts);
        } else {
          setTotalLeads(total);
        }
      } catch (error) {
        console.error("Error fetching leads count:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchLeadsCount();
  }, [projectId, refetchFilteredUser, state.filters?.project, state.contacts, view]);

  const colors = [
    { text: "#dc2626", bg: "#FDE8E8" }, // Red
    { text: "#f97316", bg: "#FFEDD5" }, // Orange
    { text: "#16a34a", bg: "#D1FAE5" }, // Green
    { text: "#2563eb", bg: "#DBEAFE" }, // Blue
    { text: "#7c3aed", bg: "#EDE9FE" }, // Purple
    { text: "#171717", bg: "#D1D5DB" }, // Dark Grey
    { text: "#facc15", bg: "#FEF9C3" }, // Yellow
    { text: "#e11d48", bg: "#FEE2E2" }, // Pink
  ];

  const leadStatusBadgeColorFinder = useCallback((title) => {
    if (
      title.includes("Attempted To Contact") ||
      title.includes("attempted to Contact") ||
      title.includes("Attempted to contact")
    ) {
      return colors[0];
    }

    if (title.includes("Connected") || title.includes("connected")) {
      return colors[1];
    }

    if (title === "New" || title === "new") {
      return colors[2];
    }
    if (title.includes("Open Deal") || title.includes("open deal")) {
      return colors[3];
    }
    if (title.includes("Reservation") || title.includes("reservation")) {
      return colors[4];
    }
    if (title.includes("Unqualified") || title.includes("unqualified")) {
      return colors[5];
    }
    if (title.includes("New Lead") || title.includes("new lead")) {
      return colors[6];
    }

    if (title.includes("Owner") || title.includes("owner")) {
      return colors[7];
    }
    if (title.includes("Last 30 Days")) {
      return {
        text: "#111928",
        bg: "#E5E7EB",
      };
    }
  }, []);

  const color = [
    "#dc2626",
    "#f97316",
    "#16a34a",
    "#2563eb",
    "#7c3aed",
    "#171717",
    "#facc15",
    "#e11d48",
  ];

  useEffect(() => {
    const fetchLast30DaysContacts = async () => {
      try {
        const response = await api.get(
          `/reports/contacts/count?projectId=${projectId}`
        );
        setLast30DaysContacts(response?.data?.last30DaysContacts || 0);
      } catch (error) {
        console.error("Error fetching last 30 days contacts:", error);
      }
    };

    if (projectId) {
      fetchLast30DaysContacts();
    }
  }, [projectId]);

  /* useEffect(() => {
    const getDuplicateContacts = async () => {
      try {
        let url = `/users/contacts/duplicates/count?projectId=${projectId}`;
        if(view === 'corporate') {
          url = `/users/contacts/duplicates/count?projectId=`
        }
        const response = await api.get(
          url
        );
        setDuplicateContacts(response?.data?.totalDuplicates || 0);
      } catch (error) {
        console.error(rror);
      }
    };

    if (projectId) {
      getDuplicateContacts();
    }
  }, [projectId]); */

  
  const handleContainerClick = (event) => {
    if (!state.isContactDetailDrawerOpen) return;
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('_id');
      const newSearch = searchParams.toString();
      const newPath = history.location.pathname 
      closeContactDetailDrawer(newPath);
      setSelectedContact(null);
  };

  useEffect(() => {
    let total = 0;
    leadsCountData?.forEach((lead) => {
      total += lead.count;
    });
    setTotalLeads(total);
  }, [leadsCountData]);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      totalRecordsAll: totalLeads,
    }));
  }, [totalLeads]);

  const LeadStatusStats = () => {
    
    return (
      <div
      onClick={handleContainerClick}
      className="bg-white px-r py-2 flex flex-col gap-y-4">
        <div className="flex-1 flex items-center gap-x-4 gap-y-6 overflow-auto">
          <div
            onClick={() => {
              setSelectedStatus(null);
              setState({
                ...state,
                filters: {
                  ...state.filters,
                  timeLine: "",
                  leadStatus: "",
                  lists: "",
                },
                search: "",
              });
            }}
            className={`flex cursor-pointer flex-col min-w-[120px] gap-y-1 pl-4 ${"border-l-[3px] border-[#2E2B2E] border-opacity-25"} ${
              selectedStatus ? "opacity-50" : ""
            }`}
          >
            <h6 className="flex-1 capitalize truncate text-[14px] mb-0 font-medium">
              Project Contacts
            </h6>
            {isLoading ? (
              <Skeleton className='rounded-xl' height={16} />
            ) : (
              <p className="text-xl mb-0 font-bold">{totalLeads}</p>
            )}
          </div>
          <div
            onClick={(e) => {
              e.stopPropagation()
              setSelectedStatus("last30Days");
              setSearch("");
              setState({
                ...state,
                filters: {
                  ...state.filters,
                  timeLine: "last30Days",
                  leadStatus: "",
                  lists: "",
                },
              });
            }}
            className={`flex cursor-pointer flex-col min-w-[120px] gap-y-1 pl-4 ${"border-l-[3px] border-[#2E2B2E] border-opacity-25"} ${
              selectedStatus && selectedStatus !== "last30Days" ? "opacity-50" : ""
            }`}
          >
            <h6 className="flex-1 capitalize truncate text-[14px] mb-0 font-medium">
              Last 30 Days
            </h6>
            {isLoading ? (
              <Skeleton className='rounded-xl' height={16} />
            ) : (
              <p className="text-xl mb-0 font-bold">{last30DaysContacts ?? 0}</p>
            )}
          </div>
          {leadsCountData?.map((type, index) => (
            <div
              onClick={(e) => {
                e.stopPropagation()
                setSelectedStatus(type._id);
                setState({
                  ...state,
                  filters: {
                    ...state.filters,
                    timeLine: "",
                    leadStatus: type?._id,
                    lists: "",
                  },
                });
                setSearch("");
              }}
              key={index}
              className={`flex cursor-pointer flex-col min-w-[90px] gap-y-1 pl-4 ${"border-l-[3px] border-[#2E2B2E] border-opacity-25"} ${
                selectedStatus && selectedStatus !== type._id ? "opacity-50" : ""
              }`}
            >
              <div className="flex items-center gap-2">
                <svg
                  className=""
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="6"
                    cy="6"
                    r="6"
                    fill={
                      leadStatusBadgeColorFinder(type._id)?.text ||
                      color[index] ||
                      "#111928"
                    }
                  />
                </svg>
                <h6 className="flex-1 capitalize truncate text-[14px] mb-0 font-medium">
                  {type?._id}
                </h6>
              </div>
              <p className="text-xl mb-0 font-bold">{type.count}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full overflow-hidden">
      <div className="relative h-full w-full flex overflow-y-auto">
        {state.isFilterDrawerOpen && <FilterDrawer />}
        <div
          className={`flex flex-col p-4 w-full max-h-full overflow-auto transition-all bg-siteColor`}
        >
          <LeadStatusStats />
          <div
            className={`flex flex-row items-center gap-3 md:gap-4 ${state.isContactDetailDrawerOpen && !isMobile
              ? "pb-4"
              : "pt-4 pb-0 md:pt-6 md:pb-0"
              }`}
          >
            <ContactModalV2 />
          </div>
          <UserTableV2 />
        </div>
      </div>
    </div>
  );
};

export default ContactsTableV2;
