/* eslint-disable */
import ReactFlowProviderComponent from 'components/ReactFlowProvider'
import useWorkFlows from 'hooks/useCustomWorkflows'
import { useEffect, useState } from 'react'

export default function WorkflowsView() {
  const [loading, setLoading] = useState(true)
  const [workflow, setWorkflow] = useState(null)
  const { getWorkflow } = useWorkFlows()

  useEffect(() => {
    const workflowId = new URLSearchParams(window.location.search).get('workflow')
    getWorkflow(workflowId).then((response) => {
      setWorkflow(response)
    }).finally(() => {
      setLoading(false)
    })
  }, [])

  if (loading) return <div>Loading...</div>
  return <ReactFlowProviderComponent
    onlyTriggerCategory={[
      'custom'
    ]}
    workflow={workflow}
  />
}
