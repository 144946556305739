/*eslint-disable */
// @ts-nocheck
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Eye, FileText } from 'lucide-react'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ShadcnComponents/card'
import { Button } from 'components/ShadcnComponents/button'
import { getProjects } from 'store/actions/projectActions'
import { getLeadsReport } from 'store/actions/usersActions'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Chart from 'chart.js/auto'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import html2canvas from 'html2canvas'
import { getPDFReport } from 'store/actions/reportAction'
import moment from 'moment'
import { getAllWebsiteConfig } from 'store/actions/websiteReportConfigActions'
import { bindChannel, subscribeToChannel, unbindChannel, unsubscribeToChannel } from 'services/pusher'
import useContactContext from 'pages/newContacts/hooks/useContactContext'
import useGoogleAdsAccounts from 'hooks/useGoogleAdsAccounts'
import { getGoogleAdsReportById } from 'store/actions/googleAdsActions'
import { formatMoney } from 'pages/desktopMode/googleAdsReport'
import { getAllEmailCampaigns } from 'store/actions/customEmailCampaignActions'

const GOOGLE_ANALYTICS_BE_URL = process.env.REACT_APP_GOOGLE_ANALYTICS_BE_URL ?? "https://google-analytics-api-service-33853d77a62a.herokuapp.com";
const PROPERTY_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_PROPERTY_ID ?? ""

const templates = [
  {
    title: 'Comprehensive Sales and Marketing Report',
    description: "Synthetic data for the Frame Residential Project's sales and marketing performance.",
    project: 'Frame Residential Project'
  },
  {
    title: 'Lead Generation Report',
    description:
      'Provides detailed insights into lead sources, weekly trends, cost efficiency, and overall lead quality.'
  },
  {
    title: 'Marketing Campaigns Report',
    description:
      'Breaks down the performance of marketing campaigns, including impressions, clicks, CTR, and ROI analysis.'
  },
  {
    title: 'Registrations and Events Report',
    description:
      'Highlights the number of new registrants from open houses, webinars, and events, as well as their conversion rates into leads.'
  }
]


const TemplatesView = () => {
  const { waitingModal, setWaitingModal } = useContactContext();
  const [projectDetails, setProjectDetails] = useState(null)
  const selectedProjectId = useSelector((state) => state.appReducer.appProject);
  const userSelector = (state) => state.authReducer.userObject;
  const userId = useSelector(userSelector);
  const { loading: isAdsAccountsLoading, accounts, googleAdsConnectionId } = useGoogleAdsAccounts(selectedProjectId)
  const [selectedAdAccountId, setSelectedAdAccountId] = useState(null)
  const [currentAdAccountCurrencyCode, setCurrentAdAccountCurrencyCode] = useState(null)

  useEffect(() => {
    fetchProjectDetails()
  }, [selectedProjectId, userId?._id])


  const fetchProjectDetails = async () => {
    if (selectedProjectId) {
      try {
        const data = await getProjects(selectedProjectId)
        setProjectDetails(data)
      } catch (error) {
        console.error('Error fetching project details:', error)
      }
    }
  }

  const accountOptions = useMemo(() => {
    const tempAccountList = accounts?.filter((account) => (account?.status === "enabled"))?.map((account) => ({
      label: account.name,
      value: account.id,
      _id: account.id,
    })) || []

    if (tempAccountList?.length) {
      console.log(tempAccountList)
      setSelectedAdAccountId(tempAccountList[0]._id)
      setCurrentAdAccountCurrencyCode(accounts?.filter((account) => (account?.status === "enabled"))?.[0]?.currencyCode)
    }
    return tempAccountList
  }, [accounts, selectedProjectId, googleAdsConnectionId])

  let device_id = localStorage.getItem('device_id');
  const onNewCallCallBackReport = useCallback(
    (data) => {
      setWaitingModal(false)
      handleLeadGenerationReport(selectedProjectId, projectDetails, data?.salesTeam)
    },
    [device_id, userId?._id]
  );
  
  useEffect(() => {
    let reportChannel = null;
    reportChannel = subscribeToChannel(`pdfReport-${device_id}`);
    if (reportChannel) {
      bindChannel(reportChannel, onNewCallCallBackReport);
    }
    return () => {
      if (reportChannel) {
        unbindChannel(reportChannel, onNewCallCallBackReport);
      }
      unsubscribeToChannel(`pdfReport-${device_id}`);
    };
  }, [onNewCallCallBackReport, selectedProjectId, device_id, userId?._id]);



  const [dateRangeQuery, setDateRangeQuery] = useState(
    `&startDate=${moment()
      .subtract(7, "days")
      .startOf("day")
      .format("YYYY-MM-DD")}&endDate=${moment()
      .endOf("day")
      .format("YYYY-MM-DD")}`
  )
  

  const getWebsiteTrafficData = async (projectId, dateRange) => {
    try {
      const analyticsResponse = await getAllWebsiteConfig("analytics", projectId);
      if (!analyticsResponse?.length) {
        return null;
      }

      const selectedPropertyId = analyticsResponse[0].propertyId;
      // const dateRangeQuery = `&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}`;
  
      // Fetch all required data in parallel
      const [chartDataResponse, cityDataResponse, countryDataResponse] = await Promise.all([
        fetch(`${GOOGLE_ANALYTICS_BE_URL}/google/analytics?propertyId=${selectedPropertyId}&groupBy=day${dateRangeQuery}`),
        fetch(`${GOOGLE_ANALYTICS_BE_URL}/google/analytics/city?propertyId=${selectedPropertyId}&groupBy=day${dateRangeQuery}`),
        fetch(`${GOOGLE_ANALYTICS_BE_URL}/google/analytics/country?propertyId=${selectedPropertyId}&groupBy=day${dateRangeQuery}`)
      ]);
  
      const [chartData, cityData, countryData] = await Promise.all([
        chartDataResponse.json(),
        cityDataResponse.json(),
        countryDataResponse.json()
      ]);
    
      // Process top pages data
      const combinedTopPages = chartData.topPages.reduce((acc, item) => {
        const existing = acc.find((i) => i.pageUrl === item.pageUrl);
        if (existing) {
          existing.uniqueVisitors += item.uniqueVisitors;
          existing.totalVisits += item.totalVisits;
          existing.totalScreenPageViews += item.totalScreenPageViews;
          existing.bounceRate = (existing.bounceRate * existing.totalVisits + item.bounceRate * item.totalVisits) /
            (existing.totalVisits + item.totalVisits);
        } else {
          const path = new URL(`https://${item.pageUrl}`).pathname;
          acc.push({ ...item, path });
        }
        return acc;
      }, []);
  
      // Format the data for PDF
      return {
        visitorChartData: chartData?.groups.map((elm) => ({
          date: moment(elm.date).format('MMM DD'),
          totalUniqueVisitors: elm.totalUniqueVisitors,
          totalBounceRate: (elm.totalBounceRate * 100).toFixed(2),
          totalSessions: elm.totalSessions
        })),
        summary: {
          totalUniqueVisitors: chartData.totalUniqueVisitors,
          totalSessions: chartData.totalSessions,
          totalBounceRate: (chartData.totalBounceRate * 100).toFixed(2),
          totalRepeaterVisitors: chartData.totalSessions - chartData.totalUniqueVisitors
        },
        topPages: combinedTopPages
          .sort((a, b) => b.totalVisits - a.totalVisits)
          .map(page => ({
            path: page.path,
            pageViews: page.totalScreenPageViews,
            uniqueVisitors: page.uniqueVisitors,
            bounceRate: (page.bounceRate * 100).toFixed(2),
            avgTimeOnPage: page.averageSessionDuration.toFixed(1)
          })),
        cityData: cityData.data
          .filter(elm => elm.city?.length > 0 && elm.city !== "(not set)")
          .sort((a, b) => b.totalVisits - a.totalVisits)
          .slice(0, 10),
        countryData: countryData.data
          .sort((a, b) => b.totalVisits - a.totalVisits)
          .slice(0, 10)
      };
    } catch (error) {
      console.error("Error fetching website traffic data:", error);
      return null;
    }
  };


const getImageAsBase64 = async (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';  // This is important for CORS
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      try {
        const dataURL = canvas.toDataURL('image/png');
        resolve(dataURL);
      } catch (err) {
        reject(err);
      }
    };
    img.onerror = (err) => reject(err);
    img.src = `${url}?t=${new Date().getTime()}`;
  });
};

const addHeader = async (doc, reportName, projectName, dateRange, projectDetails) => {
  try {
    doc.setFontSize(20);
    doc.setTextColor(0, 0, 0);
    doc.text('Project Report', 50, 20);
    
    doc.setFontSize(14);
    doc.text(projectName || '', 50, 28);
    
    doc.setFontSize(12);
    doc.setTextColor(128, 128, 128); // Gray color
    doc.text(dateRange || '', 50, 35);

    if (projectDetails?.logoImage) {
      const logoBase64 = await getImageAsBase64(projectDetails.logoImage);
      if (logoBase64) {
        doc.addImage(logoBase64, 'PNG', 15, 10, 30, 30);
      }
    } else {
      doc.setFillColor(51, 102, 153); // Blue color
      doc.rect(188, 15, 40, 40, 'F'); // Moved to top right
      doc.setTextColor(255, 255, 255); // White color
      doc.setFontSize(24);
      const initial = (projectName || 'Project')[0].toUpperCase();
      
      const textWidth = doc.getStringUnitWidth(initial) * 24 / doc.internal.scaleFactor;
      const textX = 188 + (40 - textWidth) / 2;
      const textY = 15 + 27; // Centered vertically
      
      doc.text(initial, textX, textY);
    }
  } catch (error) {
    console.error('Error adding header:', error);
  }
};

const getGoogleAdsData = async (projectId) => {
  try {
    console.log(googleAdsConnectionId, selectedAdAccountId)
    const reportResponse = await getGoogleAdsReportById(
      googleAdsConnectionId,
      selectedAdAccountId
    );

    if (!reportResponse?.data?.length) {
      return null;
    }

    const campaigns = reportResponse.data;
    const totals = campaigns.reduce((acc, campaign) => {
      acc.totalClicks += campaign.metrics.clicks;
      acc.totalBudget += campaign.budget.amount;
      acc.totalSpend += campaign.metrics.cost;
      acc.totalConversions += campaign.metrics.conversions || 0;
      return acc;
    }, {
      totalClicks: 0,
      totalBudget: 0,
      totalSpend: 0,
      totalConversions: 0
    });

    // Format the data for PDF
    return {
      summary: {
        totalCampaigns: campaigns.length,
        totalSpend: totals.totalSpend,
        totalClicks: totals.totalClicks,
        conversionRate: totals.totalClicks > 0 ? 
          (totals.totalConversions / totals.totalClicks) * 100 : 0
      },
      campaigns: campaigns.map(campaign => ({
        name: campaign.name,
        status: campaign.status,
        budget: {
          amount: campaign.budget.amount
        },
        metrics: {
          cost: campaign.metrics.cost,
          clicks: campaign.metrics.clicks,
          conversions: campaign.metrics.conversions || 0
        }
      })),
      currencyCode: accounts?.filter((account) => (account?.status === "enabled"))?.[0]?.currencyCode
    };

  } catch (error) {
    console.error("Error fetching Google Ads data:", error);
    return null;
  }
};

const createStyledPDFTable = (doc, {
  startY,
  head,
  body,
  columnStyles = {},
  customStyles = {},
  topMargin = 15
}) => {
  // Convert header array to object format if needed
  const formattedHead = Array.isArray(head) ? 
    [head.reduce((obj, key) => ({ ...obj, [key?.toLowerCase()]: key }), {})] :
    [head];

  startY = Number(startY) || topMargin;

  return doc.autoTable({
    startY: startY,
    head: formattedHead,
    body: body,
    theme: 'grid',
    useCss: true,
    margin: { top: 15, right: 15, bottom: 15, left: 15 },
    tableWidth: 'auto',
    styles: {
      font: 'helvetica',
      fontSize: 10,
      cellPadding: 5,
      overflow: 'linebreak',
      ...customStyles.styles
    },
    headStyles: {
      fillColor: [248, 249, 250],
      textColor: [160, 163, 172],
      fontSize: 10,
      ...customStyles.headStyles
    },
    bodyStyles: {
      fillColor: [255, 255, 255],
      textColor: [160, 163, 172],
      fontSize: 10,
      ...customStyles.bodyStyles
    },
    columnStyles: {
      ...columnStyles
    },
    didParseCell: function(data) {
      // Ensure all cells have valid dimensions
      data.cell.width = Number(data.cell.width) || 0;
      data.cell.height = Number(data.cell.height) || 0;
    }
  });
};

const addGoogleAdsReport = async (doc, adsData, currencyCode, projectDetails, dateRange) => {
  doc.addPage();
  await addHeader(doc, 'Google Ads Performance Report', projectDetails?.projectName, dateRange, projectDetails);
  addReportTitle(doc, 'Google Ads Performance Report', dateRange);
   
  const metricsData = [
    ['Total Campaigns', adsData.summary.totalCampaigns],
    ['Total Spend', formatMoney(adsData.summary.totalSpend.toFixed(2), currencyCode)],
    ['Total Clicks', adsData.summary.totalClicks.toLocaleString()],
    ['Conversion Rate', `${adsData.summary.conversionRate.toFixed(2)}%`]
  ];

  createStyledPDFTable(doc, {
    startY: 75,
    head: {
      metric: 'Metric',
      value: 'Value'
    },
    body: metricsData,
    columnStyles: {
      0: { fontStyle: 'bold' }
    },
    topMargin: 15  // Add consistent top margin
  });

  const chartCanvas = document.createElement('canvas');
  chartCanvas.width = 1000;
  chartCanvas.height = 300;
  const ctx = chartCanvas.getContext('2d');

  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: adsData.campaigns.map(campaign => campaign.name),
      datasets: [
        {
          label: 'Clicks',
          data: adsData.campaigns.map(campaign => campaign.metrics.clicks),
          backgroundColor: '#2236a1',
          borderColor: '#1F2E97',
          borderWidth: 0,
          borderRadius: 0,
        },
        {
          label: 'Spend',
          data: adsData.campaigns.map(campaign => campaign.metrics.cost),
          backgroundColor: '#4CAF50',
          borderColor: '#45A049',
          borderWidth: 0,
          borderRadius: 0,
        }
      ]
    },
    options: {
      responsive: false,
      animation: false,
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            color: '#EBEDF0',
          },
          ticks: {
            color: '#6B7280',
            font: { size: 9 }
          }
        },
        x: {
          grid: { display: false },
          ticks: {
            color: '#6B7280',
            font: { size: 9 }
          }
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
          labels: {
            color: '#6B7280',
            font: { size: 9 }
          }
        }
      }
    }
  });

  doc.addImage(chartCanvas.toDataURL(), 'PNG', 15, 180, 180, 90);

  const campaignData = adsData.campaigns.map(campaign => [
    campaign.name,
    getStatusText(campaign.status),
    formatMoney(campaign.budget.amount.toFixed(2), currencyCode),
    formatMoney(campaign.metrics.cost.toFixed(2), currencyCode),
    campaign.metrics.clicks.toLocaleString(),
    Math.round(campaign.metrics.conversions || 0)
  ]);

  createStyledPDFTable(doc, {
    startY: 270,
    head: {
      campaignName: 'Campaign Name',
      status: 'Status',
      budget: 'Budget',
      spent: 'Spent',
      clicks: 'Clicks',
      conversions: 'Conversions'
    },
    body: campaignData,
    columnStyles: {
      0: { cellWidth: 60, fontStyle: 'bold' },
      1: { cellWidth: 24, halign: 'center' },
      2: { cellWidth: 25, halign: 'right' },
      3: { cellWidth: 25, halign: 'right' },
      4: { cellWidth: 20, halign: 'center' },
      5: { cellWidth: 20, halign: 'center' }
    },
    topMargin: 15  // Add consistent top margin
  });
};

const getStatusText = (status) => {
  switch (status) {
    case 2:
      return "Active";
    case 3:
      return "Paused";
    default:
      return "Unknown";
  }
};

const addFooter = (doc, reportName, projectName, reportingDates) => {
  const pageCount = doc.internal.getNumberOfPages();
  
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    
    // // Add a margin line above footer
    // doc.setDrawColor(240, 240, 240); // Light gray color
    // doc.line(15, doc.internal.pageSize.height - 20, doc.internal.pageSize.width - 15, doc.internal.pageSize.height - 20);
    
    doc.setFontSize(8);
    doc.setTextColor(128, 128, 128); // Gray color
    
    // Add report info on the left
    doc.text(
      `${reportName} | ${projectName} | ${reportingDates}`,
      15,
      doc.internal.pageSize.height - 10
    );
    
    // Add page number on the right
    doc.text(
      `Page ${i} of ${pageCount}`,
      doc.internal.pageSize.width - 15,
      doc.internal.pageSize.height - 10,
      { align: 'right' }
    );
  }
};

const addReportTitle = (doc, reportName, dateRange) => {
  doc.setFontSize(18);
  doc.setTextColor(0, 0, 0);
  doc.text(reportName, 15, 55);
  
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  doc.text(dateRange, 15, 63);
};

const addCoverPage = async (doc, projectDetails, dateRange) => {
  try {
    doc.setPage(1);
    if (projectDetails?.backgroundImage) {
      const backgroundBase64 = await getImageAsBase64(projectDetails.backgroundImage);
      if (backgroundBase64) {
        doc.addImage(backgroundBase64, 'PNG', 15, 35, 180, 120);
      }
    }
    if (projectDetails?.logoImage) {
      const logoBase64 = await getImageAsBase64(projectDetails.logoImage);
      if (logoBase64) {
        doc.addImage(logoBase64, 'PNG', 20, 45, 40, 40);
      }
    }
    const startY = 185;
    doc.setFontSize(36);
    doc.setTextColor(0, 0, 0);
    doc.text('Project Report', 15, startY);

    doc.setFontSize(28);
    doc.text(projectDetails?.projectName || 'Frame by Peterson', 15, startY + 18);
    doc.setFontSize(16);
    doc.setTextColor(128, 128, 128);
    doc.text(dateRange || '12/01/2025 - 19/01/2025', 15, startY + 28);

    doc.addPage();
  } catch (error) {
    console.error('Error adding cover page:', error);
  }
};

const createLeadChart = (leadData) => {
  const canvas = document.createElement('canvas');
  canvas.width = 1000;
  canvas.height = 300;
  const ctx = canvas.getContext('2d');

  // Get date range for last 7 days
  const endDate = moment();
  const startDate = moment().subtract(7, 'days');
  const dateRange = [];
  
  // Generate array of all dates in range
  let currentDate = startDate.clone();
  while (currentDate <= endDate) {
    dateRange.push(currentDate.format('MMM DD'));
    currentDate = currentDate.clone().add(1, 'days');
  }

  // Group leads by date
  const leadsByDate = leadData.reduce((acc, lead) => {
    const date = moment(lead.createdAt).format('MMM DD');
    if (!acc[date]) {
      acc[date] = 0;
    }
    acc[date]++;
    return acc;
  }, {});

  // Map counts to full date range, defaulting to 0 for dates with no leads
  const counts = dateRange.map(date => leadsByDate[date] || 0);

  new Chart(ctx, {
    type: 'bar',
    data: {
      labels: dateRange,
      datasets: [{
        label: 'New Leads',
        data: counts,
        backgroundColor: '#2236a1',
        borderColor: '#1F2E97',
        borderWidth: 0,
        borderRadius: 0,
        barThickness: 25,
      }]
    },
    options: {
      responsive: false,
      animation: false,
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 20,
          bottom: 20
        }
      },
      scales: {
        y: {
          beginAtZero: true,
          grid: {
            color: '#EBEDF0',
            lineWidth: 1,
          },
          ticks: {
            color: '#6B7280',
            font: { size: 11 }
          }
        },
        x: {
          grid: { display: false },
          ticks: {
            color: '#6B7280',
            font: { size: 11 }
          }
        }
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            color: '#6B7280',
            font: { size: 11 },
            boxWidth: 15,
            padding: 15
          }
        }
      }
    }
  });

  return canvas;
};

const addEmailCampaignReport = async (doc, emailCampaigns, projectDetails, dateRange) => {
  doc.addPage();
  const reportName = 'Email Campaign Report';
  const projectName = projectDetails?.projectName || 'Frame by Peterson';
  
  await addHeader(doc, reportName, projectName, dateRange, projectDetails);
  addReportTitle(doc, reportName, dateRange);

  // Calculate analytics
  const analytics = emailCampaigns.reduce((acc, campaign) => {
    acc.total_email_sent += campaign.sendGridReport?.delivered || 0;
    acc.total_opened += campaign.sendGridReport?.unique_opens || 0;
    acc.total_clicked += campaign.sendGridReport?.unique_clicks || 0;
    acc.total_bounced += campaign.sendGridReport?.bounces || 0;
    acc.total_unsubscribed += campaign.sendGridReport?.unsubscribes || 0;
    return acc;
  }, {
    total_email_sent: 0,
    total_opened: 0,
    total_clicked: 0,
    total_bounced: 0,
    total_unsubscribed: 0,
    total_campaign: emailCampaigns.length
  });

  // Add summary metrics table
  const summaryTable = createStyledPDFTable(doc, {
    startY: 75,
    head: ['Metric', 'Value'],
    body: [
      ['Campaigns Sent', analytics.total_campaign],
      ['Total Emails Sent', analytics.total_email_sent],
      ['Total Opened', analytics.total_opened],
      ['Total Clicked', analytics.total_clicked],
      ['Total Bounces', analytics.total_bounced],
      ['Total Unsubscribed', analytics.total_unsubscribed]
    ],
    columnStyles: {
      0: { fontStyle: 'bold', cellWidth: 'auto' },
      1: { halign: 'center', cellWidth: 'auto' }
    }
  });

  try {
    const pieCanvas = document.createElement('canvas');
    pieCanvas.width = 600;
    pieCanvas.height = 600;
    const pieCtx = pieCanvas.getContext('2d');

    new Chart(pieCtx, {
      type: 'pie',  // Changed back to pie from doughnut
      data: {
        labels: ['Opened', 'Clicked', 'Bounced', 'Unsubscribed', 'Not Opened'],
        datasets: [{
          data: [
            analytics.total_opened,
            analytics.total_clicked,
            analytics.total_bounced,
            analytics.total_unsubscribed,
            analytics.total_email_sent - analytics.total_opened
          ],
          backgroundColor: [
          '#10B981', // Softer green
        '#60A5FA', // Softer blue
        '#FBBF24', // Softer yellow
        '#F87171', // Softer red
        '#F3F4F6'  // Light grey
          ],
          borderWidth: 0,  // Removed borders
          borderRadius: 0, // Subtle rounding
          spacing: 1,     
        }]
      },
      options: {
        responsive: false,
        animation: false,
        layout: {
          padding: 0
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            labels: {
              usePointStyle: true,
          boxWidth: 6,        // Smaller legend markers
          boxHeight: 6,
          padding: 8,         // Reduced padding
          font: {
            size: 12,          // Smaller font
            family: 'Arial',
            weight: '400'     // Regular weight
          },
              generateLabels: (chart) => {
                const data = chart.data;
                const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
                return data.labels.map((label, i) => ({
                  text: `${label} (${Math.round((data.datasets[0].data[i] / total) * 100)}%)`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  strokeStyle: '#ffffff',
                  lineWidth: 1,
                  hidden: false,
                  index: i
                }));
              }
            }
          },
          title: {
            display: true,
            text: 'Email Campaign Engagement',
            font: {
              size: 16,
              family: 'Arial',
              weight: 'bold'
            },
            padding: {
              top: 0,
              bottom: 16
            }
          }
        }
      }
    });
    const chartImage = pieCanvas.toDataURL('image/png');
    pieCanvas.width = 400;  // Reduced canvas size
    pieCanvas.height = 400;
    
    if (summaryTable.finalY + 150 > doc.internal.pageSize.height) {
      doc.addPage();
      await addHeader(doc, 'Email Campaign Report - Continued', projectDetails?.projectName, dateRange, projectDetails);
    }

    const startY = doc.previousAutoTable ? doc.previousAutoTable.finalY + 20 : 75;
    
    doc.addImage(chartImage, 'PNG', 15, startY, 120, 80);

    doc.addPage();
    await addHeader(doc, 'Email Campaign Report - Continued', projectDetails?.projectName, dateRange, projectDetails);

    const campaignData = emailCampaigns
      .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      .map(campaign => [
        campaign.emailTemplateId?.name || 'N/A',
        campaign.userList?.title || 'N/A',
        campaign.sendGridReport?.delivered || 0,
        campaign.sendGridReport?.unique_opens || 0,
        campaign.sendGridReport?.unique_clicks || 0,
        campaign.sendGridReport?.bounces || 0,
        campaign.sendGridReport?.unsubscribes || 0,
        new Date(campaign.createdAt).toLocaleDateString()
      ]);

    createStyledPDFTable(doc, {
      startY: 45,  // Start at fixed position on new page
      head: {
        template: 'Template Name',
        list: 'List Name',
        sent: 'Sent',
        opened: 'Opened',
        clicked: 'Clicked',
        bounced: 'Bounced',
        unsubscribed: 'Unsubscribed',
        date: 'Sent Date'
      },
      body: campaignData,
      columnStyles: {
        0: { cellWidth: 'auto' },
        1: { cellWidth: 'auto' },
        2: { cellWidth: 'auto', halign: 'center' },
        3: { cellWidth: 'auto', halign: 'center' },
        4: { cellWidth: 'auto', halign: 'center' },
        5: { cellWidth: 'auto', halign: 'center' },
        6: { cellWidth: 'auto', halign: 'center' },
        7: { cellWidth: 'auto', halign: 'center' }
      }
    });

  } catch (error) {
    console.error('Error creating email campaign report:', error);
    // Continue with the report even if chart fails
  }

  // After all content is added, add footers to all pages
  // addFooter(doc, reportName, projectName, dateRange);
};

const handleLeadGenerationReport = async (projectId, projectDetail, salesTeamData) => {
  try {
    await fetchProjectDetails()
    console.log(projectDetails)
    const { contacts: leadsData } = await getLeadsReport('7days', projectId);
    const endDate = new Date();
    const startDate = new Date(endDate);
    startDate.setDate(startDate.getDate() - 7);
    const dateRange = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;

    const canvas = createLeadChart(leadsData);

    const tableData = leadsData.map(contact => {
      let key = {
        Buyer: 'buyerData',
        LeadBroker: 'leadBrokerData',
      }[contact.userType];
      
      const date = new Date(contact.createdAt).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
      
      return [
        date,
        contact.firstName ? `${contact.firstName} ${contact.lastName || ''}` : 'N/A',
        contact.email || 'N/A',
        contact[key]?.leadSource || 'N/A',
        contact.userType
      ];
    });

    const doc = new jsPDF();
    
    await addCoverPage(doc, projectDetails, dateRange);
    
    doc.setPage(2);
    
    const reportName = 'Lead Generation Report';
    const projectName = projectDetails?.projectName || 'Frame by Peterson';
    
    await addHeader(doc, reportName, projectName, dateRange, projectDetails);
    addReportTitle(doc, reportName, dateRange);
    doc.setFillColor('#f1f2f4');
    doc.rect(15, 72, 184, 120, 'F');
    const chartImage = canvas.toDataURL('image/png');
    doc.addImage(chartImage, 'png', 15, 75, 180, 120);

    createStyledPDFTable(doc, {
      startY: 200,
      head: {
        date: 'DATE',
        name: 'NAME',
        email: 'EMAIL',
        source: 'SOURCE',
        userType: 'USER TYPE'
      },
      body: tableData,
      columnStyles: {
        name: { fontStyle: 'bold' }
      },
      topMargin: 15  // Add consistent top margin
    });

    for (let i = 2; i <= doc.getNumberOfPages(); i++) {
      doc.setPage(i);
      addFooter(doc, reportName, projectName, dateRange);
    }

    doc.addPage();
    const topMargin = 15;
    await addHeader(doc, 'Sales Team Activity Report', projectName, dateRange, projectDetails);
    addReportTitle(doc, 'Sales Team Activity Report', dateRange);

    const canvas2 = document.createElement('canvas');
    canvas2.width = 1000;
    canvas2.height = 300;
    const ctx2 = canvas2.getContext('2d');

    const salesRepNames = Object.keys(salesTeamData.salesRepStats);
    const totalLeadsData = salesRepNames.map(name => salesTeamData.salesRepStats[name].totalLeads);

    new Chart(ctx2, {
      type: 'bar',
      data: {
        labels: [...salesRepNames, 'Admin'],
        datasets: [{
          label: 'Total Leads',
          data: [...totalLeadsData, salesTeamData.adminStats.totalLeads],
          backgroundColor: '#2236a1',
          borderColor: '#1F2E97',
          borderWidth: 0,
          borderRadius: 0,
          barThickness: 25,
        }]
      },
      options: {
        responsive: false,
        animation: false,
        layout: {
          padding: {
            left: 20,
            right: 20,
            top: 20,
            bottom: 20
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              color: '#EBEDF0',
              lineWidth: 1,
            },
            ticks: {
              color: '#6B7280',
              font: { size: 11 }
            }
          },
          x: {
            grid: { display: false },
            ticks: {
              color: '#6B7280',
              font: { size: 11 }
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              color: '#6B7280',
              font: { size: 11 },
              boxWidth: 15,
              padding: 15
            }
          }
        }
      }
    });

    // Add chart to PDF
    doc.setFillColor('#f1f2f4');
    doc.rect(15, 72, 180, 90, 'F');
    const chartImage2 = canvas2.toDataURL('image/png');
    doc.addImage(chartImage2, 'png', 15, 75, 180, 90);

    // Add summary metrics table
    const summaryData = [
      ['Total Leads', salesTeamData.summary.totalLeads],
      ['New Leads', salesTeamData.summary.newLeads],
      ['Contacted Leads', salesTeamData.summary.contactedLeads],
      ['Unconnected Leads', salesTeamData.summary.totalUnconnectedLeads]
    ];

    createStyledPDFTable(doc, {
      startY: 180,
      head: {
        summaryMetrics: 'Summary Metrics',
        count: 'Count'
      },
      body: summaryData,
      topMargin: 25  // Add consistent top margin
    });

    const salesTeamLeadData = Object.entries(salesTeamData.salesRepStats).map(([name, stats]) => [
      name,
      stats.totalLeads,
      stats.newLeads,
      stats.contactedLeads
    ]);

    salesTeamLeadData.push([
      'Admin',
      salesTeamData.adminStats.totalLeads,
      salesTeamData.adminStats.newLeads,
      salesTeamData.adminStats.contactedLeads
    ]);

    createStyledPDFTable(doc, {
      startY: doc.lastAutoTable.finalY + 25,
      head: {
        salesRep: 'Sales Rep',
        totalLeads: 'Total Leads',
        newLeads: 'New Leads',
        contactedLeads: 'Contacted Leads'
      },
      body: salesTeamLeadData,
      columnStyles: {
        0: { cellWidth: 50 },
        1: { cellWidth: 40, halign: 'center' },
        2: { cellWidth: 40, halign: 'center' },
        3: { cellWidth: 40, halign: 'center' }
      },
      topMargin: 15  // Add consistent top margin
    });

    const activityData = Object.entries(salesTeamData.salesRepStats).map(([name, stats]) => [
      name,
      stats.activities.calls,
      stats.activities.tasks,
      stats.activities.notes,
      stats.activities.email,
      stats.activities.meeting,
      stats.activities.total
    ]);

    activityData.push([
      'Admin',
      salesTeamData.adminStats.activities.calls,
      salesTeamData.adminStats.activities.tasks,
      salesTeamData.adminStats.activities.notes,
      salesTeamData.adminStats.activities.email,
      salesTeamData.adminStats.activities.meeting,
      salesTeamData.adminStats.activities.total
    ]);

    createStyledPDFTable(doc, {
      startY: doc.lastAutoTable.finalY + 25,
      head: {
        salesRep: 'Sales Rep',
        calls: 'Calls',
        tasks: 'Tasks',
        notes: 'Notes',
        emails: 'Emails',
        meetings: 'Meetings',
        total: 'Total'
      },
      body: activityData,
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 25, halign: 'center' },
        2: { cellWidth: 25, halign: 'center' },
        3: { cellWidth: 25, halign: 'center' },
        4: { cellWidth: 25, halign: 'center' },
        5: { cellWidth: 25, halign: 'center' },
        6: { cellWidth: 25, halign: 'center' }
      },
      topMargin: 15  // Add consistent top margin
    });


    const websiteTrafficData = await getWebsiteTrafficData(projectId, dateRange);
    
    if (websiteTrafficData) {
      doc.addPage();
      await addHeader(doc, 'Website Traffic Report', projectName, dateRange, projectDetails);
      addReportTitle(doc, 'Website Traffic Report', dateRange);

      const visitorCanvas = document.createElement('canvas');
      visitorCanvas.width = 1000;
      visitorCanvas.height = 300;
      const visitorCtx = visitorCanvas.getContext('2d');

      new Chart(visitorCtx, {
        type: 'line',
        data: {
          labels: websiteTrafficData.visitorChartData.map(item => item.date),
          datasets: [{
            label: 'Unique Visitors',
            data: websiteTrafficData.visitorChartData.map(item => item.totalUniqueVisitors),
            borderColor: '#2236a1',
            tension: 0.4,
            fill: false
          }]
        },
        options: {
          responsive: false,
          animation: false,
          layout: {
            padding: {
              left: 20,
              right: 20,
              top: 20,
              bottom: 20
            }
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                color: '#EBEDF0',
                lineWidth: 1,
              },
              ticks: {
                color: '#6B7280',
                font: { size: 11 }
              }
            },
            x: {
              grid: { display: false },
              ticks: {
                color: '#6B7280',
                font: { size: 11 }
              }
            }
          },
          plugins: {
            legend: {
              display: true,
              position: 'bottom',
              labels: {
                color: '#6B7280',
                font: { size: 11 },
                boxWidth: 15,
                padding: 15
              }
            }
          }
        }
      });

      doc.addImage(visitorCanvas.toDataURL(), 'PNG', 15, 75, 180, 90);

      createStyledPDFTable(doc, {
        startY: 180,
        head: {
          metric: 'Metric',
          value: 'Value'
        },
        body: [
          ['Total Unique Visitors', websiteTrafficData.summary.totalUniqueVisitors],
          ['Total Sessions', websiteTrafficData.summary.totalSessions],
          ['Bounce Rate', `${websiteTrafficData.summary.totalBounceRate}%`],
          ['Returning Visitors', websiteTrafficData.summary.totalRepeaterVisitors]
        ],
        columnStyles: {
          0: { fontStyle: 'bold' },
          1: { fontStyle: 'bold' }
        },
        topMargin: 15  // Add consistent top margin
      });

      createStyledPDFTable(doc, {
        startY: doc.lastAutoTable.finalY + 15,
        head: {
          page: 'Page',
          views: 'Views',
          uniqueVisitors: 'Unique Visitors',
          bounceRate: 'Bounce Rate',
          avgTime: 'Avg. Time'
        },
        body: websiteTrafficData.topPages.slice(0, 10).map(page => [
          page.path,
          page.pageViews,
          page.uniqueVisitors,
          `${page.bounceRate}%`,
          page.avgTimeOnPage
        ]),
        columnStyles: {
          0: { cellWidth: 60, fontStyle: 'bold' },
          1: { cellWidth: 25, halign: 'center' },
          2: { cellWidth: 25, halign: 'center' },
          3: { cellWidth: 25, halign: 'center' },
          4: { cellWidth: 25, halign: 'center' }
        },
        topMargin: 15  // Add consistent top margin
      });

      if (websiteTrafficData.cityData.length > 0) {
        createStyledPDFTable(doc, {
          startY: doc.lastAutoTable.finalY + 15,
          head: {
            city: 'City',
            visits: 'Visits',
            percentageOfTotal: '% of Total'
          },
          body: websiteTrafficData.cityData.map(city => [
            city.city,
            city.totalVisits,
            `${((city.totalVisits / websiteTrafficData.summary.totalSessions) * 100).toFixed(1)}%`
          ]),
          columnStyles: {
            0: { cellWidth: 80, fontStyle: 'bold' },
            1: { cellWidth: 30, halign: 'center' },
            2: { cellWidth: 30, halign: 'center' }
          },
          topMargin: 15  // Add consistent top margin
        });
      }

      if (websiteTrafficData.countryData.length > 0) {
        createStyledPDFTable(doc, {
          startY: doc.lastAutoTable.finalY + 15,
          head: {
            country: 'Country',
            visits: 'Visits',
            percentageOfTotal: '% of Total'
          },
          body: websiteTrafficData.countryData.map(country => [
            country.country,
            country.totalVisits,
            `${((country.totalVisits / websiteTrafficData.summary.totalSessions) * 100).toFixed(1)}%`
          ]),
          columnStyles: {
            0: { cellWidth: 80, fontStyle: 'bold' },
            1: { cellWidth: 40, halign: 'center' },
            2: { cellWidth: 40, halign: 'center' }
          },
          topMargin: 15  // Add consistent top margin
        });
      }
    }

    const googleAdsReport = await getGoogleAdsData(projectId)

    if(googleAdsReport) {
      await addGoogleAdsReport(doc, googleAdsReport, googleAdsReport.currencyCode, projectDetails, dateRange)
    }

    const emailCampaigns = await getAllEmailCampaigns();
    if (emailCampaigns?.length) {
      await addEmailCampaignReport(doc, emailCampaigns, projectDetails, dateRange);
    }

    doc.save(`Lead_Generation_Report_${new Date().toISOString().split('T')[0]}.pdf`);
    
  } catch (e) {
    console.log(e);
    toast.error('Error generating report');
  }
};

const handleGenerateReport = async (projectId, dateRange) => {
  try {
    setWaitingModal(true);
    const response = await getPDFReport(projectId, device_id);
    if (!response.data) {
      throw new Error('Failed to initiate report generation');
    }
  } catch (error) {
    console.error("Error initiating report generation:", error);
    message.error("Failed to generate report");
    setWaitingModal(false);
  }
};


  return (
    <div className='w-full grid grid-cols-2 gap-4 mt-4'>
      {templates.map((template, index) => (
        <Card key={index} className='bg-white shadow-sm !rounded-lg'>
          <CardHeader>
            <div className='flex items-center gap-2'>
              <FileText className='h-5 w-5' />
              <div className='flex flex-col gap-2'>
                <CardTitle className='text-lg mb-0'>{template.title}</CardTitle>
                {template.project && (
                  <div className='text-sm text-muted-foreground'>{template.project}</div>
                )}
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <CardDescription className='mb-4'>{template.description}</CardDescription>
            <div className='flex gap-4'>
              <Button
                onClick={() => {
                  if(template.title === 'Lead Generation Report') {
                    handleGenerateReport(selectedProjectId, projectDetails)
                  }
                }}
                className='flex-1'
                variant='default'
              >
                <Eye className='mr-2 h-4 w-4' />
                Generate Report
              </Button>
              <Button className='flex-1' variant='outline'>
                <FileText className='mr-2 h-4 w-4' />
                Generate PDF
              </Button>
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  )
}

export default TemplatesView
