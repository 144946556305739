// @ts-nocheck
import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react'
import propTypes from 'prop-types'
import './style/style.css'
import { AdminPageWrapper, PageHeader } from 'components'
import { TFilledPlusIcon } from 'components/Icons'
import { connect } from 'react-redux'
import { Table } from 'ui'
import { formatDate, formatMoney, getOrdinalName, sanitizeCode } from 'utils'
import standardStyle from 'assets/css/standardStyle'
import { ReactComponent as DownIcon } from 'assets/icons/down.svg'
import { ReactComponent as UserCheck } from 'assets/icons/user-check.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { Select } from 'antd'
import { api, ApiErrors, getTokenRequestHeader } from 'services/api'
import { useGetContactLists } from 'hooks/useGetContactLists'
import CustomButton from 'components/CustomButton'
import CustomModal from 'ui/customModal'
import TableGrid from 'components/TableGrid/Table'
import { getUsersPagination } from 'store/actions/usersActions'
import useGetCommunicationTemplatesByType from 'hooks/useGetCommunicationTemplatesByType'
import { useHistory, withRouter } from 'react-router-dom'
import RichTextField from 'components/RichTextField'
import { Button } from 'components/ShadcnComponents/button'
import PauseResumeQueue from 'components/QueueManage'

const COST_PER_SEGMENT = 0.0079
const COST_PER_SEGMENT_FORMATTED = '$0.0079'
const SEGMENT_SIZE = 140
const FRACTIONAL_PART = 5

const TextField = ({ value }) => (
  <span className='font-medium text-base text-softBlack capitalize'>
    {value}
  </span>
)

const CardComponent = ({ IconSrc, title, count }) => (
  <div
    className='bg-white rounded'
    style={{ fontFamily: standardStyle.fonts.sfpro }}
  >
    <div className='p-6'>
      <div className='w-8 h-6'>{IconSrc}</div>
    </div>
    <div className='flex flex-col space-y-2 p-6'>
      <div className='text-xs text-softBlack_70 font-medium uppercase'>
        {title}
      </div>
      <div className='text-xl text-softBlack font-bold'>
        {count}
      </div>
    </div>
  </div>
)

// const demoTemplateMessages = [
//   {
//     title: 'Exclusive Event Invitation',
//     message: 'You are invited! Join us at our VIP Real Estate Event to explore exclusive offers and insights. RSVP now and be a part of something special. Details at www.1818pacifica.com'
//   },
//   {
//     title: 'Weekend Promo Extravaganza',
//     message: 'This weekend only! Special promotional deals on select properties. Do not miss out on these incredible savings. Visit www.1818pacifica.com for more information.'
//   },
//   {
//     title: 'Sales Launch Alert',
//     message: 'Be the first to know! Our latest property sales launch is happening soon. Exclusive early access for SMS subscribers. Stay updated at www.1818pacifica.com'
//   },
//   {
//     title: 'Discover Your Dream Home',
//     message: 'Your dream home awaits. Browse our latest listings and find the perfect match for your lifestyle. Start your journey at www.1818pacifica.com'
//   },
//   {
//     title: 'Presentation Centre Opening',
//     message: 'we are excited to announce the opening of our new Presentation Centre! Come and explore the future of living. For more details, visit www.1818pacifica.com'
//   },
//   {
//     title: 'Limited-Time Offer',
//     message: 'Act fast! Limited-time offer on our premium properties. Secure your dream home before its too late. Exclusive details at www.1818pacifica.com'
//   }
// ]

const SMSBroadcast = (props) => {
  const { appProject } = props
  const history = useHistory()
  const editorRef = useRef(null)
  const { contactsLists, getContacts } = useGetContactLists()
  const [smsBroadcastList, setSmsBroadcastList] = useState([])
  const [showCreateSMSBroadcast, setShowCreateSMSBroadcast] = useState(false)
  const [smsBroadcastDetails, setSmsBroadcastDetails] = useState({
    message: '',
    campaignName: ''
  })
  const [isLoading, setIsLoading] = useState(true)
  const [selectedContactGroupId, setSelectedContactGroupId] = useState(null)
  const [contactGroup, setContactGroup] = useState(null)
  const [extraContacts, setExtraContacts] = useState([])
  const { communicationTemplates, getCommunicationTemplates } = useGetCommunicationTemplatesByType('sms')

  const getAllSMSBroadcastList = useCallback(() => {
    return new Promise((resolve, reject) => {
      const token = getTokenRequestHeader()
      setIsLoading(true)
      // @ts-ignore
      api.setHeader('Authorization', token)

      api.get('sms/campaigns').then((response) => {
        if (response.ok) {
          resolve(response.data)
          setSmsBroadcastList(
            response.data
              .map((elm) => {
                const users = elm?.users ?? []
                const validNumbers = users?.filter((item) => {
                  return item?.phoneNumber
                })
                const invalid = users?.filter((item) => {
                  return !item?.phoneNumber
                })
                const uniqueReportUsers = elm?.report?.map(e => e.user?.toString()).filter((v, i, a) => a.indexOf(v) === i)
                const statusIndicatesDelivered = ['delivered', 'sent']
                const deliveredCount = elm?.report?.filter((message) => statusIndicatesDelivered.includes(message.status)).length
                const failedCount = elm?.report?.filter((message) => !statusIndicatesDelivered.includes(message.status)).length
                return {
                  id: elm._id,
                  broadcastName: elm.campaignName,
                  sentDate: elm.createdAt || elm.updatedAt,
                  totalUsers: users?.length || elm.report.length || 0,
                  validUsers: validNumbers?.length || uniqueReportUsers.length || 0,
                  invalidUsers: invalid?.length || 0,
                  numberOfRecipients: elm.report.length,
                  delivered: deliveredCount,
                  failed: failedCount
                }
              }))
        } else {
          ApiErrors(response)
          reject(response)
        }
      })
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (!contactsLists.length) {
      getContacts()
    }
    getCommunicationTemplates()
    getAllSMSBroadcastList()
  }, [])

  useEffect(() => {
    const arr = []
    contactsLists.forEach((el) => {
      if (!arr.find((item) => el.title === item.name)) {
        arr.push({
          id: el._id,
          name: `${el.title} (${el?.users?.length || 0})`,
          count: el?.users?.length || 0
        })
      }
    })
    setContactGroup(arr)
  }, [contactsLists])

  const RightContent = () => (
    <div className='flex flex-row items-center'>
      <PauseResumeQueue queue='sms-queue' />
      <CustomButton
        variant={showCreateSMSBroadcast ? 'outline' : 'default'}
        handleClick={() => {
          setShowCreateSMSBroadcast(!showCreateSMSBroadcast)
          setSmsBroadcastDetails({
            message: '',
            campaignName: ''
          })
          setSelectedContactGroupId(null)
          setExtraContacts([])
        }}
        disabled={!appProject}
      >
        <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
          {
            showCreateSMSBroadcast
              ? <>Cancel</>
              : <>
                <TFilledPlusIcon className='h-6 w-6' />
                Create
              </>
          }
        </span>
      </CustomButton>
    </div>
  )

  const tableColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center'>
            <span className='leading-none'>Broadcast Name</span>
          </span>
        ),
        accessor: 'broadcastName',
        dataIndex: 'broadcastName',
        Cell: (props) => {
          const original = props.row.original
          return <div className='flex items-center gap-x-1'>
            <button
              className='bg-gray-200 border border-border px-3 py-0.5 rounded-full'
              onClick={() => {
                history.push(`/admin/sms-broadcast/${original.id}`)
              }}
            >
              View Report
            </button>
            <TextField value={props.cell.value} />
          </div>
        }
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Date Sent</span>
          </span>
        ),
        accessor: 'sentDate',
        dataIndex: 'sentDate',
        Cell: (props) => <TextField value={formatDate(props.cell.value)} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Users Selected</span>
          </span>
        ),
        accessor: 'totalUsers',
        dataIndex: 'totalUsers',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Invalid Numbers</span>
          </span>
        ),
        accessor: 'invalidUsers',
        dataIndex: 'invalidUsers',
        Cell: (props) => {
          return <>
            {props.cell.value === 0 && (
              <span className='text-green-300'>-</span>
            )}
            {props.cell.value !== 0 && (
              <span className='text-red-500'>
                {props.cell.value}
              </span>
            )}
          </>
        }
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Delivered</span>
          </span>
        ),
        accessor: 'delivered',
        dataIndex: 'delivered',
        Cell: (props) => <TextField value={props.cell.value} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>Failed</span>
          </span>
        ),
        accessor: 'failed',
        dataIndex: 'failed',
        Cell: (props) => <TextField value={props.cell.value} />
      }
    ],
    []
  )

  const SMSBroadcastTable = useCallback(() => {
    return (
      <div className='mt-5 w-full'>
        <Table
          className='rounded-lg'
          dataSource={smsBroadcastList}
          columns={tableColumns}
          tailwindTable
          loading={isLoading}
        />
      </div>
    )
  }, [smsBroadcastList, tableColumns, isLoading])

  const sendSMSBroadcast = useCallback(() => {
    const cleanMessage = editorRef.current?.getEditor?.().getText?.().trim?.() ?? ''

    return new Promise((resolve, reject) => {
      const token = getTokenRequestHeader()

      // @ts-ignore
      api.setHeader('Authorization', token)

      api.post('sms/list', { ...smsBroadcastDetails, message: cleanMessage, listId: selectedContactGroupId, contacts: extraContacts ?? [] }).then((response) => {
        if (response.ok) {
          resolve(response)
          getAllSMSBroadcastList()
        } else {
          ApiErrors(response)
          reject(response)
        }
      })
      setShowCreateSMSBroadcast(false)
    })
  }, [getAllSMSBroadcastList, selectedContactGroupId, extraContacts, smsBroadcastDetails])

  const countRecipientsDeliveredAndFailed = useMemo(() => {
    let totalDelivered = 0
    let totalFailed = 0
    let totalRecipients = 0

    smsBroadcastList.forEach((elm) => {
      totalDelivered += elm.delivered
      totalFailed += elm.failed
      totalRecipients += elm.numberOfRecipients
    })

    return {
      totalDelivered,
      totalFailed,
      totalRecipients
    }
  }, [smsBroadcastList])

  const smsCostDetails = useMemo(() => {
    const totalCharacterCount = editorRef.current?.getEditor?.().getText?.().trim?.()?.length || 0
    const segmentsCount = Math.ceil(totalCharacterCount / SEGMENT_SIZE)
    let totalRecipients = 0

    if (selectedContactGroupId && contactGroup?.length) {
      totalRecipients += contactGroup?.filter((group) => (group?.id === selectedContactGroupId))?.[0]?.count || 0
    }

    if (extraContacts?.length) {
      totalRecipients += extraContacts.length
    }

    const costPerRecipient = (segmentsCount * COST_PER_SEGMENT).toFixed(FRACTIONAL_PART)
    const totalCost = (costPerRecipient * totalRecipients).toFixed(FRACTIONAL_PART)

    return {
      totalCharacterCount,
      segmentsCount,
      totalRecipients,
      costPerRecipient,
      totalCost
    }
  }, [smsBroadcastDetails?.message, editorRef, contactGroup, selectedContactGroupId, extraContacts])

  return (
    <>
      <PageHeader
        title={`${showCreateSMSBroadcast ? 'Create ' : ''}SMS Broadcast`}
        rightContent={<RightContent />}
      />
      <AdminPageWrapper style={{ height: 'calc(100vh - 164px)' }} fullscreen>
        {
          showCreateSMSBroadcast
            ? <>
              <div className='w-full h-fit py-8 grid grid-cols-2 gap-x-6'>
                <div className='min-h-full'>
                  {/* <h1 className='text-4xl font-bold'>SMS Broadcast</h1> */}
                  <div className='flex flex-col gap-y-5'>
                    <div>
                      <p className='text-base font-medium mb-1'>Message Title</p>
                      <input
                        placeholder='Type your title here.'
                        value={smsBroadcastDetails?.campaignName ?? ''}
                        onChange={(e) => setSmsBroadcastDetails({
                          ...smsBroadcastDetails,
                          campaignName: e.target.value
                        })}
                      />
                    </div>
                    <div>
                      <p className='text-base font-medium mb-1'>Your Message</p>
                      <RichTextField
                        editorRef={editorRef}
                        onChangeText={(value) => setSmsBroadcastDetails({
                          ...smsBroadcastDetails,
                          message: value
                        })}
                        text={smsBroadcastDetails?.message ?? ''}
                        placeholder={'Type text here...'}
                        height='410px'
                        style={{
                          height: '410px'
                        }}
                        hasFileAttachments={false}
                      />
                      <div className='w-full p-2 bg-white mt-[43px] text-sm lowercase' style={{ borderWidth: '1px', borderTopWidth: '0px', borderColor: '#ccc' }}>
                        {(smsCostDetails?.segmentsCount * SEGMENT_SIZE - smsCostDetails?.totalCharacterCount) || SEGMENT_SIZE} characters remaining in {getOrdinalName(smsCostDetails?.segmentsCount > 0 ? smsCostDetails?.segmentsCount - 1 : smsCostDetails?.segmentsCount)} segment
                      </div>
                    </div>
                  </div>
                </div>
                <div className='min-h-full flex flex-col gap-4'>
                  <div className='flex flex-col gap-y-5'>
                    <div>
                      <p className='text-base font-medium mb-1'>Contact Groups</p>
                      <Select
                        showArrow
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        value={selectedContactGroupId ?? 'Select groups'}
                        onChange={(value) => {
                          setSelectedContactGroupId(value)
                        }}
                        className='ant-picker-input1'
                        suffixIcon={<DownIcon />}
                        bordered={false}
                        getPopupContainer={(node) => node.parentNode}
                      >
                        {contactGroup?.map((el) => (
                          <Select.Option key={el.id} label={el.name} value={el.id}>
                            {el.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                    <div className='w-full flex justify-between items-center gap-3'>
                      <div className='flex-1'>
                        {extraContacts?.length > 0 && (
                          <div>
                            Added {extraContacts?.length} contacts
                          </div>
                        )}
                      </div>
                      <ModelAddContacts
                        existing={extraContacts}
                        onAdded={(contacts) => {
                          if (!contacts || !contacts.length || !Array.isArray(contacts)) return
                          const updatedContacts = [...extraContacts, ...contacts]
                          const uniqueContacts = updatedContacts.filter(
                            (contact, index, self) => index === self.findIndex((t) => t === contact)
                          )
                          setExtraContacts(uniqueContacts)
                        }}
                      />
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4.5 bg-white border p-4 !rounded-lg">
                      <SummaryCard
                        title="Total Characters"
                        value={smsCostDetails?.totalCharacterCount}
                      />
                      <SummaryCard
                        title="Message Segments"
                        value={`${smsCostDetails?.segmentsCount}${smsCostDetails?.segmentsCount > 0 ? ` x ${COST_PER_SEGMENT_FORMATTED}` : ''}`}
                      />
                      <SummaryCard
                        title="Cost per Recipient"
                        value={formatMoney(smsCostDetails?.costPerRecipient, '$')}
                      />
                      <SummaryCard
                        title="Total Campaign Cost"
                        value={formatMoney(smsCostDetails?.totalCost, '$')}
                      />
                    </div>

                    <div className="bg-white !rounded-lg border p-4">
                      <h2 className="text-lg font-semibold text-gray-900 mb-2.5">Cost Breakdown</h2>
                      <div className="space-y-3">
                        <CostRow
                          label="Message Length"
                          value={`${smsCostDetails?.totalCharacterCount} characters`}
                        />
                        <CostRow
                          label="Segments Required"
                          value={`${smsCostDetails?.segmentsCount}${smsCostDetails?.segmentsCount > 0 ? ` (${smsCostDetails?.segmentsCount} x ${COST_PER_SEGMENT_FORMATTED})` : ''}`}
                        />
                        <CostRow
                          label="Recipients"
                          value={smsCostDetails?.totalRecipients}
                        />
                        <CostRow
                          label="Cost per Recipient"
                          value={formatMoney(smsCostDetails?.costPerRecipient, '$')}
                        />

                        <div className="pt-3 border-t">
                          <CostRow
                            label="Total Cost"
                            value={formatMoney(smsCostDetails?.totalCost, '$')}
                            isTotal
                          />
                          <div className="mt-2 text-sm text-gray-500">
                            {COST_PER_SEGMENT} per {SEGMENT_SIZE} characters x {smsCostDetails?.segmentsCount} segments x {smsCostDetails?.totalRecipients} recipients
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-full flex'>
                    <CustomButton
                      handleClick={sendSMSBroadcast}
                      disabled={!selectedContactGroupId || !smsBroadcastDetails.message.length || !smsBroadcastDetails.campaignName.length}
                      className='!rounded-lg !w-full'
                    >
                      <span className='font-medium py-2.5 px-6 text-lg'>
                        Send Campaign ({formatMoney(smsCostDetails?.totalCost, '$')})
                      </span>
                    </CustomButton>
                  </div>
                </div>
              </div>
              <div className='mt-4 w-full flex flex-col overflow-hidden'>
                <h3 className='text-2xl font-semibold mb-3'>Template Messages</h3>
                <div className='flex-1 grid grid-cols-3 gap-4 overflow-y-auto thin-scrollbar'>
                  {
                    communicationTemplates?.length > 0
                      ? <>
                        {
                          communicationTemplates.map(({ title, content }) => (
                            <div className='p-4 col-span-1 flex flex-col rounded border border-softBlack_70/20 bg-white h-[350px] overflow-hidden'>
                              <h6 className='text-lg'>{title}</h6>
                              <div className='flex-1 *:mb-0 overflow-y-auto thin-scrollbar' dangerouslySetInnerHTML={{ __html: sanitizeCode(content) ?? '' }} />
                              <CustomButton
                                className='mt-3'
                                handleClick={() => setSmsBroadcastDetails({
                                  message: content,
                                  campaignName: title
                                })}
                              >
                                <span className='font-medium py-2 px-6 text-base flex gap-2 items-center'>
                                  Copy
                                </span>
                              </CustomButton>
                            </div>
                          ))
                        }
                      </>
                      : <h6 className='col-span-2 h-full text-lg flex justify-center items-center'>SMS template not found</h6>
                  }
                </div>
              </div>
            </>
            : <div className='h-full w-full flex flex-col mt-6 items-center'>
              <div className='grid grid-cols1 md:grid-cols-4 gap-4 w-full'>
                <CardComponent
                  IconSrc={
                    <svg
                      width='32'
                      height='32'
                      viewBox='0 0 32 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M22.6654 5.33301H9.33203V25.333H22.6654V5.33301Z'
                        fill='#2E2B2E'
                      />
                      <path d='M6.66667 8H4V22.6667H6.66667V8Z' fill='#2E2B2E' />
                      <path d='M27.9987 8H25.332V22.6667H27.9987V8Z' fill='#2E2B2E' />
                    </svg>
                  }
                  title='Total SMS Broadcast'
                  count={smsBroadcastList.length}
                />
                <CardComponent
                  IconSrc={
                    <svg
                      width='33'
                      height='32'
                      viewBox='0 0 33 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M24.5 17.3334H23.5933L20.9267 20.0001H23.4733L25.8333 22.6667H7.16667L9.54 20.0001H12.2733L9.60667 17.3334H8.5L4.5 21.3334V26.6667C4.5 28.1334 5.68667 29.3334 7.15333 29.3334H25.8333C27.3 29.3334 28.5 28.1467 28.5 26.6667V21.3334L24.5 17.3334ZM23.1667 10.6001L16.5667 17.2001L11.8467 12.4801L18.4467 5.88005L23.1667 10.6001ZM17.5133 3.05339L9.02 11.5467C8.5 12.0667 8.5 12.9067 9.02 13.4267L15.62 20.0267C16.14 20.5467 16.98 20.5467 17.5 20.0267L25.98 11.5467C26.5 11.0267 26.5 10.1867 25.98 9.66672L19.38 3.06672C18.8733 2.53339 18.0333 2.53339 17.5133 3.05339Z'
                        fill='#2E2B2E'
                      />
                    </svg>
                  }
                  title='Total Number of Recipients'
                  count={countRecipientsDeliveredAndFailed.totalRecipients}
                />
                <CardComponent
                  IconSrc={<UserCheck />}
                  title='Total Delivered'
                  count={countRecipientsDeliveredAndFailed.totalDelivered}
                />
                <CardComponent
                  IconSrc={<CrossIcon />}
                  title='Total Failed'
                  count={countRecipientsDeliveredAndFailed.totalFailed}
                />
              </div>
              <SMSBroadcastTable />
            </div>
        }
      </AdminPageWrapper>
    </>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject
})

const mapDispatchToProps = {}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SMSBroadcast))

SMSBroadcast.propTypes = {
  appProject: propTypes.string
}

// eslint-disable-next-line space-before-function-paren
function ModelAddContacts({ onAdded, existing }) {
  const [show, setShow] = React.useState(false)
  const handleClose = (contacts) => {
    setShow(false)
    if (onAdded && typeof onAdded === 'function') {
      onAdded(contacts)
    }
  }
  const tableRef = React.useRef({
    reloadTableData: () => { }
  })
  const [searchFilter, setSearchFilter] = React.useState('')
  useEffect(() => {
    if (!show) {
      if (onAdded && typeof onAdded === 'function') {
        onAdded([])
      }
    }
  }, [onAdded, show])
  return (
    <div className='w-fit'>
      <Button onClick={() => setShow(true)}>
        Add Contacts
      </Button>
      <CustomModal
        showModal={show}
        onCancel={handleClose}
      >
        <div className='w-[100%] min-w-[600px] sm:min-w-[900px]'>
          <div className='flex justify-between w-full'>
            <div>
              Add Contacts to the list
            </div>
            <div>
              <button onClick={handleClose} className='bg-red-500 hover:bg-red-700 text-white font-bold py-0.5 px-2.5 rounded'>
                Cancel
              </button>
            </div>
          </div>
          <div>
            <input
              type='text'
              placeholder='Search by Name or email'
              className='w-full border border-gray-300 rounded-md px-2 my-1'
              value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
          </div>
          <div>
            <TableGrid
              multiple
              style={{ height: '470px', minHeight: '470px' }}
              allowSelect
              allowSelectAll
              rowSelectKey='id'
              columnDefs={[
                {
                  headerName: 'Email',
                  field: 'email',
                  sortable: true,
                  checkboxSelection: true,
                  flex: 1,
                  resizable: true
                },
                {
                  headerName: 'Name',
                  field: 'firstName',
                  sortable: true,
                  resizable: true,
                  cellRenderer: ({ data }) => {
                    return `${data.firstName} ${data.lastName}`
                  }
                },
                {
                  headerName: 'Phone',
                  field: 'buyerData.phoneNumber',
                  cellRenderer: ({ value }) => {
                    return value ?? 'N/A'
                  }
                },
                {
                  headerName: 'User Type',
                  field: 'userType',
                  sortable: true,
                  cellRenderer: ({ value }) => {
                    const userType = value
                    const availableTags = [
                      ['buyer', 'bg-blue-500 text-white'],
                      ['DeveloperAdmin', 'bg-red-500 text-white'],
                      ['CoopBroker', 'bg-green-500 text-white'],
                      ['LeadBroker', 'bg-green-500 text-white'],
                      ['SalesRep', 'bg-orange-500 text-white']
                    ]
                    return <div>
                      {/* eslint-disable-next-line array-callback-return */}
                      {availableTags.map(([tag, color]) => {
                        if (userType.toLowerCase() === tag.toLowerCase()) {
                          return (
                            <div className={
                              `capitalize text-gray-800 border border-gray-300 px-2 py-1 rounded-full text-xs ${color}`
                            }>
                              {tag}
                            </div>
                          )
                        }
                      })}
                    </div>
                  }
                }
              ]}
              actions={[
                {
                  label: 'Add Selected',
                  progressLabel: 'Adding...',
                  onlyWhenSelected: true,
                  apply: async (selectedRows) => {
                    handleClose(selectedRows)
                  }
                }
              ]}
              getData={async (
                filter,
                pagination,
                sorting
              ) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                  const searchText = {}
                  let search = searchFilter
                  if (search) {
                    if (!isNaN(Number(search))) {
                      search = ''
                    }
                    if (search) {
                      if (!searchText?.$or) {
                        searchText.$or = []
                      }
                      searchText.$or = [
                        { email: { $regex: search, $options: 'i' } },
                        { firstName: { $regex: search, $options: 'i' } },
                        { lastName: { $regex: search, $options: 'i' } }
                      ]
                    }
                  }
                  const { docs: users } = await getUsersPagination({
                    ...searchText,
                    ...filter
                  }, {
                    ...pagination,
                    sort: sorting
                  }, existing)
                  const list = users.filter(e => {
                    return !existing.find(i => i === e.id)
                  })
                  resolve(list)
                })
              }} >
              {(params) => {
                tableRef.current = params
              }}
            </TableGrid>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

const SummaryCard = ({ title, value }) => {
  return (
    <div className="bg-white rounded-lg">
      <div className="text-sm text-gray-600">{title}</div>
      <div className="text-lg font-semibold mt-1">{value}</div>
    </div>
  )
}

const CostRow = ({ label, value, isTotal = false }) => {
  return (
    <div className="flex justify-between items-center">
      <div className={`text-sm ${isTotal ? 'font-semibold' : 'text-gray-600'}`}>
        {label}
      </div>
      <div className={`text-sm font-medium text-black ${isTotal ? 'font-semibold' : ''}`}>
        {value}
      </div>
    </div>
  )
}
