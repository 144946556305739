import { useQuery } from '@tanstack/react-query'
import { getWorkflows, getWorkflow, getFormWorkflow, createNewFormWorkflow, deactivateWorkflow, activateWorkflow, getWorkflowActionsLogs, getWorkflowLogs, updateWorkflow, publishWorkflow, saveWorkflowDraft, createNewWorkflow, getWorkflowActions, getWorkflowTriggers } from 'store/actions/workflowActions'

export const useGetWorkflows = (filters = {}) => {
  const { data, isLoading, refetch } = useQuery(
    ['get-custom-workflows'],
    () => getWorkflows(filters),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false }
  )
  return { workflows: data?.data ?? null, totalRecords: data?.totalRecords ?? 0, isLoading, refetch }
}

export const useWorkflows = () => {
  return {
    getWorkflows,
    getWorkflow,
    getFormWorkflow,
    getWorkflowLogs,
    getWorkflowActionsLogs,
    saveWorkflowDraft,
    activateWorkflow,
    deactivateWorkflow,
    publishWorkflow,
    createNewFormWorkflow,
    createWorkflow: createNewWorkflow,
    getTriggers: getWorkflowTriggers,
    updateWorkflow,
    getActions: getWorkflowActions
  }
}

export default useWorkflows
