// @ts-nocheck
import React from 'react'
import { useHistory } from 'react-router-dom'
import { ADMIN_EDIT_BUILDING } from 'routes'
import {
  Card,
  CardContent
} from '../../components/ShadcnComponents/card'
import { Building, MapPin } from 'lucide-react'

const BuildingCard = ({
  redirectToDetailPage = true,
  imgsrc,
  propertyName,
  price,
  noOfUnits,
  address,
  index,
  id,
  containerClasses = 'p-8'
}) => {
  const history = useHistory()
  return (
    <Card
      className="w-full overflow-hidden cursor-pointer shadow-sm !rounded-lg"
      onClick={() =>
        redirectToDetailPage && history.push({ pathname: ADMIN_EDIT_BUILDING, state: { id } })
      }
    >
      <div className="relative aspect-[4/3] overflow-hidden">
        <img
          src={imgsrc}
          alt={`Building ${propertyName}`}
          className="object-cover w-full h-full"
        />
      </div>
      <CardContent className="p-2 bg-white">
        <h3 className="text-xl font-semibold mb-1">{propertyName}</h3>
        <div className="flex items-center gap-2 text-sm text-muted-foreground mb-1">
          <span>{price}</span>
          <span className="text-muted-foreground/50">•</span>
          <div className="flex items-center gap-1">
            <Building className="h-3.5 w-3.5" />
            <span>{noOfUnits} {noOfUnits > 0 ? 'units' : 'unit'}</span>
          </div>
        </div>
        <div className="flex items-center gap-1 text-sm text-muted-foreground">
          <MapPin className="h-3.5 w-3.5" />
          <span>{address}</span>
        </div>
      </CardContent>
    </Card>
  )
}

export default BuildingCard
