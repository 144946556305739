/* eslint-disable space-before-function-paren */
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import {
  useNodesState,
  useEdgesState
} from '@xyflow/react'
import { v4 } from 'uuid'
import TriggerNode from './Nodes/Triggers/Trigger.node'
import ActionsNode from './Nodes/Actions/Action.node'
import DelayActionNode from './Nodes/Actions/Delay.action'
import SMSActionNode from './Nodes/Actions/SMS.action'
import EmailActionNode from './Nodes/Actions/Emails/Email.action'
import EmailTemplateActionNode from './Nodes/Actions/Emails/EmailTemplates.action'
import EmailNylasActionNode from './Nodes/Actions/Emails/EmailNylas.action'
import SalesRepCreateTaskActionNode from './Nodes/Actions/Tasks/SalesRepTask.actions'
import AddNewActionEdge from './Edges/Add.edge'
import useWorkflows from 'hooks/useCustomWorkflows'

import dagre from '@dagrejs/dagre'

const NODE_CONTAINER_WIDTH = 250
const NODE_CONTAINER_HEIGHT = 150
const newYPositionOffset = 150
const paddingTopAndBottom = 60

export const NODE_TYPE = {
  TRIGGERS: {
    BASE: 'trigger'
  },
  ACTIONS: {
    BASE: 'action',
    DELAY: 'delay',
    SMS: 'sms',
    email: 'email',
    emailTemplate: 'email.template',
    emailNylasTemplate: 'email.nylas.template',
    salesRepTask: 'salesRepTask'
  }
}

export const EDGE_TYPE = {
  ADD_ACTION: 'add-action'
}

const FlowContext = createContext({})

const nodeHeights = {}

const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}))

const getLayoutedElements = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR'
  dagreGraph.setGraph({ rankdir: direction })

  nodes.forEach((node) => {
    const nodeWidth = NODE_CONTAINER_WIDTH
    const nodeHeight = nodeHeights[node?.id] || NODE_CONTAINER_HEIGHT
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight })
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id)
    const nodeWidth = NODE_CONTAINER_WIDTH
    const nodeHeight = nodeHeights[node?.id] || NODE_CONTAINER_HEIGHT
    const newNode = {
      ...node,
      targetPosition: isHorizontal ? 'left' : 'top',
      sourcePosition: isHorizontal ? 'right' : 'bottom',
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2
      }
    }

    return newNode
  })

  return { nodes: newNodes, edges }
}

export const FlowProvider = ({ children, workflow }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(
    workflow?.draftFlow?.nodes || []
  )
  const [selectedNode, setSelectedNode] = useState(null)
  const [edges, setEdges, onEdgesChange] = useEdgesState(
    workflow?.draftFlow?.edges.map(e => {
      return {
        ...e,
        type: EDGE_TYPE.ADD_ACTION
      }
    }) || []
  )
  const [lastNode, setLastNode] = React.useState(null)

  const [addNodeAfterNode, setAddNodeAfterNode] = useState(null)

  const nodeTypes = useMemo(() => ({
    [`${NODE_TYPE.TRIGGERS.BASE}`]: TriggerNode,
    [`${NODE_TYPE.ACTIONS.BASE}`]: ActionsNode,
    [`${NODE_TYPE.ACTIONS.DELAY}`]: DelayActionNode,
    [`${NODE_TYPE.ACTIONS.SMS}`]: SMSActionNode,
    [`${NODE_TYPE.ACTIONS.email}`]: EmailActionNode,
    [`${NODE_TYPE.ACTIONS.emailTemplate}`]: EmailTemplateActionNode,
    [`${NODE_TYPE.ACTIONS.emailNylasTemplate}`]: EmailNylasActionNode,
    [`${NODE_TYPE.ACTIONS.salesRepTask}`]: SalesRepCreateTaskActionNode
  }), [])

  const edgeTypes = useMemo(() => ({
    [EDGE_TYPE.ADD_ACTION]: AddNewActionEdge
  }), [])

  function layoutTheNodes(direction) {
    const { nodes: layoutedNodes, edges: layoutedEdges } =
      getLayoutedElements(nodes, edges, direction)

    setNodes([...layoutedNodes])
    setEdges([...layoutedEdges])
  }

  function addNewNode(nodeConfig, nodeType) {
    const selectedNodeIndex = nodes.findIndex(node => node.id === addNodeAfterNode)
    const selectedNode = nodes[selectedNodeIndex]

    const lastNode = selectedNode || nodes[nodes.length - 1]
    console.log({
      lastNode,
      selectedNode,
      selectedNodeIndex
    })
    const position = {
      x: lastNode?.position.x || 0,
      y: lastNode?.position.y || 0
    }
    let offSet = newYPositionOffset
    if (nodeHeights[lastNode?.id]) {
      offSet = nodeHeights[lastNode?.id]
    }
    position.y += offSet + paddingTopAndBottom

    const randomId = v4()
    const newNode = {
      id: randomId,
      type: nodeConfig.type ?? nodeType,
      data: {
        config: nodeConfig.data.config,
        label: nodeConfig.data.label,
        inputs: nodeConfig.data.inputs,
        outputs: nodeConfig.data.outputs,
        inputValues: {}
      },
      position
    }
    // add node to the nodes array at position selectedNodeIndex + 1
    const newNodes = [...nodes]
    if (selectedNodeIndex === -1) {
      newNodes.push(newNode)
      setNodes(newNodes)
    } else {
      newNodes.splice(selectedNodeIndex + 1, 0, newNode)
      setNodes(newNodes)
    }
  }

  const deleteNode = useCallback((nodeId) => {
    const newNodes = nodes.filter(node => node.id !== nodeId)
    setNodes(newNodes)
  }, [nodes, setNodes])

  const updateTheEdges = useCallback(() => {
    const newEdges = []
    nodes.forEach((node, index) => {
      if (index === 0) return
      const previousNode = nodes[index - 1]
      const edgeId = v4()
      const newEdge = {
        id: edgeId,
        source: previousNode.id,
        target: node.id,
        type: EDGE_TYPE.ADD_ACTION
      }
      newEdges.push(newEdge)
    })
    setEdges(newEdges)
  }, [nodes, setEdges])

  useEffect(() => {
    updateTheEdges()
    setAddNodeAfterNode(null)
  }, [nodes, updateTheEdges])

  const [nodeInputValues, setNodeInputValues] = useState({})

  useEffect(() => {
    if (nodes.length > 0) {
      const lastNode = nodes[nodes.length - 1]
      setLastNode(lastNode)
    }
  }, [nodes])

  useEffect(() => {
    setNodeInputValues(selectedNode?.data?.inputValues || {})
  }, [selectedNode])

  const [availableActions, setAvailableActions] = useState([])
  const [availableTriggers, setAvailableTriggers] = useState([])
  const { getActions, getTriggers } = useWorkflows()

  useEffect(() => {
    getActions().then((response) => {
      setAvailableActions(response)
    })
    getTriggers().then((response) => {
      setAvailableTriggers(response)
    })
  }, [getActions, getTriggers])

  // Function to handle when a node drag stops
  // @ts-ignore
  // @ts-ignore
  const onNodeDragStop = useCallback((event, node) => {
    // Get the new y position
    const newYPosition = node.position.y
    // Set the node's position with the original x and the new y
    setNodes((nds) =>
      nds.map((nd) =>
        nd.id === node.id
          ? { ...nd, position: { x: 0, y: newYPosition } }
          : nd
      )
    )
  }, [])

  function resetNodesPositionInColumn(nodes) {
    // @ts-ignore
    const newNodes = nodes.map((node, index) => {
      return {
        ...node,
        position: {
          ...node.position
          // x: 0,
          // y: index * newYPositionOffset
        }
      }
    })
    setNodes(newNodes)
  }
  function clearNodeSelection() {
    setNodes((nds) => {
      return nds.map((nd) => {
        return {
          ...nd,
          selected: false
        }
      })
    })
  }

  const isTriggerAdded = nodes.length > 0

  const nodeId = selectedNode?.id
  const nodeDataId = selectedNode?.data?.config?.id
  const allNodes = [...availableActions, ...availableTriggers]
  const selectedDataNode = allNodes.find(node => node?.data?.config?.id === nodeDataId)

  const inputs = selectedDataNode?.data?.inputs ?? []
  const outputs = selectedDataNode?.data?.outputs ?? []

  const onKeyDown = useCallback((e) => {
    if (e.code === 'Delete') {
      deleteNode(selectedNode?.id)
    }
  }, [deleteNode, selectedNode?.id])

  useEffect(() => {
    // listen for the keydown event
    document.addEventListener('keydown', (e) => {
      onKeyDown(e)
    })
    // cleanup
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [onKeyDown])
  return (
    <FlowContext.Provider value={{
      newYPositionOffset,
      nodes,
      selectedNode,
      setSelectedNode,
      availableActions,
      availableTriggers,
      nodeId,
      inputs,
      outputs,
      lastNode,
      nodeInputValues,
      isTriggerAdded,
      edges,
      nodeTypes,
      edgeTypes,
      addNewNode,
      deleteNode,
      setAddNodeAfterNode,
      onNodesChange,
      onEdgesChange,
      onNodeDragStop,
      setNodeInputValues,
      resetNodesPositionInColumn,
      onLayout: layoutTheNodes,
      clearNodeSelection,
      setNodeHeight: (nodeId, height) => {
        nodeHeights[nodeId] = height
      }
    }}>
      {children}
    </FlowContext.Provider>
  )
}

export default FlowContext
