/* eslint-disable */
// @ts-nocheck
import TableGrid from 'components/TableGrid/Table'
import { useGetList, useGetUsersFromList, useListController, useListHistoryController } from 'hooks/useGetLists'
import React, { useEffect, useMemo } from 'react'
import { getUsersPagination } from 'store/actions/usersActions'
import CustomModal from 'ui/customModal'
import JobsRunning from './jobsRunning'
import CreateEmailCampaignForList from './sendCampaign'
import { BarChart } from '@tremor/react'
import { getAllEmailCampaigns } from 'store/actions/customEmailCampaignActions'
import useContactContext from 'pages/newContacts/hooks/useContactContext'
import ContactDetailDrawerV2 from 'pages/newContacts/ContactDetailDrawer/ContactDetailDrawerV2'
import CampaignCard from 'components/Campaigns/CampaignCard'
import { RefreshCcw } from 'lucide-react'
import { api } from 'services/api'
import CustomButton from 'components/CustomButton'
import TableWithPagination from 'components/TableWithPagination'
import { LottieWrapper } from 'components'
import loadingAnimation from '../../assets/lottieJsons/avesdoLoading.json'

const DEFAULT_LIMIT = 10

export default function ListDetailsPage(
  props
) {
  const params = props.match.params
  const listId = params.list_id
  const { data: listData, isLoading, refetch: reloadData } = useGetList(listId)
  // const { removeFromList } = useListController()
  const [refreshing, setRefreshing] = React.useState(false)
  const [selectedTab, setSelectedTab] = React.useState('overview')
  const tableRef = React.useRef({
    reloadTableData: () => { }
  })
  const { setSelectedContact } = useContactContext()
  const [currentPage, setCurrentPage] = React.useState(0)
  const [limit, setLimit] = React.useState(DEFAULT_LIMIT)
  const {
    users,
    totalUsers,
    isLoading: isUsersLoading,
    forceRefetch: forceRefetchUsers,
  } = useGetUsersFromList(listId, {
    page: currentPage,
    limit
  })

  const reloadTableData = () => {
    reloadData()
    if (tableRef.current) {
      tableRef.current?.reloadTableData()
    }
  }

  const isSmartList = listData?.systemList || listData?.listType === 'smart'

  return (
    <>
      <div className='px-5 sm:px-10 py-4 flex flex-col gap-y-2'>
        {
          isLoading
            ? <span className='h-9 my-2 animate-pulse bg-zinc-200 rounded-md w-[600px]' />
            : <span className='font-bold text-3xl my-2'>{listData?.title ?? '-'}</span>
        }
        <div>
          <JobsRunning type='list' id={listId} />
        </div>
        <div className='grid grid-cols-4 gap-x-4 mt-2'>
          {/* design card view tailwind css */}
          <div className='flex flex-col gap-y-2 py-4 px-4 shadow-sm bg-white rounded-md'>
            <div className='font-semibold text-gray-600 text-xl'>
              Total Contacts
            </div>
            <div className='flex items-start font-bold text-xl'>
              {
                isLoading
                  ? <div className="items-start font-bold text-xl text-transparent animate-pulse rounded-md bg-zinc-200 w-[80px]">...</div>
                  : <div className="items-start font-bold text-xl">{listData.users.length}</div>
              }
            </div>
          </div>
          <div className='flex flex-col gap-y-2 py-4 px-4 shadow-sm bg-white rounded-md'>
            <div className='font-semibold text-gray-600 text-xl'>
              List Type
            </div>
            <div className='flex items-start font-bold text-xl capitalize'>
              {
                isLoading
                  ? <div className="items-start font-bold text-xl text-transparent animate-pulse rounded-md bg-zinc-200 w-[80px]">...</div>
                  : <div className="items-start font-bold text-xl">{listData.listType ? listData.listType : 'Standard'}</div>
              }
            </div>
          </div>
        </div>
        <div className='flex gap-x-5 justify-between items-end border-b border-border'>
          <div>
            {['overview', 'campaigns', 'settings'].map((tab) => {
              return <button
                key={tab}
                type='button'
                onClick={() => {
                  setSelectedTab(tab)
                }}
                className={`h-full max-w-[350px] uppercase py-2 px-4 text-base font-medium overflow-hidden font-sans ${selectedTab === tab
                  ? 'border-b-softBlack border-[1px] border-t-0 border-l-0 border-r-0 text-softBlack'
                  : 'bg-transparent text-softBlack'
                  }`}
              >
                {tab}
              </button>
            })}
          </div>
        </div>
        {selectedTab === 'overview' &&
          <div className='flex items-center flex-col gap-y-4'>
            {
              !isLoading &&
              <ListAnalytics listId={listId} />
            }
            <div className='flex flex-col w-full'>
              <div className='flex items-center justify-between'>
                <h1 className='text-2xl font-bold'>Users in list</h1>
                <div className='flex-1 flex items-center justify-end gap-x-2'>
                  <div>
                    {(isSmartList) && (
                      <button
                        disabled={refreshing}
                        onClick={() => {
                          setRefreshing(true)
                          api.post('/lists/smart/refresh', { listIds: [listId] }).then(() => {
                            // refresh page
                            reloadData()
                          }).finally(() => {
                            setRefreshing(false)
                          })
                          forceRefetchUsers()
                        }}
                        className='bg-black text-white font-bold py-2 px-4 rounded whitespace-nowrap flex gap-x-1 items-center justify-center'>
                        <RefreshCcw size={18} /> {refreshing ? 'Refreshing...' : 'Refresh Data'}
                      </button>
                    )}
                  </div>
                  <ModelCreateEmailCampaign listId={listData?._id} />
                  {(!isSmartList) && <ModelAddContacts listId={listData?._id} onAdded={reloadTableData} />}
                </div>
              </div>
              <div className='flex-1 w-full flex flex-col gap-4 pt-2 pb-4'>
                <TableWithPagination
                  columns={[
                    {
                      headerName: 'Email',
                      field: 'email',
                      flex: 3,
                      onCellClicked: (params) => {
                        if (params.data._id) {
                          setSelectedContact({ _id: params.data._id })
                        }
                      }
                    },
                    {
                      headerName: 'First Name',
                      field: 'firstName',
                      flex: 1,
                      sortable: true
                    },
                    {
                      headerName: 'Last Name',
                      field: 'lastName',
                      flex: 1,
                      sortable: true
                    }
                  ]}
                  rowData={users}
                  totalCount={totalUsers}
                  page={currentPage}
                  setPage={setCurrentPage}
                  limit={limit}
                  setLimit={setLimit}
                  loading={isUsersLoading}
                />
              </div>
              {/* <TableGrid
              multiple
              style={{ height: '500px', minHeight: '500px' }}
              allowSelect={!isSmartList}
              allowSelectAll={!isSmartList}
              actions={[
                {
                  label: 'Remove From List',
                  progressLabel: 'Removing...',
                  onlyWhenSelected: true,
                  apply: async (selectedRows) => {
                    try {
                      await removeFromList(listId, selectedRows)
                      alert('Contacts removed from list')
                      reloadTableData()
                    } catch (e) {
                      alert('Error adding contacts to list')
                    }
                  }
                }
              ]}
              getData={(filter, pagination, sorting) => {
                return new Promise((resolve) => {
                  // Row Data: The data to be displayed.
                  const rowData = listData.users
                  resolve(rowData)
                })
              }}
              columnDefs={[
                {
                  headerName: 'Email',
                  field: 'email',
                  flex: 3,
                  onCellClicked: (params) => {
                    if (params.data._id) {
                      setSelectedContact({ _id: params.data._id })
                    }
                  }
                },
                {
                  headerName: 'First Name',
                  field: 'firstName',
                  flex: 1,
                  sortable: true
                },
                {
                  headerName: 'Last Name',
                  field: 'lastName',
                  flex: 1,
                  sortable: true
                }
              ]}
              pagination={true}
            >
              {(params) => {
                tableRef.current = params
              }}
            </TableGrid> */}
            </div>
          </div>
        }
        {
          selectedTab === 'campaigns' &&
          <div className='flex items-center flex-col gap-y-4'>
            <CampaignsList listId={listId} listName={listData?.title ?? ''} />
          </div>
        }
        {
          selectedTab === 'settings' &&
          <div className='flex items-center flex-col gap-y-4'>
            <div className='flex flex-col gap-y-2'>
              We are working on this feature. Please check back later.
            </div>
          </div>
        }
      </div>
      <ContactDetailDrawerV2 resetPath={`/admin/lists/${listId}`} />
    </>
  )
}

function CampaignsList({ listId, listName }) {
  const [campaigns, setCampaigns] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    getAllEmailCampaigns(`userList=${listId}`).then((data) => {
      data = data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      setCampaigns(data)
    }).then(() => {
      setIsLoading(false)
    })
  }, [listId])
  if (isLoading) {
    return <div>Loading...</div>
  }
  const filteredCampaigns = campaigns
    .filter((campaign) => {
      let _listId = ''
      if (campaign?.userList) {
        if (typeof campaign?.userList === 'object') {
          _listId = campaign?.userList?._id
        } else if (typeof campaign?.userList === 'string') {
          _listId = campaign?.userList
        }
      }
      return _listId === listId
    })
  return (
    <div className='flex flex-col gap-y-4 w-full'>
      {filteredCampaigns.length === 0 && <div className='flex flex-col my-5 gap-y-2 w-full justify-center items-center'>
        <div className='text-2xl font-bold'>No Campaigns Created</div>
        <ModelCreateEmailCampaign listId={listId} />
      </div>}
      {filteredCampaigns.length > 0 && <div className='flex items-center justify-end gap-x-2 w-full'>
        <ModelCreateEmailCampaign listId={listId} />
      </div>}
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 pb-5'>
        {filteredCampaigns.map((campaign, index) => {
          return (
            <CampaignCard campaign={campaign} key={campaign._id} listName={listName} showPreviewDefault={index === 0} />
          )
        })}
      </div>
    </div>
  )
}

function ModelAddContacts({ listId, onAdded }) {
  const [show, setShow] = React.useState(false)
  const { addToList } = useListController()
  const handleClose = () => { setShow(false) }
  const tableRef = React.useRef({
    reloadTableData: () => { }
  })
  const [searchFilter, setSearchFilter] = React.useState('')
  useEffect(() => {
    if (!show) {
      if (onAdded && typeof onAdded === 'function') {
        onAdded()
      }
    }
  }, [onAdded, show])
  return (
    <div className='w-fit'>
      <button onClick={() => setShow(true)} className='w-fit bg-black text-white font-bold py-2 px-4 rounded'>
        Add Contacts
      </button>
      <CustomModal
        showModal={show}
        onCancel={handleClose}
      >
        <div className='w-[100%] min-w-[600px] sm:min-w-[900px]'>
          <div className='flex justify-between w-full'>
            <div>
              Add Contacts to the list
            </div>
            <div>
              <button onClick={handleClose} className='bg-red-500 hover:bg-red-700 text-white font-bold py-0.5 px-2.5 rounded'>
                Cancel
              </button>
            </div>
          </div>
          <div>
            <input
              type='text'
              placeholder='Search by Name or email'
              className='w-full border border-gray-300 rounded-md px-2 my-1'
              value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} />
          </div>
          <div>
            <TableGrid
              multiple
              style={{ height: '470px', minHeight: '470px' }}
              allowSelect
              allowSelectAll
              rowSelectKey='id'
              columnDefs={[
                {
                  headerName: 'Email',
                  field: 'email',
                  sortable: true,
                  checkboxSelection: true,
                  flex: 1
                },
                {
                  headerName: 'First Name',
                  field: 'firstName',
                  sortable: true
                },
                {
                  headerName: 'Last Name',
                  field: 'lastName',
                  sortable: true
                },
                {
                  headerName: 'User Type',
                  field: 'userType',
                  sortable: true,
                  cellRenderer: ({ value }) => {
                    const userType = value
                    const availableTags = [
                      ['buyer', 'bg-blue-500 text-white'],
                      ['DeveloperAdmin', 'bg-red-500 text-white'],
                      ['CoopBroker', 'bg-green-500 text-white'],
                      ['LeadBroker', 'bg-green-500 text-white'],
                      ['SalesRep', 'bg-orange-500 text-white']
                    ]
                    return <div>
                      {/* eslint-disable-next-line array-callback-return */}
                      {availableTags.map(([tag, color]) => {
                        if (userType.toLowerCase() === tag.toLowerCase()) {
                          return (
                            <div className={
                              `capitalize text-gray-800 border border-gray-300 px-2 py-1 rounded-full text-xs ${color}`
                            }>
                              {tag}
                            </div>
                          )
                        }
                      })}
                    </div>
                  }
                }
              ]}
              actions={[
                {
                  label: 'Add to List',
                  progressLabel: 'Adding...',
                  onlyWhenSelected: true,
                  apply: async (selectedRows) => {
                    try {
                      await addToList(listId, selectedRows)
                      handleClose()
                    } catch (e) {
                      alert('Error adding contacts to list')
                    }
                  }
                }
              ]}
              getData={async (
                filter,
                pagination,
                sorting
              ) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise(async (resolve) => {
                  const searchText = {}
                  let search = searchFilter
                  if (search) {
                    if (!isNaN(Number(search))) {
                      search = ''
                    }
                    if (search) {
                      if (!searchText?.$or) {
                        searchText.$or = []
                      }
                      searchText.$or = [
                        { email: { $regex: search, $options: 'i' } },
                        { firstName: { $regex: search, $options: 'i' } },
                        { lastName: { $regex: search, $options: 'i' } }
                      ]
                    }
                  }
                  const { docs: users } = await getUsersPagination({
                    type: 'suggestion-for-list',
                    value: listId,
                    ...searchText,
                    ...filter
                  }, {
                    ...pagination,
                    sort: sorting
                  })
                  resolve(users)
                })
              }} >
              {(params) => {
                tableRef.current = params
              }}
            </TableGrid>
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

function ModelCreateEmailCampaign({ listId }) {
  const [show, setShow] = React.useState(false)
  return (
    <div>
      <CustomButton handleClick={() => setShow(true)}>
        <span className='font-medium placeholder py-2 px-4 text-base flex gap-2 items-center'>
          Create Campaign
        </span>
      </CustomButton>
      <CreateEmailCampaignForList
        selectedListId={listId}
        onClose={() => {
          setShow(false)
        }}
        open={show}
      />
    </div>
  )
}

function ListAnalytics(props) {
  const listId = props.listId
  const { data, isLoading } = useListHistoryController(listId)
  const chartData = useMemo(() => {
    return data?.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .slice(-20)
      .map((item) => {
        return {
          date: new Date(item.createdAt).toLocaleString(),
          Contacts: item?.finalContacts?.length ?? 0,
          Added: item?.addedContacts?.length ?? 0,
          Removed: item?.removedContacts?.length ?? 0
        }
      })
  }, [data])

  const dataFormatter = (number) =>
    Intl.NumberFormat('us').format(number).toString()

  return (
    <div className='w-full bg-white shadow-sm rounded-md p-4'>
      {
        isLoading
          ? <div className='h-[320px] w-full flex flex-col gap-2 items-center justify-center'>
            <LottieWrapper
              animation={loadingAnimation}
              autoplay
              loop={true}
              className="-mt-40 md:mt-0 max-w-sm h-[80px] w-[80px]"
            />
            <p className='text-center mb-0'>Loading...</p>
          </div>
          : <>
            <BarChart
              data={chartData}
              index="date"
              categories={[
                'Contacts',
                'Added',
                'Removed'
              ]}
              showTooltip
              showLegend
              showGridLines
              colors={[
                'gray',
                'green',
                'red'
              ]}
              xAxisLabel='Date & Time'
              yAxisLabel='List Contacts'
              valueFormatter={dataFormatter}
            />
          </>
      }
    </div>
  )
}
