/*eslint-disable */
// @ts-nocheck
import TableGrid from "components/TableGrid/Table"
import useGetLists, { useGetListsAll } from "hooks/useGetLists"
import { useGetUsersCounts } from "hooks/useGetUsers"
import useProjectId from "hooks/useProjectId"
import React, { useEffect, useMemo } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom"
import CreateListDialog from "./createList"
import useContactContext from "pages/newContacts/hooks/useContactContext"
import { Star } from "lucide-react"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "components/ShadcnComponents/tabs"
import { TabWrapper } from "pages/whiteCapDashboard"
import { message } from "antd"
import { updateFavouriteList } from "store/actions/usersActions"
import TableWithPagination from "components/TableWithPagination"

const DEFAULT_LIMIT = 10

const TABS = [
  {
    id: "favourite",
    name: "Favourite",
  },
  {
    id: "all",
    name: "All",
  },
  // {
  //   id: "other",
  //   name: "Other",
  // },
]

export default function ListsManagement() {
  const gridRef = React.useRef(null);
  const { projectId } = useProjectId()
  const { view } = useContactContext()
  const [activeTab, setActiveTab] = React.useState(TABS[0].id)
  const [currentPage, setCurrentPage] = React.useState(0)
  const [limit, setLimit] = React.useState(DEFAULT_LIMIT)
  const {
    lists,
    totalLists,
    isLoading: isLoadingList,
    refetch: refetchLists,
    forceRefetch,
    totalSmartList
  } = useGetLists({
    page: currentPage,
    limit,
    projectId: view === "corporate" ? undefined : projectId,
    isFavourite:
      activeTab === "favourite"
        ? true
        // : activeTab === "other"
        //   ? false
        : undefined
  })

  const history = useHistory()
  const { count: usersCounts, isLoading: isLoadingCounts } =
    useGetUsersCounts(projectId)

  useEffect(() => {
    refetchLists()
  }, [refetchLists, projectId])

  const handleFavouriteChange = async (listId, updatedStatus) => {
    await updateFavouriteList(listId, updatedStatus);
    forceRefetch();
  };

  const columns = useMemo(() => {
    const result = [
      {
        headerName: "",
        width: 60,
        field: "title",
        resizable: true,
        cellRenderer: (params) => {
          return (
            <div className="flex gap-x-2">
              <Star
                size={24}
                fill={params.data.isFavourite ? "#f59f0a" : "none"}
                className="text-yellow-500 cursor-pointer"
                onClick={() => handleFavouriteChange(params.data._id, !params.data.isFavourite)}
              />
            </div>
          )
        },
      },
      {
        headerName: "List Name",
        field: "title",
        resizable: true,
        flex: 1,
        minWidth: 400,
        cellRenderer: (params) => {
          return (
            <div className="flex flex-row gap-x-1">
              <div>
                <button
                  className="bg-gray-500 text-white border border-border px-2  py-1 rounded-full text-xs"
                  onClick={() => {
                    history.push(
                      `${window.location.pathname}/${params.data._id}`
                    )
                  }}
                >
                  View
                </button>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  history.push(`${window.location.pathname}/${params.data._id}`)
                }}
              >
                {params.value}
              </div>
            </div>
          )
        },
      },
      {
        headerName: "List Type",
        field: "listType",
        resizable: true,
        cellRenderer: ({ value }) => {
          return (
            <div>
              {value === "static" && (
                <div className="bg-gray-500 text-white border border-border px-2  py-1 rounded-full text-xs">
                  Standard
                </div>
              )}
              {value === "smart" && (
                <div className="bg-blue-500 text-white border border-border px-2  py-1 rounded-full text-xs">
                  Smart
                </div>
              )}
            </div>
          )
        },
      },
      {
        headerName: "Project",
        width: 200,
        field: "project",
        resizable: true,
        cellRenderer: (params) => {
          const project = params?.data?.projectId
          return <div className="flex gap-x-2">{project?.projectName}</div>
        },
      },
      {
        headerName: "Total Contacts",
        field: "users",
        valueGetter: (params) => params?.data?.users?.length || 0,
        resizable: true,
      },
      {
        headerName: "SendGrid",
        field: "sendGrid",
        valueGetter: (params) => params?.data?.sendGrid?.contacts || 0,
        resizable: true,
        cellRenderer: (params) => {
          const sendGrid = params?.data?.sendGrid
          if (!sendGrid) return <div>Not synced</div>
          const value = sendGrid?.contacts || 0
          const listId = sendGrid?.listId
          return <div>
            {!listId &&
              <div>{value}</div>
            }
            {listId && (
              <a href={`https://mc.sendgrid.com/contacts/lists/${listId}`} target="_blank" rel="noreferrer" className="text-blue-500 underline">{value}</a>
            )}
          </div >
        }
      },
      {
        headerName: "Created On",
        field: "createdAt",
        sortable: true,
        resizable: true,
        cellRenderer: ({ value }) => {
          return new Date(value).toLocaleString()
        },
      },
      {
        headerName: "Updated On",
        field: "updatedAt",
        sortable: true,
        resizable: true,
        cellRenderer: ({ value }) => {
          return new Date(value).toLocaleString()
        },
      },
    ]

    if (view === "project") {
      return result.filter((item) => item.headerName !== "Project")
    }
    return result
  }, [view])

  useEffect(() => {
    setCurrentPage(0)
  }, [activeTab])

  return (
    <div className="px-5 sm:px-10 h-full flex flex-col py-4 gap-3 bg-siteColor">
      <div className="flex w-full justify-between items-center">
        <div className="max-w-136">
          <TabWrapper
            tabMenus={TABS}
            setSelectedTab={setActiveTab}
            selectedTab={activeTab}
          />
        </div>

        <CreateListDialog
          isCorporate={view === "corporate"}
          refreshData={() => {
            refetchLists()
          }}
        />
      </div>
      <div className="grid grid-cols-3 sm:grid-cols-4 gap-x-4">
        {/* design card view tailwind css */}
        <div className="flex flex-col gap-y-2 py-4 px-4 shadow-sm bg-white rounded-md">
          <div className="font-semibold text-gray-600 text-xl">
            Total Contacts
          </div>
          <div className="flex items-start font-bold text-xl">
            {
              isLoadingCounts
                ? <div className="items-start font-bold text-xl text-transparent animate-pulse rounded-md bg-zinc-200 w-[80px]">...</div>
                : <div className="items-start font-bold text-xl">{usersCounts}</div>
            }
          </div>
        </div>
        <div className="flex flex-col gap-y-2 py-4 px-4 shadow-sm bg-white rounded-md">
          <div className="font-semibold text-gray-600 text-xl">Total Lists</div>
          {
            isLoadingList
              ? <div className="items-start font-bold text-xl text-transparent animate-pulse rounded-md bg-zinc-200 w-[80px]">...</div>
              : <div className="items-start font-bold text-xl">{totalLists}</div>
          }
        </div>
        <div className="flex flex-col gap-y-2 py-4 px-4 shadow-sm bg-white rounded-md">
          <div className="font-semibold text-gray-600 text-xl">Smart Lists</div>
          <div className="flex items-start font-bold text-xl">
            {
              isLoadingList
                ? <div className="items-start font-bold text-xl text-transparent animate-pulse rounded-md bg-zinc-200 w-[80px]">...</div>
                : <div className="items-start font-bold text-xl">{totalSmartList}</div>
            }
          </div>
        </div>
      </div>
      <div className='flex-1 w-full flex flex-col gap-4 pt-2 pb-4'>
        <TableWithPagination
          columns={columns}
          rowData={lists}
          totalCount={totalLists}
          page={currentPage}
          setPage={setCurrentPage}
          limit={limit}
          setLimit={setLimit}
          loading={isLoadingList}
        />
      </div>
    </div>
  )
}
