/* eslint-disable */
import React, { useCallback } from 'react'
import * as ReactFlow from '@xyflow/react'
import Container from '../Container'

const { Handle, Position } = ReactFlow

export default function TriggerNode(_data) {
    const selected = _data.selected
    const data = _data.data
    const config = data.config

    return (
        <Container _data={_data} config={config} selected={selected} >
            <Handle type="source" position={Position.Bottom} />
        </Container>
    )
}
