/* eslint-disable */
import { PlusIcon, SettingsIcon } from 'lucide-react'
import { Drawer } from 'antd'
import { getCustomForm, updateCustomFormSettings } from 'store/actions/customFormActions'
import CustomInput from 'components/CustomInput'
import { getProjects } from 'store/actions/projectActions'
import CustomSelect from 'components/VerticalCardProfile/components/CustomSelect'
import { getUserLists } from 'store/actions/usersActions'
import useLeadSource from 'hooks/useLeadSource'
import React, { useEffect, useState } from 'react'
import CustomButton from 'components/CustomButton'
import { getEmailTemplates } from 'store/actions/emailTemplateActions'
import useWorkflows from 'hooks/useCustomWorkflows'
import WorkflowsView from 'pages/workflows/view'
import ReactFlowProviderComponent from 'components/ReactFlowProvider'
import ToggleSwitch from 'components/ToggleSwitch'

export default function FormSettingsDrawer({ formId, onClose }) {
    const [showDrawer, setShowDrawer] = React.useState(false)
    const [form, setForm] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [saving, setSaving] = React.useState(false)
    const [loadingLists, setLoadingLists] = React.useState(true)
    const [lists, setLists] = React.useState([])
    const [loadingProjects, setLoadingProjects] = React.useState(true)
    const [projects, setProjects] = React.useState([])
    const { formattedLeadsources: leadSources } = useLeadSource()
    const [recaptchaEnabled, setRecaptchaEnabled] = React.useState(false)
    const [formSettings, setFormSetting] = React.useState({
        projectId: null,
        leadSource: 'Website',
        usersLists: [],
        autoReplyEmailTemplate: '',
        successRedirect: '',
        alreadyExistRedirect: '',
        errorRedirect: '',
        recaptchaEnabled: false
    })
    const [sendGridOptions, setSendGridOptions] = React.useState([])
    const [isFetchingSendGridOptions, setIsFetchingSendGridOptions] = React.useState(true)

    const getAllTemplates = () => {
        setIsFetchingSendGridOptions(true)
        getEmailTemplates().then((response) => {
            setSendGridOptions([...response.map((elm) => ({ label: elm.name, value: elm.sendGridTemplateId }))])
            setIsFetchingSendGridOptions(false)
        }).catch((error) => console.log(error))
    }

    useEffect(() => {
        getAllTemplates()
    }, [])

    useEffect(() => {
        setFormSetting({
            ...formSettings,
            recaptchaEnabled: recaptchaEnabled
        })
    }, [recaptchaEnabled])

    useEffect(() => {
        setLoadingProjects(true)
        getProjects().then((response) => {
            setProjects(response)
        }).finally(() => {
            setLoadingProjects(false)
        })
    }, [
        formId,
        showDrawer
    ])

    useEffect(() => {
        setLoadingLists(true)
        getUserLists().then((response) => {
            const lists = response?.data
            const staticLists = lists.filter((list) => {
                return !list?.systemList && list?.listType !== 'smart'
            })
            setLists(staticLists.map(e => {
                return {
                    _id: e._id,
                    name: e.title
                }
            }))
        }).finally(() => {
            setLoadingLists(false)
        })
    }, [
        formId, showDrawer, formSettings.projectId
    ])

    useEffect(() => {
        if (formId) {
            getCustomForm(formId).then((response) => {
                setForm(response.data)
                setFormSetting(response.data.settings || {
                    projectId: null,
                    leadSource: 'Website',
                    usersLists: [],
                    autoReplyEmailTemplate: '',
                    successRedirect: '',
                    alreadyExistRedirect: '',
                    errorRedirect: '',
                    recaptchaEnabled: false
                })
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [
        formId, showDrawer
    ])
    if (!form) return null

    const disabled = saving || loading

    function saveFormSettings() {
        if (saving) return
        setSaving(true)
        const settings = {
            ...form.settings,
            ...formSettings
        }
        updateCustomFormSettings(formId, settings).then((response) => {
            console.log(response)
        }).finally(() => {
            setSaving(false)
        })
    }

    const selectedLists = []
    if (lists && formSettings?.usersLists?.length > 0) {
        formSettings.usersLists.map((listId) => {
            const result = lists.findIndex((list) => list._id === listId)
            if (result !== -1) {
                selectedLists.push({
                    value: listId,
                    label: lists[result].name
                })
            }
            return true
        })
    }
    return (
        <div>
            <CustomButton
                className='py-1 px-2 flex gap-x-1 rounded-full'
                onClick={() => setShowDrawer(!showDrawer)}
            >
                <SettingsIcon size={14} />
                <span>Setting</span>
            </CustomButton>
            <Drawer title='Form Settings'
                size='large'
                visible={showDrawer} onClose={() => {
                    if (onClose && typeof onClose === 'function') {
                        onClose()
                    }
                    setShowDrawer(false)
                }}
                width={"100%"}>
                <div className='flex flex-row divide-x gap-x-2.5 max-h-[100%]'>
                    <div className='max-w-xs px-2 py-4 flex-1 overflow-y-auto'>
                        <h1 className='text-sm font-semibold'>{form.name}</h1>
                        <p className='font-light text-gray-400 text-sm'>
                            Manage form and automation settings for this form. You can set project, lead source, users list, auto reply email template and redirect URL(s) on different events.
                        </p>
                        <div className='flex flex-col gap-y-2 divide-y my-3'>
                            <div className='py-2'>
                                {loadingProjects && <div>Loading Projects...</div>}
                                {!loadingProjects && projects?.length > 0 && (
                                    <CustomSelect
                                        label="Choose Project"
                                        options={projects.map((project) => ({
                                            value: project._id,
                                            label: project.projectName
                                        }))}
                                        value={formSettings.projectId ? [formSettings.projectId] : []}
                                        handleChange={(value) => {
                                            setFormSetting({
                                                ...formSettings,
                                                projectId: value
                                            })
                                        }}
                                        optionFilterProp="label"
                                    />
                                )}
                            </div>
                            <div className='py-2'>
                                {loadingLists && <div className='w-full h-[66px] flex justify-center items-center text-center'>Loading Lists...</div>}
                                {!loadingLists && lists?.length > 0 && (
                                    <CustomSelect
                                        mode='tags'
                                        label="Add Users to Lists when they submit this form"
                                        options={lists.map((list) => ({
                                            value: list._id,
                                            label: list.name
                                        }))}
                                        value={selectedLists}
                                        handleChange={(indexes) => {
                                            const listIds = indexes.map((index) => {
                                                const indexValue = Number(index)
                                                if (isNaN(indexValue)) return index
                                                const result = lists[index]?._id || null
                                                return result
                                            }).filter((e) => e)
                                            // remove duplicates
                                            const finalLists = []
                                            listIds.map((listId) => {
                                                if (!finalLists.includes(listId)) {
                                                    finalLists.push(listId)
                                                }
                                                return true
                                            })
                                            setFormSetting({
                                                ...formSettings,
                                                usersLists: finalLists
                                            })
                                        }}
                                    />
                                )}
                            </div>
                            <div className='py-2'>
                                <CustomSelect
                                    label='Lead Source'
                                    options={leadSources}
                                    value={formSettings.leadSource}
                                    handleChange={(value) => {
                                        setFormSetting({
                                            ...formSettings,
                                            leadSource: value
                                        })
                                    }}
                                />
                            </div>
                            <div className='py-2 flex flex-col gap-3'>
                                <p className="my-2 text-sm font-normal leading-[0.16px]">
                                    Auto Reply Email Template-ID (SendGrid)
                                </p>
                                <CustomInput
                                    type='text'
                                    classes='max-h-14'
                                    label='Enter custom email template ID'
                                    placeholder='d-1234567890abcdef'
                                    onChange={(e) => {
                                        setFormSetting({
                                            ...formSettings,
                                            autoReplyEmailTemplate: e.target.value
                                        })
                                    }}
                                    value={formSettings.autoReplyEmailTemplate}
                                    containerClasses={undefined}
                                    disabled={disabled}
                                    labelClassName='text-softBlack_70/50 !text-xs !font-normal'
                                />
                                <p className="w-full text-center my-2 text-softBlack_70/50 text-xs font-normal uppercase leading-[0.16px]">
                                    OR
                                </p>
                                <CustomSelect
                                    label="Select email template"
                                    loading={isFetchingSendGridOptions}
                                    options={sendGridOptions}
                                    value={formSettings.autoReplyEmailTemplate}
                                    handleChange={(value) => {
                                        setFormSetting({
                                            ...formSettings,
                                            autoReplyEmailTemplate: value
                                        })
                                    }}
                                    optionFilterProp="label"
                                    placeholder="d-1234567890abcdef"
                                />
                            </div>
                            <div className='py-2'>
                                <div>
                                    Redirect URL(s) on different events
                                </div>
                                <CustomInput
                                    type='text'
                                    classes='max-h-14'
                                    label='Success Redirect'
                                    placeholder='https://example.com/success'
                                    onChange={(e) => {
                                        setFormSetting({
                                            ...formSettings,
                                            successRedirect: e.target.value
                                        })
                                    }}
                                    value={formSettings.successRedirect}
                                    containerClasses={undefined}
                                    disabled={disabled}
                                />

                                <CustomInput
                                    type='text'
                                    classes='max-h-14'
                                    label='Already Exist Redirect'
                                    placeholder='https://example.com/already-exist'
                                    onChange={(e) => {
                                        setFormSetting({
                                            ...formSettings,
                                            alreadyExistRedirect: e.target.value
                                        })
                                    }}
                                    value={formSettings.alreadyExistRedirect}
                                    containerClasses={undefined}
                                    disabled={disabled}
                                />

                                <CustomInput
                                    type='text'
                                    classes='max-h-14'
                                    label='Error Redirect'
                                    placeholder="https://example.com/error"
                                    onChange={(e) => {
                                        setFormSetting({
                                            ...formSettings,
                                            errorRedirect: e.target.value
                                        })
                                    }}
                                    value={formSettings.errorRedirect}
                                    containerClasses={undefined}
                                    disabled={disabled} />

                            </div>
                            <div className='flex items-center justify-between py-2'>
                                <div>
                                    Google reCAPTCHA
                                    <p className='text-[12px] text-gray-400'>
                                        Enable Google reCAPTCHA to prevent spam submissions on this form
                                    </p>
                                </div>
                                <ToggleSwitch checked={formSettings?.recaptchaEnabled} setChecked={setRecaptchaEnabled} />
                            </div>
                        </div>
                        <div className='flex'>
                            <CustomButton
                                disabled={saving}
                                className='py-2 px-3.5'
                                onClick={() => {
                                    saveFormSettings()
                                }}
                            >
                                {saving ? 'Saving...' : 'Save'}
                            </CustomButton>
                        </div>
                    </div>
                    <div className='px-2 flex-1 max-h-screen overflow-y-auto'>
                        <FormAutomationSetting formId={formId} />
                    </div>
                </div>
            </Drawer>
        </div>
    )
}


function FormAutomationSetting({
    formId
}) {
    const [loading, setLoading] = useState(true)
    const [workflow, setWorkflow] = useState(null)
    const [creating, setCreating] = useState(false)
    const [noWorkflow, setNoWorkflow] = useState(false)
    const [count, setCount] = useState(0)
    const { getFormWorkflow, createNewFormWorkflow } = useWorkflows()

    useEffect(() => {
        setLoading(true)
        getFormWorkflow(formId).then((response) => {
            console.log('response', response)
            const success = response.success
            if (success) {
                setWorkflow(response.data)
                setNoWorkflow(false)
            } else {
                setNoWorkflow(true)
            }
        }).finally(() => {
            setLoading(false)
        }).catch(() => {
            setNoWorkflow(true)
            setLoading(false)
        })
    }, [count])
    function reload() {
        setCount((e) => e + 1)
    }

    function createFormAutomationWorkflow() {
        setCreating(true)
        createNewFormWorkflow(formId, {}).then((response) => {
            reload()
        }).finally(() => {
            setCreating(false)
        })
    }

    if (loading) return <div>Loading...</div>
    if (noWorkflow) return <div className='w-full flex items-center justify-center flex-col'>
        <div className='text-center'>
            <h1 className='text-lg font-semibold'>
                No Form Automation workflow found
            </h1>
            <p className='font-light text-gray-400 text-sm'>
                Create a new workflow to automate actions on form submission
            </p>
        </div>
        <div>
            <CustomButton
                disabled={creating}
                className='py-2 px-3.5 flex'
                onClick={() => {
                    if (creating) return
                    createFormAutomationWorkflow()
                }}
            >
                <PlusIcon size={18} />
                {creating ? 'Creating...' : 'Create Workflow'}
            </CustomButton>
        </div>
    </div>

    return (
        <ReactFlowProviderComponent
            workflow={workflow}
            onlyTriggerCategory={[
                'forms'
            ]} />
    )
}

