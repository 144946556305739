// @ts-nocheck
/* eslint-disable */
import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { getDocusealConfig } from "store/actions/docusealConfigAction"

const useDocusealConfigModal = () => {
  const [docusealData, setDocusealData] = useState(null)
  const [loading, setLoading] = useState(false)
  const userObject = useSelector((state) => state.authReducer.userObject)
  const projectId = useSelector((state) => state.appReducer.appProject)

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        setLoading(true)
        const response = await getDocusealConfig(projectId)
        if (response.authToken && response.apiUrl && response.email) {
            setDocusealData(response)
        } else {
            setDocusealData(null)
        }
      } catch (error) {
        setDocusealData(null)
        console.error("Error fetching Docuseal config:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchConfig()
  }, [projectId])

  return {
    docusealData,
    userObject,
    loading
  }
}

export default useDocusealConfigModal
