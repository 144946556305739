// @ts-nocheck
/* eslint-disable */
import useGetUsers from 'hooks/useGetUsers'
import useGetUsersByParam from 'hooks/useGetUsersByParam'
import useSearchParams from 'hooks/useSearchParams'
import moment from 'moment'
import React, { createContext, useEffect, useRef, useState } from 'react'
import useGetFilterUsers from '../../../hooks/useGetFilterUsers'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import toast from 'react-hot-toast'

export const sortContacts = (a, b, type) => {
  if (moment(a.createdAt).isBefore(b.createdAt)) return type === 'asc' ? 1 : -1
  else if (moment(a.createdAt).isAfter(b.createdAt)) return type === 'asc' ? -1 : 1
  return 0
}

export const filterNullAssignments = (assignments) => {
  if (!assignments?.length) return false
  let result = null
  result = assignments?.some?.(
    (_assignment) =>
      _assignment?.salesRep?._id?.length || _assignment?.salesRep?.length
  )
  return result ?? true
}

export const filterAssignmentsBySaleRepId = (
  assignments,
  userObject,
  skipForDevAdmin = false,
  salesRepId = null
) => {
  if ((!skipForDevAdmin && !assignments?.length) || !userObject) {
    return false
  }
  let result = null
  if (userObject?.userType === 'SalesRep') {
    result = assignments?.some?.((_assignment) => {
      return (
        _assignment?.salesRep?._id === (salesRepId ?? userObject?._id) ||
        _assignment?.salesRep === (salesRepId ?? userObject?._id)
      )
    })
  } else {
    if (userObject?.userType === 'DeveloperAdmin' && skipForDevAdmin) {
      result = true
    } else {
      result = filterNullAssignments(assignments)
    }
  }
  return result ?? true
}

const LIMIT = 100

export const MainContactContext = createContext({
  LIMIT,
  openFilterDrawer: () => { },
  closeFilterDrawer: () => { },
  toggleFilterDrawer: () => { },
  applyBuyerFilter: () => { },
  applyDefaultFilter: () => { },
  applyBrokerFilter: () => { },
  openContactDetailDrawer: () => { },
  closeContactDetailDrawer: (path) => { },
  setSelectedContact: (contact) => { },
  setSearch: (search) => { },
  setFilters: (filters) => { },
  toggleCheckContact: (contactId, needToOpenAddToListDrawer, currentIndex) => { },
  toggleCheckAllContacts: () => { },
  resetFilters: () => { },
  openAddToListDrawer: () => { },
  openAssignSalesRep: () => { },
  closeAssignSalesRep: () => { },
  openDownloadContactsModal: () => { },
  openCreateContactModal: () => { },
  closeCreateContactModal: () => { },
  closeDownloadContactsModal: () => { },
  closeAddToListDrawer: () => { },
  toggleCheckList: (listId) => { },
  toggleProjectList: (projectId) => { },
  emptyProjectList: () => { },
  emptyCheckedSelections: () => { },
  refetch: () => { },
  setState: (state) => { },
  refetchFilteredUser: () => { },
  sortContactsByCreatedDate: (a, b) => { },
  toggleContactsSortingType: () => { },
  refreshUsers: () => { },
  setWaitingModal: (value) => { },
  setView: (view) => { },
  setSortedContactIds: (ids) => { },
  sortedContactIds: [],
  page: 0,
  setPage: (page) => { },
  state: {
    isFilterDrawerOpen: false,
    isFilterDrawerBox: false,
    contactUserType: null,
    isContactDetailDrawerOpen: false,
    isAddToListDrawerOpen: false,
    isAssignSalesRepDrawerOpen: false,
    selectedContact: null,
    contacts: [],
    search: '',
    filters: {},
    checkedContacts: {},
    checkedLists: {},
    checkedProjects: {},
    referralAgent: '',
    leadSource: '',
    totalRecords: 0,
    totalContacts: 0,
    isNeedToEmptySelections: false,
    sortType: '',
    refreshUsers: false,
    downloadContactsModal: false,
    createContactModal: false,
    isAddToProjectDrawerOpen: false
  },
  isUserLoading: false,
  filtering: false,
  waitingModal: false,
  view: 'corporate',
  openAddToProjectDrawer: () => { },
  closeAddToProjectDrawer: () => { },
  updateSortedContactIds: (ids) => { },
  initializeSortedContactIds: (contacts) => { },
  isBulkContactImportModalOpen: false,
  openBulkContactImportModal: () => { },
  closeBulkContactImportModal: () => { },
})

const MainContactProvider = ({ children }) => {
  const searchParams = useSearchParams()
  const projectId = useSelector((state) => state.appReducer.appProject)
  const id = searchParams.get('id')
  const userType = searchParams.get('userType')
  const [page, setPage] = useState(1)
  const history = useHistory()
  const [view, setView] = useState(history.location.pathname.includes('corporate') ? 'corporate' : 'project')
  const [isBulkContactImportModalOpen, setIsBulkContactImportModalOpen] = useState(false)

  const [state, setState] = useState({
    isFilterDrawerOpen: false,
    isFilterDrawerBox: false,
    contactUserType: userType ?? 'Default',
    isContactDetailDrawerOpen: false,
    isAddToListDrawerOpen: false,
    isAssignSalesRepDrawerOpen: false,
    selectedContact: null,
    contacts: [],
    search: '',
    filters: { page: 0, limit: 100, project: view === 'corporate' ? '' : projectId, sortType: 'desc' },
    checkedContacts: {},
    checkedLists: {},
    checkedProjects: {},
    referralAgent: '',
    leadSource: '',
    totalRecords: 0,
    totalContacts: 0,
    isNeedToEmptySelections: false,
    sortType: 'asc',
    refreshUsers: false,
    downloadContactsModal: false,
    createContactModal: false,
    isAddToProjectDrawerOpen: false
  })
  const [waitingModal, setWaitingModal] = useState(false)
  const [lastSelectedRowIndex, setLastSelectedRowIndex] = useState(null);
  const { users, isLoading: isUserLoading, refetch } = useGetUsers()
  const [sortedContactIds, setSortedContactIds] = useState(
    ()=> {
      return state.contacts.map((contact)=> contact._id)
    }  
  );
  const { filteredUsers, filtering, refetchFilteredUser } = useGetFilterUsers(
    state.search,
    state.contactUserType,
    state.filters
  )

  const openBulkContactImportModal = () => {
    setIsBulkContactImportModalOpen(true)
  }

  const closeBulkContactImportModal = () => {
    setIsBulkContactImportModalOpen(false)
  }

  const getParamName = () => {
    if (state.referralAgent) return 'referralAgent'
    if (state.leadSource) return 'leadSource'
    return ''
  }

  const getParamId = () => {
    if (state.referralAgent) return state.referralAgent
    if (state.leadSource) return state.leadSource
    return ''
  }

  const { usersByParam } = useGetUsersByParam(
    getParamName(),
    getParamId(),
    state.contactUserType,
    state.contactUserType === 'Buyer' ? 'buyerData' : 'leadBrokerData'
  )

  const updateSortedContactIds = (newSortedIds) => {
    setSortedContactIds(newSortedIds);
  };
  
  const openFilterDrawer = () =>
    setState({
      ...state,
      isFilterDrawerOpen: true,
      isFilterDrawerBox: true,
      isAddToListDrawerOpen: false,
      isAssignSalesRepDrawerOpen: false
    })
  const closeFilterDrawer = () =>
    setState({ ...state, isFilterDrawerOpen: false, isFilterDrawerBox: false })

  const toggleFilterDrawer = () =>
    setState({ ...state, isFilterDrawerBox: !state.isFilterDrawerBox })
  const applyDefaultFilter = () => {
    setState({ ...state, filters: { page: 0, limit: LIMIT, project: view === 'corporate' ? '' : projectId, sortType: 'desc' }, contactUserType: 'Default' })
    setPage(1)
  }
  const applyBuyerFilter = () => {
    setState({ ...state, filters: { page: 0, limit: LIMIT, project: view === 'corporate' ? '' : projectId, sortType: 'desc' }, contactUserType: 'Buyer' })
    setPage(1)
  }
  const applyBrokerFilter = () => {
    setState({ ...state, filters: { page: 0, limit: LIMIT, project: view === 'corporate' ? '' : projectId, sortType: 'desc' }, contactUserType: 'LeadBroker' })
    setPage(1)
  }
  const setFilters = (filters) => setState({ ...state, filters })
  const resetFilters = (resetContactUserType) => {
    setState({
      ...state,
      ...(resetContactUserType && { contactUserType: 'Default' }),
      filters: { page: 0, limit: LIMIT, project: view === 'corporate' ? '' : projectId, sortType: 'desc' }
    })
    setPage(1)
  }

  const openCreateContactModal = () =>
    setState({ ...state, createContactModal: true })

  const closeCreateContactModal = () =>
    setState({ ...state, createContactModal: false })

  const openDownloadContactsModal = () =>
    setState({ ...state, downloadContactsModal: true })

  const closeDownloadContactsModal = () =>
    setState({ ...state, downloadContactsModal: false })

  const openContactDetailDrawer = () =>
    setState({
      ...state,
      isContactDetailDrawerOpen: true
    })

  const closeContactDetailDrawer = (resetPath = '/admin/contacts') => {
    setState({
      ...state,
      isContactDetailDrawerOpen: false,
      selectedContact: null
    })
    history.push(resetPath)
    refetchFilteredUser()
  }

  const initializeSortedContactIds = (contacts) => {
    setSortedContactIds(contacts.map(contact => contact._id));
  };

  const refreshUsers = () => {
    setState({ ...state, refreshUsers: !state.refreshUsers, filters: { page: 0, limit: LIMIT, project: view === 'corporate' ? '' : projectId, sortType: 'desc' } })
    toast.success('Refreshed')
    setPage(1)
  }

  const handleShiftClickSelection = (contactId, currentIndex, isCheckboxClick) => {
    if (!isCheckboxClick) return;

    const isShiftKey = window.event.shiftKey;

    if (isShiftKey && lastSelectedRowIndex !== null) {
      const start = Math.min(lastSelectedRowIndex, currentIndex);
      const end = Math.max(lastSelectedRowIndex, currentIndex);

      const newCheckedContacts = { ...state.checkedContacts };
      const shouldSelect = !state.checkedContacts[contactId];
      for (let i = start; i <= end; i++) {
        const currentContactId = sortedContactIds[i];
        newCheckedContacts[currentContactId] = shouldSelect;
      }
      setState({
        ...state,
        checkedContacts: newCheckedContacts,
        isNeedToEmptySelections: true,
      });
    } else {
      setState({
        ...state,
        checkedContacts: {
          ...state.checkedContacts,
          [contactId]: !state.checkedContacts?.[contactId],
        },
        isNeedToEmptySelections: true,
      });
    }

    setLastSelectedRowIndex(currentIndex);
  };

  const openAddToListDrawer = () =>
    setState({ ...state, isAddToListDrawerOpen: true })

  const openAssignSalesRep = () =>
    setState({ ...state, isAssignSalesRepDrawerOpen: true })

  const closeAssignSalesRep = () => {
    if (state.isNeedToEmptySelections){
      setState({
        ...state,
        checkedContacts: {},
        checkedLists: {},
        isAssignSalesRepDrawerOpen: false,
        isAddToListDrawerOpen: false,
        isNeedToEmptySelections: false
      })
    } else {
      setState({
        ...state,
        isAssignSalesRepDrawerOpen: false
      })
    }
    refetchFilteredUser()
  }

  const closeAddToListDrawer = () => {
    if (state.isNeedToEmptySelections) {
      setState({
        ...state,
        checkedContacts: {},
        checkedLists: {},
        isAddToListDrawerOpen: false,
        isAssignSalesRepDrawerOpen: false,
        isNeedToEmptySelections: false
      })
    } else {
      setState({ ...state, isAddToListDrawerOpen: false })
    }
  }

  const setSelectedContact = (contact) => {
    setState((prevState) => ({
      ...prevState,
      selectedContact: contact,
      isContactDetailDrawerOpen: true
    }))
  }

  const toggleCheckContact = (contactId, needToOpenAddToListDrawer = false, currentIndex, isCheckboxClick = false) => {
    if (needToOpenAddToListDrawer) {
      setState({
        ...state,
        checkedContacts: {
          [contactId]: true
        },
        checkedLists: {},
        isAddToListDrawerOpen: true,
        isAssignSalesRepDrawerOpen: false,
        isNeedToEmptySelections: true
      })
    } else {
      handleShiftClickSelection(contactId, currentIndex, isCheckboxClick)
    }
  }

  const emptyCheckedSelections = () => {
    if (state.isNeedToEmptySelections) {
      setState({
        ...state,
        checkedContacts: {},
        checkedLists: {},
        checkedProjects: {},
        isAddToListDrawerOpen: false,
        isAssignSalesRepDrawerOpen: false,
        isNeedToEmptySelections: false
      })
    }
  }

  const toggleCheckAllContacts = () => {
    const value =
      Object.values(state.checkedContacts).filter((val) => val === true)
        .length === filteredUsers?.data.length
    const checkedContacts = {}
    if (filteredUsers?.data?.length) {
      filteredUsers?.data?.forEach?.(
        (user) => {
          checkedContacts[user?._id] = !value
        }
      )
    } else if (filteredUsers?.data?.length) {
      filteredUsers?.data?.forEach?.(
        (user) => (checkedContacts[user?._id] = !value)
      )
    } else users?.forEach?.((user) => (checkedContacts[user?._id] = !value))
    setState({ ...state, checkedContacts, isNeedToEmptySelections: true })
  }

  const toggleCheckList = (listId) =>
    setState({
      ...state,
      checkedLists: {
        ...state.checkedLists,
        [listId]: !state.checkedLists?.[listId]
      }
    })

  const toggleProjectList = (projectId) =>
    setState({
      ...state,
      checkedProjects: {
        ...state.checkedProjects,
        [projectId]: !state.checkedProjects?.[projectId]
      }
    })

  const emptyProjectList = () =>
    setState({
      ...state,
      checkedProjects: {}
    })

  const setSearch = async (search) => {
    setState(prev => ({
      ...prev,
      search,
      filters: {
        ...prev.filters,
        page: 0,
        limit: LIMIT,
        project: view === 'corporate' ? '' : projectId,
        sortType: 'desc'
      }
    }))

    if (state.filters.page === 0) {
      setPage(1)
    }
  }

  const setContacts = useRef((contacts) => { })
  setContacts.current = (contacts = []) => setState({ ...state, contacts })

  useEffect(() => {
    if (!id || !users?.length || filtering || isUserLoading) {
      return
    }
    const _selectedContact = users.filter((user) => user._id === id)?.[0]
    if (_selectedContact) {
      setSelectedContact(_selectedContact)
    }
  }, [id, users, filtering, isUserLoading, projectId])

  useEffect(() => {
    if (userType) {
      setState({ ...state, contactUserType: userType })
    }
  }, [userType])

  useEffect(() => {
    if (!state.search && filteredUsers?.data) {
      setContacts.current(filteredUsers.data)
    }
  }, [state.search])

  useEffect(() => {
    if (!state.referralAgent) {
      setContacts.current(filteredUsers?.data)
    }
  }, [state.referralAgent])

  useEffect(() => {
    if (!state.leadSource) {
      setContacts.current(filteredUsers?.data)
    }
  }, [state.leadSource])

  useEffect(() => {
    if (filteredUsers?.data !== undefined) {
      setContacts.current(filteredUsers.data)
      setState(prev => ({ ...prev, totalRecords: filteredUsers.totalRecords }))
    }
  }, [filteredUsers, projectId, state.filters])

  useEffect(() => {
    if (usersByParam) {
      setContacts.current(usersByParam)
    }
  }, [usersByParam])

  const toggleContactsSortingType = () => {
    setState({ ...state, sortType: state.sortType === 'asc' ? 'desc' : 'asc' })
  }

  const sortContactsByCreatedDate = (a, b) => {
    if (moment(a.createdAt).isBefore(b.createdAt)) return state.sortType === 'asc' ? 1 : -1
    else if (moment(a.createdAt).isAfter(b.createdAt)) return state.sortType === 'asc' ? -1 : 1
    return 0
  }

  useEffect(() => {
    setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        project: view === 'corporate' ? '' : projectId
      }
    }))
  }, [view, projectId])

  const openAddToProjectDrawer = () => {
    setState({ ...state, isAddToProjectDrawerOpen: true });
  };

  const closeAddToProjectDrawer = () => {
    if (state.isNeedToEmptySelections) {
      setState({
        ...state,
        checkedContacts: {},
        checkedLists: {},
        isAddToProjectDrawerOpen: false,
        isNeedToEmptySelections: false
      })
    } else {
      setState({ ...state, isAddToProjectDrawerOpen: false })
    }
  };

  return (
    <MainContactContext.Provider
      value={{
        waitingModal,
        setWaitingModal,
        setState,
        filtering,
        LIMIT,
        state,
        setFilters,
        refetchFilteredUser,
        refreshUsers,
        resetFilters,
        openFilterDrawer,
        closeFilterDrawer,
        toggleFilterDrawer,
        applyDefaultFilter,
        applyBuyerFilter,
        applyBrokerFilter,
        openContactDetailDrawer,
        closeContactDetailDrawer,
        setSelectedContact,
        setSearch,
        toggleCheckContact,
        toggleCheckAllContacts,
        openAddToListDrawer,
        closeAddToListDrawer,
        openAssignSalesRep,
        closeAssignSalesRep,
        toggleCheckList,
        toggleProjectList,
        emptyCheckedSelections,
        refetch,
        isUserLoading,
        sortContactsByCreatedDate,
        toggleContactsSortingType,
        page,
        setPage,
        openDownloadContactsModal,
        closeDownloadContactsModal,
        openCreateContactModal,
        closeCreateContactModal,
        view,
        setView,
        openAddToProjectDrawer,
        closeAddToProjectDrawer,
        emptyProjectList,
        updateSortedContactIds,
        sortedContactIds,
        initializeSortedContactIds,
        isBulkContactImportModalOpen,
        openBulkContactImportModal,
        closeBulkContactImportModal
      }}
    >
      {children}
    </MainContactContext.Provider>
  )
}

export default MainContactProvider
