/* eslint-disable no-unused-expressions */

import React from 'react'

import {
  CREATE_PROJECT_PARKING_STEP,
  CREATE_PROJECT_UPGRADE_STEP
} from '../constant'
import ProjectOptions from './ProjectOptions'

const ProjectColors = ({ setStep = () => {}, type = 'create' }) => {
  const COLORS = 'colors'
  return (
    <div className='min-h-screen relative mb-4'>
      <div className='p-3 rounded-xl border bg-card text-card-foreground shadow bg-white'>
        <h1 className='font-black text-lg font-openSans'>Colors</h1>
        <div className='border-b-2 border-black my-2'></div>
        <h2 className='font-openSans'>
          You can set here all the available color schemes
        </h2>
        <div className='mt-4 col-span-2'>
          <ProjectOptions viewMode={type} type={COLORS} setStep={setStep} prev={CREATE_PROJECT_PARKING_STEP} next={CREATE_PROJECT_UPGRADE_STEP} />
        </div>
      </div>
    </div>
  )
}

export default ProjectColors
