/*eslint-disable */
// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react'
import { defaultRatingOptions } from 'utils';
import { formatPhoneNumber } from 'pages/newContacts/ContactsTable/UserTable/UserTableV2';
import CustomSelect from 'components/VerticalCardProfile/components/CustomSelect';
import useGetSalesReps from 'hooks/useGetSalesReps';
import toast from 'react-hot-toast';
import { updateLeadSource, updateLeadStatus, updateSalesRep, updateUserDeleteStatus } from 'store/actions/usersActions';
import { ContactContext } from '../context/Contacts';
import useBuyerScore from 'hooks/useBuyerScore';
import useBrokerScore from 'hooks/useBrokerScore';
import useLeadStatus from 'hooks/useLeadStatus';
import useGetLeadBrokers from 'hooks/useGetLeadBrokers';
import { useSelector } from 'react-redux';
import useUpdateUsers from 'hooks/useUpdateUsers';
import useLeadSource from 'hooks/useLeadSource';
import useBrokerageCompanies from 'hooks/useBrokerageCompanies';
import useGetReferralAgent from 'hooks/useGetReferralAgent';
import useLeadApproachForBuyer from 'hooks/useLeadApproachForBuyer';
import useReasonForBuyerLost from 'hooks/useReasonForBuyerLost';
import EmailModal from 'pages/contacts/EmailModal';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';
import { Modal } from '@material-ui/core';
import { Button } from 'components/ShadcnComponents/button';
import CustomButton from 'components/CustomButton';
import { isMobile } from 'react-device-detect';
import useContactContext from 'pages/newContacts/hooks/useContactContext';
import { Mail, Phone, Trash } from 'lucide-react';

const Select = ({
  label,
  value,
  options,
  placeholder = '',
  isName = false,
  onChange,
  containerClassName = '',
  className = '',
  defaultValue = '',
}) => {
  return (
    <div className={`flex flex-col gap-1 ${className} w-full lg:w-fit lg:min-w-[240px]`}>
      <label className='text-zinc-900 text-opacity-70 text-sm font-medium'>
        {label}
      </label>
      <select
        className='w-full border border-softBlack30 outline-none focus:ring-1 focus:ring-blue-500 text-sm font-medium rounded-md h-10 px-3 truncate'
        onChange={(e) => onChange(e.target.value)}
        value={value ?? ''}
      >
        <option value={defaultValue} selected disabled>{placeholder}</option>
        {options?.length > 0 && options?.map((_item, index) => (
          <option key={index} value={isName ? _item._id : _item.value}>
            {isName ? _item?.firstName + ' ' + _item?.lastName : _item.label}
          </option>
        ))}
      </select>
    </div>
  )
}

const ContactTopView = ({ contact, setSelectedTabHeader }) => {
  if (!contact) return null;
  const { createOrUpdateUser } = useUpdateUsers();
  const { updateContact } = useContext(ContactContext);
  const { state, closeContactDetailDrawer } = useContactContext();
  const {
    firstName,
    lastName,
    userType,
    buyerData,
    buyerEmail,
    email,
    _id,
    leadBrokerData,
  } = contact;
  const isLeadSourceAvailable = userType === 'Buyer';
  const isReasonForBuyerLostAvailable = userType === 'Buyer';
  const isBuyerLeadApproachAvailable = userType === 'Buyer';
  const userObject = useSelector((state) => state.authReducer.userObject);
  const projectId = useSelector((state) => state.appReducer.appProject);
  const [rating, setRating] = useState(null);
  const [salesRep, setSalesRep] = useState();
  const { salesReps, refetch } = useGetSalesReps();
  const { formattedScores: scores } = userType === 'Buyer' ? useBuyerScore() : useBrokerScore();
  const { formattedLeadStatuses: leadStatuses } = useLeadStatus();
  const { formattedLeadsources: leadSources } = useLeadSource();
  const { companies } = useBrokerageCompanies();
  const { data: referralAgents, refetch: refetchReferralAgents } =
    useGetReferralAgent();
  const { formattedReasonForBuyerLost: reasonsForBuyerLost } =
    useReasonForBuyerLost();
  const { formattedLeadApproachesForBuyer: leadApproachesForBuyer } =
    useLeadApproachForBuyer();
  const [broker, setBroker] = useState(null);
  const [selectedLeadSource, setSelectedLeadSource] = useState(
    isLeadSourceAvailable ? buyerData?.leadSource : null
  );
  const [brokerageCompany, setBrokerageCompany] = useState(null);
  const [brokerageTeamName, setBrokerageTeamName] = useState(null);
  const [referralAgent, setReferralAgent] = useState(null);
  const [selectedReasonForBuyerLost, setSelectedReasonForBuyerLost] = useState(
    isReasonForBuyerLostAvailable ? buyerData?.reasonForLost : null
  );
  const [selectedLeadApproachForBuyer, setSelectedLeadApproachForBuyer] =
    useState(isBuyerLeadApproachAvailable ? buyerData?.leadApproach : null);
  const { data: brokers, refetchBrokers } =
    userType === 'Lead' || userType === 'Buyer'
      ? useGetLeadBrokers()
      : { data: null, refetchBrokers: (value) => { } };

  const [isAircallDialogOpen, setIsAircallDialogOpen] = useState(false);
  const [showEmailBox, setShowEmailBox] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (!brokers?.length) refetchBrokers();
    if (!referralAgents?.length) refetchReferralAgents();
  }, []);

  useEffect(() => {
    setRating(
      contact?.userType === 'LeadBroker'
        ? contact?.leadBrokerData?.rating
        : contact?.buyerData?.rating
    );
  }, [contact?.userType]);

  useEffect(() => {
    if (contact?.userType === 'Buyer') {
      setBroker(contact?.buyerData?.assignments?.[0]?.leadBroker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.buyerData?.assignments?.[0]?.leadBroker]);

  useEffect(() => {
    if (leadBrokerData?.brokerageCompany) {
      setBrokerageCompany(leadBrokerData.brokerageCompany);
    }
    if (leadBrokerData?.brokerageTeamName) {
      setBrokerageTeamName(leadBrokerData.brokerageTeamName);
    }
  }, [leadBrokerData]);

  useEffect(() => {
    if (contact?.userType === 'Buyer') {
      setReferralAgent(contact?.buyerData?.referralAgent);
    }
  }, [contact?.buyerData?.referralAgent]);

  useEffect(() => {
    if (!salesReps?.length) refetch();
  }, [salesReps]);

  useEffect(() => {
    if (brokers?.length > 0) {
      let brokerFoundTemp = false;
      for (let i = 0; i < brokers.length; i++) {
        const brokerTemp = brokers[i];
        if (brokerTemp._id === broker) {
          brokerFoundTemp = true;
          break;
        }
      }
      if (!brokerFoundTemp) {
        setBroker('');
      }
    }
  }, [brokers]);

  const salesRepChange = (type, params) => {
    const temp = salesReps?.find?.((rep) => rep?._id === params?.salesRep);
    setSalesRep(temp);
    handleSalesRepChange(type, params);
  };

  useEffect(() => {
    let rep;
    if (userType === "Buyer") {
      rep = salesReps?.find?.(rep => {
        const assignment = buyerData?.assignments?.find(a => a.project === projectId);
        return assignment && rep?._id === assignment.salesRep;
      });
      if (!rep) {
        rep = salesReps?.find?.(rep => rep?._id === buyerData?.assignments?.[0]?.salesRep);
      }
    } else {
      rep = salesReps?.find?.(rep => {
        const assignment = leadBrokerData?.assignments?.find(a => a.project === projectId);
        return assignment && rep?._id === assignment.salesRep;
      });
      if (!rep) {
        rep = salesReps?.find?.(rep => rep?._id === leadBrokerData?.assignments?.[0]?.salesRep);
      }
    }
    setSalesRep(rep);
    if (!rep) {
      const assign = leadBrokerData?.assignments?.[0]?.salesRep?._id;
      const rep = salesReps.find((obj) => obj?._id === assign);
      setSalesRep(rep);
    }
  }, [buyerData, salesReps, leadBrokerData]);

  const handleSalesRepChange = async (type, params) => {
    const toastId = toast.loading('loading...');
    try {
      await updateSalesRep({
        salesRep: params.salesRep,
        contactId: contact?._id,
        project: projectId
      });
      toast.dismiss(toastId);
      toast.success('SalesRep updated successfully');
    } catch (error) {
      toast.dismiss(toastId);
      console.error("SalesRep couldn't be updated", error);
    }
  };

  const handleLeadStatusChange = async (type) => {
    const toastId = toast.loading('loading...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (contact?.userType === 'Buyer') {
      if (Object.keys(newUserData?.buyerData).length) {
        newUserData.buyerData.leadStatus = type?.trim();
      }
    } else {
      if (Object.keys(newUserData?.leadBrokerData).length) {
        newUserData.leadBrokerData.leadStatus = type?.trim();
      }
    }
    try {
      updateContact(newUserData);
      await updateLeadStatus(type, contact._id);
      toast.dismiss(toastId);
      toast.success('Lead status updated successfully');
    } catch (err) {
      updateContact(prevValue);
      console.log(err);
    }
  };

  const handleRatingChange = async (value) => {
    const toastId = toast.loading('loading...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (contact?.userType === 'LeadBroker') {
      if (Object.keys(newUserData?.leadBrokerData)?.length) {
        newUserData.leadBrokerData.rating = value;
      }
    } else {
      if (Object.keys(newUserData?.buyerData)?.length) {
        newUserData.buyerData.rating = value;
      }
    }

    try {
      updateContact(newUserData);
      await createOrUpdateUser({ ...newUserData }, false, false);
      setRating(value);
      toast.dismiss(toastId);
      toast.success('Rating updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.error("Rating couldn't be updated", err);
    }
  };

  const handleBrokerChange = async (value) => {
    if (!value) return;
    const toastId = toast.loading('loading...');
    setBroker(value);
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData?.buyerData?.assignments?.[0]) {
      newUserData.buyerData.assignments[0].leadBroker = value;
    }
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      toast.dismiss(toastId);
      toast.success('Realtor updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.log(err);
    }
  };

  const handleLeadSourceChange = async (leadSource) => {
    const toastId = toast.loading('loading...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (Object.keys(newUserData?.buyerData).length) {
      newUserData.buyerData.leadSource = leadSource;
    }

    try {
      updateContact(newUserData);
      await updateLeadSource(leadSource, contact._id);
      setSelectedLeadSource(leadSource);
      toast.dismiss(toastId);
      toast.success('Lead source updated successfully');
    } catch (err) {
      updateContact(prevValue);
      console.log(err);
    }
  };

  const handleBrokerageCompanyChange = async (company) => {
    const toastId = toast.loading('loading...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData.leadBrokerData) {
      newUserData.leadBrokerData.brokerageCompany = company;
    } else {
      newUserData.leadBrokerData = { brokerageCompany: company };
    }

    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      setBrokerageCompany(company);
      toast.dismiss(toastId);
      toast.success('Brokerage company updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.error("Brokerage company couldn't be updated", err);
    }
  };

  const handleReferralAgentChange = async (value) => {
    if (!value) return;
    const toastId = toast.loading('loading...');
    setReferralAgent(value);
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    newUserData.buyerData.referralAgent = value;
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      toast.dismiss(toastId);
      toast.success('Referral agent updated successfully');
    } catch (err) {
      updateContact(prevValue);
      console.log(err);
    }
  };

  const handleReasonForBuyerLostChange = async (reasonForLost) => {
    const toastId = toast.loading('updating Reason for lost...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData.buyerData) {
      newUserData.buyerData.reasonForLost = reasonForLost;
    } else {
      newUserData.buyerData = { reasonForLost: reasonForLost };
    }
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      setSelectedReasonForBuyerLost(reasonForLost)
      toast.dismiss(toastId);
      toast.success('Reason for lost updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.error("Reason for lost couldn't be updated", err);
    }
  };

  const handleBuyerLeadApproachChange = async (leadApproach) => {
    const toastId = toast.loading('updating lead approach...');
    const prevValue = { ...contact };
    const newUserData = { ...contact };
    if (newUserData.buyerData) {
      newUserData.buyerData.leadApproach = leadApproach;
    } else {
      newUserData.buyerData = { leadApproach: leadApproach };
    }
    try {
      updateContact(newUserData);
      await createOrUpdateUser(newUserData, false, false);
      toast.dismiss(toastId);
      toast.success('Lead approach updated successfully');
    } catch (err) {
      toast.dismiss(toastId);
      updateContact(prevValue);
      console.error("Lead approach couldn't be updated", err);
    }
  };

  const handleSoftDelete = async () => {
    try {
      const response = await updateUserDeleteStatus(contact._id).then(() => {
        setDeleteModal(false);
        closeContactDetailDrawer();
      });
    } catch (error) {
      console.log('Error in deleting user', error);
    }
  };

  return (
    <>
      <div className="w-full p-4 md:p-0 flex flex-col md:flex-row  border-b border-softBlack30 shadow-sm overflow-auto">
        <div className="flex flex-col gap-y-4 pl-10 py-8">
          <div className="flex items-center gap-4 overflow-hidden">
            <div className='flex flex-col gap-y-2 items-center'>
              <div className="h-14 w-14 rounded-full flex items-center justify-center bg-gray-100 text-text-softBlack_70/50 font-bold">
                {firstName && firstName[0]}
                {lastName && lastName.length && lastName[0]}
              </div>
              <p className="text-zinc-900 text-sm font-medium m-0">
                {userType === 'LeadBroker'
                  ? 'Realtor'
                  : userType
                }
              </p>
            </div>
            <div className="flex-1 flex flex-col justify-center gap-0.5 overflow-hidden">
              <h6 className="text-zinc-900 text-xl font-semibold m-0 truncate">
                {firstName + " " + lastName}
              </h6>
              <p className="text-zinc-900 text-opacity-70 text-sm font-medium m-0">
                {email}
              </p>
              <p className="text-zinc-900 text-opacity-70 text-sm font-medium m-0">
                {formatPhoneNumber(
                  contact?.buyerData?.phoneNumber ||
                    contact?.leadBrokerData?.phoneNumber
                )}
              </p>
              {
                (userType === 'LeadBroker' && brokerageTeamName && brokerageTeamName?.length > 0) &&
                <div className="flex flex-col gap-1 pt-1">
                  <p className="text-zinc-900 text-opacity-70 text-sm font-medium m-0 leading-none">
                    Brokerage Team:
                  </p>
                  <p className="text-zinc-900 text-sm font-medium m-0">
                    {brokerageTeamName ?? '-'}
                  </p>
                </div>
              }
            </div>
          </div>
          <div className="flex gap-3 pt-3 items-start">
            <CustomButton
              variant="outline"
              className='p-2 h-10 w-10 border border-softBlack30'
              handleClick={() => setShowEmailBox(true)}
            >
              <span className="flex justify-center">
                <Mail className="h-4 w-4" />
              </span>
            </CustomButton>
            <CustomButton
              variant="outline"
              className='p-2 h-10 w-10 border border-softBlack30'
              handleClick={() => setIsAircallDialogOpen(true)}
            >
              <span className=" flex justify-center">
                <Phone className="h-4 w-4" />
              </span>
            </CustomButton>
            <CustomButton
              variant="outline"
              className='p-2 h-10 w-10 border border-softBlack30'
              handleClick={() => setDeleteModal(true)}
            >
              <span className="flex justify-center">
                <Trash className="h-4 w-4" />
              </span>
            </CustomButton>
          </div>
        </div>
        <div className='h-[1px] md:h-full w-full md:w-[1px] mx-6 bg-softBlack30' />
        <div className="flex w-full flex-wrap py-8 pr-0 md:pr-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-4">
            {(userType === "Buyer" || userType === "LeadBroker") && (
              <Select
                label="Sales Rep"
                value={salesRep?._id}
                options={salesReps}
                onChange={(salesRepId) => {
                  salesRepChange(
                    { create: !salesRep?.firstName },
                    {
                      salesRep: salesRepId,
                      developerAdmin: userObject?._id,
                      project: projectId,
                      buyer: buyerEmail,
                    }
                  );
                }}
                isName
                placeholder="Select Sales Rep"
              />
            )}
            <Select
              label="Lead Status"
              value={
                (contact?.buyerData?.leadStatus?.trim()) ||
                (contact?.leadBrokerData?.leadStatus?.trim())
              }
              options={leadStatuses}
              onChange={handleLeadStatusChange}
              placeholder="Select Lead Status"
            />
            <Select
              label="Rating"
              value={rating}
              options={scores}
              onChange={handleRatingChange}
              placeholder='Not rated'
              defaultValue='Unrated'
            />
            {isLeadSourceAvailable && (
              <Select
                label="Lead Source"
                value={selectedLeadSource}
                options={leadSources}
                onChange={handleLeadSourceChange}
                placeholder="Select Lead Source"
              />
            )}
            {(userType === "Buyer" || userType === "Lead") && (
              <Select
                label="Realtor"
                value={broker}
                options={brokers}
                onChange={handleBrokerChange}
                isName
                placeholder="Select Realtor"
              />
            )}
            {(userType === "LeadBroker" && !(brokerageTeamName && brokerageTeamName?.length > 0)) && (
              <Select
                label="Brokerage Company"
                value={brokerageCompany}
                options={companies?.values?.map((value) => ({
                  label: value,
                  value,
                }))}
                onChange={handleBrokerageCompanyChange}
                placeholder="Select Brokerage Company"
              />
            )}
            {isReasonForBuyerLostAvailable && (
              <Select
                label="Reason For Lost"
                value={selectedReasonForBuyerLost}
                options={reasonsForBuyerLost}
                onChange={handleReasonForBuyerLostChange}
                placeholder="Select Reason For Lost"
              />
            )}
          </div>
        </div>
      </div>
      <Modal
        open={isAircallDialogOpen}
        onClose={() => setIsAircallDialogOpen(false)}
      >
        {
          process.env.REACT_APP_RINGCENTRAL_CLIENT_ID ? ( 
          <div className="w-full h-screen flex justify-center items-center font-openSans absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded shadow-lg">
          <div className="relative pt-10 bg-[#FCFCFC] rounded shadow-lg overflow-hidden flex flex-col w-[90%] md:w-fit h-[80%]">
          <button
              className="absolute right-4 top-4"
              onClick={() => setIsAircallDialogOpen(false)}
            >
              <CrossIcon />
            </button>
            <div id="phone">
              <iframe
                width="360"
                height="500"
                id="rc-widget"
                allow="autoplay; microphone"
                src={`https://apps.ringcentral.com/integration/ringcentral-embeddable/latest/app.html?appServer=${process.env.REACT_APP_RINGCENTRAL_APP_SERVER}&clientId=${process.env.REACT_APP_RINGCENTRAL_CLIENT_ID}&redirectUri=${process.env.REACT_APP_RINGCENTRAL_REDIRECT_URI}`}
              ></iframe>
            </div>
          </div>
          </div>
        ): (
        <div className="w-full h-screen flex justify-center items-center font-openSans absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded shadow-lg">
          <div className="w-fit flex relative bg-grey-8 overflow-x-hidden overflow-y-auto flex-col pt-10 rounded">
            <button
              className="absolute right-4 top-4"
              onClick={() => setIsAircallDialogOpen(false)}
            >
              <CrossIcon />
            </button>
            <div className="flex flex-col gap-6">
              <div>
                <iframe
                  allow="microphone; autoplay; clipboard-read; clipboard-write; hid"
                  src="https://phone.aircall.io?integration=generic"
                  className="w-[376px] h-[666px]"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
          )}
      </Modal>
      {showEmailBox && (
        <EmailModal
          show={showEmailBox}
          handleClose={() => setShowEmailBox(!showEmailBox)}
          contact={contact}
          onEmailSent={() => {
            if (setSelectedTabHeader) {
              setSelectedTabHeader("Email");
            }
            toast.success("Email Sent successfully");
          }}
        />
      )}
      {deleteModal && (
        <Modal open={deleteModal}>
          <div
            style={{ width: isMobile ? "95vw" : 400 }}
            className="bg-grey-8 p-2 md:p-6 relative top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <button
              className="absolute right-3 top-3"
              onClick={() => setDeleteModal(false)}
            >
              <CrossIcon />
            </button>
            <div className="flex flex-col gap-y-6 justify-center items-center">
              <div className="mt-6 text-[18px]">
                Are you sure you want delete Contact?
              </div>
              <div className="flex gap-x-6">
                <CustomButton
                  handleClick={() => {
                    handleSoftDelete();
                  }}
                >
                  <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                    Yes
                  </span>
                </CustomButton>
                <CustomButton
                  handleClick={() => {
                    setDeleteModal(false);
                  }}
                >
                  <span className="font-medium py-2 px-4 text-base flex gap-2 items-center">
                    No
                  </span>
                </CustomButton>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ContactTopView
