import { api, ApiErrors } from 'services/api'

const GOOGLE_ADS_BE_URL = process.env.REACT_APP_GOOGLE_ANALYTICS_BE_URL || 'https://google-analytics-api-service-33853d77a62a.herokuapp.com'

const BASE_URL = GOOGLE_ADS_BE_URL + '/google/ads'

const getServerUrl = () => {
  try {
    const url = process.env.REACT_APP_BE_URL
    const parsedUrl = new URL(url)

    const hostname = parsedUrl.hostname

    const parts = hostname.split('.')
    if (parts.length > 2) {
      return parts.slice(0, parts.length - 2).join('.')
    }

    return hostname
  } catch (error) {
    console.error('Invalid URL:', error)
    return null
  }
}

export const getGoogleAdsAuth = (projectId, userId) => {
  const serverId = getServerUrl()

  return new Promise((resolve, reject) => {
    api.get(`${BASE_URL}/auth/authorize?projectId=${projectId}&serverId=${serverId}&userId=${userId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getAllGoogleAdsAccounts = (projectId) => {
  const serverId = getServerUrl()

  return new Promise((resolve, reject) => {
    api.get(`${BASE_URL}/accounts?serverId=${serverId}&projectId=${projectId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getAllGoogleAdsCustomerAccounts = (connectionId) => {
  return new Promise((resolve, reject) => {
    api.get(`${BASE_URL}/getAdsAccounts?connectionId=${connectionId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getGoogleAdsReportById = (connectionId, customerId) => {
  return new Promise((resolve, reject) => {
    api.get(`${BASE_URL}/report?connectionId=${connectionId}&customerId=${customerId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}
