import React from 'react'
import userImage from '../../../../assets/img/userImg.jpg'
import phoneIcon from '../../../../assets/img/phone.png'
import Dropdown from './Dropdown/Dropdown'
import { EyeIcon } from '@heroicons/react/outline'
import { EditIcon } from 'components'

const SidePanel = ({ sidePanelData }) => {
  const options = [
    {
      title: 'View',
      icon: <EyeIcon className='w-6 h-6' />,
      onClick: () => console.log('View')
    },
    {
      title: 'Edit',
      icon: <EditIcon className='w-6 h-6' />,
      onClick: () => console.log('Edit')
    }
  ]

  return (
    <div className='col-span-4 h-screen p-4 px-6'>
      <div className='p-6 h-full w-full border bg-white shadow-md rounded-2xl grid grid-rows-4'>
        <div className='row-span-1 grid grid-cols-10 gap-x-4'>
          <div className='col-span-5'>
            <div className='relative w-fit'>
              <div className='h-22 w-22 border-1.5 border-darkModeSecundaryColor rounded-full overflow-hidden'>
                <img className='h-full object-cover' src={userImage} />
              </div>
              <span className='absolute top-0 right-0 bg-green-600 h-5 w-5 rounded-full border-1.5 border-darkModeSecundaryColor'></span>
            </div>
            <h1 className='text-lg 2xl:text-xl text-darkModeSecundaryColor font-bold my-5'>NEW LEAD</h1>
            <div className='flex justify-center items-center py-3 px-2 lg:px-4 2xl:px-7 rounded-lg bg-darkModeSecundaryColor'>
              <h1 className='mb-0 text-sm 2xl:text-base text-white font-medium truncate'>View Social Profiles</h1>
            </div>
          </div>
          <div className='col-span-5 grid'>
            <h1 className='text-xl 2xl:text-2xl font-bold mt-5 mb-1.5 truncate'>Stefan Dupuis</h1>
            <h1 className='text-lg 2xl:text-xl font-normal truncate'>Vancouver, Canada</h1>
            <h1 className='text-base 2xl:text-lg font-semibold mt-6'>Lead Score</h1>
            <h1 className='text-base 2xl:text-lg font-normal truncate'>Still Calculating...</h1>
          </div>
          <hr className='h-0.5 mt-5 col-span-10 bg-black' />
        </div>
        <div className='row-span-3 pt-9 overflow-hidden'>
          <div className='flex flex-col gap-6 xl:gap-8 2xl:gap-11.75 h-full overflow-y-auto overflow-x-hidden'>
            {
              sidePanelData.map((elm) => (
                <div key={elm.id} className='grid grid-cols-8'>
                  <div className='col-span-7 flex gap-4'>
                    <img className='h-9' src={phoneIcon} alt='Profile picture' />
                    <div className='w-fit overflow-hidden'>
                      <h1 className='text-xl xl:text-2xl font-medium mb-1.5 truncate'>{elm.title}</h1>
                      <p className='text-base xl:text-lg font-light mb-0 truncate'>{elm.description}</p>
                    </div>
                  </div>
                  <div className='col-span-1 flex justify-center items-center'>
                    <Dropdown options={options} />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SidePanel
