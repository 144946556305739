// @ts-nocheck
/* eslint-disable */
import React, { useEffect, useState } from 'react'
import CustomSectionOptions from './CustomSectionOptions'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'
import { LottieWrapper } from 'components'
import successAnimation from 'assets/lottieJsons/avesdoLoading.json'

const LoadingUI = () => (
  <div className='flex flex-col items-center justify-center w-full h-full'>
    <div
      role='presentation'
      style={{
        height: 150,
        width: 150,
        margin: '0 auto'
      }}
    >
      <LottieWrapper
        animation={successAnimation}
        autoplay
        loop={true}
        className='-mt-40 md:mt-0 max-w-sm'
      />
    </div>
    <h1 className='mt-4 text-[16px] truncate text-center'>
      Loading...
    </h1>
  </div>
)

const ProjectCustomSection = ({ _id }) => {
  const customSections = useSelector((state) => state.appReducer.customSections)
  const [customSection, setCustomSection] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setCustomSection(customSections.filter((section) => (section?._id === _id))[0])
    setIsLoading(false)
  }, [customSections, _id])

  return (
    <div className='min-h-screen relative mb-4'>
      {
        isLoading
          ? <LoadingUI />
          : <div className='p-3 rounded-xl border bg-card text-card-foreground shadow bg-white'>
            <h1 className='font-black text-lg font-openSans'>{customSection?.title ?? ''}</h1>
            <div className='border-b-2 border-black my-2'></div>
            <h2 className='font-openSans'>
              Fill below all the available {customSection?.title ?? ''} options for this project
            </h2>
            {
              customSection?.dataFields?.length > 0 &&
              <div className='mt-4 col-span-2'>
                <CustomSectionOptions _id={_id} customSection={customSection} type={customSection?._id ?? ''} />
              </div>
            }
          </div>
      }
    </div>
  )
}

export default ProjectCustomSection
