import { api, ApiErrors } from 'services/api'
import { message } from 'antd'

export const getFlatFileToken = () => {
  return new Promise((resolve, reject) => {
    api.get('units/import/flatfile/token').then((response) => {
      if (response.ok) {
        resolve(response.data.token)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
}

export const getUnits = (id = '') =>
  new Promise((resolve, reject) => {
    api.get(`units/${id}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

// export const getUnitsByUserProjects = () => new Promise((resolve, reject) => {
//   const token = getTokenRequestHeader()

//   api.setHeader('Authorization', token)

//   api.get('units/projects')
//     .then((response) => {
//       if (response.ok) {
//         resolve(response.data)
//       } else {
//         ApiErrors(response)
//         reject()
//       }
//     })
// })

export const createOrUpdateUnit = (props) =>
  new Promise((resolve, reject) => {
    const { _id, ...params } = props

    // If id, so we have to update the record, or we're gonna create
    const verb = _id ? 'put' : 'post'
    const url = _id ? `units/${_id}` : 'units'

    api[verb](url, params).then((response) => {
      if (response.ok) {
        message.success(
          `Unit-#${props?.unitNumber} was ${_id ? 'successfully updated' : 'created successfully'
          }`
        )
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const deleteUnit = (id) =>
  new Promise((resolve, reject) => {
    api.delete(`/units/${id}`).then((response) => {
      if (response.ok) {
        message.success('Unit deleted successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectUnits = (projectId, isPublicPage = false) =>
  new Promise((resolve, reject) => {
    api
      .get(
        `units/project/${projectId}?sortBy=unitNumber&orderBy=asc&isPublicPage=${isPublicPage}`
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data.data)
        } else {
          ApiErrors(response)
        }
      })
  })

export const reserveUnitToBuyer = (unitId, buyerList) =>
  new Promise((resolve, reject) => {
    api
      .put(`units/${unitId}/reservation`, {
        buyer: buyerList
      })
      .then((response) => {
        if (response.ok) {
          message.success('Unit reserved successfully')
          resolve()
        } else {
          ApiErrors(response)
          reject()
        }
      })
  })

export const unreserveUnit = (unitId) =>
  new Promise((resolve, reject) => {
    api.put(`units/${unitId}/reservation/cancel`).then((response) => {
      if (response.ok) {
        message.success('Unit unreserved successfully')
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getReservedUnits = (buyerId) =>
  new Promise((resolve, reject) => {
    api.get(`units/reserved/${buyerId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getProjectUnitGroups = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`units/project/${projectId}/unit-group`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const reserveUnitsToBroker = (
  brokerList,
  units,
  brokerMaxReservationDays
) =>
  new Promise((resolve, reject) => {
    const params = {
      broker: brokerList,
      units,
      brokerMaxReservationDays
    }

    api.put('units/broker/reservation', params).then((response) => {
      if (response.ok) {
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getUnitsCSV = ({ buildingId }) =>
  new Promise((resolve, reject) => {
    api.get(`units/download/csv?building=${buildingId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const downloadUnitsCSV = async (buildingId) => {
  try {
    const result = await getUnitsCSV({ buildingId })
    const buffer = Buffer.from(result)
    const blob = new Blob([buffer])
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    document.body.appendChild(a)
    // a.style = "display: none"
    a.href = url
    a.download = buildingId + '-units.csv'
    a.click()
    window.URL.revokeObjectURL(url)
  } catch (e) {
    console.error('downloadUnitsCSV Error', buildingId, e)
  }
}

export const getUnitsByBuildingId = (buildingId) =>
  new Promise((resolve, reject) => {
    api.get(`units/building/${buildingId}`).then((response) => {
      if (response.ok) {
        resolve(response.data.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getUnitsByBuildingIdTableView = (buildingId) =>
  new Promise((resolve, reject) => {
    api.get(`units/building/tableView/${buildingId}`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const getUnitsByProjectSummary = (projectId) =>
  new Promise((resolve, reject) => {
    api.get(`units/project/${projectId}/summary`).then((response) => {
      if (response.ok) {
        resolve(response.data)
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const updateUnitStatus = (unit) =>
  new Promise((resolve, reject) => {
    api.put(`units/${unit._id}/status`, { status: unit.status }).then((response) => {
      if (response.ok) {
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })

export const updateUnitField = (unitId, unit) =>
  new Promise((resolve, reject) => {
    api.put(`units/${unitId}/field-update`, { ...unit }).then((response) => {
      if (response.ok) {
        resolve()
      } else {
        ApiErrors(response)
        reject()
      }
    })
  })
