import React from 'react'

export default function PlusButton (data) {
  let {
    top,
    left,
    right,
    bottom,
    translateX,
    translateY,
    callback
  } = data || {}
  if (!top) top = ''
  if (!left) left = ''
  if (!right) right = ''
  if (!bottom) bottom = ''
  if (!translateX) translateX = ''
  if (!translateY) translateY = ''

  return (
    <button
      style={{
        top,
        left,
        right,
        bottom,
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${translateX}px,${translateY}px)`,
        pointerEvents: 'all'
      }}
      className="nodrag nopan bg-white px-2.5 py-0.5 border border-white-600 rounded-full text-black"
      onClick={(e) => {
        callback?.(e)
      }}
    >
      +
    </button>
  )
}
