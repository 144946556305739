// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import { createOrUpdateUnit } from 'store/actions/unitActions'
import useGetFloorPlans from 'hooks/useGetFloorPlans'
import useGetUnitGroups from 'hooks/useGetUnitGroups'
import { useSelector } from 'react-redux'
import { isObject } from 'lodash'
// import useCurrencyList from 'hooks/useCurrencyList'
import ProjectFileSelector from 'components/ProjectFileSelector'
import { ADMIN_OFFER } from 'routes'
import { useHistory } from 'react-router-dom'
import CustomButton from 'components/CustomButton'

const EditUnitView = ({
  editDetails,
  setEditDetails,
  refetch,
  building,
  closeParkingInfoDrawer,
  isAbleToPurchase
}) => {
  const history = useHistory()
  // @ts-ignore
  const appProject = useSelector((state) => state.appReducer.appProject)
  const mountedRef = useRef(true)
  const { floorPlans } = useGetFloorPlans()
  const { unitGroups } = useGetUnitGroups()
  // const { formattedCurrencyList: currencyData } = useCurrencyList()
  const initialEmptyValues = {
    unitNumber: '',
    fullAddress: '',
    strataLotNumber: '',
    status: '',
    price: '',
    // priceCurrencyType: '',
    unitGroup: '',
    floorPlan: '',
    floorNumber: '',
    numberOfBedrooms: '',
    numberOfBathrooms: '',
    balcony: '',
    building
  }
  const [value, setValues] = useState({
    ...initialEmptyValues
  })

  const TEMP_AVAILABILITY_STATUS = {
    open: 'Open',
    soldFirm: 'SoldFirm',
    developerHeld: 'DeveloperHeld',
    reserved: 'reserved',
    reservedBroker: 'ReservedBroker',
    reservedBuyer: 'ReservedBuyer',
    closed: 'Closed',
    underContract: 'UnderContract',
    offerAccepted: 'OfferAccepted'
  }

  const STATUS_OPTIONS = [
    ...Object.values(TEMP_AVAILABILITY_STATUS).map((value) => {
      return { value: value, id: value }
    })
  ]

  const balconyOptions = [
    { id: 'true', value: 'Yes' },
    { id: 'false', value: 'No' }
  ]

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true)
      const temp = {
        // @ts-ignore
        _id: value?._id ?? '',
        unitNumber: value.unitNumber,
        fullAddress: value.fullAddress,
        strataLotNumber: value.strataLotNumber,
        status: value.status,
        price: value.price,
        // priceCurrencyType: value.priceCurrencyType,
        unitGroup: isObject(value.unitGroup)
          ? value.unitGroup?._id
          : value.unitGroup,
        floorPlan: isObject(value.floorPlan)
          ? value.floorPlan?._id
          : value.floorPlan,
        floorNumber: value.floorNumber,
        numberOfBedrooms: value.numberOfBedrooms,
        numberOfBathrooms: value.numberOfBathrooms,
        balcony: value.balcony ?? false,
        building: isObject(value.building)
          ? value.building?._id
          : value.building,
        project: appProject,
        contractPdf: value.contractPdf ?? '',
        parkingPdf: value.parkingPdf ?? ''
      }
      createOrUpdateUnit(temp)
        .then((data) => {
          refetch()
          setLoading(false)
          setEditDetails(data)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const onChange = (fieldName, newValue) => {
    setValues({ ...value, [fieldName]: newValue })
  }
  const [loading, setLoading] = useState(false)

  const validate = () => {
    for (const key in initialEmptyValues) {
      if (!value[key]) {
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if (!mountedRef.current) return null
    if (editDetails?.type === 'Units') {
      setValues({
        ...value,
        ...editDetails,
        status: STATUS_OPTIONS.filter(
          ({ id }) => id === editDetails.status
        )?.[0]?.id,
        balcony: editDetails.balcony ? 'true' : 'false'
      })
    } else {
      setValues(initialEmptyValues)
    }
  }, [editDetails])

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const handleStatusChange = (id) => {
    const temp = STATUS_OPTIONS.filter((option) => option.id === id)[0]
    onChange('status', temp.id)
  }

  const handleContractPDFChange = (contractObject) => {
    onChange('contractPdf', contractObject._id ?? contractObject.id)
  }

  const handleParkingPDFChange = (parkingObject) => {
    onChange('parkingPdf', parkingObject._id ?? parkingObject.id)
  }

  // const handleCurrencyChange = (id) => {
  //   const temp = currencyData.filter((option) => option.id === id)[0]
  //   onChange('priceCurrencyType', temp.id)
  // }

  const handleBalconyChange = (id) => {
    const temp = balconyOptions.filter((option) => option.id === id)[0]
    onChange('balcony', temp.id)
  }

  return (
    <div
      className='font-openSans relative bg-grey-8 flex flex-col h-[calc(100%-64px)] overflow-hidden'
    >
      <div className='flex-1 flex flex-col gap-4 overflow-y-auto thin-scrollbar'>
        <CustomInput
          label='Name *'
          placeholder='Enter name'
          labelClassName='italic'
          value={value.unitNumber}
          onChange={(e) => {
            onChange('unitNumber', e.target.value)
          }}
          required='required'
          errorMessage='Please enter name'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomInput
          label='unit address *'
          placeholder='Enter address'
          labelClassName='italic'
          value={value.fullAddress}
          onChange={(e) => {
            onChange('fullAddress', e.target.value)
          }}
          required='required'
          errorMessage='Please enter unit address'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomInput
          label='strata lot number *'
          placeholder='Enter number'
          labelClassName='italic'
          value={value.strataLotNumber}
          onChange={(e) => {
            onChange('strataLotNumber', e.target.value)
          }}
          required='required'
          errorMessage='Please enter lot number'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='status *'
          inputClasses='h-11'
          labelClasses={undefined}
          options={STATUS_OPTIONS}
          selectedOption={value.status}
          setSelectedOption={(option) => {
            handleStatusChange(option)
          }}
        />
        <CustomInput
          label='price *'
          placeholder='Enter amount'
          labelClassName='italic'
          value={value.price}
          onChange={(e) => {
            onChange('price', e.target.value)
          }}
          type='number'
          required='required'
          errorMessage='Please enter price'
          containerClasses={undefined}
          classes={undefined}
        />
        {/* <CustomSelect
          label='currency *'
          inputClasses='h-11'
          options={currencyData}
          selectedOption={value.priceCurrencyType}
          setSelectedOption={(option) => {
            handleCurrencyChange(option)
          }}
          labelClasses={undefined}
        /> */}
        <CustomSelect
          label='unit group *'
          inputClasses='h-11'
          labelClasses={undefined}
          options={unitGroups}
          selectedOption={
            // @ts-ignore
            isObject(value.unitGroup) ? value.unitGroup?._id : value.unitGroup
          }
          toIterateField='name'
          setSelectedOption={(newValue) => {
            onChange('unitGroup', newValue)
          }}
        />
        <CustomSelect
          label='floor plan *'
          options={floorPlans}
          inputClasses='h-11'
          selectedOption={
            // @ts-ignore
            isObject(value.floorPlan) ? value.floorPlan?._id : value.floorPlan
          }
          toIterateField='name'
          setSelectedOption={(newValue) => {
            onChange('floorPlan', newValue)
          }}
          labelClasses={undefined}
        />
        <CustomInput
          label='floor number *'
          placeholder='Enter number'
          labelClassName='italic'
          value={value.floorNumber}
          onChange={(e) => {
            onChange('floorNumber', e.target.value)
          }}
          type='number'
          required='required'
          errorMessage='Please enter floor number'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomInput
          label='number of bedrooms *'
          value={value.numberOfBedrooms}
          onChange={(e) => {
            onChange('numberOfBedrooms', e.target.value)
          }}
          type='number'
          required='required'
          errorMessage='Please enter number of bedrooms'
          containerClasses={undefined}
          classes={undefined}
          placeholder={undefined}
        />
        <CustomInput
          label='number of bathrooms *'
          placeholder='Enter number'
          labelClassName='italic'
          value={value.numberOfBathrooms}
          onChange={(e) => {
            onChange('numberOfBathrooms', e.target.value)
          }}
          type='number'
          required='required'
          errorMessage='Please enter number of bathrooms'
          containerClasses={undefined}
          classes={undefined}
        />
        <CustomSelect
          label='balcony *'
          labelClasses='italic'
          inputClasses='h-11'
          options={balconyOptions}
          setSelectedOption={(value) => {
            handleBalconyChange(value)
          }}
          selectedOption={value.balcony}
        />
        <ProjectFileSelector
          title={'Contract'}
          onSuccess={(selectedFileData) => {
            handleContractPDFChange(JSON.parse(selectedFileData))
          }}
        />
        <ProjectFileSelector
          title={'Unit'}
          onSuccess={(selectedFileData) => {
            handleParkingPDFChange(JSON.parse(selectedFileData))
          }}
        />
      </div>
      <div className='mt-2 border-t border-softBlack/[30%] flex flex-col gap-2'>
        <CustomButton
          disabled={!validate() || loading}
          handleClick={handleSubmit}
        >
          <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
            {loading && <Oval height='18' width='18' color='white' />}
            {value?._id ? 'Update' : 'Add'} Unit
          </span>
        </CustomButton>
        {
          isAbleToPurchase &&
          <CustomButton
            handleClick={() => history.push(ADMIN_OFFER)}
          >
            <span className='font-medium py-3 px-6 text-base flex gap-2 items-center'>
              Purchase
            </span>
          </CustomButton>
        }
      </div>
    </div>
  )
}

export default EditUnitView
