/* eslint-disable space-before-function-paren */
import React from 'react'
import {
  BaseEdge,
  EdgeLabelRenderer,
  getSimpleBezierPath
} from '@xyflow/react'
import PlusButton from '../Utils/components/PlusButton'
import FlowContext from '../flowContext'

export default function AddNewActionEdge(data) {
  const { id, sourceX, sourceY, targetX, source, targetY } = data
  const [edgePath, labelX, labelY] = getSimpleBezierPath({
    sourceX,
    sourceY,
    targetX,
    targetY
  })

  return (
    <FlowContext.Consumer>
      {/* @ts-ignore */}
      {({ setAddNodeAfterNode }) => (
        <>
          <BaseEdge id={id} path={edgePath} />
          <EdgeLabelRenderer>
            <PlusButton
              translateX={labelX}
              translateY={labelY}
              callback={() => {
                setAddNodeAfterNode(source)
              }}
            >
            </PlusButton>
          </EdgeLabelRenderer></>
      )}
    </FlowContext.Consumer>
  )
}
