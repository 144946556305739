/* eslint-disable */
// @ts-nocheck
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  memo,
  useMemo,
  useCallback,
  useRef
} from 'react'
import propTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { AiOutlineDown } from 'react-icons/ai'

import {
  getProjectOffers,
  getProjectStatistics
} from 'store/actions/offerActions'
import { Table } from 'ui'
import { formatMoney } from 'utils'
import { ReactComponent as SorterIcon } from 'assets/icons/sorter.svg'
import ProgressBar from 'components/ProgressBar'
import { FadedAnimatedDiv } from 'components'
import DetailsPanel from './DetailsPanel'

const BrokersTemplate = [
  {
    userAvatar: 'A',
    firstName: 'A',
    lastName: 'A',
    email: 'A.A@gmail.com',
    brokerData: { country: 'A-CA' },
    selected: false
  },
  {
    userAvatar: 'B',
    firstName: 'B',
    lastName: 'B',
    email: 'B.B@gmail.com',
    brokerData: { country: 'B-CA' },
    selected: false
  },
  {
    userAvatar: 'C',
    firstName: 'C',
    lastName: 'C',
    email: 'C.C@gmail.com',
    brokerData: { country: 'C-CA' },
    selected: false
  },
  {
    userAvatar: '',
    firstName: 'J',
    lastName: 'J',
    email: 'j.J@gmail.com',
    brokerData: { country: 'CA' },
    selected: false
  }
]

const units = [
  {
    unitName: '# 204',
    noOfBedrooms: 4,
    noOfBathrooms: 3,
    noOfParking: 1,
    brokerName: 'Adam Anderson',
    price: 'CA$105,000,000',
    status: 'reserved'
  },
  {
    unitName: '# 204',
    noOfBedrooms: 2,
    noOfBathrooms: 1,
    noOfParking: 4,
    brokerName: 'Adam Anderson',
    price: 'CA$105,000,000',
    status: 'available'
  },
  {
    unitName: '# 204',
    noOfBedrooms: 2,
    noOfBathrooms: 2,
    noOfParking: 2,
    brokerName: 'Adam Anderson',
    price: 'CA$105,000,000',
    status: 'sold'
  }
]

const LoadingContext = createContext()

export const offerStatus = {
  SoldFirm: { text: 'Sold - Firm', backgroundColor: 'bg-green-700' },
  Signed: { text: 'Signed', backgroundColor: 'bg-blue-500' },
  OfferSent: { text: 'Offer Sent', backgroundColor: 'bg-blue-300' },
  OfferCreated: { text: 'Offer Created', backgroundColor: 'bg-gray-600' },
  OfferAccepted: { text: 'Offer Accepted', backgroundColor: 'bg-blue-300' },
  Paid: { text: 'Paid', backgroundColor: 'bg-green-900' },
  SevenDaysRescission: {
    text: 'Seven Days Rescission',
    backgroundColor: 'bg-yellow-600'
  },
  OfferRejected: { text: 'Offer Rejected', backgroundColor: 'bg-red-600' }
}

const BrokerList = ({ brokers }) => {
  return (
    <div className={'relative flex h-10'}>
      {brokers.map((broker, idx, array) => (
        <div key={idx} className='absolute' style={{ left: `${idx * 20}px` }}>
          <div
            className={
              'text-gray-400 h-12 w-12 border border-white rounded-full flex items-center justify-center bg-gray-200 text-text-gray-500 font-bold'
            }
          >
            {idx === array.length - 1 ? (
              <span>{`+ ${brokers.length + 1}`}</span>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  )
}

const TextField = ({ value }) => (
  <span className='font-light text-base text-softBlack capitalize italic'>
    {value}
  </span>
)

const ColoredStatus = ({ status }) => (
  <div
    className={`font-medium py-1 text-base text-white uppercase
        ${offerStatus[status].backgroundColor || 'bg-black'} text-center
      `}
  >
    {offerStatus[status].text}
  </div>
)

const StatisticValue = memo(({ title, value, loadingObject }) => {
  const { gettingProjectOffers, gettingstProjectStatistics } =
    useContext(LoadingContext)

  return (
    <AnimatePresence exitBeforeEnter initial>
      {gettingProjectOffers || gettingstProjectStatistics ? (
        <FadedAnimatedDiv key={`statisticValueLoading${title}`}>
          {loadingObject}
        </FadedAnimatedDiv>
      ) : (
        <FadedAnimatedDiv key={`statisticValue${title}`}>
          {value}
        </FadedAnimatedDiv>
      )}
    </AnimatePresence>
  )
})

const MemoTable = ({
  dataSource,
  columns,
  loading,
  idBeingDeleted,
  deleting
}) => {
  return (
    <Table
      className='rounded-lg'
      dataSource={dataSource}
      columns={columns}
      tailwindTable
      loading={loading}
      idBeingDeleted={idBeingDeleted}
      deleting={deleting}
    />
  )
}

const Allocations = ({ appProject, userObject }) => {
  const mountedRef = useRef(true)
  const history = useHistory()
  const [gettingProjectOffers, setGettingProjectOffers] = useState(false)
  const [gettingstProjectStatistics, setGettingProjectStatistics] =
    useState(false)
  const [projectOffers, setProjectOffers] = useState([])
  const [filterProjectOffers, setFilterProjectOffers] = useState([])
  const [idBeingDeleted, setIdBeingDeleted] = useState('')
  const [cancelingOffer, setCancelingOffer] = useState(false)
  const [sortByAllocationName, setSortByAllocationName] = useState('asc')
  const [sortByPercentageSold, setSortByPercentageSold] = useState('asc')
  const [sortByExpiration, setSortByExpiration] = useState('asc')

  useEffect(() => {
    if (projectOffers?.length > 0 && sortByAllocationName) {
      if (!mountedRef.current) return null
      setFilterProjectOffers([...sortedByAllocationName])
    }
  }, [sortByAllocationName, projectOffers])

  const sortedByAllocationName = projectOffers.sort((a, b) => {
    if (sortByAllocationName === 'asc')
      return a.buyer.name.localeCompare(b.buyer.name)
    if (sortByAllocationName === 'desc')
      return b.buyer.name.localeCompare(a.buyer.name)
    return 0
  })

  useEffect(() => {
    if (projectOffers?.length > 0 && sortByPercentageSold) {
      if (!mountedRef.current) return null
      setFilterProjectOffers([...sortedByPercentageSold])
    }
  }, [sortByPercentageSold, projectOffers])

  const sortedByPercentageSold = projectOffers.sort((a, b) => {
    if (sortByPercentageSold === 'asc')
      return a.buyer.name.localeCompare(b.buyer.name)
    if (sortByPercentageSold === 'desc')
      return b.buyer.name.localeCompare(a.buyer.name)
    return 0
  })

  useEffect(() => {
    if (projectOffers?.length > 0 && sortByExpiration) {
      if (!mountedRef.current) return null
      setFilterProjectOffers([...sortedByExpiration])
    }
  }, [sortByExpiration, projectOffers])

  const sortedByExpiration = projectOffers.sort((a, b) => {
    if (sortByExpiration === 'asc')
      return a.buyer.name.localeCompare(b.buyer.name)
    if (sortByExpiration === 'desc')
      return b.buyer.name.localeCompare(a.buyer.name)
    return 0
  })

  useEffect(() => {
    return () => {
      mountedRef.current = false
    }
  }, [])

  const projectOffersColumns = useMemo(
    () => [
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>ALLOCATION NAME</span>
            <button
              onClick={() =>
                setSortByAllocationName((prev) =>
                  prev === 'asc' ? 'desc' : 'asc'
                )
              }
            >
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'allocation_name',
        dataIndex: 'allocation_name',
        Cell: (props) => (
          <TextField value={props.cell.value || 'Sub penthouse'} />
        )
      },
      {
        Title: 'ALLOCATION METHOD',
        accessor: 'allocation_method',
        Cell: (props) => (
          <TextField value={props.cell.value || 'Multi Broker Sequenced'} />
        )
      },
      {
        Title: 'RECEIVER',
        accessor: 'receiver',
        Cell: (props) => <BrokerList brokers={BrokersTemplate} />
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>PERCENTAGE SOLD</span>
            <button
              onClick={() =>
                setSortByPercentageSold((prev) =>
                  prev === 'asc' ? 'desc' : 'asc'
                )
              }
            >
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'percentage_sold',
        dataIndex: 'percentage_sold',
        Cell: (props) => (
          <ProgressBar
            value={0.15}
            progressColor='#EDDF65'
            backgroundColor='#2E2B2E4D'
            displayPercent={true}
          />
        )
      },
      {
        Title: (
          <span className='flex items-center space-x-2'>
            <span className='leading-none'>EXPIRATION</span>
            <button
              onClick={() =>
                setSortByExpiration((prev) => (prev === 'asc' ? 'desc' : 'asc'))
              }
            >
              <SorterIcon />
            </button>
          </span>
        ),
        accessor: 'expiration',
        dataIndex: 'expiration',
        Cell: (props) => <TextField value={props.cell.value || '06/30/2022'} />
      },
      {
        Title: '',
        accessor: 'setting',
        Cell: (props) => <DetailsPanel units={units} />
      }
    ],
    []
  )

  const getData = useCallback(() => {
    if (!mountedRef.current) return null
    if (appProject) {
      setProjectOffers([])
      setGettingProjectOffers(true)
      setGettingProjectStatistics(true)

      getProjectStatistics(appProject)
        .then((statistics) => {
          if (!mountedRef.current) return null
          const { counters } = statistics
          setProjectStatistics(counters)

          setGettingProjectStatistics(false)
        })
        .catch(() => {
          setGettingProjectStatistics(false)
        })

      getProjectOffers(appProject)
        .then((offers) => {
          if (!mountedRef.current) return null
          const tmpProjectOffers = offers?.data.map((offer, index) => {
            const {
              buyer: { _id: buyerId, firstName, lastName, email },
              unit: { unitNumber, price: unitPrice },
              project: { totalDollarValueCurrencyType },
              totalCost,
              status,
              _id
            } = offer

            const tmpOffer = {
              _id,
              buyerId,
              buyer: {
                name: `${firstName} ${lastName}`.toLowerCase(),
                image: null
              },
              buyerEmail: email,
              unitNumber,
              saleAmount: formatMoney(unitPrice, totalDollarValueCurrencyType),
              amountOptionsCost: formatMoney(
                totalCost,
                totalDollarValueCurrencyType
              ),
              totalDollarValueCurrencyType,
              status
            }

            return tmpOffer
          })
          if (!mountedRef.current) return null
          setProjectOffers(tmpProjectOffers)
          setFilterProjectOffers(tmpProjectOffers)
          setGettingProjectOffers(false)
        })
        .catch(() => {
          setGettingProjectOffers(false)
        })
    }
  }, [appProject])

  useEffect(() => {
    getData()
  }, [getData])

  const values = useMemo(
    () => ({
      gettingProjectOffers,
      gettingstProjectStatistics
    }),
    [gettingProjectOffers, gettingstProjectStatistics]
  )

  return (
    <LoadingContext.Provider value={values}>
      <div className='h-full w-full flex flex-col mt-6 items-center'>
        <div className='relative w-full lg:overflow-x-hidden'>
          <MemoTable
            dataSource={filterProjectOffers.slice(0, 4)}
            columns={projectOffersColumns}
            loading={gettingProjectOffers}
            idBeingDeleted={idBeingDeleted}
            deleting={cancelingOffer}
          />
        </div>
      </div>
    </LoadingContext.Provider>
  )
}

const mapStateToProps = (state) => ({
  appProject: state.appReducer.appProject,
  userObject: state.authReducer.userObject
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Allocations)

Allocations.propTypes = {
  appProject: propTypes.string,
  userObject: propTypes.shape({
    userType: propTypes.string
  }),
  cell: propTypes.shape({
    value: propTypes.string
  }),
  row: propTypes.shape({
    original: propTypes.shape({
      _id: propTypes.string
    })
  })
}

StatisticValue.propTypes = {
  title: propTypes.string,
  value: propTypes.oneOfType([propTypes.object, propTypes.string]),
  loadingObject: propTypes.object
}

MemoTable.propTypes = {
  dataSource: propTypes.array,
  columns: propTypes.array,
  loading: propTypes.bool,
  idBeingDeleted: propTypes.string,
  deleting: propTypes.bool
}
