// @ts-nocheck
/* eslint-disable */
import { api, ApiErrors } from "services/api";

export const saveDailyReport = (reportData) =>
  new Promise((resolve, reject) => {
    const { id, ...data } = reportData;
    id
      ? api.put(`reports/daily-reports/${id}`, data).then((response) => {
          if (response.ok) {
            resolve(response.data);
          } else {
            ApiErrors(response);
            reject();
          }
        })
      : api.post(`reports/daily-reports`, data).then((response) => {
          if (response.ok) {
            resolve(response.data);
          } else {
            ApiErrors(response);
            reject();
          }
        });
  });

export const getDailyReport = (projectId) =>
  new Promise((resolve, reject) => {
    api
      .get(`reports/daily-reports/${projectId}`)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          ApiErrors(response);
          reject();
        }
      });
  });

export const getDailyReportById = (reportId = '', projectId = '', date = '', endDate = '') =>
  new Promise((resolve, reject) => {
    let url = `reports/daily-report/report/${reportId}?date=${date}&projectId=${projectId}&endDate=${endDate}`;
    if(reportId === ''){
      url = `reports/daily-report/report?date=${date}&projectId=${projectId}&endDate=${endDate}`;
    } else {
      url = `reports/daily-report/report/${reportId}?date=${date}&projectId=${projectId}&endDate=${endDate}`;
    }
    api
      .get(url)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          ApiErrors(response);
          reject();
        }
      });
  });

export const getTodayReport = (projectId, date, endDate) =>
  new Promise((resolve, reject) => {
    api
      .get(`reports/daily-report/summary/${projectId}?date=${date}&endDate=${endDate}`)
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          ApiErrors(response);
          reject();
        }
      });
  });

export const getPDFReport = (projectId, deviceId) =>
  new Promise((resolve, reject) => {
    api.get(`report/pdf/${projectId}?deviceId=${deviceId}`).then((response) => {
      if (response.ok) {
        resolve(response.data);
      } else {
        ApiErrors(response);
        reject();
      }
    });
  });
