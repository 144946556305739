import React, { useEffect, useState } from 'react'
import { PParkingIcon, TFilledEyeIcon } from '../Icons'
import standardStyle from 'assets/css/standardStyle'
import { ReactComponent as PDFIcon } from 'assets/icons/icn_file_pdf.svg'

const Button = ({ onClick, children, className = '', disabled = false }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        'px-4 py-2 border border-gray-700 text-sm rounded flex items-center justify-center hover:bg-gray-100 ' +
        className
      }
    >
      {children}
    </button>
  )
}

export const ImageUploaderBlock = ({
  imgsrc = '',
  imgTitle = '',
  imgFileType = '',
  mbSize = 1,
  edit = false,
  description,
  parking,
  onUpload = () => {},
  onDelete = () => {}
}) => {
  return (
    <div className='p-4 flex space-x-4 text-softBlack bg-white rounded  border border-dashed border-softBlack_30'>
      <div className='w-48 h-32 overflow-hidden flex items-center'>
        <img src={imgsrc} alt='Uploaded Image' className='object-cover' />
      </div>
      <div>
        <h1 className='font-bold'>{imgTitle}</h1>
        <div className='text-xs text-gray-400 space-x-2 mt-3 font-openSans flex items-center gap-1 text-black'>
          {parking && <PParkingIcon iconStyle={{ width: 20, height: 15 }} />}{' '}
          {description}
        </div>
        {!parking && (
          <div className='text-xs text-gray-400 space-x-2 mt-3 font-openSans'>
            <span className='font-openSans'>{imgFileType}</span>
            <span>•</span>
            <span className='font-openSans'>{mbSize}MB</span>
          </div>
        )}
        <div className='mt-6 space-x-4 flex'>
          <Button onClick={onUpload} className='font-openSans italic'>
            {!edit
              ? (
              <svg
                width='12'
                height='15'
                viewBox='0 0 12 15'
                className='mr-3 font-openSans italtic'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.5013 11.1488H8.5013V6.14876H11.8346L6.0013 0.31543L0.167969 6.14876H3.5013V11.1488ZM0.167969 12.8154H11.8346V14.4821H0.167969V12.8154Z'
                  fill='#2E2B2E'
                />
              </svg>
                )
              : (
              <svg
                width={20}
                height={21}
                fill='none'
                style={{ marginRight: '8px' }}
                xmlns='http://www.w3.org/2000/svg'
              >
                <g clipPath='url(#a)'>
                  <path
                    d='M2.5 14.875V18h3.125l9.217-9.217-3.125-3.125L2.5 14.875Zm14.758-8.508a.83.83 0 0 0 0-1.175l-1.95-1.95a.83.83 0 0 0-1.175 0l-1.525 1.525 3.125 3.125 1.525-1.525Z'
                    fill='#2E2B2E'
                  />
                </g>
                <defs>
                  <clipPath id='a'>
                    <path
                      fill='#fff'
                      transform='translate(0 .5)'
                      d='M0 0h20v20H0z'
                    />
                  </clipPath>
                </defs>
              </svg>
                )}
            {edit ? 'Edit' : 'Upload'}
          </Button>
          <Button onClick={onDelete} className='font-openSans italic'>
            <svg
              width='12'
              height='16'
              viewBox='0 0 12 16'
              className='mr-3'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.0013 13.6488C1.0013 14.5654 1.7513 15.3154 2.66797 15.3154H9.33464C10.2513 15.3154 11.0013 14.5654 11.0013 13.6488V3.64876H1.0013V13.6488ZM11.8346 1.14876H8.91797L8.08464 0.31543H3.91797L3.08464 1.14876H0.167969V2.81543H11.8346V1.14876Z'
                fill='#2E2B2E'
              />
            </svg>
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}

export const ImageUploaderPrimary = ({
  onUseCamera = () => {},
  imgsrc = '',
  imgTitle = '',
  imgFileType = '',
  mbSize = '',
  imgSize = 'normal',
  onFileChange = (e) => e,
  onUpload = () => {},
  onDelete = () => {},
  value = '',
  allowedFileTypeSizeText = '',
  setValue = (prev) => prev,
  uploader = false,
  isDeleted = false,
  disabled = false,
  isDeleteBtnShow = true,
  allowedFileType = '.jpg,.png,.jpeg,.pdf'
}) => {
  const inputRef = React.useRef(null)
  const [file, setFile] = useState('')
  const handleChange = (e) => {
    setValue(e?.target?.value || '')
    onFileChange(e)
    setFile(e.target.files[0])
  }

  useEffect(() => {
    if (isDeleted) {
      setFile('')
    }
  }, [isDeleted])
  return (
    <>
      {(file === '' && imgsrc === '') || uploader
        ? (
        <div className='flex flex-col items-center p-6 bg-white font-openSans rounded border border-dashed border-softBlack_30'>
          <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M21.8333 7.33329V11.32C21.8333 11.32 19.18 11.3333 19.1667 11.32V7.33329H15.1667C15.1667 7.33329 15.18 4.67996 15.1667 4.66663H19.1667V0.666626H21.8333V4.66663H25.8333V7.33329H21.8333ZM17.8333 12.6666V8.66663H13.8333V4.66663H3.16667C1.7 4.66663 0.5 5.86663 0.5 7.33329V23.3333C0.5 24.8 1.7 26 3.16667 26H19.1667C20.6333 26 21.8333 24.8 21.8333 23.3333V12.6666H17.8333ZM3.16667 23.3333L7.16667 18L9.83333 22L13.8333 16.6666L19.1667 23.3333H3.16667Z'
              fill='#2E2B2E'
            />
          </svg>
          <h1 className='font-light text-sm mt-4 font-openSans italic'>
            {value || 'Drag & Drop Files Here'}
          </h1>
          <p className='mt-2 text-xs text-gray-500 font-openSans'>
            {allowedFileTypeSizeText || 'JPG, JPEG, PNG, PDF, max size 10 MB'}
          </p>
          <div className='font-semibold font-openSans'>
            <button
              disabled={disabled}
              onClick={() => inputRef.current.click()}
              className='px-8 py-2 border border-softBlack_70 font-light italic mt-1 font-openSans'
              style={{
                borderRadius: '4px',
                border: '1px solid rgba(46, 43, 46, 0.7)'
              }}
            >
              <input
                type='file'
                className='hidden'
                ref={inputRef}
                onChange={handleChange}
                accept={allowedFileType}
              />
              Browse
            </button>
          </div>
        </div>
          )
        : (
        <div
          className={`p-4 flex ${
            imgSize === 'fullWidth' ? 'flex-col' : 'space-x-4'
          }  text-softBlack bg-white rounded  border border-dashed border-softBlack_30`}
        >
          <div
            className={`${
              imgSize === 'fullWidth' ? 'w-full h-40' : 'w-48 h-32'
            } overflow-hidden flex items-center`}
          >
            {file?.type === 'application/pdf'
              ? (<div className='w-full flex justify-center items-center gap-4'>
                <PDFIcon className='w-[80px] h-[80px]' />
              </div>)
              : (<img
                src={(file && URL.createObjectURL(file)) || imgsrc}
                alt='Uploaded Image'
                className='object-cover'
              />)}
          </div>
          <div
            className={`${
              imgSize === 'fullWidth' ? 'flex flex-col justify-between' : ''
            }`}
          >
            <div
              className={`${
                imgSize === 'fullWidth'
                  ? 'mt-6 flex flex-col items-start justify-start'
                  : ''
              }`}
            >
              <h1 className='font-bold text-lg'>{imgTitle || file?.name}</h1>
              <div className='text-xs text-gray-400 space-x-2 mt-3 font-openSans'>
                <span className='font-openSans'>{imgFileType}</span>
                {(file || mbSize !== '') && (
                  <>
                    <span>•</span>
                    <span className='font-openSans'>
                      {(Math.round((file?.size / 100000) * 10) / 10 || mbSize) +
                        'MB'}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className='mt-6 space-x-4 flex justify-center'>
              <Button
                onClick={() => inputRef.current.click()}
                disabled={disabled}
                className='font-openSans italic'
              >
                <svg
                  width='12'
                  height='15'
                  viewBox='0 0 12 15'
                  className='mr-3 font-openSans italtic'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M3.5013 11.1488H8.5013V6.14876H11.8346L6.0013 0.31543L0.167969 6.14876H3.5013V11.1488ZM0.167969 12.8154H11.8346V14.4821H0.167969V12.8154Z'
                    fill='#2E2B2E'
                  />
                </svg>
                <input
                  type='file'
                  className='hidden'
                  ref={inputRef}
                  onChange={handleChange}
                  accept='.jpg,.png,.jpeg,.pdf'
                />
                Upload
              </Button>
              {
                isDeleteBtnShow &&
                <Button
                  onClick={onDelete}
                  disabled={disabled}
                  className='font-openSans italic'
                >
                  <svg
                    width='12'
                    height='16'
                    viewBox='0 0 12 16'
                    className='mr-3'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M1.0013 13.6488C1.0013 14.5654 1.7513 15.3154 2.66797 15.3154H9.33464C10.2513 15.3154 11.0013 14.5654 11.0013 13.6488V3.64876H1.0013V13.6488ZM11.8346 1.14876H8.91797L8.08464 0.31543H3.91797L3.08464 1.14876H0.167969V2.81543H11.8346V1.14876Z'
                      fill='#2E2B2E'
                    />
                  </svg>
                  Delete
                </Button>
              }
            </div>
          </div>
        </div>
          )}
    </>
  )
}

export const ImageUploaderBrowse = ({
  onUseCamera = () => {},
  onFileChange = (e) => e,
  value = '',
  setValue = (prev) => prev
}) => {
  const inputRef = React.useRef(null)

  const handleChange = (e) => {
    setValue(e?.target?.value || '')
    onFileChange(e)
  }
  return (
    <div className='flex flex-col items-center p-6 bg-white rounded'>
      <svg
        width='26'
        height='26'
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M21.8333 7.33329V11.32C21.8333 11.32 19.18 11.3333 19.1667 11.32V7.33329H15.1667C15.1667 7.33329 15.18 4.67996 15.1667 4.66663H19.1667V0.666626H21.8333V4.66663H25.8333V7.33329H21.8333ZM17.8333 12.6666V8.66663H13.8333V4.66663H3.16667C1.7 4.66663 0.5 5.86663 0.5 7.33329V23.3333C0.5 24.8 1.7 26 3.16667 26H19.1667C20.6333 26 21.8333 24.8 21.8333 23.3333V12.6666H17.8333ZM3.16667 23.3333L7.16667 18L9.83333 22L13.8333 16.6666L19.1667 23.3333H3.16667Z'
          fill='#2E2B2E'
        />
      </svg>
      <h1 className='font-semibold mt-4'>
        {value || 'Drag & Drop Files Here'}
      </h1>
      <p className='mt-2 text-xs text-gray-500'>Only PDF, max size 10 MB</p>
      <div className='grid grid-cols-2 gap-3 mt-5 font-semibold'>
        <Button onClick={() => inputRef.current.click()}>
          <input
            type='file'
            className='hidden'
            ref={inputRef}
            onChange={handleChange}
          />
          Browse
        </Button>
        <Button onClick={onUseCamera}>Use Camera</Button>
      </div>
    </div>
  )
}

export const ImageUploaderWide = ({
  imgsrc = '',
  imgTitle = '',
  imgFileType = '',
  mbSize = 1,
  onUpload = () => {},
  onDelete = () => {}
}) => {
  return (
    <div className='p-4 flex flex-col space-y-4 text-softBlack bg-white rounded'>
      <div className='w-full h-32 overflow-hidden flex items-center'>
        <img src={imgsrc} alt='Uploaded Image' className='object-cover' />
      </div>
      <div className='flex justify-between items-center'>
        <div>
          <h1 className='font-bold'>{imgTitle}</h1>
          <div className='text-xs text-gray-400 space-x-2 mt-2'>
            <span>{imgFileType}</span>
            <span>•</span>
            <span>{mbSize}MB</span>
          </div>
        </div>
        <div className='space-x-4 flex'>
          <Button onClick={onUpload}>
            <svg
              width='12'
              height='15'
              viewBox='0 0 12 15'
              className='mr-3'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.5013 11.1488H8.5013V6.14876H11.8346L6.0013 0.31543L0.167969 6.14876H3.5013V11.1488ZM0.167969 12.8154H11.8346V14.4821H0.167969V12.8154Z'
                fill='#2E2B2E'
              />
            </svg>
            Upload
          </Button>
          <Button onClick={onDelete}>
            <svg
              width='12'
              height='16'
              viewBox='0 0 12 16'
              className='mr-3'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M1.0013 13.6488C1.0013 14.5654 1.7513 15.3154 2.66797 15.3154H9.33464C10.2513 15.3154 11.0013 14.5654 11.0013 13.6488V3.64876H1.0013V13.6488ZM11.8346 1.14876H8.91797L8.08464 0.31543H3.91797L3.08464 1.14876H0.167969V2.81543H11.8346V1.14876Z'
                fill='#2E2B2E'
              />
            </svg>
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}

export const ImageUploaderViewerWide = ({
  imgsrc = '',
  onView = () => {},
  onDelete = (e) => {},
  onFileChange = (e) => e,
  onUpload = () => {},
  value = '',
  allowedFileTypeSizeText = '',
  setValue = (prev) => prev
}) => {
  const inputRef = React.useRef(null)
  const [file, setFile] = useState('')
  const handleChange = (e) => {
    setValue(e?.target?.value || '')
    onFileChange(e)
    setFile(e.target.files[0])
  }
  return (
    <>
      {file === '' && imgsrc === ''
        ? (
        <div className='flex flex-col items-center p-6 bg-white font-openSans rounded border border-dashed border-softBlack_30'>
          <svg
            width='26'
            height='26'
            viewBox='0 0 26 26'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M21.8333 7.33329V11.32C21.8333 11.32 19.18 11.3333 19.1667 11.32V7.33329H15.1667C15.1667 7.33329 15.18 4.67996 15.1667 4.66663H19.1667V0.666626H21.8333V4.66663H25.8333V7.33329H21.8333ZM17.8333 12.6666V8.66663H13.8333V4.66663H3.16667C1.7 4.66663 0.5 5.86663 0.5 7.33329V23.3333C0.5 24.8 1.7 26 3.16667 26H19.1667C20.6333 26 21.8333 24.8 21.8333 23.3333V12.6666H17.8333ZM3.16667 23.3333L7.16667 18L9.83333 22L13.8333 16.6666L19.1667 23.3333H3.16667Z'
              fill='#2E2B2E'
            />
          </svg>
          <h1 className='font-light text-sm mt-4 font-openSans italic'>
            {value || 'Drag & Drop Files Here'}
          </h1>
          <p className='mt-2 text-xs text-gray-500 font-openSans'>
            {allowedFileTypeSizeText || 'JPG, JPEG, PNG, PDF, max size 10 MB'}
          </p>
          <div className='font-semibold font-openSans'>
            <button
              onClick={() => inputRef.current.click()}
              className='px-8 py-2 border border-softBlack_70 font-light italic mt-1 font-openSans'
              style={{
                borderRadius: '4px',
                border: '1px solid rgba(46, 43, 46, 0.7)'
              }}
            >
              <input
                type='file'
                className='hidden'
                ref={inputRef}
                onChange={handleChange}
                accept='.jpg,.png,.jpeg,.pdf'
              />
              Browse
            </button>
          </div>
        </div>
          )
        : (
        <div className='p-4 flex flex-col space-y-4 text-softBlack bg-white rounded border border-dashed border-softBlack_30'>
          <div className='w-full h-60 overflow-hidden flex items-start relative'>
            <div
              className='absolute w-full h-full'
              style={{ backgroundColor: standardStyle.colors.darkGray30 }}
            ></div>
            <img
              src={(file && URL.createObjectURL(file)) || imgsrc}
              alt='Uploaded Image'
              className='object-cover'
            />
            <div className='space-x-4 flex items-end justify-end absolute right-2 top-2 w-full p-6'>
              <button
                onClick={onView}
                className='w-9 h-9 flex items-center justify-center'
                style={{
                  backgroundColor: standardStyle.colors.white30,
                  borderRadius: '4px'
                }}
              >
                <TFilledEyeIcon className='w-5 h-5' />
              </button>
              <button
                onClick={onDelete}
                className='w-9 h-9 flex items-center justify-center'
                style={{
                  backgroundColor: standardStyle.colors.white30,
                  borderRadius: '4px'
                }}
              >
                <svg
                  width='12'
                  height='16'
                  viewBox='0 0 12 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M1.0013 13.6488C1.0013 14.5654 1.7513 15.3154 2.66797 15.3154H9.33464C10.2513 15.3154 11.0013 14.5654 11.0013 13.6488V3.64876H1.0013V13.6488ZM11.8346 1.14876H8.91797L8.08464 0.31543H3.91797L3.08464 1.14876H0.167969V2.81543H11.8346V1.14876Z'
                    fill='#2E2B2E'
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
          )}
    </>
  )
}
