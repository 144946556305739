import React from 'react'

import { Check } from 'lucide-react'

const Checkbox = ({ checked = false, label, handleChange = () => { }, containerClasses = '', labelClasses = '', iconClasses = '' }) => {
  return (
    <div className={`flex items-center ${containerClasses}`} onClick={handleChange}>
      {checked
        ? <div className={`w-4 h-4 bg-black dark:bg-white shadow flex justify-center items-center cursor-pointer mr-2.5 transition duration-200 rounded ${iconClasses}`}>
          <Check className='w-3 h-3 text-white dark:text-black' />
        </div>
        : <div className={`cursor-pointer w-4 h-4 shadow border border-zinc-900 dark:border-zinc-100 rounded mr-2.5 transition duration-200 bg-white dark:bg-black ${iconClasses}`} />}
      {label && (
        <label className={`m-0 text-base text-softBlack dark:text-darkModeText font-medium ${labelClasses}`}>
          {label}
        </label>
      )}
    </div>
  )
}

export default Checkbox
