/* eslint-disable */
import React from 'react'
import * as ReactFlow from '@xyflow/react'
import Container from '../Container'
import humanizeDuration from 'humanize-duration'
const { Handle, Position } = ReactFlow

export default function DelayActionNode(_data) {
    const selected = _data.selected
    const data = _data.data
    const config = data.config
    const inputValues = data.inputValues ?? {}
    const delay = inputValues?.delay || 0
    return (
        <Container
            _data={_data}
            config={config} selected={selected} >
            <Handle type="target" position={Position.Top} />
            <div className='flex items-center space-x-1 mt-0.5' style={{
                fontSize: 8,
            }}>
                Will run after &nbsp;
                <span className='font-semibold'>{humanizeDuration(delay * 1000)}</span>
            </div>
            <Handle type="source" position={Position.Bottom} />
        </Container>
    )
}
