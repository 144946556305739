import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getProjectOffers } from 'store/actions/offerActions'
import { getUserActivityOffers } from 'store/actions/userActivityActions'

export const useGetOffers = (projectId, filters = {}) => {
  const [refetchIndex, setRefetchIndex] = useState(0)

  const { data, isLoading, refetch } = useQuery(
    ['get-project-offers', { ...filters, refetchIndex }],
    () => getProjectOffers(projectId, filters),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!projectId }
  )

  const forceRefetch = () => setRefetchIndex((prev) => prev + 1)

  return { offers: data?.data ?? null, totalRecords: data?.totalRecords ?? 0, isLoading, refetch, forceRefetch }
}

export const useGetUsersOfferActivity = (projectId, filters = {}) => {
  const [refetchIndex, setRefetchIndex] = useState(0)

  const { data, isLoading, refetch } = useQuery(
    ['get-users-offer-activity', { ...filters, refetchIndex }],
    () => getUserActivityOffers(projectId, filters),
    { refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false, retry: false, enabled: !!projectId }
  )

  const forceRefetch = () => setRefetchIndex((prev) => prev + 1)

  return { usersOfferActivity: data?.data ?? null, totalRecords: data?.totalRecords ?? 0, isLoading, refetch, forceRefetch }
}
